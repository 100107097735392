import { Button, Divider, Drawer, Input, Layout, message, Popconfirm, Radio, Select, Space, Spin, Table } from "antd";
import PageHeader from "../../comps/pageHeader";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    FilterFilled
} from '@ant-design/icons';
import { fetchContract, SuspendClientFromContract } from "./contractSlice";
import { setUpdateClientSuccess, UpdateClient } from "./cactusProSlice";

const ContractClientList = () => {

    const { id } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [updateData, setUpdateData] = useState({
        id: null,
        name: "",
        email: "",
        type: null,
        password: null,
        referer: "",
        phoneNumber: "",
    });

    const singleContractData = useSelector((state) => state.contract.singleContractSuccess);
    const isLoadingContractData = useSelector((state) => state.contract.isFetchingSingleContract);
    const isSuspendingSingleClientFromContract = useSelector((state) => state.contract.isSuspendingSingleClientFromContract);
    const suspendSingleClientFromContractSuccess = useSelector((state) => state.contract.suspendSingleClientFromContractSuccess);
    const isLoadingUpdate = useSelector((state) => state.cactusPro.isUpdatingClient);
    const updateClientStatus = useSelector((state) => state.cactusPro.updateClientSuccess);

    const establishmentTypesOptionsFrench = {
        school: 'École',
        association: 'Association',
        library: 'Bibliothèque',
        footballTeam: 'Équipe de football',
        company: 'Entreprise',
        townHall: 'Mairie',
        academy: 'Académie',
        federation: 'Fédération'
    };

    const establishmentTypes = [
        {
            value: 'school',
            label: 'École',
        },
        {
            value: 'association',
            label: 'Association',
        },
        {
            value: 'library',
            label: 'Bibliothèque',
        },
        {
            value: 'footballTeam',
            label: 'Équipe de football',
        },
        {
            value: 'company',
            label: 'Entreprise',
        },
        {
            value: 'townHall',
            label: 'Mairie',
        },
        {
            value: 'academy',
            label: 'Académie',
        },
        {
            value: 'federation',
            label: 'Fédération',
        },
    ]

    const [contractsFilter, setContractsFilter] = useState({
        name: "",
        establishmentTypesOptions: null,
        contractType: 'grouped',
        pageLength: 10,
        page: 1,

    });

    useEffect(() => {
        dispatch(fetchContract({ contractId: id }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!suspendSingleClientFromContractSuccess) {
            return;
        }
        message.success("Client suspendu avec succès !");
        dispatch(fetchContract({ contractId: id }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suspendSingleClientFromContractSuccess])

    useEffect(() => {
        if (!updateClientStatus) {
            return;
        }

        dispatch(setUpdateClientSuccess(null));
        dispatch(fetchContract({ contractId: id }));
        setUpdateData({
            id: null,
            name: "",
            email: "",
            type: null,
            password: null,
            referer: "",
            phoneNumber: "",
        });
        setDrawerOpen(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateClientStatus])

    const onUpdateUser = () => {
        dispatch(UpdateClient(updateData));
    }

    const getColumnFilterTypeProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Radio.Group onChange={(e) => setContractsFilter({ ...contractsFilter, establishmentTypesOptions: e.target.value })}
                    value={contractsFilter.establishmentTypesOptions}>
                    <Space direction="vertical">
                        <Radio value={"school"}>École</Radio>
                        <Radio value={"association"}>Association</Radio>
                        <Radio value={"library"}>Bibliothèque</Radio>
                        <Radio value={"footballTeam"}>Équipe de football</Radio>
                        <Radio value={"company"}>Entreprise</Radio>
                        <Radio value={"townHall"}>Mairie</Radio>
                        <Radio value={"academy"}>Académie</Radio>
                        <Radio value={"federation"}>Fédération</Radio>
                        <Radio value={null}>Tout</Radio>
                    </Space>
                </Radio.Group>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        close();
                    }}
                >
                    Fermer
                </Button>
            </div>),
        filterIcon: (filtered) => (<FilterFilled
            style={{
                color: filtered ? '#39B13D' : undefined,
            }}
        />),
    });

    const columns = [

        {
            title: 'Nom de l’établissement',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            render: (name) => name,
            // ...getColumnNameSearchProps()
        },
        {
            title: 'Réferant',
            dataIndex: 'ReferentName',
            key: 'ReferentName',
            width: '20%',
            render: (referent) => referent,
            // ...getColumnNameSearchProps()
        },
        {
            title: 'Adresse Mail',
            dataIndex: 'email',
            key: 'email',
            width: '20%',
            render: (email) => email,
            // ...getColumnNameSearchProps()
        },
        {
            title: 'Date d’expiration',
            dataIndex: 'endDate',
            key: 'endDate',
            render: (data) => (<p>{moment(singleContractData?.contract.endDate).format("DD/MM/YYYY")}</p>),
            width: '17%',

        },

        {
            title: 'Type',
            dataIndex: 'type',
            key: 'establishmentTypesOptions',
            render: (text, record) => establishmentTypesOptionsFrench[text],
            width: '18%',
            ...getColumnFilterTypeProps()
        },
        {
            title: 'Nbr de codes',
            dataIndex: 'subscriptionCount',
            key: 'totalSubscriptions',
            width: '18%',
            render: (totalSubs) => totalSubs

        },
        {
            title: "Actions",
            width: "25%",
            align: "center",
            dataIndex: "_id",
            key: "_id",
            render: (_id, record) => {
                return (
                    <Space split={<Divider type="vertical" />}>


                        <Button
                            type="link"
                            style={{ color: "#39B13D", padding: 0 }}

                            size="small"
                            onClick={(e) => {
                                setUpdateData({
                                    id: _id,
                                    email: record.email,
                                    name: record.name,
                                    type: record.type,
                                    password: record.password,
                                    referer: record.ReferentName,
                                    phoneNumber: record.phoneNumber
                                });
                                setDrawerOpen(true);
                            }}
                        >
                            Modifier
                        </Button>
                        <Popconfirm
                            title="Attention !"
                            description="Voulez-vous vraiment suspendre ce client ?"
                            okText={isSuspendingSingleClientFromContract ? <Spin /> : "Oui"}
                            cancelText="Non"
                            onConfirm={() => dispatch(SuspendClientFromContract({ id: _id, contractId: singleContractData?.contract._id }))}
                        >
                            <Button
                                type="link"
                                style={{ color: "#39B13D", padding: 0 }}

                                color='green'
                                size="small"

                            >
                                Suspendre
                            </Button>
                        </Popconfirm>
                    </Space>

                );
            },
        },


    ];

    return (
        <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
            <Layout>
                <PageHeader title={singleContractData ? singleContractData?.contract.name : "Chargement..."} breadcrumbItems={["Panneau Clients Pro", "Vue d'ensemble"]} />
                <div style={{
                    margin: "2em",
                    marginTop: "2em",
                    padding: "2em 10vw 2em 10vw",
                    display: "flex",
                    direction: "column",
                    justifyContent: "center",
                    backgroundColor: "#fff",
                }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "30%" }}>
                        <h3 style={{ fontSize: "18px", color: "#00000073" }}>Total de Clients Pro</h3>
                        <h1 style={{ fontSize: "28px", width: "fit-content", margin: "5px 5px 5px 0px" }}>{singleContractData?.contract.numberOfClientPros}</h1>
                    </div>
                    <div style={{ display: "flex", marginLeft: "24px", marginRight: "24px", width: "30%", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <h3 style={{ fontSize: "18px", color: "#00000073" }}>Dernier Client Inscrit le</h3>
                        <h1 style={{ fontSize: "28px", margin: "5px 5px 5px 0px" }}>{moment(singleContractData?.contract.lastClientProCreatedAt).format("DD/MM/YYYY")}</h1>
                    </div>
                    <div style={{ display: "flex", marginLeft: "24px", marginRight: "24px", width: "30%", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <h3 style={{ fontSize: "18px", color: "#00000073" }}>Nombre total de codes générés</h3>
                        <h1 style={{ fontSize: "28px", margin: "5px 5px 5px 0px" }}>{singleContractData?.contract.totalSubscriptions}</h1>
                    </div>
                </div>

                <div style={{
                    margin: "2em",
                    marginTop: "0em",
                    padding: "2em 2em 3em 2em",
                    display: "flex",
                    direction: "column",
                    justifyContent: "space-around",
                    backgroundColor: "#424242"
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <p style={{ color: "#fff", fontWeight: "600" }}>Ajouter un établissement :</p>
                        <Button type='primary'
                            onClick={() => navigate(`/dashboard/pro/contracts/addtoclientcontract/${id}`)}
                            style={{ width: "77px", height: "32px" }}> Ajouter</Button>
                    </div>

                </div>

                <div
                    style={{
                        margin: "2em",
                        padding: "1em 2em 1em 2em",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        backgroundColor: "#fff"
                    }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}>
                        <h3>Liste des Clients Pro</h3>
                    </div>
                    <Divider />
                    <Table loading={isLoadingContractData} columns={columns} dataSource={singleContractData?.contract.clientsPro} />
                </div>

                <Drawer
                    title={
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                            <h3 style={{ margin: "0px 16px 0px 0px" }}>Modifier un client pro</h3>
                            <div>
                                <Button style={{ marginRight: "16px" }} onClick={() => { setDrawerOpen(false); }}>Annuler</Button>
                                <Button type='primary' onClick={onUpdateUser}>{isLoadingUpdate ? <Spin className='proClientSpinner' /> : "Sauvegarder"}</Button>
                            </div>
                        </div>
                    }
                    width={750}
                    placement={"right"}
                    closable={true}
                    onClose={() => setDrawerOpen(false)}
                    open={drawerOpen}
                    key={"right"}
                >
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "2em" }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", width: "45%" }}>

                            <p style={{ margin: "5px 0px" }}>Nom de l’établissement</p>
                            <Input
                                placeholder='Collège'
                                value={updateData.name}
                                onChange={(e) => setUpdateData({ ...updateData, name: e.target.value })}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", width: "45%" }}>
                            <p style={{ margin: "5px 0px" }}>Type</p>
                            <Select style={{ width: "100%" }} value={updateData.type} options={establishmentTypes} onChange={(e) => setUpdateData({ ...updateData, type: e })}>
                            </Select>
                        </div>

                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "2em" }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", width: "45%" }}>

                            <p style={{ margin: "5px 0px" }}>Nom du référant</p>
                            <Input
                                placeholder='John Doe'
                                value={updateData.referer}
                                onChange={(e) => setUpdateData({ ...updateData, referer: e.target.value })}
                            />
                        </div>

                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", width: "45%" }}>

                            <p style={{ margin: "5px 0px" }}>Numéro de téléphone</p>
                            <Input
                                placeholder='+33 XXXX XXXX'
                                value={updateData.phoneNumber}
                                onChange={(e) => setUpdateData({ ...updateData, phoneNumber: e.target.value })}
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", width: "45%" }}>

                            <p style={{ margin: "5px 0px" }}>Email</p>
                            <Input
                                placeholder='mail@entreprise.com'
                                value={updateData.email}
                                onChange={(e) => setUpdateData({ ...updateData, email: e.target.value })}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "45%" }}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", width: "100%" }}>
                                <p style={{ margin: "5px 0px" }}>Mot de Passe</p>
                                <Input
                                    placeholder='Mot de passe'
                                    value={updateData.password}
                                    rules={[
                                        {
                                            required: true,
                                            // type: "regexp",
                                            pattern: new RegExp(/(?=^.{8,12}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/g),
                                            message: 'Veuillez insérer un mot de passe valide',
                                        },
                                    ]}
                                    onChange={(e) => setUpdateData({ ...updateData, password: e.target.value })}
                                />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", width: "100%" }}>
                                <p style={{ color: "#00000073", margin: "5px 0px" }}>Le Mot de Passe doit comporter au moins :</p>
                                <ul style={{ paddingLeft: "16px", marginTop: 0 }}>
                                    <li style={{ color: "#00000073" }}>De 8 à 12 caractères</li>
                                    <li style={{ color: "#00000073" }}>Au moins 1 minuscule</li>
                                    <li style={{ color: "#00000073" }}>Au moins 1 majuscule</li>
                                    <li style={{ color: "#00000073" }}>Au moins 1 chiffre</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </Drawer>
            </Layout>
        </Space>
    )
};



export default ContractClientList;