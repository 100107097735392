import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";
import { createCodesUrl, getAllClientNamesUrl, getCodesListUrl } from "../../constants";
import { setfetchServerErr } from "../admins/adminsSlice";

export const ActivationCodesSlice = createSlice({
    name: "activationCodes",
    initialState: {
        // Code lsit
        fetchCodesSuccess: null,
        fetchCodesFailure: null,
        isFetchingCodes: false,

        // Generate codes
        clientIdToGenerateCodes: null,
        isGeneratingCodes: false,
        generateCodesSuccess: null,
        generateCodesFailure: null,

        // Fetch all client names
        fetchClientNamesSuccess: null,
        fetchClientNamesFailure: null,
        isFetchingClientNames: false,
    },
    reducers: {
        // Fetch codes list
        setFetchCodesSuccess: (state, action) => {
            state.fetchCodesSuccess = action.payload;
            state.fetchCodesFailure = null;
            state.isFetchingCodes = false;
        },
        setFetchCodesFailure: (state, action) => {
            state.fetchCodesFailure = action.payload;
            state.fetchCodesSuccess = [];
            state.isFetchingCodes = false;
        },
        setFetchCodesStarted: (state, action) => {
            state.isFetchingCodes = true;
        },
        // Generate codes
        setClientIdToGenerateCodes: (state, action) => {
            state.clientIdToGenerateCodes = action.payload;
        },
        setGenerateCodesSuccess: (state, action) => {
            state.generateCodesSuccess = action.payload;
            state.generateCodesFailure = null;
            state.isGeneratingCodes = false;
        },
        setGenerateCodesFailure: (state, action) => {
            state.generateCodesFailure = action.payload;
            state.generateCodesSuccess = [];
            state.isGeneratingCodes = false;
        },
        setGenerateCodesStarted: (state, action) => {
            state.isGeneratingCodes = true;
        },
        // Fetch all client names
        setFetchAllClientNamesSuccess: (state, action) => {
            state.fetchClientNamesSuccess = action.payload;
            state.fetchClientNamesFailure = null;
            state.isFetchingClientNames = false;
        },
        setFetchAllClientNamesFailure: (state, action) => {
            state.fetchClientNamesFailure = action.payload;
            state.fetchClientNamesSuccess = [];
            state.isFetchingClientNames = false;
        },
        setFetchAllClientNamesStarted: (state, action) => {
            state.isFetchingClientNames = true;
        },
    }
});

export const {
    // Codes
    setFetchCodesSuccess,
    setFetchCodesFailure,
    setFetchCodesStarted,
    // Generate codes
    setClientIdToGenerateCodes,
    setGenerateCodesSuccess,
    setGenerateCodesFailure,
    setGenerateCodesStarted,
    // Fetch client names
    setFetchAllClientNamesSuccess,
    setFetchAllClientNamesFailure,
    setFetchAllClientNamesStarted,
} = ActivationCodesSlice.actions;

export const getCodesList = (data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: getCodesListUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token
        },
        data
    };
    console.log(data);
    dispatch(setFetchCodesStarted());
    axios(config)
        .then((response) => {
            dispatch(setFetchCodesSuccess(response.data));
        })
        .catch((error) => {
            if (!error.response) {
                dispatch(setfetchServerErr(true));
                dispatch(setFetchCodesFailure("Le serveur ne répond pas, veuillez réessayer plus tard."))
                return;
            }
            if (error.response.status === 401) {
                message.error("Vous êtes déconnecté, veuillez vous reconnecter.");
                dispatch(setFetchCodesFailure("Vous n'êtes pas autorisé à faire cette action."));
                return;
            }
            message.error(error.response.data.message);
            dispatch(setFetchCodesFailure(error.response.data.message));
        });
}

export const createCodes = (data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: createCodesUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token
        },
        data
    };
    console.log(data);
    dispatch(setGenerateCodesStarted());
    axios(config)
        .then((response) => {
            dispatch(setGenerateCodesSuccess(response.data));
        })
        .catch((error) => {
            if (!error.response) {
                dispatch(setfetchServerErr(true));
                dispatch(setGenerateCodesFailure("Le serveur ne répond pas, veuillez réessayer plus tard."))
                return;
            }
            if (error.response.status === 401) {
                message.error("Vous êtes déconnecté, veuillez vous reconnecter.");
                dispatch(setGenerateCodesFailure("Vous n'êtes pas autorisé à faire cette action."));
                return;
            }
            message.error(error.response.data.message);
            dispatch(setGenerateCodesFailure(error.response.data.message));
        });
}

export const getClientNames = () => (dispatch, getState) => {
    const config = {
        method: "post",
        url: getAllClientNamesUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token
        },
        
    };
    dispatch(setFetchAllClientNamesStarted());
    axios(config)
        .then((response) => {
            dispatch(setFetchAllClientNamesSuccess(response.data));
        })
        .catch((error) => {
            if (!error.response) {
                dispatch(setfetchServerErr(true));
                dispatch(setFetchAllClientNamesFailure("Le serveur ne répond pas, veuillez réessayer plus tard."))
                return;
            }
            if (error.response.status === 401) {
                message.error("Vous êtes déconnecté, veuillez vous reconnecter.");
                dispatch(setFetchAllClientNamesFailure("Vous n'êtes pas autorisé à faire cette action."));
                return;
            }
            message.error(error.response.data.message);
            dispatch(setFetchAllClientNamesFailure(error.response.data.message));
        });
}

export default ActivationCodesSlice.reducer;