import Icon from '@ant-design/icons';

const Calendar = () => (<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Iconly/Light/Calendar">
        <g id="Calendar">
            <path id="line-200" d="M3.09277 9.40421H20.9167" stroke="#39B13D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path id="line-201" d="M16.442 13.3097H16.4512" stroke="#39B13D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path id="line-202" d="M12.0045 13.3097H12.0137" stroke="#39B13D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path id="line-203" d="M7.55818 13.3097H7.56744" stroke="#39B13D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path id="line-204" d="M16.442 17.1962H16.4512" stroke="#39B13D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path id="line-205" d="M12.0045 17.1962H12.0137" stroke="#39B13D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path id="line-206" d="M7.55818 17.1962H7.56744" stroke="#39B13D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path id="line-207" d="M16.0433 2V5.29078" stroke="#39B13D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path id="line-208" d="M7.96515 2V5.29078" stroke="#39B13D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path id="path" fillRule="evenodd" clipRule="evenodd" d="M16.2383 3.57922H7.77096C4.83427 3.57922 3 5.21516 3 8.22225V17.2719C3 20.3263 4.83427 22 7.77096 22H16.229C19.175 22 21 20.3546 21 17.3475V8.22225C21.0092 5.21516 19.1842 3.57922 16.2383 3.57922Z" stroke="#39B13D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
    </g>
</svg>
)

export default function CalendarIcon(props) {
    const MyIcon =(props) => <Icon component={Calendar} {...props}/>;
    return (
        <MyIcon style={{
            width:"30px",
            height:"30px"
        }}/>
    )
}