import { Row, Col, Typography, Layout, Card, Form, InputNumber, Space, Image, Button } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../comps/pageHeader';
import { editAppParams, fetchappPrams } from './appPramsSlice';


const { Text } = Typography;


const { Content } = Layout;

const AppPrams = ({ title, breadcrumbItems }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchappPrams());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const [appParamsForm, setAppParamsForm] = useState({
        id: null,
        notificationRate: 0,
        defiPoints: 0,
        defiCoins: 0,
        lessonPoints: 0,
        lessonCoins: 0,
        moduleDiamonds: 0,
        defiTimeLimit: 0,
        defisResposeTime: 0,
        defiIdleTime: 0
    })
    const fetchappPramsResult = useSelector((state) => state.appPrams.fetchappPramsResult?.data);
    useEffect(() => {
        if (fetchappPramsResult) {
            setAppParamsForm({

                id: fetchappPramsResult._id,
                notificationRate: fetchappPramsResult.notificationRate,
                defiPoints: fetchappPramsResult.defiPoints,
                defiCoins: fetchappPramsResult.defiCoins,
                lessonPoints: fetchappPramsResult.lessonPoints,
                lessonCoins: fetchappPramsResult.lessonCoins,
                moduleDiamonds: fetchappPramsResult.moduleDiamonds,
                defiTimeLimit: fetchappPramsResult.defiTimeLimit,
                defisResposeTime: fetchappPramsResult.defisResposeTime,
                defiIdleTime: fetchappPramsResult.defiIdleTime,
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchappPramsResult]);
    const handleupdateParams = () => {
        dispatch(editAppParams({ ...appParamsForm }));

    };
    return (
        <Content
            className="site-layout-background"
            style={{
                padding: 0,
                margin: 0,
                minHeight: "100vh",
            }}
        >
            <PageHeader title={"Paramètres In-App"}
                breadcrumbItems={["Paramètres Générales", "Paramètres In-App"]} />
            <Row >
                <Col span={11}>
                    <Card title="Paramètres des Défis"
                        style={{ width: "95%", margin: "24px" }}
                    >
                        <Form>
                            <Form.Item
                                label="Points gagnés par défi :"
                            >
                                <Space size={8}>
                                    <InputNumber
                                        value={appParamsForm.defiPoints}
                                        min={0}
                                        // step={0.1}
                                        onChange={(value) => {
                                            setAppParamsForm({ ...appParamsForm, defiPoints: value })
                                        }}
                                    />
                                    <Image src="/Star.svg" width={32} height={32} alt="coin" preview={false} />

                                </Space>

                            </Form.Item>
                            <Form.Item
                                label="Pièces gagnés par défi :"
                            >
                                <Space size={8}>

                                    <InputNumber
                                        value={appParamsForm.defiCoins}
                                        min={0}
                                        // step={0.1}
                                        onChange={(value) => {
                                            setAppParamsForm({ ...appParamsForm, defiCoins: value })
                                        }}
                                    />
                                    <Image src="/Coin.svg" width={32} height={32} alt="coin" preview={false} />
                                </Space>

                            </Form.Item>
                            <Form.Item
                                label="Temps de réponse :"
                            >
                                <Space size={8}>

                                    <InputNumber
                                        value={appParamsForm.defisResposeTime}
                                        min={0}
                                        step={0.1}
                                        onChange={(value) => {
                                            setAppParamsForm({ ...appParamsForm, defisResposeTime: value })
                                        }}
                                    />
                                    <Text >seconds</Text>
                                </Space>

                            </Form.Item>
                            <Form.Item
                                label="Limite de temps :"
                            >
                                <Space size={8}>

                                    <InputNumber
                                        value={appParamsForm.defiTimeLimit}
                                        min={0}
                                        step={0.1}
                                        onChange={(value) => {
                                            setAppParamsForm({ ...appParamsForm, defiTimeLimit: value })
                                        }}
                                    />
                                    <Text >seconds</Text>
                                </Space>

                            </Form.Item>
                            <Form.Item
                                label="Temps de lecture :"
                            >
                                <Space size={8}>

                                    <InputNumber
                                        value={appParamsForm.defiIdleTime}
                                        min={0}
                                        step={0.1}
                                        onChange={(value) => {
                                            setAppParamsForm({ ...appParamsForm, defiIdleTime: value })
                                        }}
                                    />
                                    <Text >seconds</Text>
                                </Space>

                            </Form.Item>


                        </Form>

                    </Card>

                </Col>
                <Col span={11}>
                    <Card title="Paramètres des Leçons"
                        style={{ width: "95%", margin: "24px" }}
                    >
                        <Form>
                            <Form.Item
                                label="Points gagnés par leçon :"
                            >
                                <Space size={8}>
                                    <InputNumber
                                        value={appParamsForm.lessonPoints}
                                        min={0}
                                        // step={0.1}
                                        onChange={(value) => {
                                            setAppParamsForm({ ...appParamsForm, lessonPoints: value })
                                        }}
                                    />
                                    <Image src="/Star.svg" width={32} height={32} alt="coin" preview={false} />

                                </Space>

                            </Form.Item>
                            <Form.Item
                                label="Pièces gagnés par leçon :"
                            >
                                <Space size={8}>

                                    <InputNumber
                                        value={appParamsForm.lessonCoins}
                                        min={0}
                                        // step={0.1}
                                        onChange={(value) => {
                                            setAppParamsForm({ ...appParamsForm, lessonCoins: value })
                                        }}
                                    />
                                    <Image src="/Coin.svg" width={32} height={32} alt="coin" preview={false} />
                                </Space>

                            </Form.Item>
                        </Form>

                    </Card>

                </Col>
            </Row>
            <Row >
                <Col span={11} >
                    <Card title="Paramètres des Modules"
                        style={{ width: "95%", margin: "24px" }}
                    >
                        <Form>
                            <Form.Item
                                label="Diamants gagnés par module acheté :"
                            >
                                <Space size={8}>
                                    <InputNumber
                                        value={appParamsForm.moduleDiamonds}
                                        min={0}
                                        // step={0.1}
                                        onChange={(value) => {
                                            setAppParamsForm({ ...appParamsForm, moduleDiamonds: value })
                                        }}
                                    />
                                    <Image src="/Diamond.svg" width={32} height={32} alt="coin" preview={false} />

                                </Space>

                            </Form.Item>

                        </Form>

                    </Card>

                </Col>
                <Col span={11}>
                    <Card title="Paramètres des Notifications"
                        style={{ width: "95%", margin: "24px" }}
                    >
                        <Form>
                            <Form.Item
                                label="Taux des notifications :"
                            >
                                <Space size={8}>
                                     <Text strong  >Tous les </Text>
                                    <InputNumber
                                        value={appParamsForm.notificationRate}
                                        min={0}
                                        // step={0.1}
                                        onChange={(value) => {
                                            setAppParamsForm({ ...appParamsForm, notificationRate: value })
                                        }}
                                    />
                                     <Text strong>jours</Text>

                                </Space>

                            </Form.Item>

                        </Form>

                    </Card>

                </Col>
            </Row>
            <Row justify={"center"} style={{ marginBottom: "24px" }}>
                <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button type='primary' style={{ width: "200px" }}
                        onClick={handleupdateParams}
                    >Sauvegrader</Button>
                </Col>
            </Row>
        </Content>
    );
}

export default AppPrams;
