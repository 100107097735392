import { createSlice } from "@reduxjs/toolkit";

import { getOnboardingStatsUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";

export const OnboardingdatasSlice = createSlice({
    name: "onboardingdatas",
    initialState: {
        fetchOnboardingdataResult: null,
        fetchOnboardingdataError: null,
        isFetchingOnboardingdata: false,
    },
    reducers: {


        setOnboardingdataResult: (state, action) => {
            state.fetchOnboardingdataResult = action.payload;
            state.fetchOnboardingdataError = null;

            state.isFetchingOnboardingdata = false;
        },

        setOnboardingdataError: (state, action) => {
            state.fetchOnboardingdataError = action.payload;
            state.isFetchingOnboardingdata = false;
            state.fetchOnboardingdataResult = null

        },
        setIsFetchingSingupChartData: (state, action) => {
            state.isFetchingOnboardingdata = action.payload;
        },
    },
});

export const {


    setOnboardingdataResult,
    setIsFetchingSingupChartData,
    setOnboardingdataError,

} = OnboardingdatasSlice.actions;


export const fetchOnboardingdata = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getOnboardingStatsUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data: data
    };
    dispatch(setIsFetchingSingupChartData(true));
    const response = axios(config)
        .then(response => {
            dispatch(setOnboardingdataResult(response.data));
        })
        .catch((error) => {
            message.error(error.response.data.message)
            dispatch(setOnboardingdataError(error.response.data.message));

        });

    return response.data;

}



export default OnboardingdatasSlice.reducer;