/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Form, Button,
    Layout, Spin, Input,
    Divider, Modal, Select,
    InputNumber,
    DatePicker,
} from 'antd';
import PageHeader from '../../comps/pageHeader';
import { CheckDuplicatedContract, CreateContract, setCheckExistingContractFailure, setCheckExistingContractSuccess, setCreateContractFailure, setCreateContractSuccess } from './addContractSlice';
// import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const { Content } = Layout;
// const { Option } = Select;
const { RangePicker } = DatePicker;

export default function AddInvdContract() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const establishmentType = [
        {
            value: 'school',
            label: 'École',
        },
        {
            value: 'association',
            label: 'Association',
        },
        {
            value: 'library',
            label: 'Bibliothèque',
        },
        {
            value: 'footballTeam',
            label: 'Équipe de football',
        },
        {
            value: 'company',
            label: 'Entreprise',
        },
        {
            value: 'townHall',
            label: 'Mairie',
        },
        {
            value: 'academy',
            label: 'Académie',
        },
        {
            value: 'federation',
            label: 'Fédération',
        },
    ]
    const finishForum = () => {
        const dataTosend = { ...contractData, clientProList: [{ ...clientProData }] }
        // dispatch(CreateContract(dataTosend));
        dispatch(CheckDuplicatedContract(dataTosend));

    }
    const [contractData, setContractData] = useState({
        establishmentType: "school",
        endDate: "",
        startDate: "",
        contractType: "individual",
    });
    const [clientProData, setClientProData] = useState({
        name: "",
        ReferentName: "",
        email: "",
        phoneNumber: "",
        type: "school",
        password: "",
        status: "active",
        numberOfActivationCodes: 0,
    });

    const createContractSuccess = useSelector((state) => state.addContract.createContractSuccess);
    const isCreatingContract = useSelector((state) => state.addContract.isCreatingContract);

    const checkExistingContractFailure = useSelector((state) => state.addContract.checkExistingContractFailure);
    // const isCheckingContract = useSelector((state) => state.addContract.isCheckingContract);
    const checkExistingContractSuccess = useSelector((state) => state.addContract.checkExistingContractSuccess);
    useEffect(() => {
        if (!createContractSuccess) {
            return;
        }
        Modal.success({
            title: "Succès",
            content: "Contract ajouté avec succès",
            onOk() {
                dispatch(setCreateContractSuccess(null));
                navigate("/dashboard/pro/contracts/list");
            },
        })
    }, [createContractSuccess])

    useEffect(() => {
        if (!checkExistingContractFailure) {
            return;
        }
        Modal.confirm({
            title: "Duplication de Contrat Existante",
            content: "Un contrat avec les mêmes dates et e-mail existe déjà. Si vous confirmez, les coordonnées du client ne changent pas et un nouveau contrat est établi à son nom",
            onOk() {
                dispatch(setCheckExistingContractFailure(null));
                const dataTosend = { ...contractData, clientProList: [{ ...clientProData }] }
                dispatch(CreateContract(dataTosend));

                navigate("/dashboard/pro/contracts/list");
            },
            onCancel() {
                dispatch(setCreateContractFailure(null));
                dispatch(setCheckExistingContractFailure(null));

            }

        })
    }, [checkExistingContractFailure])
    useEffect(() => {
        if (!checkExistingContractSuccess) {
            return;
        }
        dispatch(setCheckExistingContractSuccess(null));
        const dataTosend = { ...contractData, clientProList: [{ ...clientProData }] }
        dispatch(CreateContract(dataTosend));
        navigate("/dashboard/pro/contracts/list");

    }, [checkExistingContractSuccess])

    const onChangeDates = (e) => {
        console.log(e);
        if (e) {
            setContractData({
                ...contractData,
                startDate: e[0]?.startOf('day').format(),
                endDate: e[1]?.startOf('day').format()
            });
        }
        else {
            setContractData({ ...contractData, startDate: null, endDate: null });
        }
    }
    return (
        <Content
            className="site-layout-background"
            style={{
                padding: 0,
                margin: 0,
                minHeight: 280,
            }}
        >
            <PageHeader
                title={"Ajouter un nouveau Contrat Pro"}
                breadcrumbItems={["Panneau Contrats Pro", "Ajouter un Contrat Pro"]}
            />

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#FFFFFF",
                    margin: "2em",
                    padding: "1em 2em 1em 2em"
                }}
            >
                <div style={{ width: "100%", alignItems: "flex-start" }}>
                    <h2 style={{ fontSize: "18px", marginLeft: "0", marginTop: "8px", marginBottom: "8px" }}>Informations pertinentes</h2>
                </div>
                <Divider style={{ margin: "10px 0px 10px 0px" }} />
                <div style={{ width: "80%", alignItems: "center" }}>
                    <Form
                        name="Inscription"
                        layout='vertical'
                        onFinish={finishForum}
                    >
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap" }}>
                            <Form.Item
                                style={{ width: "40%", marginRight: "4%" }}
                                label={<p style={{ fontWeight: "400", margin: "0px 0px 0px 0px" }}>Entrer le nom de l’établissement</p>}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez insérer le nom de l\'établissement.',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='Le nom du client Pro'
                                    onChange={(e) => setClientProData({ ...clientProData, name: e.target.value })}
                                    addonAfter={(
                                        <Select
                                            style={{ width: 100 }}
                                            options={establishmentType}
                                            defaultValue={"school"}
                                            value={contractData.type} onChange={(e) => {
                                                setContractData({ ...contractData, establishmentType: e })
                                                setClientProData({ ...clientProData, type: e })

                                            }} />
                                    )}
                                />
                            </Form.Item>
                            <Form.Item
                                style={{ width: "40%", marginRight: "4%" }}
                                label={<p style={{ fontWeight: "400", margin: "0px 0px 0px 0px" }}>Entrer le nom du referant</p>}
                                name="ReferentName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez insérer le nom du referant.',
                                    },
                                ]}

                            >
                                <Input placeholder='Nom du referant' onChange={(e) => setClientProData({ ...clientProData, ReferentName: e.target.value })} />
                            </Form.Item>

                            <Form.Item
                                style={{ width: "40%", marginRight: "4%" }}
                                label={<p style={{ fontWeight: "400", margin: "0px 0px 0px 0px" }}>Entrer une adresse mail</p>}
                                name="email"
                                initialValue={null}
                                rules={[
                                    {
                                        required: true,
                                        // eslint-disable-next-line no-useless-escape
                                        pattern: new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g),
                                        message: 'Veuillez insérer une adresse mail valide',
                                    },
                                ]}
                            >
                                <Input placeholder='address@mail.com' onChange={(e) => setClientProData({ ...clientProData, email: e.target.value })} />
                            </Form.Item>
                            <Form.Item
                                style={{ width: "40%", marginRight: "4%" }}
                                label={<p style={{ fontWeight: "400", margin: "0px 0px 0px 0px" }}>Entrer un numéro de téléphone</p>}
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez insérer un numéro de téléphone',
                                    },
                                ]}
                            >
                                <Input placeholder='00000000000' onChange={(e) => setClientProData({ ...clientProData, phoneNumber: e.target.value })} />
                            </Form.Item>

                            <Form.Item
                                style={{ width: "40%", marginRight: "4%" }}
                                label={<p style={{ fontWeight: "400", margin: "0px 0px 0px 0px" }}>Ajouter un Mot de Passe</p>}
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        pattern: new RegExp(/(?=^.{8,12}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/g),
                                        message: 'Veuillez insérer un mot de passe valide',
                                    },
                                ]}
                            >
                                <Input.Password type='password' placeholder='Mot de Passe' onChange={(e) => setClientProData({ ...clientProData, password: e.target.value })} />
                            </Form.Item>

                            <Form.Item
                                style={{ width: "40%", marginRight: "4%" }}
                                label={<p style={{ fontWeight: "400", margin: "0px 0px 0px 0px" }}>Entrer le nombre d’accès attribués</p>}
                                name="activationCodes"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez insérer le nombre d’accès attribués',
                                    },
                                ]}
                            >
                                <InputNumber min={0} defaultValue={0}
                                    onChange={(e) => setClientProData({ ...clientProData, numberOfActivationCodes: e })}
                                    changeOnWheel />
                            </Form.Item>
                            <div style={{ justifyContent: "start", width: "40%", marginRight: "4%" }}>
                                <p style={{ color: "#00000073" }}>Le Mot de Passe doit comporter au moins :</p>
                                <ul style={{ paddingLeft: "16px" }}>
                                    <li style={{ color: "#00000073" }}>De 8 à 12 caractères</li>
                                    <li style={{ color: "#00000073" }}>Au moins 1 minuscule</li>
                                    <li style={{ color: "#00000073" }}>Au moins 1 majuscule</li>
                                    <li style={{ color: "#00000073" }}>Au moins 1 chiffre</li>
                                </ul>
                            </div>
                            <Form.Item
                                style={{ width: "40%", marginRight: "4%" }}
                                label={<p style={{ fontWeight: "400", margin: "0px 0px 0px 0px" }}>Entrer la date du début et de la fin du contrat</p>}
                                name="dates"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez insérer la date du début et de la fin du contrat',
                                    },
                                ]}
                            >
                                <RangePicker
                                    style={{
                                        height: "2.5em"
                                    }}
                                    onCalendarChange={onChangeDates}
                                />
                            </Form.Item>
                        </div>

                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

                            <Divider style={{ margin: "10px 0px 10px 0px" }} />
                            <p>Vérifier les informations avant de confirmer.</p>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {isCreatingContract ? <Spin className='proClientSpinner' /> : "Ajouter"}
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>

            </div>
        </Content>
    )
}