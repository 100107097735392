import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Space, Row, Result, Button, Col, Typography, Layout, Spin, Divider, Tabs, Input, Radio, Modal, Select, DatePicker, message, Drawer, Popconfirm } from 'antd';
import dayjs from 'dayjs';

import { fetchContracts } from './contractListSlice';

import PageHeader from '../../comps/pageHeader';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import {
    SearchOutlined, FilterFilled, ExclamationCircleTwoTone
} from '@ant-design/icons';
import ContractFormModal from '../../comps/addGroupedContract';
import { UpdateContract } from './singleContractSlice';
import { SuspendContract, updateIndividualContract } from './contractSlice';
// import SingleAdmin from '../admin/singleAdmin';

const { Text } = Typography;
const { Content } = Layout;
const { RangePicker } = DatePicker;

const establishmentTypesOptions = [
    {
        value: 'school',
        label: 'École',
    },
    {
        value: 'association',
        label: 'Association',
    },
    {
        value: 'library',
        label: 'Bibliothèque',
    },
    {
        value: 'footballTeam',
        label: 'Équipe de football',
    },
    {
        value: 'company',
        label: 'Entreprise',
    },
    {
        value: 'townHall',
        label: 'Mairie',
    },
    {
        value: 'academy',
        label: 'Académie',
    },
    {
        value: 'federation',
        label: 'Fédération',
    },
]
const establishmentTypesOptionsFrench = {
    school: 'École',
    association: 'Association',
    library: 'Bibliothèque',
    footballTeam: 'Équipe de football',
    company: 'Entreprise',
    townHall: 'Mairie',
    academy: 'Académie',
    federation: 'Fédération'
};
const Contracts = () => {
    const navigate = useNavigate();

    const { useEffect } = React;
    const dispatch = useDispatch();
    let fetchServerErr = useSelector(
        (state) => state.contracts.fetchServerErr
    );
    const [contractsFilter, setContractsFilter] = useState({
        name: "",
        establishmentTypesOptions: null,
        contractType: 'grouped',
        pageLength: 10,
        page: 1,

    });
    const [nameDisplay, setNameDisplay] = useState("");
    const [openSuspendModal, setOpenSuspendModal] = useState(false);
    const [updateData, setUpdateData] = useState({
        id: null,
        name: "",
        email: "",
        type: null,
        password: null,
        referer: "",
        phoneNumber: "",
        startDate: null,
        endDate: null,
    });

    useEffect(() => {
        // dispatch(getBannerInfo());
        dispatch(fetchContracts(contractsFilter));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractsFilter]);

    // useEffect(() => {
    //     dispatch(fetchContracts({ limit: 10 }));
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const fetchContractsResult = useSelector(
        (state) => state.contracts.fetchContractsResult
    );

    const isFetchingContracts = useSelector(
        (state) => state.contracts.isFetchingContracts
    );

    const isLoadingUpdateIndividual = useSelector((state) => state.contract.isUpdatingIndividualContract);
    const UpdateIndividualSuccess = useSelector((state) => state.contract.individualContractUpdateSuccess);
    const isSuspendingContract = useSelector((state) => state.contract.isSuspendingContract);
    const suspendContractSuccess = useSelector((state) => state.contract.suspendContractSuccess);


    const [visibleModify, setVisibleModify] = useState(false)
    const [openDrawer, setOpenDrawer] = useState(false);
    const [contractId, setContractID] = useState(null);
    // Grouped state for contract details
    const [singleContract, setSingleContract] = useState({
        id: null,
        name: '',
        establishmentTypesOptions: '',
        startDate: null,
        endDate: null
    });
    const [contractType, setcontractType] = useState("grouped");
    const getColumnNameSearchProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >

                <Input
                    placeholder={`Nom du contract`}
                    value={nameDisplay}
                    onChange={(e) => timedNameSearch(e.target.value)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                    onPressEnter={() => dispatch(fetchContracts(contractsFilter))}
                />

                <Space>
                    <Button
                        type="primary"
                        onClick={() => dispatch(fetchContracts(contractsFilter))}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 110,
                        }}
                    >
                        Chercher
                    </Button>
                    <Button
                        onClick={() => { setContractsFilter({ ...contractsFilter, name: null }); setNameDisplay("") }}
                        size="small"
                        style={{
                            width: 110,
                        }}
                    >
                        Réinitialiser
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fermer
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#39B13D' : undefined,
                }}
            />
        ),
    });

    const getColumnFilterTypeProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Radio.Group onChange={(e) => setContractsFilter({ ...contractsFilter, establishmentTypesOptions: e.target.value })}
                    value={contractsFilter.establishmentTypesOptions}>
                    <Space direction="vertical">
                        <Radio value={"school"}>École</Radio>
                        <Radio value={"association"}>Association</Radio>
                        <Radio value={"library"}>Bibliothèque</Radio>
                        <Radio value={"footballTeam"}>Équipe de football</Radio>
                        <Radio value={"company"}>Entreprise</Radio>
                        <Radio value={"townHall"}>Mairie</Radio>
                        <Radio value={"academy"}>Académie</Radio>
                        <Radio value={"federation"}>Fédération</Radio>
                        <Radio value={null}>Tout</Radio>
                    </Space>
                </Radio.Group>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        close();
                    }}
                >
                    Fermer
                </Button>
            </div>),
        filterIcon: (filtered) => (<FilterFilled
            style={{
                color: filtered ? '#39B13D' : undefined,
            }}
        />),
    });

    const columnsGrouped = [
        {
            title: 'Nom du groupe',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            ...getColumnNameSearchProps()

        },
        {
            title: 'Début du contrat',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (data) => (<p>{moment(data).format("DD/MM/YYYY")}</p>),
            width: '17%',

        },
        {
            title: 'Fin du contrat',
            dataIndex: 'endDate',
            key: 'endDate',
            render: (data) => (<p>{moment(data).format("DD/MM/YYYY")}</p>),
            width: '17%',

        },
        {
            title: 'Nbr de clients',
            dataIndex: 'numberOfClientPros',
            key: 'numberOfClientPros',
            width: '15%',

        },
        {
            title: 'Type',
            dataIndex: 'establishmentType',
            key: 'establishmentType',
            render: (text, record) => establishmentTypesOptionsFrench[text],
            width: '18%',
            ...getColumnFilterTypeProps()
        },
        {
            title: 'Nbr de codes',
            dataIndex: 'totalSubscriptions',
            key: 'totalSubscriptions',
            width: '18%',

        },
        {
            title: "Actions",
            width: "25%",
            align: "center",
            dataIndex: "_id",
            key: "_id",
            render: (_id) => {
                return (
                    <Space split={<Divider type="vertical" />}>

                        <Button
                            style={{ color: "#39B13D", padding: 0 }}
                            type="link"
                            size="small"
                            onClick={(e) => {
                                navigate(`/dashboard/pro/contracts/clientcontract/${_id}`);

                            }}
                        >
                            Voir
                        </Button>

                        <Button
                            type="link"
                            style={{ color: "#39B13D", padding: 0 }}

                            size="small"
                            onClick={(e) => {
                                setVisibleModify(true)
                                setContractID(_id)
                                // setSingleContract({...singleContract,id:_id})
                                const contract = fetchContractsResult.data.find(item => item._id === _id);
                                if (contract) {
                                    let { _id, name, establishmentType, startDate, endDate } = contract;
                                    setSingleContract({ id: _id, name, establishmentType, startDate, endDate });  // Set the singleContract state with only the needed fields
                                    // console.log(moment(singleContract.startDate).format(), moment(singleContract.endDate));
                                }
                            }}
                        >
                            Modifier
                        </Button>

                        <Button
                            type="link"
                            style={{ color: "#39B13D", padding: 0 }}

                            color='green'
                            size="small"
                            onClick={(e) => {
                                setOpenSuspendModal(true);
                                setContractID(_id);

                            }}
                        >
                            Suspendre
                        </Button>
                    </Space>

                );
            },
        },


    ];

    const columnsIndividual = [

        {
            title: 'Nom de l’établissement',
            dataIndex: 'clientsPro',
            key: 'clientsPro.name',
            width: '20%',
            render: (clientsPro) => clientsPro.map(client => client.name).join(', '),
            // ...getColumnNameSearchProps()
        },
        {
            title: 'Réferant',
            dataIndex: 'clientsPro',
            key: 'clientsPro.ReferentName',
            width: '20%',
            render: (clientsPro) => clientsPro.map(client => client.ReferentName).join(', '),
            // ...getColumnNameSearchProps()
        },
        {
            title: 'Adresse Mail',
            dataIndex: 'clientsPro',
            key: 'clientsPro.email',
            width: '20%',
            render: (clientsPro) => clientsPro.map(client => client.email).join(', '),
            // ...getColumnNameSearchProps()
        },
        // {
        //     title: 'Début du contrat',
        //     dataIndex: 'startDate',
        //     key: 'startDate',
        //     render: (data) => (<p>{moment(data).format("DD/MM/YYYY")}</p>),
        //     width: '17%',

        // },
        {
            title: 'Date d’expiration',
            dataIndex: 'endDate',
            key: 'endDate',
            render: (data) => (<p>{moment(data).format("DD/MM/YYYY")}</p>),
            width: '17%',

        },

        {
            title: 'Type',
            dataIndex: 'establishmentType',
            key: 'establishmentType',
            render: (text, record) => establishmentTypesOptionsFrench[text],
            width: '18%',
            ...getColumnFilterTypeProps()
        },
        {
            title: 'Nbr de codes',
            dataIndex: 'totalSubscriptions',
            key: 'totalSubscriptions',
            width: '18%',

        },
        {
            title: "Actions",
            width: "25%",
            align: "center",
            dataIndex: "_id",
            key: "_id",
            render: (_id, record) => {
                return (
                    <Space split={<Divider type="vertical" />}>


                        <Button
                            type="link"
                            style={{ color: "#39B13D", padding: 0 }}

                            size="small"
                            onClick={(e) => {
                                setOpenDrawer(true)
                                setUpdateData({
                                    id: _id,
                                    email: record.clientsPro.map(client => client.email).join(', '),
                                    name: record.clientsPro.map(client => client.name).join(', '),
                                    referer: record.clientsPro.map(client => client.ReferentName).join(', '),
                                    phoneNumber: record.clientsPro.map(client => client.phoneNumber).join(', '),
                                    type: record.establishmentType,
                                    startDate: record.startDate,
                                    endDate: record.endDate,
                                })
                                // setContractID(_id)
                            }}
                        >
                            Modifier
                        </Button>

                        <Button
                            type="link"
                            style={{ color: "#39B13D", padding: 0 }}

                            color='green'
                            size="small"
                            onClick={(e) => {
                                setOpenSuspendModal(true)
                                setContractID(_id)
                            }}
                        >
                            Suspendre
                        </Button>
                    </Space>

                );
            },
        },


    ];

    const items =
        [
            {
                label: 'Liste des Contrats groupés',
                key: 'grouped',
            },
            {
                label: 'Liste des Contrats individuels',
                key: 'individual',
            },

        ]

    const onChange = (key) => {
        setContractsFilter({
            name: "",
            establishmentType: null,
            contractType: key,
            pageLength: 10,
            page: 1,
        })
        setcontractType(key)
    };

    const onChangePage = (e) => {
        setContractsFilter({ ...contractsFilter, page: e.current, limit: e.pageSize });
    }

    const [timeout, setTimeoutVal] = useState(0);

    const timedNameSearch = (data) => {
        setNameDisplay(data);
        if (timeout) clearTimeout(timeout);
        setTimeoutVal(setTimeout(() => {
            setContractsFilter({ ...contractsFilter, name: data });
        }, 300));
    }

    const [modalVisible, setModalVisible] = useState(false);

    const showModal = () => {
        setModalVisible(true);
    };

    const handleCancel = () => {
        setModalVisible(false);
    };

    const handleCancelModify = () => {
        setVisibleModify(false);
    };

    const handleCancelModifyIndividual = () => {
        setOpenDrawer(false);
    };

    const onChangeDates = (e) => {
        console.log(e);
        if (e) {
            setSingleContract({
                ...singleContract,
                startDate: e[0]?.startOf('day').format(),
                endDate: e[1]?.startOf('day').format()
            });
        }
        else {
            setSingleContract({ ...singleContract, startDate: null, endDate: null });
        }
    }

    const onChangeDatesIndividual = (e) => {

        if (e) {
            setUpdateData({
                ...updateData,
                startDate: e[0]?.startOf('day').format(),
                endDate: e[1]?.startOf('day').format()
            });
        }
        else {
            setUpdateData({ ...updateData, startDate: null, endDate: null });
        }
    }

    const onUpdateContractIndividual = () => {
        if (isLoadingUpdateIndividual) return;
        dispatch(updateIndividualContract(updateData));
    }


    const handleSave = () => {
        // Handle save logic here
        // You can call an API or update the state
        dispatch(UpdateContract(singleContract))
        setVisibleModify(false);
    };

    // Handlers to update state
    const handleChange = (key, value) => {
        setSingleContract((prevDetails) => ({
            ...prevDetails,
            [key]: value,
        }));
    };

    useEffect(() => {
        if (!UpdateIndividualSuccess) return;
        setOpenDrawer(false);
        dispatch(fetchContracts(contractsFilter));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [UpdateIndividualSuccess])

    useEffect(() => {
        if (!suspendContractSuccess) {
            return;
        }

        message.success("Contrat suspendu avec succès !");
        setContractID(null);
        setOpenSuspendModal(false);
        dispatch(fetchContracts(contractsFilter));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suspendContractSuccess])

    return (

        <Content
            className="site-layout-background"
            style={{
                padding: 0,
                margin: 0,
                minHeight: 280,
            }}
        >
            {fetchServerErr ?
                <Result
                    status="500"
                    title="500"
                    subTitle="Une erreur inattendue est survenue, veuillez réessayer plus tard !"
                />
                : <>
                    {/* <SingleAdmin openDrawer={openDrawer} onClose={onClose} contractID={contractID} /> */}
                    <Modal
                        title="Modifier le Contrat"
                        open={visibleModify}
                        okText="Sauvegarder"
                        cancelText="Annuler"
                        onCancel={handleCancelModify}
                        onOk={handleSave}
                    >
                        <div>
                            <label>Nom du contrat</label>
                            <Input
                                value={singleContract.name}
                                onChange={(e) => handleChange('name', e.target.value)}
                                placeholder="Nom du contrat"
                            />
                        </div>
                        <div style={{ marginTop: 16 }}>
                            <label>Type d'établissement</label>
                            <Select
                                value={singleContract.establishmentType}
                                onChange={(value) => handleChange('establishmentType', value)}
                                style={{ width: '100%' }}
                                options={establishmentTypesOptions}
                            />

                        </div>
                        <div style={{ marginTop: 16 }}>
                            <label>Période du contrat</label>
                            <RangePicker
                                value={[dayjs(singleContract.startDate, 'YYYY/MM/DD'), dayjs(singleContract.endDate, 'YYYY/MM/DD')]}
                                onChange={onChangeDates}
                                style={{ width: '100%' }}
                            />
                        </div>
                    </Modal>

                    <Drawer
                        title={
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                <h3 style={{ margin: "0px 16px 0px 0px" }}>Modifier un contrat individuel</h3>
                                <div>
                                    <Button style={{ marginRight: "16px" }} onClick={() => {
                                        if (isLoadingUpdateIndividual) return;
                                        setOpenDrawer(false);
                                    }}>Annuler</Button>
                                    <Button type='primary' onClick={onUpdateContractIndividual}>{isLoadingUpdateIndividual ? <Spin className='proClientSpinner' /> : "Sauvegarder"}</Button>
                                </div>
                            </div>
                        }
                        width={750}
                        placement={"right"}
                        closable={isLoadingUpdateIndividual}
                        open={openDrawer}
                        okText="Sauvegarder"
                        cancelText="Annuler"
                        onClose={handleCancelModifyIndividual}
                    >
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "2em" }}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", width: "45%" }}>

                                <p style={{ margin: "5px 0px" }}>Nom de l’établissement</p>
                                <Input
                                    placeholder='Collège'
                                    value={updateData.name}
                                    onChange={(e) => setUpdateData({ ...updateData, name: e.target.value })}
                                />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", width: "45%" }}>
                                <p style={{ margin: "5px 0px" }}>Type</p>
                                <Select style={{ width: "100%" }} value={updateData.type} options={establishmentTypesOptions} onChange={(e) => setUpdateData({ ...updateData, type: e })}>
                                </Select>
                            </div>

                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "2em" }}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", width: "45%" }}>

                                <p style={{ margin: "5px 0px" }}>Nom du référant</p>
                                <Input
                                    placeholder='John Doe'
                                    value={updateData.referer}
                                    onChange={(e) => setUpdateData({ ...updateData, referer: e.target.value })}
                                />
                            </div>

                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", width: "45%" }}>

                                <p style={{ margin: "5px 0px" }}>Numéro de téléphone</p>
                                <Input
                                    placeholder='+33 XXXX XXXX'
                                    value={updateData.phoneNumber}
                                    onChange={(e) => setUpdateData({ ...updateData, phoneNumber: e.target.value })}
                                />
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "2em" }}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", width: "100%" }}>
                                <label>Période du contrat</label>
                                <RangePicker
                                    value={[dayjs(updateData.startDate, 'YYYY/MM/DD'), dayjs(updateData.endDate, 'YYYY/MM/DD')]}
                                    onChange={onChangeDatesIndividual}
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", width: "45%" }}>

                                <p style={{ margin: "5px 0px" }}>Email</p>
                                <Input
                                    placeholder='mail@entreprise.com'
                                    value={updateData.email}
                                    onChange={(e) => setUpdateData({ ...updateData, email: e.target.value })}
                                />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "45%" }}>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", width: "100%" }}>
                                    <p style={{ margin: "5px 0px" }}>Mot de Passe</p>
                                    <Input
                                        placeholder='Mot de passe'
                                        value={updateData.password}
                                        rules={[
                                            {
                                                required: true,
                                                // type: "regexp",
                                                pattern: new RegExp(/(?=^.{8,12}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/g),
                                                message: 'Veuillez insérer un mot de passe valide',
                                            },
                                        ]}
                                        onChange={(e) => setUpdateData({ ...updateData, password: e.target.value })}
                                    />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", width: "100%" }}>
                                    <p style={{ color: "#00000073", margin: "5px 0px" }}>Le Mot de Passe doit comporter au moins :</p>
                                    <ul style={{ paddingLeft: "16px", marginTop: 0 }}>
                                        <li style={{ color: "#00000073" }}>De 8 à 12 caractères</li>
                                        <li style={{ color: "#00000073" }}>Au moins 1 minuscule</li>
                                        <li style={{ color: "#00000073" }}>Au moins 1 majuscule</li>
                                        <li style={{ color: "#00000073" }}>Au moins 1 chiffre</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Drawer>

                    <ContractFormModal visible={modalVisible}
                        onClose={handleCancel}
                    />

                    <PageHeader title={"Vue d’ensemble des Contrats"}
                        breadcrumbItems={["Panneau Contrats", "Vue d’ensemble "]} />

                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", background: "#FFFFFF", margin: "24px", minHeight: "164px", padding: "24px 128px 24px 128px" }}
                    >
                        {isFetchingContracts ? (<Spin style={{ margin: "auto" }} />) : (
                            <>
                                <div style={{ width: "20vw", display: "flex", flexDirection: "column", alignItems: "center" }} >
                                    <h2 style={{ fontSize: "14px", fontWeight: "400" }}>Total de Contrats</h2>
                                    <p style={{ fontSize: "38px", margin: "0" }}>{fetchContractsResult ? fetchContractsResult.totalContracts : "NaN"}</p>
                                </div>
                                <div style={{ width: "20vw", display: "flex", flexDirection: "column", alignItems: "center" }} >
                                    <h2 style={{ fontSize: "14px", fontWeight: "400" }}>Dernier Client Inscrit le</h2>
                                    <p style={{ fontSize: "38px", margin: "0" }}>{fetchContractsResult?.lastContractDate ? moment(fetchContractsResult.lastContractDate).format("DD/MM/YYYY") : "Aucune date"}</p>
                                </div>
                                <div style={{ width: "20vw", display: "flex", flexDirection: "column", alignItems: "center" }} >
                                    <h2 style={{ fontSize: "14px", fontWeight: "400" }}>Nombre total de codes générés</h2>
                                    <p style={{ fontSize: "38px", margin: "0" }}>{fetchContractsResult?.totalSubscriptions ? fetchContractsResult.totalSubscriptions : "NaN"}</p>
                                </div></>
                        )}

                    </div>
                    <Row justify={"center"} style={{ background: "#424242", margin: "24px", minHeight: "112px" }}
                    >
                        <Col span={5} style={{ paddingTop: "24px" }}                >
                            <Space align='center' direction="vertical" size="middle" style={{ display: 'flex' }}>
                                <Text style={{ color: "white", fontSize: "16px" }}>Ajouter un contrat groupé</Text>
                                <Button type='primary'
                                    onClick={showModal}
                                    style={{ width: "77px", height: "32px" }}> Ajouter</Button>
                            </Space>
                        </Col>
                        <Col span={5}></Col>
                        <Col span={5} style={{ paddingTop: "24px" }}                >
                            <Space align='center' direction="vertical" size="middle" style={{ display: 'flex' }}>
                                <Text style={{ color: "white", fontSize: "16px" }}>Ajouter un contrat individuel</Text>
                                <Button type='primary'
                                    onClick={() => {
                                        navigate("/dashboard/pro/contracts/add");
                                    }}
                                    style={{ width: "77px", height: "32px" }}> Ajouter</Button>
                            </Space>
                        </Col>
                    </Row>
                    <Modal
                        title={<div style={{ display: "flex", flexDirection: "row" }}>
                            <ExclamationCircleTwoTone twoToneColor="#FAAD14" style={{ marginRight: "8px" }} />
                            <p>Attention !</p>
                        </div>}
                        open={openSuspendModal}
                        cancelText="Non"
                        onCancel={() => { setOpenSuspendModal(false) }}

                        okText={(
                            <Popconfirm
                                title="Attention !"
                                description="Êtes-vous certain de vouloir suspendre ce contrat ?"
                                okText={isSuspendingContract ? <Spin /> : "Oui"}
                                cancelText="Non"
                                onConfirm={() => dispatch(SuspendContract({ id: contractId }))}
                            >
                                {isSuspendingContract ? <Spin /> : "Oui"}
                            </Popconfirm>
                        )}
                    >
                        <p style={{ marginLeft: "16px" }}>Voulez-vous vraiment suspendre ce contrat ?</p>
                    </Modal>

                    <Row justify={"center"} style={{ margin: "24px" }}>
                        <Space.Compact block direction='vertical'>
                            <Tabs defaultActiveKey="1" items={items}
                                style={{ backgroundColor: 'white', padding: 20, paddingBottom: 0 }}
                                onChange={onChange}
                            />
                            {fetchContractsResult && fetchContractsResult.data ?
                                <Table style={{ width: "100%" }} columns={contractType === "grouped" ? columnsGrouped : columnsIndividual} pagination={{ total: fetchContractsResult?.totalContracts, pageSize: 10 }}
                                    onChange={onChangePage}
                                    loading={isFetchingContracts} dataSource={fetchContractsResult ? fetchContractsResult.data : null} />
                                :
                                <Table style={{ width: "100%" }} columns={contractType === "grouped" ? columnsGrouped : columnsIndividual}

                                    loading={isFetchingContracts} dataSource={null} />
                            }

                        </Space.Compact>
                    </Row>
                </>
            }
        </Content>

    );
}

export default Contracts;