import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";
import { checkDuplicatedUrl, createContractUrl } from "../../constants";
import { setfetchServerErr } from "../admins/adminsSlice";

export const CactusProSlice = createSlice({
    name: "addContract",
    initialState: {

        // Create Contract
        isCreatingContract: false,
        createContractSuccess: null,
        createContractFailure: null,
        // check duplicated Contract
        isCheckingContract: false,
        checkExistingContractSuccess: null,
        checkExistingContractFailure: null,

    },
    reducers: {

        // Create client
        setCreateContractSuccess: (state, action) => {
            state.createContractSuccess = action.payload;
            state.createContractFailure = null;
            state.isCreatingContract = false;
        },
        setCreateContractFailure: (state, action) => {
            state.createContractFailure = action.payload;
            state.createContractSuccess = null;
            state.isCreatingContract = false;
        },
        setCreateContractStarted: (state, action) => {
            state.isCreatingContract = true;
        },

        // Check existing Contract
        setCheckExistingContractSuccess: (state, action) => {
            state.checkExistingContractSuccess = action.payload;
            state.checkExistingContractFailure = null;
            state.isCheckingContract = false;
        },
        setCheckExistingContractFailure: (state, action) => {
            state.checkExistingContractFailure = action.payload;
            state.checkExistingContractSuccess = null;
            state.isCheckingContract = false;
        },
        setCheckExistingContractStarted: (state, action) => {
            state.isCheckingContract = true;
        },


    }
});

export const {

    // Create Contract
    setCreateContractSuccess,
    setCreateContractFailure,
    setCreateContractStarted,

    setCheckExistingContractStarted,
    setCheckExistingContractFailure,
    setCheckExistingContractSuccess


} = CactusProSlice.actions;




export const CreateContract = (data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: createContractUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    console.log("DATA creation :", data);
    dispatch(setCreateContractStarted());
    dispatch(setCheckExistingContractSuccess(null));
    dispatch(setCheckExistingContractFailure(null));
    axios(config)
        .then((response) => {
            dispatch(setCreateContractSuccess(response.data));
        })
        .catch((error) => {
            if (!error.response) {
                dispatch(setfetchServerErr(true));
                dispatch(setCreateContractFailure("Le serveur ne répond pas, veuillez réessayer plus tard."))
                return;
            }
            if (error.response.status === 401) {
                message.error("Vous êtes déconnecté, veuillez vous reconnecter.");
                dispatch(setCreateContractFailure("Vous n'êtes pas autorisé à faire cette action."));
                return;
            }
            message.error(error.response.data.message);
            dispatch(setCreateContractFailure(error.response.data.message));
        });
}
export const CheckDuplicatedContract = (data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: checkDuplicatedUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    // console.log("DATA creation :", data);
    dispatch(setCheckExistingContractStarted());
    axios(config)
        .then((response) => {
            dispatch(setCheckExistingContractSuccess(response.data));
        })
        .catch((error) => {
            if (!error.response) {
                dispatch(setfetchServerErr(true));
                dispatch(setCheckExistingContractFailure("Le serveur ne répond pas, veuillez réessayer plus tard."))
                return;
            }
            if (error.response.status === 401) {
                message.error("Vous êtes déconnecté, veuillez vous reconnecter.");
                dispatch(setCheckExistingContractFailure("Vous n'êtes pas autorisé à faire cette action."));
                return;
            }
            message.error(error.response.data.message);
            dispatch(setCheckExistingContractFailure(error.response.data.message));
        });
}

export default CactusProSlice.reducer;
