import { createSlice } from "@reduxjs/toolkit";

import { getPacksUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";
import { setFetchAddPackResult } from "./addPackSlice";
export const PacksSlice = createSlice({
    name: "packs",
    initialState: {

        fetchPacksResult: null,
        fetchPacksError: null,
        isFetchingPacks: false,
    },
    reducers: {

        setFetchPacksResult: (state, action) => {
            state.fetchPacksResult = action.payload;
            state.isFetchingPacks = false;
        },

        setFetchPacksError: (state, action) => {
            state.fetchPacksError = action.payload;
            state.isFetchingPacks = false;
            state.fetchPacksResult = null

        },
        setIsFetchingPacks: (state, action) => {
            state.isFetchingPacks = action.payload;
        },
    },
});

export const {

    setFetchPacksResult,
    setFetchPacksError,
    setIsFetchingPacks,

} = PacksSlice.actions;


export const fetchPacks = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getPacksUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsFetchingPacks(true));
    const response = axios(config)
        .then(response => {

            dispatch(setFetchPacksResult(response.data));
            dispatch(setFetchAddPackResult(null));
        })
        .catch((error) => {
            console.log(error);
            message.error(error.response.data.message)
            dispatch(setFetchPacksError(response.message));

        });

    return response.data;

}

export default PacksSlice.reducer;