import React, { useState } from 'react';
import { Button, Image, Modal, Row } from 'antd';
import Dialog from '../features/activation/components/Dialog';
const SuccessActivation = ({ visible}) => {

    return (
        <>

            <Modal
                open={visible}
                footer={null}
                closable={false}    
                            width={600}>
                <>
                    <Row style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "5vh"
                    }}>
                        <Dialog text="Hourra !!" />
                    </Row>
                    <Row style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                        <Image
                            src="/activation/Lilly.png"
                            preview={false} />
                    </Row>
                 
                    <Row style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "0vh"
                    }}>
                        <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "20px", color: "#616161" ,textAlign:"center"}}>Vous avez ajouté les modules à votre compte avec succès !</p>
                    </Row>
                    <Row style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "5vh"
                    }}>
                        <a href="https://app.cactusfrance.com/">
                            <Button className="customButton" style={{ color: "#fff", backgroundColor: "#39B13D", borderRadius: "100px" ,height:"50px"}}>CONTINUER VERS L'APPLICATION WEB</Button>
                        </a>
                    </Row>
                </>
            </Modal>
        </>
    );
};
export default SuccessActivation;