/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Layout, Card, Form, Checkbox, Input, Radio, Divider, InputNumber, Space, Select, Image, Switch, Button, Upload } from 'antd';
import { PlusOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';

import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../comps/pageHeader';
import { addItem, fetchItemCategory, fetchUploadItemColorMask, fetchUploadItemTexture, fetchUploadItemThumbnail, fetchUploadSprites } from './addItemSlice';
const randomString = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};

// const { Text } = Typography;
const { Content } = Layout;
const subCategories = {
    Cloth: [
        { key: 'Armor', label: 'T-shirt' },
        { key: 'Boots', label: 'chaussures' },
        { key: 'Gloves', label: 'Gants' },
        { key: 'Helmet', label: 'Casque' },
        { key: 'Cape', label: 'Cap' },
        { key: 'Skirt', label: 'Jupe' },
        { key: 'Pants', label: 'Pantalon' },
    ],
    Face: [
        { key: 'Hair', label: 'Cheveux' },
        { key: 'FacialHair', label: 'Poils' },
        { key: 'Eyebrow', label: 'Sourcil' },
        { key: 'Eyes', label: 'Yeux' },
        { key: 'Nose', label: 'Nez' },
        { key: 'Mouth ', label: 'Bouche' },
        { key: 'Ear ', label: 'Oreille' },
    ],
    Weapon: [
        { key: 'OneHanded', label: 'Arme' },
        { key: 'Shield', label: 'Bouclier' },

    ],
}
const statsLabel = {
    attaque: "Attaque", defense: "Défense", vitesse: "Vitesse", technique: "Technique", intelligence: "Intelligence"
}
const AddItem = () => {
    const { cat, section } = useParams();

    let navigate = useNavigate();

    // const pathname = useSelector((state) => state.dashboard.pathname);

    const dispatch = useDispatch();
    const [labelCat, setlabelCat] = useState("");

    useEffect(() => {
        const subCategory = subCategories[section].find((s) => s.key === cat);
        // console.log('subCategory  ', subCategory?.label);
        setlabelCat(subCategory?.label)
        dispatch(fetchItemCategory({ name: cat }));


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [itemStats, setitemStats] = useState({ attaque: 0, defense: 0, vitesse: 0, technique: 0, intelligence: 0 });

    const [addItemForm, setAddItemForm] = useState({
        folderPath: `items/${cat.toLocaleLowerCase()}_${randomString(10)}/`, // concatenating random string with "cat"
        category: null, name: "", rare: false,
        currency: "coins", isColorable: false, stats: itemStats, price: 0,
        sale: false, saleRate: 0, thumbnail: "", texture: "", colorMask: "", status: "active", sprites: []
    })

    const fetchItemCategoryResult = useSelector((state) => state.addItem.fetchItemCategoryResult);

    const itemThumbnailUrl = useSelector((state) => state.addItem.itemThumbnailUrl);
    const isUploadingItemThumbnail = useSelector((state) => state.addItem.isUploadingItemThumbnail);

    const itemTextureUrl = useSelector((state) => state.addItem.itemTextureUrl);
    const isUploadingItemTexture = useSelector((state) => state.addItem.isUploadingItemTexture);

    const isFetchingUploadSprites = useSelector((state) => state.addItem.isFetchingUploadSprites);
    const spritesUrls = useSelector((state) => state.addItem.spritesUrls);

    const isUploadingItemColorMask = useSelector((state) => state.addItem.isUploadingItemColorMask);
    const itemColorMaskUrl = useSelector((state) => state.addItem.itemColorMaskUrl);

    const handleInputStatsChange = (key, value) => {
        let statsTemp = itemStats
        statsTemp[key] = value
        setitemStats({ ...itemStats, [key]: value });
        setAddItemForm({ ...addItemForm, stats: statsTemp })
        console.log('addItemForm ', addItemForm);
    };

    useEffect(() => {
        if (fetchItemCategoryResult) {
            setAddItemForm({ ...addItemForm, category: fetchItemCategoryResult._id })
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchItemCategoryResult]);
    const addItemResult = useSelector((state) => state.addItem.addItemResult);

    // const isFetchingItems = useSelector((state) => state.lessons.isFetchingItems);
    useEffect(() => {
        if (itemThumbnailUrl) {
            setAddItemForm({ ...addItemForm, thumbnail: itemThumbnailUrl })
        }
    }, [itemThumbnailUrl]);
    const uploadItemThumbnailProps = {
        showUploadList: { showPreviewIcon: false },
        beforeUpload: (file) => {
            dispatch(
                fetchUploadItemThumbnail({ folder_path: addItemForm.folderPath }, file)
            );
            return false;
        },
    };

    const uploadSpritesProps = {
        showUploadList: { showRemoveIcon: false },

        beforeUpload: (files) => {
            dispatch(fetchUploadSprites({ folder_path: addItemForm.folderPath }, files))
            return false;
        },
        // fileList: state.fileList,

    };
    useEffect(() => {
        if (itemTextureUrl) {
            setAddItemForm({ ...addItemForm, texture: itemTextureUrl })
        }
    }, [itemTextureUrl]);
    useEffect(() => {
        if (spritesUrls.length > 0) {
            setAddItemForm({ ...addItemForm, sprites: spritesUrls })
        }
    }, [spritesUrls]);
    const uploadItemTextureProps = {
        showUploadList: { showRemoveIcon: false },
        beforeUpload: (file) => {
            dispatch(
                fetchUploadItemTexture({ folder_path: addItemForm.folderPath }, file)
            );
            return false;
        },
    };
    const uploadItemColorMaskProps = {
        beforeUpload: (file) => {
            dispatch(fetchUploadItemColorMask({ folder_path: addItemForm.folderPath }, file));
            return false;
        },
    };
    useEffect(() => {
        if (itemColorMaskUrl) {
            setAddItemForm({ ...addItemForm, colorMask: itemColorMaskUrl })
        }
    }, [itemColorMaskUrl]);
    useEffect(() => {
        if (addItemResult) {
            navigate(-1)
        }
    }, [addItemResult]);
    const handleConfirmAddItem = (e) => {
        // console.log('exerciceForm' ,exerciceForm);
        console.log('addItemForm ', addItemForm);
        dispatch(addItem(addItemForm));


    };
    return (

        <Content
            className="site-layout-background"
            style={{
                padding: 0,
                margin: 0,
                minHeight: 280,
            }}
        >
            <PageHeader title={"Ajouter un nouveau item (" + labelCat + " )"}

                breadcrumbItems={["Création de Contenu", "Avatar", "Liste des Items", "Ajouter un nouveau item"]} />

            <Card title={'Ajouter un Nouveau Item : ' + labelCat}
                className="cardItem"
                style={{ width: "95%", margin: "24px" }}
                bodyStyle={{ padding: "24px 80px 24px 80px" }}
                actions={[
                    <Checkbox checked={addItemForm.status === "active"} onChange={(e) => {
                        setAddItemForm({ ...addItemForm, status: e.target.checked ? "active" : "inactive" })
                    }}>Activer l’item à la création.</Checkbox>,

                    <Button type="primary"
                        onClick={handleConfirmAddItem}
                    // loading={isUpdatingQuestions}
                    >Confirmer</Button>,
                ]}
            >
                <Row justify={"space-between"}>
                    <Col span={11}>
                        <Form labelCol={{ span: 10, }} wrapperCol={{ span: 14, }} layout="vertical"
                        >
                            {/* <Form.Item label="Checkbox" name="disabled" valuePropName="checked">inactive
                        <Checkbox>Checkbox</Checkbox>
                    </Form.Item> */}

                            <Form.Item style={{ fontWeight: "normal" }} label="Nom de l’item :">
                                <Input
                                    value={addItemForm.name}
                                    // style={{ width: "488px" }}
                                    onChange={(e) => {
                                        setAddItemForm({ ...addItemForm, name: e.target.value })
                                    }}
                                />
                            </Form.Item>
                            <Form.Item >
                                <Radio.Group value={addItemForm.rare}
                                    onChange={(e) => {
                                        setAddItemForm({ ...addItemForm, rare: e.target.value })
                                    }}
                                >

                                    <Radio value={false}> Normal </Radio>
                                    <Radio value={true}> Rare </Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Divider />
                        </Form>
                        <Form layout="horizontal"  >
                            <Space style={{ width: "100%" }} size={15}>
                                <Form.Item >
                                    <InputNumber
                                        style={{ minWidth: "100px" }}

                                        min={0}
                                        value={addItemForm.price}
                                        // style={{ width: "488px" }}
                                        onChange={(value) => {
                                            setAddItemForm({ ...addItemForm, price: value })
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item >
                                    <Select
                                        disabled={addItemForm.price < 1}
                                        style={{ minWidth: "120px" }}
                                        onChange={(value) => {
                                            setAddItemForm({ ...addItemForm, currency: value })
                                        }}
                                        value={addItemForm.currency}
                                        options={[
                                            {
                                                value: 'coins',
                                                label: 'Pièce',
                                            },
                                            {
                                                value: 'diamonds',
                                                label: 'Diamants',
                                            },
                                        ]}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    {addItemForm.currency === "coins" ?
                                        <Image src="/Coin.svg" width={24} height={24} alt="coin" preview={false} />

                                        :
                                        <Image src="/Diamond.svg" width={24} height={24} alt="coin" preview={false} />

                                    }
                                </Form.Item>

                            </Space>
                            <Space>

                                <Form.Item label="En Soldes :" labelAlign="left" labelCol={{ span: 10 }}
                                    style={{ width: '180px' }}
                                >
                                    <Switch checked={addItemForm.sale}
                                        onChange={(checked) => {
                                            setAddItemForm({
                                                ...addItemForm,
                                                sale: checked,
                                            });
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Réduction de :" labelAlign="left">
                                    <InputNumber
                                        disabled={!addItemForm.sale}
                                        step={1}
                                        value={!addItemForm.sale ? 0 : addItemForm.saleRate * 100}
                                        formatter={(value) => `% ${value}`}
                                        parser={(value) => value.replace('%', '')}
                                        onChange={(value) => {
                                            setAddItemForm({
                                                ...addItemForm,
                                                saleRate: (value / 100),
                                            });
                                        }}
                                    />
                                </Form.Item>

                            </Space>
                            <Divider />

                            <Form labelAlign="left" labelCol={{ span: 10, }}  >
                                <Row >

                                    {Object.keys(itemStats).map((key) => (
                                        <Col span={11}>
                                            <Form.Item label={statsLabel[key]}>
                                                <InputNumber
                                                    min={0}
                                                    key={key}
                                                    value={itemStats[key]}
                                                    onChange={(value) => handleInputStatsChange(key, value)}
                                                />
                                            </Form.Item>

                                        </Col>
                                    ))}
                                </Row>

                            </Form>
                        </Form>
                    </Col>

                    <Col span={11}>
                        <Form labelCol={{ span: 10, }} wrapperCol={{ span: 14, }}
                            layout="vertical">
                            <Form.Item className="upload"
                                label="Miniature :" valuePropName="fileList" rules={[{ required: true, message: 'Voulliez saiser l\'image du variant!' }]}>
                                <Upload maxCount={1}
                                    disabled={itemThumbnailUrl}
                                    accept="image/*"

                                    {...uploadItemThumbnailProps}
                                    listType="picture-card">
                                    <div>
                                        {isUploadingItemThumbnail ? (<LoadingOutlined />
                                        ) : (<PlusOutlined />)}

                                        <div style={{ marginTop: 8 }}>Upload</div>
                                    </div>
                                </Upload>
                            </Form.Item>
                            <Form.Item className="upload" label="Texture :" valuePropName="fileList" rules={[{ required: true, message: 'Voulliez saiser l\'image du variant!' }]}>
                                <Upload
                                    maxCount={1}
                                    disabled={itemTextureUrl}

                                    accept="image/*"
                                    {...uploadItemTextureProps}>
                                    <Button
                                        disabled={itemTextureUrl}
                                        loading={isUploadingItemTexture}
                                        icon={<UploadOutlined />}>Cliquez pour télécharger</Button>
                                </Upload>

                            </Form.Item>
                            <Form.Item className="upload" label="Sprites :" valuePropName="fileList" rules={[{ required: true, message: 'Voulliez saiser l\'image du variant!' }]}>
                                <Upload
                                    multiple
                                    accept="image/*"
                                    {...uploadSpritesProps}>
                                    <Button
                                        loading={isFetchingUploadSprites}
                                        icon={<UploadOutlined />}>Cliquez pour télécharger</Button>
                                </Upload>

                            </Form.Item>
                            <Divider />

                            <Form.Item className="upload" label="Couleur :" valuePropName="fileList" rules={[{ required: true, message: 'Voulliez saiser l\'image du variant!' }]}>
                                <Space direction='vertical'
                                    size={10}>
                                    <Checkbox checked={addItemForm.isColorable} onChange={(e) => {
                                        setAddItemForm({ ...addItemForm, isColorable: e.target.checked })
                                    }}>Colorable</Checkbox>
                                    <Upload
                                        multiple
                                        disabled={!addItemForm.isColorable || itemColorMaskUrl}

                                        accept="image/*"
                                        {...uploadItemColorMaskProps}>
                                        <Button
                                            disabled={!addItemForm.isColorable || itemColorMaskUrl}

                                            loading={isUploadingItemColorMask}
                                            icon={<UploadOutlined />}>Cliquez pour télécharger</Button>
                                    </Upload>
                                </Space>

                            </Form.Item>
                        </Form>
                    </Col>
                </Row>



            </Card>

        </Content>

    );
}

export default AddItem;