

import { Badge, Card, Col, Row, } from 'antd';

import "./finance.css"


const Top5Days = ({ topFive }) => {


    return (

        <Card
            title="Top 5 des piques de revenus :"
            style={{ backgroundColor: "white", cursor: "default", boxShadow: "none", minHeight: "200px" }}
            headStyle={{ fontWeight: "400", borderBottom: "0", }}

            bordered={false}


        >

            {
                topFive.map((item, index) => (
                    <Row style={{ marginBottom: "10px" }}>
                        <Col span={2}>
                            <Badge count={index + 1} />
                        </Col>
                        <Col span={8}>
                            {item.period}
                        </Col>
                        <Col span={14} style={{ display: "flex", justifyContent: "flex-end" }}>
                            {(item.price).toFixed(2)} €
                        </Col>

                    </Row>
                ))
            }






        </Card>
    );
}

export default Top5Days;