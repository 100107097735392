import { createSlice } from "@reduxjs/toolkit";

import {  getaddExercicetUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";

export const AddExerciceSlice = createSlice({
    name: "addExo",
    initialState: {

        addExerciceResult: null,
        addExerciceError: null,
        isAddingExercice: false,
    },
    reducers: {
        setAddExerciceResult: (state, action) => {
            state.addExerciceResult = action.payload;
            state.addExerciceError = null;
            state.isAddingExercice = false;
        },
        setAddExerciceError: (state, action) => {
            state.addExerciceError = action.payload;
            state.isAddingExercice = false;
            state.addExerciceResult = null

        },
        setIsAddingExercice: (state, action) => {
            state.isAddingExercice = action.payload;
        },
    },
});

export const {
    setAddExerciceResult,
    setAddExerciceError,
    setIsAddingExercice,

} = AddExerciceSlice.actions;


export const addExercice = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getaddExercicetUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsAddingExercice(true));
    const response = axios(config)
        .then(response => {

            dispatch(setAddExerciceResult(response.data));
        })
        .catch((error) => {
            message.error(error.response.data)
            dispatch(setAddExerciceError(error.response.data));

        });

    return response.data;

}



export default AddExerciceSlice.reducer;