import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { getThemesListUrl, profile_url, pingUrl } from "../../constants";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    fetchProfileResult: null,
    fetchProfileError: null,
    isFetchingProfile: false,
    value: 2,
    pathname: "",
    token: localStorage.token,
    currentMenuItem: "admins",
    fetchThemesResult: null,

    ping: null,
    pinginigErr: null
  },
  reducers: {
    setPathname: (state, action) => {
      state.pathname = action.payload;
    },
    setCurrentMenuItem: (state, action) => {
      state.currentMenuItem = action.payload;
    },
    setProfileResult: (state, action) => {
      state.fetchProfileResult = action.payload;
      state.isFetchingProfile = false;
    },
    setProfileError: (state, action) => {
      state.fetchProfileError = action.payload;
      state.isFetchingProfile = false;
    },
    setFetchingProfile: (state, action) => {
      state.isFetchingProfile = action.payload;
    },
    setFetchThemesResult: (state, action) => {
      state.fetchThemesResult = action.payload;
    },
    setPingError: (state, action) => {
      state.pinginigErr = action.payload;
    },
    setPingResult: (state, action) => {
      state.ping = action.payload;
      state.pinginigErr = null;
    },

  },
});

export const {
  setPathname,
  setProfileResult,
  setProfileError,
  setCurrentMenuItem,
  setFetchingProfile,
  setFetchThemesResult,

  setPingError,
  setPingResult
} = dashboardSlice.actions;

export const fetchUserProfile = () => (dispatch, getState) => {
  const config = {
    method: "post",
    url: profile_url,
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
  };

  dispatch(setFetchingProfile(true));
  const response = axios(config)
    .then((response) => {
      dispatch(setProfileResult(response.data));
    })
    .catch((error) => {
      dispatch(setProfileError(error.response.message));
    });


  return response.data;
};
export const fetchThemes = (data) => (dispatch, getState) => {

  const config = {
    method: "post",
    url: getThemesListUrl,
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
    data
  };
  const response = axios(config)
    .then(response => {

      dispatch(setFetchThemesResult(response.data));
    })
    .catch((error) => {
      console.log(error);
      message.error(error.response.data.message)

    });

  return response.data;

}
export const Ping = (data) => (dispatch, getState) => {

  const config = {
    method: "post",
    url: pingUrl,
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
    data
  };
  const response = axios(config)
    .then(response => {

      dispatch(setPingResult(response.data));
    })
    .catch((error) => {
      dispatch(setPingError("Err"));


    });

  return response.data;

}
// export const incrementAsync = (amount) => (dispatch) => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount));
//   }, 1000);
// };

// export const selectCount = (state) => state.dashboard.value;

export default dashboardSlice.reducer;
