import { createSlice } from "@reduxjs/toolkit";

import { getLessonsUrl } from "../../constants";
import axios from "axios";
export const LessonsSlice = createSlice({
    name: "lessons",
    initialState: {

        fetchLessonsResult: null,
        fetchLessonsError: null,
        isFetchingLessons: false,
    },
    reducers: {

        setFetchLessonsResult: (state, action) => {
            state.fetchLessonsResult = action.payload;
            state.isFetchingLessons = false;
        },

        setFetchLessonsError: (state, action) => {
            state.fetchLessonsError = action.payload;
            state.isFetchingLessons = false;
            state.fetchLessonsResult = null

        },
        setIsFetchingLessons: (state, action) => {
            state.isFetchingLessons = action.payload;
        },
    },
});

export const {

    setFetchLessonsResult,
    setFetchLessonsError,
    setIsFetchingLessons,

} = LessonsSlice.actions;


export const fetchLessons = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getLessonsUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsFetchingLessons(true));
    const response = axios(config)
        .then(response => {

            dispatch(setFetchLessonsResult(response.data));
        })
        .catch((error) => {
            console.log(error);
            // message.error(error.response.data.message)
            dispatch(setFetchLessonsError(response.message));

        });

    return response.data;

}

export default LessonsSlice.reducer;