

import { Col, DatePicker, Form, Input, InputNumber, Modal, Row, Switch } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { fetchSelectCategories } from "../categories/categoriesSlice";
import { AddnewPromoCode } from "./AddPromoCodeSlice";





const AddPromoCode = ({ visible, setVisible }) => {

    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        code: "",
        saleRate: 0,
        unlimitedStock: false,
        unlimitedTime: false,
        usesLeft: 0,
        numberOfUses: 0,
        expireAt: null,
    });
    const fetchAddPromoCodeResult = useSelector((state) => state.addPromoCode?.fetchAddPromoCodeResult);
    const isFetchingAddPromoCode = useSelector((state) => state.addPromoCode?.isFetchingAddPromoCode);


    const handleInputChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleFormSubmit = (event) => {
        dispatch(AddnewPromoCode(formData));

    };
    useEffect(() => {
        if (fetchAddPromoCodeResult) {
            setFormData({
                code: "",
                saleRate: 0,
                unlimitedStock: false,
                unlimitedTime: false,
                usesLeft: 0,
                numberOfUses: 0,
                expireAt: null,
            })
            setVisible(false)

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchAddPromoCodeResult]);



    return (
        < >
            <Modal title="Générer un Code Promo"

                open={visible}
                okText="Confirmer"
                centered

                maskClosable={false}
                closable={false}
                onOk={handleFormSubmit}
                okButtonProps={{
                    loading: isFetchingAddPromoCode,
                    disabled: (formData.code === "" || formData.saleRate < 1) || (!formData.expireAt && !formData.unlimitedTime) || (formData.numberOfUses < 1 && !formData.unlimitedStock)
                }}
                onCancel={() =>
                    setVisible(false)}
                width={600}
                maskStyle={{
                    backgroundColor: "#10101088",
                    backdropFilter: "blur(0.7rem)",
                }}>
                <Row justify="center" style={{ marginTop: "40px" }}>


                    <Col span={24} >
                        <Form
                            // layout="vertical"
                            labelAlign="right"
                            labelCol={{ span: 5, offset: 0 }}

                            style={{ display: "block", }} >

                            <Form.Item
                                name="code"
                                label="Code"

                            >
                                <Input
                                    value={formData.code}
                                    name="code"
                                    placeholder="Entrez le code promo"
                                    style={{ width: "80%" }}
                                    onChange={handleInputChange}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Réduction"
                                name="solde"
                            >
                                <InputNumber addonAfter="%"
                                    min={0}
                                    max={100}
                                    precision={0}
                                    value={formData.saleRate}
                                    name="saleRate"
                                    placeholder="%"
                                    style={{ width: "23%" }}
                                    onChange={(value) => {
                                        setFormData({
                                            ...formData,
                                            saleRate: value,
                                        });
                                    }} />
                            </Form.Item>
                            <Form.Item label="expirable">
                                <Switch
                                    checked={!formData.unlimitedTime}
                                    onChange={(checked) => {
                                        setFormData({
                                            ...formData,
                                            unlimitedTime: !checked,
                                            expireAt: null
                                        });
                                    }}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Expire le" >
                                <DatePicker
                                    disabled={formData.unlimitedTime}
                                    showTime onChange={(value, dateString) => {
                                        setFormData({
                                            ...formData,
                                            expireAt: moment(dateString).format(),
                                        });
                                    }} />

                            </Form.Item>
                            <Form.Item label="stock limité">
                                <Switch
                                    checked={!formData.unlimitedStock}
                                    onChange={(checked) => {
                                        setFormData({
                                            ...formData,
                                            unlimitedStock: !checked,
                                            numberOfUses: 0,
                                            usesLeft: 0,
                                        });
                                    }}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Utilisations" >
                                <InputNumber
                                    disabled={formData.unlimitedStock}
                                    min={0}
                                    // max={100}
                                    precision={0}
                                    value={formData.numberOfUses}
                                    // step={0.01}
                                    name="numberOfUses"
                                    style={{ width: "23%" }}

                                    onChange={(value) => {
                                        setFormData({
                                            ...formData,
                                            numberOfUses: value,
                                            usesLeft: value,
                                        });
                                    }} />
                            </Form.Item>

                        </Form>
                    </Col>


                </Row>
            </Modal>

        </>
    );
}

export default AddPromoCode;