

import { Col, Layout, Row } from "antd";
import { useEffect } from "react";
// import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../comps/pageHeader";
import CounterComp from "./counterComp";

import { UserOutlined, DesktopOutlined } from '@ant-design/icons';
import { fetchUsersCount } from "./statisticsSlice";
import { TfiAndroid } from "react-icons/tfi"
import { GrApple } from "react-icons/gr"
import SignupChart from "./signupChart";
import ModulesAchv from "./modulesAchivmnt";
import ModuleProgress from "./moduleProgLesson";
import DefisChartChart from "./defisChart";
import Onboardingdata from "./onboardingdatas";

const { Content } = Layout;

const Stats = () => {
    const fetchUserCountResult = useSelector((state) => state.userscount.fetchUserCountResult);
    const isFetchingusersCount = useSelector((state) => state.userscount.isFetchingusersCount);



    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUsersCount({}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Content
            className="site-layout-background"
            style={{
                padding: 0,
                margin: 0,
                minHeight: 280,
            }}
        >
            <PageHeader title={"Analytiques de Cactus"}
                breadcrumbItems={["Statistiques Cactus", "Analytiques"]} />
            <Row gutter={[24, 0]} justify={"space-between"} style={{ padding: "24px" }}  >
                <Col span={6}>
                    <CounterComp value={fetchUserCountResult?.stats.totalUsers} icon={<UserOutlined />} label={"Inscrits aujourd'hui"}
                        title={"Total d’utilisateurs inscrits"} todaysValue={fetchUserCountResult?.stats.usersToday} loading={isFetchingusersCount} />
                </Col>
                <Col span={6}>
                    <CounterComp value={fetchUserCountResult?.stats.androidUsers} icon={<TfiAndroid color="#7cb342" />} label={"Inscrits aujourd'hui"}
                        title={"Actifs sur Android"} todaysValue={fetchUserCountResult?.stats.androidUsersToday} loading={isFetchingusersCount} />
                </Col>

                <Col span={6}>

                    <CounterComp value={fetchUserCountResult?.stats.iosUsers} icon={<GrApple color="#8C8C8C" />} label={"Inscrits aujourd'hui"}
                        title={"Actifs sur iOs"} todaysValue={fetchUserCountResult?.stats.iosUsersToday} loading={isFetchingusersCount} />
                </Col>

                <Col span={6}>

                    <CounterComp value={fetchUserCountResult?.stats.webUsers} icon={<DesktopOutlined style={{ color: "#39B13D" }} />} label={"Inscrits aujourd'hui"}
                        title={"Actifs sur Web"} todaysValue={fetchUserCountResult?.stats.webUsersToday} loading={isFetchingusersCount} />
                </Col>

            </Row>
            <Row style={{ padding: "24px" }}>
                <Col span={24}>
                    <SignupChart />
                </Col>
            </Row>
            <Row style={{ padding: "24px" }}>
                <Col span={24}>
                    <ModulesAchv />
                </Col>
            </Row>
            <Row style={{ padding: "24px" }}>
                <Col span={24}>
                    <ModuleProgress />
                </Col>
            </Row>
            <Row style={{ padding: "24px" }}>
                <Col span={24}>
                    <DefisChartChart />
                </Col>
            </Row>
            <Row style={{ padding: "24px" }}>
                <Col span={24}>
                    <Onboardingdata />
                </Col>
            </Row>


        </Content>
    );
}

export default Stats;