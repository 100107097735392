import { createSlice } from "@reduxjs/toolkit";

import { deleteDefiUrl, getSingleDefiUrl, updateDefiUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";
import { fetchDefis } from "../defis/defisListSlice";
export const SingleDefiSlice = createSlice({
    name: "singleDefi",
    initialState: {

        fetchUpdateDefiResult: null,
        fetchUpdateDefiError: null,
        isUpdatingDefi: false,

        fetchSingleDefiResult: null,
        fetchSingleDefiError: null,
        isFetchingSingleDefi: false,

        deleteSingleDefiResult: null,
        deleteSingleDefiError: null,
        isDeletingSingleDefi: false,
    },
    reducers: {

        setFetchUpdateDefiResult: (state, action) => {
            state.fetchUpdateDefiResult = action.payload;
            state.isUpdatingDefi = false;
        },

        setFetchUpdateDefiError: (state, action) => {
            state.fetchUpdateDefiError = action.payload;
            state.isUpdatingDefi = false;
            state.fetchUpdateDefiResult = null

        },
        setIsUpdatingDefi: (state, action) => {
            state.isUpdatingDefi = action.payload;
        },

        setFetchSingleDefiResult: (state, action) => {
            state.fetchSingleDefiResult = action.payload;
            state.fetchSingleDefiError = null;

            state.isFetchingSingleDefi = false;
        },

        setFetchSingleDefiError: (state, action) => {
            state.fetchSingleDefiError = action.payload;
            state.isFetchingSingleDefi = false;
            state.fetchSingleDefiResult = null

        },
        setIsFetchingSingleDefi: (state, action) => {
            state.isFetchingSingleDefi = action.payload;
        },

        setDeleteSingleDefiResult: (state, action) => {
            state.deleteSingleDefiResult = action.payload;
            state.deleteSingleDefiError = null;

            state.isDeletingSingleDefi = false;
        },

        setDeleteSingleDefiError: (state, action) => {
            state.deleteSingleDefiError = action.payload;
            state.isDeletingSingleDefi = false;
            state.deleteSingleDefiResult = null

        },
        setIsDeleteingSingleDefi: (state, action) => {
            state.isDeletingSingleDefi = action.payload;
        },
    },
});

export const {

    setFetchUpdateDefiResult,
    setFetchUpdateDefiError,
    setIsUpdatingDefi,

    setFetchSingleDefiResult,
    setFetchSingleDefiError,
    setIsFetchingSingleDefi,

    setDeleteSingleDefiResult,
    setDeleteSingleDefiError,
    setIsDeleteingSingleDefi,

} = SingleDefiSlice.actions;

export const fetchSingleDefi = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getSingleDefiUrl,

        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsFetchingSingleDefi(true));
    const response = axios(config)
        .then(response => {
            dispatch(setFetchSingleDefiResult(response.data));
        })
        .catch((error) => {
            console.log(error);
            message.error(error.response.data.message)
            dispatch(setFetchSingleDefiError(error.response.data.message));

        });

    return response.data;

}

export const UpdateDefi = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: updateDefiUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsUpdatingDefi(true));
    const response = axios(config)
        .then(response => {
            message.success(response.data.message)
            dispatch(setFetchUpdateDefiResult(response.data));
            dispatch(fetchDefis());
        })
        .catch((error) => {
            console.log(error);
            message.error(error.response.data.message)
            dispatch(setFetchUpdateDefiError(response.message));

        });

    return response.data;

}

export const deleteSingleDefi = (data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: deleteDefiUrl,

        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsDeleteingSingleDefi(true));
    const response = axios(config)
        .then(response => {
            message.success(response.data.message)
            dispatch(fetchDefis());

            dispatch(setDeleteSingleDefiResult(response.data));
        })
        .catch((error) => {
            console.log(error);
            message.error(error.response.data.message)
            dispatch(setDeleteSingleDefiError(error.response.data.message));

        });

    return response.data;

}

export default SingleDefiSlice.reducer;