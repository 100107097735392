import Icon from '@ant-design/icons';

const Level0 = () => <svg viewBox="0 0 38 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33 3C34.1046 3 35 3.89543 35 5V27C35 28.1046 34.1046 29 33 29C31.8954 29 31 28.1046 31 27V5C31 3.89543 31.8954 3 33 3ZM19 13C20.1046 13 21 13.8954 21 15V27C21 28.1046 20.1046 29 19 29C17.8954 29 17 28.1046 17 27V15C17 13.8954 17.8954 13 19 13ZM5 23C6.10457 23 7 23.8954 7 25V27C7 28.1046 6.10457 29 5 29C3.89543 29 3 28.1046 3 27V25C3 23.8954 3.89543 23 5 23ZM38 5C38 2.23858 35.7614 0 33 0C30.2386 0 28 2.23858 28 5V27C28 29.7614 30.2386 32 33 32C35.7614 32 38 29.7614 38 27V5ZM24 15C24 12.2386 21.7614 10 19 10C16.2386 10 14 12.2386 14 15V27C14 29.7614 16.2386 32 19 32C21.7614 32 24 29.7614 24 27V15ZM10 25C10 22.2386 7.76142 20 5 20C2.23858 20 0 22.2386 0 25V27C0 29.7614 2.23858 32 5 32C7.76142 32 10 29.7614 10 27V25Z" fill="#EE59A0" />
</svg>;

const Level1 = () => <svg viewBox="0 0 38 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33 3C34.1046 3 35 3.89543 35 5V27C35 28.1046 34.1046 29 33 29C31.8954 29 31 28.1046 31 27V5C31 3.89543 31.8954 3 33 3ZM19 13C20.1046 13 21 13.8954 21 15V27C21 28.1046 20.1046 29 19 29C17.8954 29 17 28.1046 17 27V15C17 13.8954 17.8954 13 19 13ZM38 5C38 2.23858 35.7614 0 33 0C30.2386 0 28 2.23858 28 5V27C28 29.7614 30.2386 32 33 32C35.7614 32 38 29.7614 38 27V5ZM24 15C24 12.2386 21.7614 10 19 10C16.2386 10 14 12.2386 14 15V27C14 29.7614 16.2386 32 19 32C21.7614 32 24 29.7614 24 27V15ZM10 25C10 22.2386 7.76142 20 5 20C2.23858 20 0 22.2386 0 25V27C0 29.7614 2.23858 32 5 32C7.76142 32 10 29.7614 10 27V25Z" fill="#EE59A0" />
</svg>;

const Level2 = () => <svg viewBox="0 0 38 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33 3C34.1046 3 35 3.89543 35 5V27C35 28.1046 34.1046 29 33 29C31.8954 29 31 28.1046 31 27V5C31 3.89543 31.8954 3 33 3ZM38 5C38 2.23858 35.7614 0 33 0C30.2386 0 28 2.23858 28 5V27C28 29.7614 30.2386 32 33 32C35.7614 32 38 29.7614 38 27V5ZM24 15C24 12.2386 21.7614 10 19 10C16.2386 10 14 12.2386 14 15V27C14 29.7614 16.2386 32 19 32C21.7614 32 24 29.7614 24 27V15ZM10 25C10 22.2386 7.76142 20 5 20C2.23858 20 0 22.2386 0 25V27C0 29.7614 2.23858 32 5 32C7.76142 32 10 29.7614 10 27V25Z" fill="#EE59A0" />
</svg>;

const Level3 = () => <svg viewBox="0 0 38 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M38 5C38 2.23858 35.7614 0 33 0C30.2386 0 28 2.23858 28 5V27C28 29.7614 30.2386 32 33 32C35.7614 32 38 29.7614 38 27V5ZM24 15C24 12.2386 21.7614 10 19 10C16.2386 10 14 12.2386 14 15V27C14 29.7614 16.2386 32 19 32C21.7614 32 24 29.7614 24 27V15ZM10 25C10 22.2386 7.76142 20 5 20C2.23858 20 0 22.2386 0 25V27C0 29.7614 2.23858 32 5 32C7.76142 32 10 29.7614 10 27V25Z" fill="#EE59A0" />
</svg>;



export default function LevelIcon(props) {
    const Level0Icon = (props) => <Icon component={Level0} {...props} />
    const Level1Icon = (props) => <Icon component={Level1} {...props} />
    const Level2Icon = (props) => <Icon component={Level2} {...props} />
    const Level3Icon = (props) => <Icon component={Level3} {...props} />

    switch (props.level) {
        case 0:
            return (<Level0Icon style={{
                width: '25px',
                height: '25px',
                marginRight:"10px"
            }} />);
            break;
        case 1:
            return (<Level1Icon style={{
                width: '25px',
                height: '25px',
                marginRight:"10px"
              }} />);
            break;
        case 2:
            return (<Level2Icon style={{
                width: '25px',
                height: '25px',
                marginRight:"10px"
              }} />);
            break;
        case 3:
            return (<Level3Icon style={{
                width: '25px',
                height: '25px',
                marginRight:"10px"
              }} />);
            break;
        default:
            return (<Level0Icon style={{
                width: '25px',
                height: '25px',
                marginRight:"10px"
              }} />);
            break;
    }
}