

import { Button, Card, Form, Input, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";



const gridStyle = {
    width: 'auto',
    textAlign: 'start',
    padding: '8px',
    border: "0px",
    marginBottom: '10px'
    // fontWeight: 'bold'


};
const PrposQst = ({ switcherEdit, setQuestionsForm, questionsForm, indexQ }) => {

    // const dispatch = useDispatch();

    // const propositions = JSON.parse(JSON.stringify(exerciceForm.propositions))
    const [propositions, setPropositions] = useState([]);

    // const dispatch = useDispatch();
    useEffect(() => {
        if (questionsForm) {
            // console.log('questionsForm : ', questionsForm);
            setPropositions(questionsForm.questions[indexQ].propositions)
            // console.log('propositions : ' + indexQ + " :", propositions);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionsForm]);


    return (
        < >
            <Card
                headStyle={{ padding: "0px" }}
                bodyStyle={{ border: "0px", padding: "0px" }}
                style={{ width: '100%', backgroundColor: 'transparent' }}

                bordered={false}>
                {!switcherEdit ?

                    questionsForm.questions[indexQ].propositions.map((prop, index) =>
                        <Card.Grid key={index} style={gridStyle}>{prop}</Card.Grid>
                    )

                    :
                    questionsForm.questions[indexQ].propositions.length > 0 ?
                        <Form name="dynamic_form_item"
                        >
                            <Space size={[12, 0]} wrap>
                                <Form.Item>

                                    <Button type="primary" onClick={() => {
                                        let propsE = [...questionsForm.questions]
                                        propsE[indexQ].propositions.push('')
                                        setQuestionsForm({ ...questionsForm, questions: propsE })
                                    }} block icon={<PlusOutlined />}>
                                        Ajouter
                                    </Button>
                                </Form.Item>
                                {propositions.map((item, index) => (
                                    <Space
                                        key={index}
                                        style={{
                                            display: 'flex',
                                        }}
                                        align="baseline"
                                    >

                                        <Form.Item
                                            name={[index, 'last']}
                                             
                                        >
                                            <Input
                                                style={{ width: "100px" }}
                                                defaultValue={item}
                                                value={questionsForm?.questions[indexQ].propositions[index]}
                                                onChange={(e) => {
                                                    let propsE = [...questionsForm.questions]
                                                    propsE[indexQ].propositions[index] = (e.target.value).trim()
                                                    setQuestionsForm({ ...questionsForm, questions: propsE })
                                                }}
                                            />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => {
                                            // remove(index)
                                            let propsE = [...questionsForm.questions]
                                            propsE[indexQ].propositions.splice(index, 1)
                                            setQuestionsForm({ ...questionsForm, questions: propsE })

                                        }
                                        } />

                                    </Space>
                                ))}

                            </Space>


                        </Form>
                        : null
                }
            </Card>

        </>
    );
}

export default PrposQst;