import { Row, Col, Typography, Layout, Space, Progress } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchUserProgressions } from './userSlice';
import { useParams } from 'react-router-dom';

const { Title, Text } = Typography;


const { Content } = Layout;

const UserProgression = ({ title, breadcrumbItems }) => {
    const dispatch = useDispatch();

    const { id } = useParams();

    const fetchUserProgressionsResult = useSelector((state) => state.user.fetchUserProgressionsResult);

    useEffect(() => {
        dispatch(fetchUserProgressions({ id }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <Content
            className="site-layout-background"
            style={{
                padding: 0,
                margin: 0,
                minHeight: 400,
            }}
        >

            <Row>
                <Col span={24} style={{ display: 'flex', justifyContent: "center" }}>
                    <Title level={4} style={{ marginBottom: "0" }}>Progressions des Modules</Title>
                </Col>
            </Row>
            <Row justify={"start"} style={{ margin: "24px", minHeight: "100px" }}  >





                {fetchUserProgressionsResult?.data.map((item, index) => {
                    return (<>
                        <Col className="gutter-row" span={6} style={{ display: 'flex', justifyContent: "center", marginRight: "24px", marginBottom: "24px" }}  >

                            <Space

                                direction="vertical"
                                size="middle"
                                align='center'
                                style={{ display: 'flex', alignItems: 'center', width: '100%' }}
                            >
                                <Title level={5} >Module {item.module.seq}</Title>

                                <Progress

                                    size='small'
                                    // eslint-disable-next-line eqeqeq
                                    type="circle" percent={item.module.seq === 1 ? (item.progress) * 100 / 10:(item.progress) * 100 / 42}
                                    format={(percent) => `${Math.round(percent)}%`}
                                    strokeColor='#39B13D'
                                />
                                <Text  >Leçons terminées :{item.progress}{item.module.seq === 1 ? "/10":"/42"}</Text>

                            </Space>


                        </Col>
                    </>

                    )
                })
                }




            </Row>

        </Content>
    );
}

export default UserProgression;