import React, { useEffect, useState } from 'react';
import {

    Col,
    Button,
    Divider,
    Row,
    Typography,
    Input,
    Select,
    Drawer,
    Layout,
    Form,
    InputNumber,
    Space,
    Image,

} from "antd";
import { Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchSinglePack, UpdatePack } from "./singlePackSlice";
import {
    CloseCircleOutlined,
    CheckCircleTwoTone,
    LoadingOutlined,
} from "@ant-design/icons";

import { fetchPacks } from './packsListSlice';

const { Text, Paragraph } = Typography;

const SinglePack = ({ packID, openDrawer, onClose }) => {
    const dispatch = useDispatch();

    const fetchSinglePackResult = useSelector((state) => state.singlePack?.fetchSinglePackResult);
    const isFetchingSinglePack = useSelector((state) => state.singlePack?.isFetchingSinglePack);

    const fetchUpdatePackResult = useSelector((state) => state.singlePack.fetchUpdatePackResult);
    const isUpdatingPack = useSelector((state) => state.singlePack.isUpdatingPack);


    const [state, setstate] = useState({
        switcher: false,
        disable: false,
        editSinglePack: false,
    });
    const [edit, setEdit] = useState({
        id: null,
        name: "", description: "", amount: 0, currency: "", recurringPayment: false,
        price: 0, sale: false, saleRate: 0, status: "active", type: "", inAppProductID: null
    });
    useEffect(() => {
        if (packID) {
            dispatch(fetchSinglePack({ id: packID }));
            setEdit({ ...edit, id: packID })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [packID]);
    useEffect(() => {
        if (fetchUpdatePackResult && packID) {
            dispatch(fetchSinglePack({ id: packID }));

            setstate({
                ...state,
                switcher: false,
                disable: false,
                editSingleVariant: false,
            });

            dispatch(fetchPacks({}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps, no-use-before-define
    }, [fetchUpdatePackResult]);


    const handelUpdateInfo = () => {

        dispatch(UpdatePack({ id: packID, ...edit }))


    };
    // const onClose = () => {
    //   setOpen(false);
    // };
    const handleInputChange = (event) => {
        setEdit({ ...edit, [event.target.name]: event.target.value });
    };
    const { Content } = Layout;
    const handleSwitchStatus = ({ checked, id }) => {
        let status = checked ? "active" : "inactive"
        setEdit({ ...edit, status: status })
    };


    return (
        <Drawer title="Pack" placement="right" onClose={onClose} width={500}
            open={openDrawer} closable={false} maskClosable={!state.switcher}
            extra={<Switch
                checkedChildren="modifier"
                unCheckedChildren="modifier"
                disabled={state.disable}
                checked={state.switcher}
                // style={{ marginLeft: "90%", marginBottom: "24px" }}
                onChange={(e) => {

                    setstate({
                        ...state,
                        switcher: true,
                        disable: true,
                        editSinglePack: true,
                    });
                    setEdit({
                        ...edit,
                        name: fetchSinglePackResult?.data.name,
                        description: fetchSinglePackResult?.data.description,
                        inAppProductID: fetchSinglePackResult?.data.inAppProductID,
                        amount: fetchSinglePackResult?.data.amount,
                        currency: fetchSinglePackResult?.data.currency,
                        price: fetchSinglePackResult?.data.price,
                        sale: fetchSinglePackResult?.data.sale,
                        saleRate: fetchSinglePackResult?.data.saleRate,
                        status: fetchSinglePackResult?.data.status,
                        type: fetchSinglePackResult?.data.type

                    });
                }}
            />}
        >
            <Content className="site-layout-background" style={{ margin: 0, minHeight: 280 }} >
                <Row gutter={[25, 8]} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{
                        background: "white", height: "100%", minHeight: "30vh"
                    }} >
                        {!isFetchingSinglePack && fetchSinglePackResult && !isUpdatingPack ? (
                            <>
                                <Content>
                                    {
                                        <div>
                                            <Form
                                                labelCol={{ span: 8 }}
                                                // wrapperCol={{ span: 14 }}
                                                layout="horizontal"
                                                labelAlign='right'
                                            >

                                                <Form.Item label="Nom">
                                                    {state.switcher ?
                                                        <Input
                                                            name="name"

                                                            value={edit.name}
                                                            onChange={handleInputChange}
                                                        />
                                                        :
                                                        <Text style={{ color: 'GrayText' }}>
                                                            {fetchSinglePackResult.data?.name}
                                                        </Text>}

                                                </Form.Item>
                                                {state.switcher ? (
                                                    <Form.Item label="Type">
                                                        <Select
                                                            defaultValue={edit.type}
                                                            options={[
                                                                {
                                                                    value: 'module',
                                                                    label: 'Module',
                                                                },
                                                                {
                                                                    value: 'diamants',
                                                                    label: 'Diamants',
                                                                },
                                                                {
                                                                    value: 'diamants',
                                                                    label: 'Cœurs',
                                                                },

                                                            ]}
                                                            onSelect={(value) => {

                                                                setEdit({
                                                                    ...edit,
                                                                    type: value,
                                                                });

                                                            }}
                                                        />
                                                    </Form.Item>
                                                ) : fetchSinglePackResult?.data?.type ? (
                                                    <Form.Item label="Type">
                                                        <Text style={{ color: 'GrayText' }}>
                                                            {fetchSinglePackResult.data?.type}
                                                        </Text>
                                                    </Form.Item>
                                                ) : null}

                                                {state.switcher ? (
                                                    <Form.Item label="Description">
                                                        <Input.TextArea
                                                            autoSize
                                                            value={edit.description}
                                                            onChange={(e) => {
                                                                if (e.target.value === "") {
                                                                    setEdit({
                                                                        ...edit,
                                                                        description: null,
                                                                    })
                                                                } else {

                                                                    setEdit({
                                                                        ...edit,
                                                                        description: e.target.value,
                                                                    });
                                                                }
                                                            }}
                                                        />  </Form.Item>
                                                ) : fetchSinglePackResult.data?.description ? (
                                                    <Form.Item label="Description">

                                                        <Paragraph
                                                            style={{ marginBottom: "0px" }}
                                                            ellipsis={{
                                                                rows: 3,
                                                                expandable: true,
                                                            }}>
                                                            {fetchSinglePackResult.data?.description}
                                                        </Paragraph>
                                                    </Form.Item>
                                                ) : null}
                                                <Form.Item label="Stores ID">
                                                    {state.switcher ?
                                                        <Input
                                                            name="inAppProductID"
                                                            value={edit.inAppProductID}
                                                            onChange={handleInputChange}
                                                        />
                                                        :
                                                        <Text style={{ color: 'GrayText' }}>
                                                            {fetchSinglePackResult.data?.inAppProductID}
                                                        </Text>}

                                                </Form.Item>


                                                <Form.Item label="Quantité">
                                                    {state.switcher ?
                                                        <InputNumber
                                                            min={0}
                                                            precision={0}
                                                            value={edit.amount}

                                                            // step={0.01}
                                                            name="amount"
                                                            placeholder="Quantité"
                                                            style={{ width: "40%" }}

                                                            onChange={(value) => {
                                                                setEdit({
                                                                    ...edit,
                                                                    amount: value,
                                                                });
                                                            }} />
                                                        :
                                                        <Text style={{ color: 'GrayText' }}>
                                                            {fetchSinglePackResult.data?.amount}
                                                        </Text>}

                                                </Form.Item>

                                                <Form.Item label="Prix">
                                                    {state.switcher ?
                                                        <InputNumber
                                                            min={0}
                                                            precision={2}
                                                            // step={0.01}
                                                            name="price"
                                                            placeholder="Prix"
                                                            style={{ width: "40%" }}
                                                            value={edit.price}
                                                            onChange={(value) => {
                                                                setEdit({
                                                                    ...edit,
                                                                    price: value,
                                                                });
                                                            }} />
                                                        :
                                                        <Space align='center'>
                                                            <Text> {fetchSinglePackResult.data?.price} </Text>
                                                            {fetchSinglePackResult.data?.currency === "money" ? <Text>€</Text> :
                                                                <Image src={fetchSinglePackResult.data?.currency === "coins" ? "/Coin.svg" : "/Diamond.svg"} width={16} height={16} alt="coin" preview={false} />
                                                            }

                                                        </Space>}

                                                </Form.Item>

                                                {fetchSinglePackResult?.data?.currency ? (
                                                    <Form.Item label="Monnaie">
                                                        <Text style={{ color: 'GrayText' }}>
                                                            {fetchSinglePackResult.data?.currency}
                                                        </Text>
                                                    </Form.Item>
                                                ) : null}
                                                <Form.Item label="Etat">
                                                    {state.switcher ? (
                                                        <Switch
                                                            loading={isUpdatingPack}
                                                            checked={edit.status === "active"}
                                                            onChange={(checked) => handleSwitchStatus({ checked })} />


                                                    ) : (
                                                        <Text style={{ color: 'GrayText' }}>{fetchSinglePackResult.data?.status}   </Text>
                                                    )}
                                                </Form.Item>

                                                <Form.Item label="En Soldes :"
                                                >
                                                    <Switch
                                                        checked={edit.sale}
                                                        disabled={!state.switcher}
                                                        onChange={(checked) => {
                                                            setEdit({
                                                                ...edit,
                                                                sale: checked,
                                                                saleRate: 0
                                                            });
                                                        }}
                                                    />
                                                </Form.Item>
                                                <Form.Item label="Abonnement :"
                                                >
                                                    <Switch
                                                        checked={edit.recurringPayment}
                                                        disabled={!state.switcher}
                                                        onChange={(checked) => {
                                                            setEdit({
                                                                ...edit,
                                                                recurringPayment: checked,
                                                            });
                                                        }}
                                                    />
                                                </Form.Item>
                                                <Form.Item label="Réduction de :">
                                                    {state.switcher ?
                                                        <InputNumber
                                                            disabled={!edit.sale}
                                                            step={1}
                                                            value={!edit.sale ? 0 : edit.saleRate * 100}
                                                            formatter={(value) => `% ${value}`}
                                                            parser={(value) => value.replace('%', '')}
                                                            onChange={(value) => {
                                                                setEdit({
                                                                    ...edit,
                                                                    saleRate: (value / 100),
                                                                });
                                                            }}
                                                        />
                                                        :

                                                        <Text> {(fetchSinglePackResult.data?.saleRate) * 100} %</Text>

                                                    }

                                                </Form.Item>





                                            </Form>
                                            {state.disable ? (
                                                <Divider>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            marginTop: 33,
                                                        }}
                                                    >
                                                        <Button
                                                            shape="round"
                                                            type="dashed"
                                                            onClick={handelUpdateInfo}
                                                            style={{
                                                                margin: "5px",
                                                                border: "1px solid #4CAF50",
                                                            }}
                                                        >
                                                            Update
                                                            <CheckCircleTwoTone twoToneColor="#52c41a" />
                                                        </Button>
                                                        <Button
                                                            style={{ margin: "5px" }}
                                                            shape="round"
                                                            danger
                                                            onClick={() => {
                                                                setEdit({ ...edit });
                                                                setstate({
                                                                    ...state,
                                                                    switcher: false,
                                                                    disable: false,
                                                                    editSinglePack: false,
                                                                });
                                                            }}
                                                        >
                                                            Cancel
                                                            <CloseCircleOutlined twoToneColor="#eb2f96" />
                                                        </Button>
                                                    </div>
                                                </Divider>
                                            ) : null}
                                        </div>
                                    }
                                </Content>
                            </>
                        ) : (
                            <div style={{ height: "80vh" }}>
                                <LoadingOutlined
                                    twoToneColor="#eb2f96"
                                    style={{ fontSize: 24, marginLeft: "50%", marginTop: "50%" }}
                                    spin
                                />
                            </div>
                        )}
                    </Col>

                    <>
                        <Divider type="vertical" style={{
                            height: "100%",
                            minHeight: "30vh",

                        }} />
                    </>
                </Row>
            </Content >
        </Drawer>

    );
};
export default SinglePack;