import { createSlice } from "@reduxjs/toolkit";

import { getTransactionsStatsUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";

export const TransactionsCountSlice = createSlice({
    name: "transactionscount",
    initialState: {
        fetchTransactionsCountResult: null,
        fetchTransactionsCountError: null,
        isFetchingtransactionsCount: false,
    },
    reducers: {


        setTransactionsCountResult: (state, action) => {
            state.fetchTransactionsCountResult = action.payload;
            state.fetchTransactionsCountError = null;

            state.isFetchingtransactionsCount = false;
        },

        setTransactionsCountError: (state, action) => {
            state.fetchTransactionsCountError = action.payload;
            state.isFetchingtransactionsCount = false;
            state.fetchTransactionsCountResult = null

        },
        setIsCountingTransactions: (state, action) => {
            state.isFetchingtransactionsCount = action.payload;
        },


    },
});

export const {
    setTransactionsCountResult,
    setTransactionsCountError,
    setIsCountingTransactions,



} = TransactionsCountSlice.actions;


export const fetchTransactionsCount = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getTransactionsStatsUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsCountingTransactions(true));
    const response = axios(config)
        .then(response => {
            dispatch(setTransactionsCountResult(response.data));
        })
        .catch((error) => {
            message.error(error.response.data.message)
            dispatch(setTransactionsCountError(error.response.data.message));

        });

    return response.data;

}



export default TransactionsCountSlice.reducer;