import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Space, Row, Button, Typography, Layout, Card, Tag, Switch, Divider, Badge, Radio, Input, Col } from 'antd';

import { FilterFilled, SearchOutlined, RedoOutlined } from "@ant-design/icons";
import { fetchDefis } from './defisListSlice';
import { deleteSingleDefi, UpdateDefi } from '../defi/singleDefiSlice';
import PageHeader from '../../comps/pageHeader';
import SingleDefi from '../defi/singleDefi';
import AddDefis from './addDefis';


const { Paragraph, Text } = Typography;
const { Content } = Layout;


const DefisList = () => {
    const dispatch = useDispatch();

    const handleSwitchStatus = ({ checked, id }) => {
        let status = checked ? "active" : "inactive"
        dispatch(UpdateDefi({ id, status }));
    };
    const fetchDefisResult = useSelector((state) => state.defis.fetchDefisResult);
    const isFetchingDefis = useSelector((state) => state.defis.isFetchingDefis);

    const isUpdatingDefi = useSelector((state) => state.singleDefi.isUpdatingDefi);
    const isDeletingSingleDefi = useSelector((state) => state.singleDefi.isDeletingSingleDefi);

    const [defiFilter, setDefiFilter] = useState({
        correct: "",
        question: "",
        pageLength: 10,
        page: 1,
        difficulty: null,
        frequency: null,
        theme: null,
        status: "",
        cactus: null,

    });
    const [defiID, setDefiID] = useState(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [visible, setVisible] = useState(false)


    useEffect(() => {
        // dispatch(getBannerInfo());
        dispatch(fetchDefis(defiFilter));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defiFilter]);
    const getColumnFilterStatusProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Radio.Group
                    onChange={(e) => setDefiFilter({
                        ...defiFilter, status: e.target.value, page: 1,
                    })} value={defiFilter.status}>
                    <Space direction="vertical">
                        <Radio value={"active"}>Activé</Radio>
                        <Radio value={"inactive"}>inactivé</Radio>
                        <Radio value={null}>Tout</Radio>
                    </Space>
                </Radio.Group>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        close();
                    }}
                >
                    Fermer
                </Button>
            </div>),
        filterIcon: (filtered) => (<FilterFilled
            style={{
                color: filtered ? '#39B13D' : undefined,
            }}
        />),
    });
    const getColumnFilterCactusProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Radio.Group
                    onChange={(e) => setDefiFilter({ ...defiFilter, cactus: e.target.value, page: 1 })}
                    value={defiFilter.cactus}>
                    <Space direction="vertical">
                        <Radio value={true}>cactus</Radio>
                        <Radio value={false}>non Cactus</Radio>
                        <Radio value={null}>Tout</Radio>
                    </Space>
                </Radio.Group>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        close();
                    }}
                >
                    Fermer
                </Button>
            </div>),
        filterIcon: (filtered) => (<FilterFilled
            style={{
                color: filtered ? '#39B13D' : undefined,
            }}
        />),
    });

    const getColumnQuestionSearchProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >

                <Input
                    placeholder={`Tapez un seul mot`}
                    value={defiFilter.question}
                    onChange={(e) => {
                        const inputValue = e.target.value.trim(); // Remove leading/trailing whitespace
                        const firstWord = inputValue.split(' ')[0]; // Take the first word
                        setDefiFilter({ ...defiFilter, question: firstWord, page: 1 });
                    }} style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                // onPressEnter={() => dispatch(getClientList(clientsFilter))}
                />

                <Space>
                    <Button
                        onClick={() => { setDefiFilter({ ...defiFilter, question: null }); }}
                        size="small"
                        style={{
                            width: 110,
                        }}
                    >
                        Réinitialiser
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fermer
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#39B13D' : undefined,
                }}
            />
        ),
    });
    const getColumnResponseSearchProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >

                <Input
                    value={defiFilter.correct}
                    placeholder={`Tapez un seul mot`}
                    onChange={(e) => {
                        const inputValue = e.target.value.trim(); // Remove leading/trailing whitespace
                        const firstWord = inputValue.split(' ')[0]; // Take the first word
                        setDefiFilter({ ...defiFilter, correct: firstWord, page: 1 });
                    }}
                    style={{ marginBottom: 8, display: 'block' }}
                />

                <Space>
                    <Button
                        onClick={() => { setDefiFilter({ ...defiFilter, correct: null }); }}
                        size="small"
                        style={{
                            width: 110,
                        }}
                    >
                        Réinitialiser
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fermer
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#39B13D' : undefined,
                }}
            />
        ),
    });

    const columns = [
        {
            title: 'status',
            dataIndex: 'status',
            key: 'status',
            width: '5%',
            ...getColumnFilterStatusProps(),

            render: (status, defis) => {
                return (
                    <Switch
                        loading={isUpdatingDefi}
                        checked={status === "active"}
                        onChange={(checked) => handleSwitchStatus({ checked, id: defis._id })} />
                );
            },

        },
        {

            title: 'La question',
            dataIndex: 'question',
            key: 'question',
            width: '25%',
            ...getColumnQuestionSearchProps(),
            render: (question) => {
                return (

                    <Paragraph
                        style={{ marginBottom: "0px" }}
                        ellipsis={{
                            rows: 1,
                            expandable: true,
                        }}>
                        {question.slice(0, 3).join(" ")}  ... {question.length > 3 ? question[question.length - 1] : null}
                    </Paragraph>
                );
            },

        },
        {
            title: 'Cactus',
            dataIndex: 'cactus',
            key: 'cactus',
            width: '12%',
            render: (cactus) => {
                return (
                    <Badge color={cactus ? "red" : "green"} text={cactus ? "Cactus" : "Pas Cactus"} />

                );
            },
            ...getColumnFilterCactusProps()
        },
        {
            title: 'La Réponse',
            dataIndex: 'correct',
            key: 'correct',
            width: '15%',
            align: "center",
            ...getColumnResponseSearchProps()
        },
        {
            title: 'Thème',
            dataIndex: 'theme',
            key: 'theme',
            width: '10%',
            render: (theme) => {
                let color = theme.type === "football" ? "blue" : theme.type === "manga" ? "red" : theme.type === "cactus" ? "green" : null

                return (

                    <Tag color={color} key={theme._id}>
                        {theme.displayName}
                    </Tag>
                );
            },

        },
        {
            title: 'Difficulté',
            dataIndex: 'difficulty',
            key: 'difficulty',
            width: '5%',
            align: "center",

        },
        {
            title: 'Fréquence',
            dataIndex: 'frequency',
            key: 'frequency',
            width: '5%',
            align: "center",

            render: (frequency) => {
                return (
                    frequency ? frequency : 0
                );
            },
        },
        {
            title: "Actions",
            width: "25%",
            align: "center",
            dataIndex: "_id",
            key: "_id",
            render: (_id) => {
                return (<>

                    <Button
                        type="link"
                        size="small"
                        onClick={(e) => {
                            setOpenDrawer(true)
                            setDefiID(_id)

                        }}
                    >
                        Modifier
                    </Button>
                    <Divider type='vertical' />
                    <Button
                        type="link"
                        size="small"
                        onClick={(e) => {
                            dispatch(deleteSingleDefi({ id: _id }))
                        }}
                    >
                        Supprimer
                    </Button>
                </>
                );
            },
        },
    ];

    const onChangePage = (e) => {
        setDefiFilter({ ...defiFilter, page: e.current, pageLength: e.pageSize });
    }
    const clearFilters = () => {
        setDefiFilter({
            correct: "",
            question: "",
            pageLength: 10,
            page: 1,
            difficulty: null,
            frequency: null,
            theme: null,
            status: "",
            cactus: null,
        });
    };
    const onClose = () => {
        setOpenDrawer(false);
    };

    return (

        <Content
            className="site-layout-background"
            style={{
                padding: 0,
                margin: 0,
                minHeight: 280,
            }}
        >
            <>
                <SingleDefi openDrawer={openDrawer} onClose={onClose} defiID={defiID} />

                <PageHeader title={"Liste des Défis"}
                    breadcrumbItems={["Création de Contenu", "Défis", "Liste des Défis"]} />
                <AddDefis visible={visible} setVisible={setVisible} />


                <Row justify={"center"} style={{ background: "#424242", margin: "24px", minHeight: "112px" }}
                >
                    <Col span={5} style={{ paddingTop: 10 }}                >
                        <Space align='center' direction="vertical" size="middle" style={{ display: 'flex' }}>
                            <Text style={{ color: "white", fontSize: "16px" }}>Ajouter une question défi</Text>
                            <Button type='primary'
                                onClick={() => { setVisible(true) }}
                                style={{ height: "32px" }}> Nouveau Défi</Button>
                        </Space>
                    </Col>
                </Row>
                <Row justify={"center"} style={{ margin: "24px" }}>
                    <Card
                        style={{ width: "100%" }}
                        bodyStyle={{ padding: "18px" }}
                    >
                        <Row justify={"end"}>
                            <Col span={4}>

                                <Button icon={<RedoOutlined />} onClick={clearFilters} danger >Réinitialiser les filtres</Button>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "16px" }} >
                            <Col span={24}>

                                <Table
                                    style={{ width: "100%" }}
                                    columns={columns}
                                    pagination={{
                                        total: fetchDefisResult?.total,
                                        pageSize: defiFilter.pageLength,
                                        current: defiFilter.page,
                                        showSizeChanger: true,
                                        pageSizeOptions: [10, 15, 20, 30, 40, 50],
                                        showQuickJumper: true,
                                        showTotal: (total, range) => (<>Nombre de defis : {total} </>),
                                    }}
                                    loading={isFetchingDefis || isDeletingSingleDefi}
                                    dataSource={fetchDefisResult?.defiQuestions}
                                    onChange={onChangePage}
                                />
                            </Col>
                        </Row>

                    </Card>

                </Row>
            </>

        </Content>

    );
}

export default DefisList;