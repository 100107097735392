import { Image, Typography } from 'antd';
const { Text } = Typography;

export default function Dialog({ text }) {

    return (
        <>
            <Image
                preview={false}
                src="/activation/DialogBubble.svg"
            >

            </Image>
            <p style={{
                color:"black",
                position: "absolute",
                margin: 0,
                height:"fit-content",
                fontSize:"20px",
                fontWeight:"bold",
                marginBottom:"1.5vh"
                // top: "50%",
                // left: "50%",
                // transform: "translate(-50%, -50%)"
            }}>{text}</p>
        </>
    )
}