import { createSlice } from "@reduxjs/toolkit";

import { getSingleUserUrl, updateUserUrl, getUserTransactionsUrl, getUserProgressionsUrl, getAvatarStatsUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";
export const SingleUserSlice = createSlice({
    name: "user",
    initialState: {
        fetchSingleUserResult: null,
        fetchSingleUserError: null,
        isfetchingSingleUser: false,

        updateSingleUserResult: null,
        updateSingleUserError: null,
        isUpdatingSingleUser: false,

        fetchUserTransactionsResult: null,
        fetchUserTransactionsError: null,
        isfetchingUserTransactions: false,

        fetchUserProgressionsResult: null,
        fetchUserProgressionsError: null,
        isfetchingUserProgressions: false,

        fetchUserStatsResult: null,
    },
    reducers: {
        setFetchUserStatsResult: (state, action) => {
            state.fetchUserStatsResult = action.payload;

        },
        setSingleUserResult: (state, action) => {
            state.fetchSingleUserResult = action.payload;
            state.fetchSingleUserError = null;
            state.isfetchingSingleUser = false;
        },
        setSingleUserError: (state, action) => {
            state.fetchSingleUserError = action.payload;
            state.fetchSingleUserResult = null;
            state.isfetchingSingleUser = false;
        },
        setisfetchingSingleUser: (state, action) => {
            state.isfetchingSingleUser = action.payload;
        },

        setUpdateSingleUserResult: (state, action) => {
            state.updateSingleUserResult = action.payload;
            state.updateSingleUserError = null;
            state.isUpdatingSingleUser = false;
        },
        setUpdateSingleUserError: (state, action) => {
            state.updateSingleUserError = action.payload;
            state.updateSingleUserResult = null;
            state.isUpdatingSingleUser = false;
        },
        setisupdateingSingleUser: (state, action) => {
            state.isUpdatingSingleUser = action.payload;
        },

        setUserTransactionsResult: (state, action) => {
            state.fetchUserTransactionsResult = action.payload;
            state.isfetchingUserTransactions = false;
        },
        setUserTransactionsError: (state, action) => {
            state.fetchUserTransactionsError = action.payload;
            state.isfetchingUserTransactions = false;
        },
        setisfetchingUserTransactions: (state, action) => {
            state.isfetchingUserTransactions = action.payload;
        },

        setUserProgressionsResult: (state, action) => {
            state.fetchUserProgressionsResult = action.payload;
            state.isfetchingUserProgressions = false;
        },
        setUserProgressionsError: (state, action) => {
            state.fetchUserProgressionsError = action.payload;
            state.isfetchingUserProgressions = false;
        },
        setisfetchingUserProgressions: (state, action) => {
            state.isfetchingUserProgressions = action.payload;
        },

    },
});

export const {
    setSingleUserResult,
    setSingleUserError,
    setisfetchingSingleUser,

    setFetchUserStatsResult,

    setUserTransactionsResult,
    setUserTransactionsError,
    setisfetchingUserTransactions,

    setUserProgressionsResult,
    setUserProgressionsError,
    setisfetchingUserProgressions,

    setUpdateSingleUserError,
    setUpdateSingleUserResult,
    setisupdateingSingleUser,


} = SingleUserSlice.actions;
export const fetchSinglSingleUser = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getSingleUserUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data,
    };
    dispatch(setisfetchingSingleUser(true));

    const response = axios(config)
        .then((response) => {
            dispatch(setSingleUserResult(response.data));
            // dispatch( setmessage({name:'success',message :"SingleUser fetched successfully"}))
            console.log(response);
        })
        .catch((err) => {
            dispatch(setSingleUserError(err.response.data.message));
        });

    console.log(response);
    return response.data;
};

export const fetchUserTransactions = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getUserTransactionsUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data,
    };
    dispatch(setisfetchingUserTransactions(true));

    const response = axios(config)
        .then((response) => {
            dispatch(setUserTransactionsResult(response.data));
            // dispatch( setmessage({name:'success',message :"UserTransactions fetched successfully"}))
            console.log(response);
        })
        .catch((response) => {
            dispatch(setUserTransactionsError(response.message));
        });

    return response.data;
};

export const fetchUserProgressions = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getUserProgressionsUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data,
    };
    dispatch(setisfetchingUserProgressions(true));

    const response = axios(config)
        .then((response) => {
            dispatch(setUserProgressionsResult(response.data));
            // dispatch( setmessage({name:'success',message :"UserProgressions fetched successfully"}))
            console.log(response);
        })
        .catch((response) => {
            dispatch(setUserProgressionsError(response.message));
        });

    return response.data;
};
export const fetchUserStats = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getAvatarStatsUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data,
    };

    const response = axios(config)
        .then((response) => {
            dispatch(setFetchUserStatsResult(response.data));
            // dispatch( setmessage({name:'success',message :"UserProgressions fetched successfully"}))
            console.log(response);
        })
        .catch((error) => {
            message.error(error.response.data.message)

        });

    return response.data;
};


export const updatelSingleUser = (data) => async (dispatch, getState) => {
    const config = {
        method: "post",
        url: updateUserUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data: data,
    };
    dispatch(setisupdateingSingleUser(true));

    const response = await axios(config)
        .then((response) => {
            dispatch(setUpdateSingleUserResult(response.data));
            console.log(response);
        })
        .catch((err) => {
            dispatch(setUpdateSingleUserError(err.response.data.message));
            message.error(err.response.data.message)

            console.log("can't edit")

            // dispatch( setmessage({name:'error',message :response.message}))
        });
    return response;
};



export default SingleUserSlice.reducer;