import { createSlice } from "@reduxjs/toolkit";

import { deleteFileUrl, deleteItemUrl, getSingleItemUrl, updateItemStatsUrl, updateItemUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";
export const UpdateItemSlice = createSlice({
    name: "singleItem",
    initialState: {

        fetchUpdateItemResult: null,
        fetchUpdateItemError: null,
        isUpdatingItem: false,

        fetchSingleItemResult: null,
        fetchSingleItemError: null,
        isFetchingSingleItem: false,

        deleteSingleItemResult: null,
        deleteSingleItemError: null,
        isDeletingSingleItem: false,
    },
    reducers: {

        setFetchUpdateItemResult: (state, action) => {
            state.fetchUpdateItemResult = action.payload;
            state.isUpdatingItem = false;
        },

        setFetchUpdateItemError: (state, action) => {
            state.fetchUpdateItemError = action.payload;
            state.isUpdatingItem = false;
            state.fetchUpdateItemResult = null

        },
        setIsUpdatingItem: (state, action) => {
            state.isUpdatingItem = action.payload;
        },

        setFetchSingleItemResult: (state, action) => {
            state.fetchSingleItemResult = action.payload;
            state.fetchSingleItemError = null;

            state.isFetchingSingleItem = false;
        },

        setFetchSingleItemError: (state, action) => {
            state.fetchSingleItemError = action.payload;
            state.isFetchingSingleItem = false;
            state.fetchSingleItemResult = null

        },
        setIsFetchingSingleItem: (state, action) => {
            state.isFetchingSingleItem = action.payload;
        },

        setDeleteSingleItemResult: (state, action) => {
            state.deleteSingleItemResult = action.payload;
            state.deleteSingleItemError = null;

            state.isDeletingSingleItem = false;
        },

        setDeleteSingleItemError: (state, action) => {
            state.deleteSingleItemError = action.payload;
            state.isDeletingSingleItem = false;
            state.deleteSingleItemResult = null

        },
        setIsDeleteingSingleItem: (state, action) => {
            state.isDeletingSingleItem = action.payload;
        },
    },
});

export const {

    setFetchUpdateItemResult,
    setFetchUpdateItemError,
    setIsUpdatingItem,

    setFetchSingleItemResult,
    setFetchSingleItemError,
    setIsFetchingSingleItem,

    setDeleteSingleItemResult,
    setDeleteSingleItemError,
    setIsDeleteingSingleItem,

} = UpdateItemSlice.actions;

export const fetchSingleItem = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getSingleItemUrl,

        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsFetchingSingleItem(true));
    const response = axios(config)
        .then(response => {
            dispatch(setFetchSingleItemResult(response.data));
        })
        .catch((error) => {
            console.log(error);
            message.error(error.response.data.message)
            dispatch(setFetchSingleItemError(error.response.data.message));

        });

    return response.data;

}

export const deleteSpriteFile = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: deleteFileUrl,

        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsFetchingSingleItem(true));
    const response = axios(config)
        .then(response => {
            message.success(response.data.message)

        })
        .catch((error) => {
            console.log(error);
            message.error(error.response.data.message)
        });

    return response.data;

}
export const UpdateItem = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: updateItemUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsUpdatingItem(true));
    const response = axios(config)
        .then(response => {

            message.success(response.data.message)
            dispatch(setFetchUpdateItemResult(response.data));
        })
        .catch((error) => {
            console.log(error);
            message.error(error.response.data.message)
            dispatch(setFetchUpdateItemError(response.message));

        });

    return response.data;

}
export const UpdateItemStats = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: updateItemStatsUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsUpdatingItem(true));
    const response = axios(config)
        .then(response => {
            console.log(response.data.message);
        })
        .catch((error) => {
            console.log(error);
            message.error(error.response.data.message)

        });

    return response.data;

}
export const deleteSingleItem = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: deleteItemUrl,

        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsDeleteingSingleItem(true));
    const response = axios(config)
        .then(response => {
            message.success(response.data.message)

            dispatch(setDeleteSingleItemResult(response.data));
        })
        .catch((error) => {
            console.log(error);
            message.error(error.response.data.message)
            dispatch(setDeleteSingleItemError(error.response.data.message));

        });

    return response.data;

}

export default UpdateItemSlice.reducer;