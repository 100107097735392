import { Button, Col, Form, Input, InputNumber, Popconfirm, Row, Skeleton, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../comps/pageHeader';

import { useSelector } from 'react-redux';
import { AddnewLesson, editLesson, fetchSingleLesson } from './singleLessonSlice';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quill.css'
import { fetchSinglModule } from '../modules/moduleSlice';
import ChoseTypeModal from '../exercices/choseTypeModal';
import { deleteSingleExercice, setFetchSingleExerciceResult } from '../exercices/singleExerciceSlice';
import { setAddExerciceResult } from '../exercices/addExerciceSlice';
let Inline = Quill.import('blots/inline');
class BoldBlot extends Inline { }
BoldBlot.blotName = 'bold';
BoldBlot.tagName = 'b';
Quill.register(BoldBlot);
class ItalicBlot extends Inline { }
ItalicBlot.blotName = 'italic';
ItalicBlot.tagName = 'i';
Quill.register(ItalicBlot);

const { Text } = Typography;

const modules = {

    toolbar: [
        ['bold', 'italic', 'underline'],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    ],
}
const formats = [

    'bold', 'italic', 'underline', "script"
]
const themes = ['football', 'cactus', 'manga']
const SingleLesson = () => {
    let navigate = useNavigate();

    const { lseq, id, seq } = useParams();
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({ name: "", seq: 0, contentFoot: "", contentManga: "", contentCactus: "" });
    const [themesState, setThemesState] = useState(null);
    const [slectedTheme, setSelectedTheme] = useState(null);
    const [newPath, setNewPath] = useState("");

    const lessonAddResult = useSelector((state) => state.lesson.lessonAddResult);
    const fetchSingleLessonResult = useSelector((state) => state.lesson.fetchSingleLessonResult);
    const fetchSingleLessonError = useSelector((state) => state.lesson.fetchSingleLessonError);

    const deleteSingleExerciceResult = useSelector((state) => state.exercice.deleteSingleExerciceResult);

    const fetchModuleResult = useSelector((state) => state.module.fetchModuleResult);

    const pathname = useSelector((state) => state.dashboard.pathname);

    useEffect(() => {
        if (id) {
            dispatch(fetchSingleLesson({ id, seq }));
            dispatch(setFetchSingleExerciceResult(null));
            dispatch(setAddExerciceResult(null))

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, deleteSingleExerciceResult]);
    useEffect(() => {
        if (!fetchModuleResult) {

            dispatch(fetchSinglModule({ seq: seq }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seq]);

    useEffect(() => {
        if (lessonAddResult || fetchSingleLessonError) {
            navigate(-1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lessonAddResult, fetchSingleLessonError]);

    useEffect(() => {
        if (fetchSingleLessonResult) {

            setFormData({
                name: fetchSingleLessonResult.lesson.name,
                seq: fetchSingleLessonResult.lesson.seq,
                contentFoot: fetchSingleLessonResult.lesson.contentFoot,
                contentManga: fetchSingleLessonResult.lesson.contentManga,
                contentCactus: fetchSingleLessonResult.lesson.contentCactus,
            })
            setThemesState(themes.filter(theme => {
                return !(fetchSingleLessonResult?.exercices.some(exercice => exercice.theme.type === theme));
            }))
            //delete :id from pathname params
            let lastIndex = pathname.lastIndexOf("/");
            let result = pathname.substring(0, lastIndex);
            setNewPath(result)
        } else {
            setFormData({ seq: lseq })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchSingleLessonResult, lseq]);

    const handelSubmit = () => {
        if (id) {
            dispatch(editLesson({ id: id, ...formData }));

        } else {
            if (lseq) {
                dispatch(AddnewLesson({ ...formData, module: fetchModuleResult.module._id }));

            }
        }
    };
    const [visible, setVisible] = useState(false)

    return (
        <>
            {fetchSingleLessonResult || lseq ?
                <PageHeader title={id ? "Aperçu de la leçon " + fetchSingleLessonResult?.lesson.seq : "Ajout de la leçon " + lseq}
                    breadcrumbItems={["Création de Contenu", "Modules", "Module " + seq, "leçon " + (fetchSingleLessonResult ? fetchSingleLessonResult.lesson.seq : lseq)]} />
                : null}


            <Row justify={"start"} gutter={[8, 0]}>
                {fetchSingleLessonResult?.exercices.map((item, idex) =>
                    <>
                        <Col span={7} style={{ backgroundColor: "white", height: "72px", margin: "24px", display: "flex", alignItems: "center", paddingLeft: "15px" }}>
                            <Space direction='horizontal' size={"large"} >
                                <Text strong>Exercice Thème {item.theme.type === "cactus" ? "culture G" : item.theme.type}</Text>
                                <Space.Compact >
                                    <Button type='link' style={{ paddingRight: "4px" }}
                                        onClick={() => {
                                            navigate(newPath + '/lessons/' + fetchSingleLessonResult.lesson.seq + "/exercice/" + item.theme.type + "/" + item._id)

                                        }

                                        }
                                    >Voir</Button >
                                    <Popconfirm
                                        title="Supprimer l'exercice"
                                        description="Êtes-vous sûr de supprimer cet exercice ?"
                                        onConfirm={() => {
                                            dispatch(deleteSingleExercice({ id: item._id }));

                                        }}
                                        // onCancel={cancel}
                                        okText="Yes"
                                        cancelText="No"
                                    >

                                        <Button type='link' style={{ paddingLeft: "4px" }}>Supprimer</Button >
                                    </Popconfirm>
                                </Space.Compact>


                            </Space>
                        </Col>

                    </>
                )
                }
                {themesState ?

                    themesState?.map((item, idex) =>
                        <>

                            <Col span={7} style={{ background: "#424242", height: "72px", margin: "24px", display: "flex", alignItems: "center", justifyContent: "center" }}  >
                                <Space align='center' direction="horizontal" size="middle" style={{ display: 'flex' }}>
                                    <Text style={{ color: "white", fontSize: "16px" }}>Exercice Thème {item === "cactus" ? "culture G" : item}</Text>
                                    <Button type='primary'
                                        onClick={() => {
                                            setVisible(true)
                                            setSelectedTheme(item)
                                        }}
                                    // style={{ width: "145px", height: "32px" }}
                                    > Ajouter</Button>
                                </Space>
                            </Col>
                        </>
                    ) : null
                }

                {/* {fetchSingleLessonResult?.exercices.length < 3 ?
                    <Col span={7} style={{ background: "#424242", height: "72px", margin: "24px", display: "flex", alignItems: "center", justifyContent: "center" }}  >
                        <Space align='center' direction="vertical" size="middle" style={{ display: 'flex' }}>
                            <Button type='primary'
                                onClick={() => { setVisible(true) }}
                            // style={{ width: "145px", height: "32px" }}
                            > Ajouter un exercice</Button>
                        </Space>
                    </Col> : null
                } */}
            </Row>
            <ChoseTypeModal visible={visible} setVisible={setVisible} slectedTheme={slectedTheme} />

            {(formData.seq > 0) ?
                <Form layout="horizontal">
                    {/* <Row justify={"center"} style={{marginTop:"24px"}}>
                        <Col span={4} style={{ display: "flex", justifyContent: "center" }} >
                            <Button type='primary'
                                disabled={!formData.name}
                                onClick={handelSubmit}
                                style={{ width: "145px", height: "32px" }}
                            > Sauvegrader</Button>
                        </Col>
                    </Row> */}
                    <Row style={{
                        background: "white", margin: "24px", minHeight: "80px", alignContent: "flex-end",
                    }}  >
                        <Col span={8} style={{ marginLeft: "24px" }}>
                            <Form.Item label="Titre :" labelAlign="left"
                                rules={[
                                    { required: true, message: "Veuillez saisir Le nom de la leçon" },
                                ]}
                            >
                                <Input
                                    value={formData.name}
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            name: e.target.value,
                                        });
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={11}></Col>
                        <Col span={4} >
                            <Form.Item label="Leçon N° :" labelAlign="right"
                                rules={[
                                    { required: true, message: "Veuillez saisir Le nom de la leçon" },
                                ]}
                            >
                                <InputNumber
                                    min={1}
                                    max={42}
                                    style={{ width: "60px" }}
                                    value={formData.seq}

                                    onChange={(value) => {
                                        setFormData({
                                            ...formData,
                                            seq: value,
                                        });
                                    }}
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row style={{ background: "white", margin: "24px", minHeight: "80px", alignContent: "flex-end", padding: "24px", }}  >
                        <Col span={24}>
                            <Form.Item label="Contenu Thème Foot : " labelAlign="right"
                                labelCol={{ span: 24, offset: 12 }}
                            >
                                <ReactQuill theme="snow" className='quillIDE'
                                    value={formData.contentFoot}

                                    modules={modules}
                                    formats={formats}
                                    onChange={(value) => {
                                        setFormData({
                                            ...formData,
                                            contentFoot: value,
                                        });
                                    }}
                                >
                                </ReactQuill>
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row style={{ background: "white", margin: "24px", minHeight: "80px", alignContent: "flex-end", padding: "24px", }}  >
                        <Col span={24}>
                            <Form.Item label="Contenu Thème Manga: " labelAlign="right"
                                labelCol={{ span: 24, offset: 12 }}
                            >
                                <ReactQuill theme="snow" className='quillIDE'
                                    value={formData.contentManga}
                                    modules={modules}
                                    formats={formats}
                                    onChange={(value) => {
                                        setFormData({
                                            ...formData,
                                            contentManga: value,
                                        });
                                    }}
                                >
                                </ReactQuill>
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row style={{ background: "white", margin: "24px", minHeight: "80px", alignContent: "flex-end", padding: "24px", }}  >
                        <Col span={24}>
                            <Form.Item label="Contenu Thème Culture Générale  : " labelAlign="right"
                                labelCol={{ span: 24, offset: 12 }}
                            >
                                <ReactQuill theme="snow" className='quillIDE'
                                    value={formData.contentCactus}

                                    modules={modules}
                                    formats={formats}
                                    onChange={(value) => {
                                        setFormData({
                                            ...formData,
                                            contentCactus: value,
                                        });
                                    }}
                                >
                                </ReactQuill>
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row justify={"center"} >
                        <Col span={4} style={{ display: "flex", justifyContent: "center" }} >
                            <Button type='primary'
                                disabled={!formData.name}

                                onClick={handelSubmit}
                                style={{ width: "145px", height: "32px" }}
                            > Sauvegrader</Button>
                        </Col>
                    </Row>

                </Form>
                : <Skeleton />
            }
        </>

    );
}

export default SingleLesson;