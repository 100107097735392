

import { Space, Typography } from "antd";


import ReactQuill, { Quill } from 'react-quill';

let Inline = Quill.import('blots/inline');
class BoldBlot extends Inline { }
BoldBlot.blotName = 'bold';
BoldBlot.tagName = 'b';
Quill.register(BoldBlot);
class ItalicBlot extends Inline { }
ItalicBlot.blotName = 'italic';
ItalicBlot.tagName = 'i';
Quill.register(ItalicBlot);

const modules = {

    toolbar: [
        ['bold', 'italic', 'underline'],
    ],
}
const formats = [

    'bold', 'italic', 'underline'
]


const { Title } = Typography;


const StatementComps = ({ switcherEdit, setExerciceForm, exerciceForm }) => {

    // const dispatch = useDispatch();

    return (
        < >


            <Space direction="vertical" style={{ width: "100%" }}>
                <Title level={5} style={{ height: "30px", margin: "0px" }}>Énoncé : </Title>

                <ReactQuill theme="snow" className='quillSTATMENT'
                    value={exerciceForm?.statement}
                    readOnly={!switcherEdit}
                    modules={modules}
                    formats={formats}
                    onChange={(value) => {
                        setExerciceForm({
                            ...exerciceForm,
                            statement: value,
                        });
                    }}
                />


            </Space>



        </>
    );
}

export default StatementComps;