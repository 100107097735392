import { createSlice } from "@reduxjs/toolkit";

import { getModuleUrl, editModuleUrl, getModulesUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";
export const ModuleSlice = createSlice({
    name: "module",
    initialState: {
        fetchModuleResult: null,
        fetchModuleError: null,
        isfetchingModule: false,

        fetchModulesResult: null,
        fetchModulesError: null,
        isFetchingModules: false,
    },
    reducers: {
        setModuleResult: (state, action) => {
            state.fetchModuleResult = action.payload;
            state.isfetchingModule = false;
        },
        setModuleError: (state, action) => {
            state.fetchModuleError = action.payload;
            state.isfetchingModule = false;
        },
        setisfetchingModule: (state, action) => {
            state.isfetchingModule = action.payload;
        },

        setFetchModulesResult: (state, action) => {
            state.fetchModulesResult = action.payload;
            state.isFetchingModules = false;
        },

        setFetchModulesError: (state, action) => {
            state.fetchModulesError = action.payload;
            state.isFetchingModules = false;
            state.fetchModulesResult = null

        },
        setIsFetchingModules: (state, action) => {
            state.isFetchingModules = action.payload;
        },
    },
});

export const {
    setModuleResult,
    setModuleError,
    setisfetchingModule,

    setFetchModulesResult,
    setFetchModulesError,
    setIsFetchingModules,

} = ModuleSlice.actions;
export const fetchSinglModule = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getModuleUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data,
    };
    dispatch(setisfetchingModule(true));

    const response = axios(config)
        .then((response) => {
            dispatch(setModuleResult(response.data));
        })
        .catch((response) => {
            dispatch(setModuleError(response.message));
        });

    console.log(response);
    return response.data;
};


export const editlModule = (data) => async (dispatch, getState) => {
    const config = {
        method: "post",
        url: editModuleUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };

    const response = await axios(config)
        .then((response) => {
            message.success(response.data.message)
        })
        .catch((error) => {
            message.error(error.response.data.message)

        });
    return response;
};

export const fetchModules = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getModulesUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data

    };
    dispatch(setIsFetchingModules(true));
    const response = axios(config)
        .then(response => {
            dispatch(setFetchModulesResult(response.data));
        })
        .catch(err => {
            if (err.respons) {
                dispatch(setFetchModulesError(err.response.data.error));
            }

        });

    return response.data;

}

export default ModuleSlice.reducer;