import { createSlice } from "@reduxjs/toolkit";

import { getAdminUrl,editAdminUrl } from "../../constants";
import axios from "axios";
export const AdminSlice = createSlice({
  name: "admin",
  initialState: {
    fetchAdminResult: null,
    fetchAdminError: null,
    isfetchingAdmin: false,
  },
  reducers: {
    setAdminResult: (state, action) => {
      state.fetchAdminResult = action.payload;
      state.isfetchingAdmin = false;
    },
    setAdminError: (state, action) => {
      state.fetchAdminError = action.payload;
      state.isfetchingAdmin = false;
    },
    setisfetchingAdmin: (state, action) => {
      state.isfetchingAdmin = action.payload;
    },
  },
});

export const {
  setAdminResult,
  setAdminError,
  setisfetchingAdmin,
} = AdminSlice.actions;
export const fetchSinglAdmin = (data) => (dispatch, getState) => {

  const config = {
    method: "post",
    url: getAdminUrl,
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
    data,
  };
  dispatch(setisfetchingAdmin(true));

  const response = axios(config)
    .then((response) => {
      dispatch(setAdminResult(response.data));
      // dispatch( setmessage({name:'success',message :"Admin fetched successfully"}))
      console.log(response);
    })
    .catch((response) => {
      dispatch(setAdminError(response.message));
      // dispatch( setmessage({name:'error',message :"Error while fetching admin"}))
    });

  console.log(response);
  return response.data;
};


export const editlAdmin = (data) =>async (dispatch, getState) => {  
    const config = {
      method: "post",
      url: editAdminUrl,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.token,
      },
      data:data.edit,
    };
   
    const response = await  axios(config)
    .then((response) => {
      console.log("Edit successfully ")
      console.log(response);
    })
    .catch((response) => {
      console.log("can't edit")
      // dispatch( setmessage({name:'error',message :response.message}))
    });
    return response;
  };
 


export default AdminSlice.reducer;