

import React, { useState, useEffect } from 'react';
import { Column } from '@ant-design/plots';
import { Card, Radio, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchModulesProgress } from './moduleProgLessonSlice';
import { fetchModules } from '../modules/moduleSlice';

const ModuleProgress = () => {
    const dispatch = useDispatch();
    const fetchModulesProgressResult = useSelector((state) => state.moduleProgress.fetchModulesProgressResult);
    const isFetchingModulesProgress = useSelector((state) => state.moduleProgress.isFetchingModulesProgress);

    const fetchModulesResult = useSelector((state) => state.module.fetchModulesResult);

    const [statfilter, setStatfilter] = useState({
        moduleId: fetchModulesResult?.modules[0]._id,

    });

    useEffect(() => {
        if (fetchModulesResult) {
            setStatfilter({ moduleId: fetchModulesResult?.modules[0]._id })
        } else {
            dispatch(fetchModules());

        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchModulesResult]);
    useEffect(() => {
        if (fetchModulesResult) {
            dispatch(fetchModulesProgress({ moduleId: statfilter.moduleId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statfilter]);

    const config = {
        data: fetchModulesProgressResult?.stats,
        xField: 'lesson',
        yField: 'percentage',
        color: "#39B13D",
        label: {

            position: 'middle',
            style: {
                fill: '#FFFFFF',
                opacity: 0.1,
            },
        },
        tooltip: {
            formatter: (datum) => ({
                name: "pourcentage",
                value: `${datum.percentage} %`, // access the y value of the data
            }),
        },
        xAxis: {
            label: {
                autoEllipsis: false,
                autoRotate: true,
                autoHide: false,
            },
        },


    };
    const handleChangeFilter = (e) => {
        setStatfilter({ moduleId: e.target.value })
    };
    return (

        <Card title="Progression des Utilisateurs par Module"
            style={{ backgroundColor: "white", cursor: "default", minHeight: "500px", }}
            hoverable
            extra={

                <Space >
                    <Radio.Group
                        size='small'
                        value={statfilter.moduleId}
                        onChange={handleChangeFilter}
                        disabled={isFetchingModulesProgress || statfilter.startDate}>
                        {fetchModulesResult?.modules.map((item, index) => (
                            <Space>
                                <Radio.Button style={{ border: "0" }} value={item._id}>{"Module " + item.seq}</Radio.Button>
                            </Space>
                        ))}
                    </Radio.Group>
                </Space>}

        >

            {fetchModulesProgressResult ?
                <Column {...config} />
                : null}


        </Card>
    );
}

export default ModuleProgress;