import { Row, Col, Typography, Layout, Space, Button, Alert } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../comps/pageHeader';
import { DeleteOutlined } from "@ant-design/icons";

import { fetchPromoCodes } from './promoCodesListSlice';
import moment from 'moment';
import AddPromoCode from './addPromoCode';
import { deletePromoCode } from './AddPromoCodeSlice';
// import AddPromoCode from './addPromoCode';

const { Text } = Typography;


const { Content } = Layout;

const PromoCodesList = ({ title, breadcrumbItems }) => {
    const dispatch = useDispatch();


    const [visible, setVisible] = useState(false)




    const fetchPromoCodesResult = useSelector((state) => state.pormoCodes.fetchPromoCodesResult);
    const isFetchingPromocodes = useSelector((state) => state.pormoCodes.isFetchingPromocodes);

    // const deletePromoCodeResult = useSelector((state) => state.addPromoCode?.deletePromoCodeResult);
    const isDeletingPromoCode = useSelector((state) => state.addPromoCode?.isDeletingPromoCode);



    // const isFetchingAddPromoCode = useSelector((state) => state.addPromoCode.isFetchingAddPromoCode);

    useEffect(() => {
        dispatch(fetchPromoCodes());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <Content
            className="site-layout-background"
            style={{
                padding: 0,
                margin: 0,
                minHeight: 280,
            }}
        >

            <AddPromoCode visible={visible} setVisible={setVisible} />
            <PageHeader title={"Gérer les Codes Promo"}
                breadcrumbItems={["Paramètres Shop", "Paramètres Shop"]} />

            <Row justify={"center"} style={{ background: "#424242", margin: "24px", minHeight: "100px" }}
            >
                <Col span={5} style={{ paddingTop: 10 }}                >
                    <Space align='center' direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <Text style={{ color: "white", fontSize: "16px" }}>Ajouter un Code promo</Text>
                        <Button type='primary'
                            onClick={() => { setVisible(true) }}
                            style={{ width: "132px", height: "32px" }}> Ajouter</Button>
                    </Space>
                </Col>
            </Row>



            {isFetchingPromocodes ?
                <>
                    {Array.from({ length: 12 }, (_, i) => (

                        <Row justify={"start"} style={{ margin: "24px", minHeight: "100px", backgroundColor: "white" }}  >

                            <Col className="gutter-row" span={5} style={{ display: 'flex', justifyContent: "center", marginRight: "24px", marginBottom: "24px" }}  >


                            </Col>


                        </Row>

                    ))}
                </>
                :

                fetchPromoCodesResult?.data.map((item, index) => {
                    return (<>
                        <Row justify={"start"} style={{ margin: "24px", backgroundColor: "white", minHeight: "80px", padding: "10px" }}  >

                            <Col className="gutter-row" span={3} style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}  >
                                <Text>{item.code}</Text>
                            </Col>

                            <Col className="gutter-row" span={3} style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}  >
                                <Space>
                                    <Text>Réduction : </Text>
                                    <Text>{item.saleRate*100} %</Text>
                                </Space>
                            </Col>

                            <Col className="gutter-row" span={4} style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}  >

                                <Space>
                                    <Text>Expire le :</Text>
                                    {item.unlimitedTime ?
                                        <Alert message="Pas de Date" type="warning" />

                                        :
                                        <Text>{moment(item.expireAt).format("YYYY-MM-DD")} </Text>
                                    }
                                </Space>
                            </Col>
                            <Col className="gutter-row" span={7} style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}  >

                                <Space>
                                    <Text>Utilisations restantes:</Text>
                                    {item.unlimitedStock ?
                                        <Alert message="Utilisations illimitées" type="warning" />
                                        :
                                        <Text>{item.usesLeft + " / " + item.numberOfUses} </Text>
                                    }
                                </Space>
                            </Col>
                            <Col className="gutter-row" span={4} style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}  >

                            </Col>
                            <Col className="gutter-row" span={3} style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}  >
                                <Button loading={isDeletingPromoCode} type='primary' danger style={{ width: "120px" }} icon={<DeleteOutlined />}
                                    onClick={() => {
                                        dispatch(deletePromoCode({ id: item._id }))
                                    }}
                                ></Button>
                            </Col>


                        </Row>
                    </>
                    )
                }

                )
            }


        </Content>
    );
}

export default PromoCodesList;