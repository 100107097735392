import React, { useState } from "react";
import { Layout, Space } from 'antd';
import '../../fonts/avenir-next-rounded-pro-medium.otf';
import "./css/Activation.css";
import ChoiceScreen from "./pages/ChoiceScreen";
import { Helmet } from "react-helmet";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
const { Header, Content } = Layout;

const headerStyle = {
    textAlign: 'center',
    color: '#fff',
    height: '10vh',
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: '#fff',
}

const contentStyle = {
    textAlign: 'center',
    minHeight: '90vh',
    lineHeight: '120px',
    display:"flex",
    justifyContent:"center",
    color: '#fff',
    backgroundColor: '#fff',
}

export const ComponentStates = {
    ChoiceScreen: 1,
    SignInScreen: 2,
    SignUpScreen: 3
}

export default function Activation({ props }) {

    const [componentState, setComponentState] = useState(ComponentStates.ChoiceScreen);
    localStorage.setItem("ModulesLink", window.location.href);

    return (
        <Space direction="vertical" style={{ width: '100%', height:"100%" }}>
            <Layout>
                <Header style={headerStyle}></Header>
                <Helmet>
                    <title>Débloquer des modules Cactus</title>
                </Helmet>
                <Content className="activationBody" style={contentStyle}>
                    {(() => {
                        switch (componentState) {
                            case ComponentStates.ChoiceScreen:
                                return (<ChoiceScreen setComponentState={setComponentState} />)
                            case ComponentStates.SignInScreen:
                                return (<SignIn setComponentState={setComponentState} />)
                            case ComponentStates.SignUpScreen:
                                return (<SignUp setComponentState={setComponentState}  />)
                            default:
                                return (<ChoiceScreen setComponentState={setComponentState} />)
                        }
                    })()}
                </Content>
            </Layout>
        </Space>
    )
}


