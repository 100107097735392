import { createSlice } from "@reduxjs/toolkit";

import { getRevenueChartUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";

export const RevenueChartSlice = createSlice({
    name: "revenueChartData",
    initialState: {
        fetchRevenueChartDataResult: null,
        fetchRevenueChartDataError: null,
        isFetchingRevenueChartData: false,
    },
    reducers: {


        setRevenueChartDataResult: (state, action) => {
            state.fetchRevenueChartDataResult = action.payload;
            state.fetchRevenueChartDataError = null;

            state.isFetchingRevenueChartData = false;
        },

        setRevenueChartDataError: (state, action) => {
            state.fetchRevenueChartDataError = action.payload;
            state.isFetchingRevenueChartData = false;
            state.fetchRevenueChartDataResult = null

        },
        setIsFetchingRevenueChartData: (state, action) => {
            state.isFetchingRevenueChartData = action.payload;
        },
    },
});

export const {


    setRevenueChartDataResult,
    setIsFetchingRevenueChartData,
    setRevenueChartDataError,

} = RevenueChartSlice.actions;


export const fetchRevenueChartData = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getRevenueChartUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data: data
    };
    dispatch(setIsFetchingRevenueChartData(true));
    const response = axios(config)
        .then(response => {
            dispatch(setRevenueChartDataResult(response.data));
        })
        .catch((error) => {
            message.error(error.response.data.message)
            dispatch(setRevenueChartDataError(error.response.data.message));

        });

    return response.data;

}



export default RevenueChartSlice.reducer;