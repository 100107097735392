

import React, { useState, useEffect } from 'react';
import { Column } from '@ant-design/plots';
import { Card, Col, DatePicker, Descriptions, Divider, Radio, Row, Space, Statistic } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingupChartData } from './defisChartSlice';
import moment from 'moment';

const { RangePicker } = DatePicker
const DefisChartChart = () => {
    const dispatch = useDispatch();
    const fetchDefisChartDataResult = useSelector((state) => state.defisChartData.fetchDefisChartDataResult);
    const isFetchingDefisChartData = useSelector((state) => state.defisChartData.isFetchingDefisChartData);

    const [statfilter, setStatfilter] = useState({
        filter: "days",
        startDate: null,
        endDate: null,
    });

    useEffect(() => {

        dispatch(fetchSingupChartData({ ...statfilter }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statfilter]);

    const config = {
        data: fetchDefisChartDataResult?.stats,
        xField: 'period',
        yField: 'count',
        color: "#39B13D",
        tooltip: {
            formatter: (datum) => ({
                name: "Nombre",
                value: datum.count, 
            }),
        },
        label: {
            position: 'middle',
            style: {
                fill: '#FFFFFF',
                opacity: 0.5,
            },
        },
        xAxis: {
            label: {
                autoRotate: true,
                autoHide: false,
                autoEllipsis: false,
            },
        },

    };
    const handleChangeFilter = (e) => {
        setStatfilter({ filter: e.target.value })
    };
    return (
        <Row justify={"space-between"}>
            <Col span={17}>
                <Card title="Analytiques des Défis" style={{ backgroundColor: "white", cursor: "default", minHeight: "500px" }}
                    hoverable
                    extra={

                        <Space >
                            <Radio.Group
                                size='small'
                                value={statfilter.filter}
                                onChange={handleChangeFilter}
                                disabled={isFetchingDefisChartData || statfilter.startDate}
                            >
                                <Space>
                                    <Radio.Button style={{ border: "0" }} value={"days"}>Par jours</Radio.Button>
                                    <Radio.Button style={{ border: "0" }} value={"week"}>Par semaines</Radio.Button>
                                    <Radio.Button style={{ border: "0" }} value={"month"}>Par mois</Radio.Button>
                                </Space>
                            </Radio.Group>
                            <RangePicker
                                disabled={isFetchingDefisChartData}
                                onChange={(dates, datesString) => {

                                    setStatfilter({
                                        ...statfilter, startDate: datesString[0] === "" ? null : moment(datesString[0]).format(),
                                        endDate: datesString[1] === "" ? null : moment(datesString[1]).format(),
                                        filter: datesString[0] === "" ? "days" : "rangeDate"
                                    })
                                }}
                                size='small' />

                        </Space>}

                >
                    <Row>
                        <Col span={24}>

                            {fetchDefisChartDataResult ?
                                <Column {...config} />
                                : null}
                        </Col>

                    </Row>
                </Card>
            </Col>
            <Col span={6}>
                {fetchDefisChartDataResult ?
                    <Card>

                        <Statistic title="Total des Défis Lancés" value={fetchDefisChartDataResult.totalDefis} />
                        <Divider style={{ margin: "10px 0px" }} />
                        <Descriptions
                            column={1}
                            contentStyle={{ width: "100%", justifyContent: "flex-end" }}
                            labelStyle={{ color: "black" }}
                        >

                            <Descriptions.Item style={{ padding: '0' }} span={24} label="Défis lancés aujourd’hui">{fetchDefisChartDataResult?.totalDefisToday}</Descriptions.Item>

                        </Descriptions>

                    </Card>
                    : null}
            </Col>


        </Row>
    );
}

export default DefisChartChart;