import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Space, Row, Button, Col, Typography, Layout, Card, Tag } from 'antd';

import { EditOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from 'react-router-dom';
import { setFetchSingleLessonResult, setLessonAddResult } from '../lesson/singleLessonSlice';


const { Text } = Typography;
const { Content } = Layout;


const Lessons = () => {
    const { seq } = useParams();

    let navigate = useNavigate();
    const fetchSingleLessonResult = useSelector((state) => state.lesson.fetchSingleLessonResult);
    const lessonAddResult = useSelector((state) => state.lesson.lessonAddResult);

    const dispatch = useDispatch();

    useEffect(() => {
        if (fetchSingleLessonResult || lessonAddResult) {
            dispatch(setFetchSingleLessonResult(null));
            dispatch(setLessonAddResult(null));

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchSingleLessonResult, lessonAddResult]);

    const fetchLessonsResult = useSelector((state) => state.lessons.fetchLessonsResult);

    const isFetchingLessons = useSelector((state) => state.lessons.isFetchingLessons);

    const columns = [
        {
            title: 'N°',
            dataIndex: 'seq',
            key: 'seq',
            width: '5%',
            sorter: (a, b) => a.seq - b.seq,


        },
        {
            title: 'Nom de la leçon',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
        },
        {
            title: 'Exercices',
            key: 'exercices',
            dataIndex: 'exercices',
            width: '35%',

            filters: [
                {
                    text: 'Foot',
                    value: 'exoFoot',
                },
                {
                    text: 'Manga',
                    value: 'exoManga',
                },
                {
                    text: 'Culture G',
                    value: 'exoCactus',
                },

            ],
            filterMultiple: false,
            onFilter: (value, record) =>
                record.exercices.includes(value) === false,


            render: (_, { exercices }) => (
                <>
                    {exercices.map((tag) => {
                        let key = tag
                        let color = key === "exoFoot" ? "blue" : key === "exoManga" ? "red" : key === "exoCactus" ? "green" : null
                        let text = key === "exoFoot" ? "Foot" : key === "exoManga" ? "Manga" : key === "exoCactus" ? "CultureG" : null

                        return (
                            color ?
                                <Tag color={color} key={key}>
                                    {text}
                                </Tag>
                                : null
                        );
                    })}
                </>
            )
        },
        {
            title: 'Contenu',
            key: 'Content',
            dataIndex: 'Content',
            filterMultiple: false,
            width: '35%',

            filters: [
                {
                    text: 'Foot',
                    value: 'contentFoot',
                },
                {
                    text: 'Manga',
                    value: 'contentManga',
                },
                {
                    text: 'Culture G',
                    value: 'contentCactus',
                },

            ],
            onFilter: (value, record) =>
                record.Content.includes(value) === false,
            render: (_, { Content }) => (
                <>
                    {Content.map((tag) => {
                        let key = tag
                        let color = key === "contentFoot" ? "blue" : key === "contentManga" ? "red" : key === "contentCactus" ? "green" : null
                        let text = key === "contentFoot" ? "Foot" : key === "contentManga" ? "Manga" : key === "contentCactus" ? "CultureG" : null

                        return (
                            color ?
                                <Tag color={color} key={key}>
                                    {text}
                                </Tag>
                                : null
                        );
                    })}
                </>
            )
        },


        {
            title: "Actions",
            width: "5%",
            align: "center",
            dataIndex: "_id",
            key: "_id",
            render: (_id) => {
                return (
                    <Button
                        type="link"
                        color='green'
                        icon={<EditOutlined />}
                        size="small"
                        onClick={(e) => {
                            navigate(_id)
                        }}
                    >
                        Gérer
                    </Button>
                );
            },
        },

    ];

    return (

        <Content
            className="site-layout-background"
            style={{
                padding: 0,
                margin: 0,
                minHeight: 280,
            }}
        >
            <>
                <Row justify={"center"} style={{ background: "#424242", margin: "24px", minHeight: "112px" }}  >
                    <Col span={7} style={{ paddingTop: 10 }}                >
                        <Space align='center' direction="vertical" size="middle" style={{ display: 'flex' }}>
                            <Text style={{ color: "white", fontSize: "16px" }}>Ajouter une leçon à ce module</Text>
                            <Button type='primary'
                                disabled={(seq === "1" && fetchLessonsResult?.total >= 10) || (seq !== "1" && fetchLessonsResult?.total >= 42)}
                                onClick={() => { navigate("addLesson/" + (fetchLessonsResult.total + 1)) }}
                                style={{ width: "145px", height: "32px" }}> Ajouter une Leçon</Button>
                        </Space>
                    </Col>
                </Row>
                <Row justify={"center"} style={{ margin: "24px" }}>
                    <Card title='Liste des leçons du module'
                        style={{ width: "100%" }}
                        bodyStyle={{ padding: "18px" }}
                    >

                        {fetchLessonsResult && fetchLessonsResult.lessons ?
                            <Table style={{ width: "100%" }}
                                columns={columns} pagination={{ total: fetchLessonsResult?.total, pageSize: 5 }}
                                loading={isFetchingLessons} dataSource={fetchLessonsResult ? fetchLessonsResult.lessons : null} />
                            :
                            <Table style={{ width: "100%" }} columns={columns}
                                loading={isFetchingLessons} dataSource={null} />
                        }
                    </Card>

                </Row>
            </>

        </Content>

    );
}

export default Lessons;