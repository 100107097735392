

import { Button, Card, Checkbox, Col, Form, Image, Input, Row, Space, Typography, Upload } from "antd";
import { useEffect, useState } from "react";
import { PlusOutlined, LoadingOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../comps/pageHeader";
import { fetchUploadNotificationImage, sendNotification, setNotificationImageUrl } from "./notificationSlice";
import { upload_path } from "../../constants";
import { deleteSpriteFile, fetchSingleItem } from "../avatar/singleItemSlice";
import { useParams } from 'react-router-dom';

const { Text } = Typography



const NotificatoinPush = ({ visible, setVisible, slectedTheme }) => {
    const { id } = useParams();

    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        title: "",
        message: "",
        type: id ? "item" : "custom",
        image: "",
        theme: [],
        meta: id ? id : ""

    });
    const [fileListSprites, setFileList] = useState([

    ]);
    const [checkBoxTheme, setCheckBoxTheme] = useState(null);

    const fetchThemesResult = useSelector((state) => state.dashboard.fetchThemesResult);

    // const fetchUploadNotificationImageResult = useSelector((state) => state.sendNotif.fetchUploadNotificationImageResult);
    const isUploadingNotificationImage = useSelector((state) => state.sendNotif.isUploadingNotificationImage);
    const notifImgUrl = useSelector((state) => state.sendNotif.notifImgUrl);

    const fetchSendNotifResult = useSelector((state) => state.sendNotif.fetchSendNotifResult);

    const isFetchingSendNotif = useSelector((state) => state.sendNotif.isFetchingSendNotif);
    const fetchSingleItemResult = useSelector((state) => state.singleItem.fetchSingleItemResult);

    useEffect(() => {
        if (notifImgUrl) {
            setFormData({ ...formData, image: upload_path + notifImgUrl })
            setFileList([{
                name: 0,
                status: 'done',
                url: upload_path + notifImgUrl,

            }])
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifImgUrl]);
    useEffect(() => {
        if (fetchThemesResult) {
            let options = []
            // eslint-disable-next-line array-callback-return
            fetchThemesResult.data.map((theme) => {
                options.push({ label: theme.displayName, value: theme._id })
            })
            setCheckBoxTheme(options)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchThemesResult]);
    const handleCheckTheme = (checkedValues) => {
        setFormData({ ...formData, theme: checkedValues })
    };
    const handleRemoceImage = () => {

        dispatch(deleteSpriteFile({ filePath: notifImgUrl }))
        dispatch(setNotificationImageUrl(null))

        setFileList([])
    };

    const uploadnotifImageProps = {
        showUploadList: { showPreviewIcon: false, showRemoveIcon: false },
        beforeUpload: (file) => {
            dispatch(
                fetchUploadNotificationImage({}, file)
            );
            return false;
        },
    };
    const onFinish = (values) => {
        // console.log('formData ', formData);
        dispatch(
            sendNotification(formData))
    };

    useEffect(() => {
        if (!fetchSingleItemResult && id) {

            dispatch(fetchSingleItem({ id }));

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    useEffect(() => {
        if (fetchSingleItemResult) {

            setFormData({ ...formData, image: upload_path + fetchSingleItemResult?.data.thumbnail })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchSingleItemResult]);


    return (
        < >
            <PageHeader title={"Envoyer une notifications push"}
                breadcrumbItems={["Centre des Notifications", "Notification Push"]} />

            <Space direction="vertical" style={{ backgroundColor: "white", minHeight: "80vh", width: "95%", margin: "24px", }}>


                <Row justify={"center"} style={{ padding: "48px" }} >
                    <Col span={15} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Text type="secondary">
                            Note : Le contenu de la notification doit être simplifié et non chargé.
                        </Text>
                    </Col>
                </Row>

                <Row justify={"center"} style={{ marginTop: "" }}  >
                    <Col span={20} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 17 }}
                            layout="horizontal"
                            style={{ width: "70%" }}
                            onFinish={onFinish}
                            disabled={fetchSendNotifResult}
                            requiredMark={false}

                        >

                            <Form.Item label="Monde" name={"themes"}
                                style={{ marginBottom: "32px" }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez sélectionner les mondes auxquels envoyer la notification !',
                                    },
                                ]}
                            >
                                <Checkbox.Group options={checkBoxTheme ? checkBoxTheme : null} defaultValue={null} onChange={handleCheckTheme} />
                            </Form.Item>
                            <Form.Item label="Titre" name={"Title"}
                                style={{ marginBottom: "32px" }}

                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez saisir le titre de la notification !',
                                    },
                                ]}
                            >

                                <Input
                                    value={formData.title}
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            title: e.target.value,
                                        });
                                    }}
                                />


                            </Form.Item>
                            <Form.Item label="Contenu" name={"message"}
                                style={{ marginBottom: "32px" }}

                                rules={[
                                    {
                                        required: true,
                                        message: 'le contenu de la notification ne peut pas être vide!',
                                    },
                                ]}
                            >

                                <Input.TextArea

                                    showCount
                                    value={formData.message}
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            message: e.target.value,
                                        });
                                    }}
                                />


                            </Form.Item>
                            {id ?
                                <Form.Item
                                    label="Image" valuePropName="fileList" >
                                    <Image
                                        fallback='/sadCactus.svg'
                                        height={100}
                                        src={upload_path + fetchSingleItemResult?.data.thumbnail}
                                    />
                                </Form.Item>


                                : <Form.Item
                                    label="Image" valuePropName="fileList" >
                                    <Space.Compact style={{ margin: "0", alignItems: "center" }} direction="vertical" align="center">

                                        <Upload maxCount={1}
                                            disabled={notifImgUrl}
                                            accept="image/*"
                                            fileList={fileListSprites}
                                            {...uploadnotifImageProps}
                                            listType="picture-card">
                                            <div>
                                                {isUploadingNotificationImage ? (<LoadingOutlined />
                                                ) : (<PlusOutlined />)}

                                                <div style={{ marginTop: 8 }}>Upload</div>
                                            </div>
                                        </Upload>
                                        {notifImgUrl && !fetchSendNotifResult ?

                                            <Card
                                                // bodyStyle={{ maxHeight: "10px" }}
                                                bodyStyle={{ padding: 0, minWidth: "100px" }}
                                                onClick={handleRemoceImage}
                                                hoverable

                                            >

                                                <DeleteOutlined style={{ color: "red" }} />
                                            </Card> : null
                                        }
                                    </Space.Compact>
                                </Form.Item>
                            }
                            <Form.Item
                                wrapperCol={{ offset: 8, span: 16, }}
                            >
                                <Button type="primary" htmlType="submit" loading={isFetchingSendNotif}>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Space>

        </>
    );
}

export default NotificatoinPush;