import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import axios from 'axios';
import {  add_admin_url, admins_url } from '../../constants';


export const AdminsSlice = createSlice({
    name: 'admins',
    initialState: {

        fetchServerErr: null,
        fetchAdminsResult: null,
        fetchAdminsError: null,
        isFetchingAdmins: false,
        isConnected: (localStorage.token !== undefined || localStorage.token !== null),

    },
    reducers: {
        setFetchAdminsResult: (state, action) => {
            state.fetchAdminsResult = action.payload;
            state.isFetchingAdmins = false;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setFetchAdminsError: (state, action) => {
            state.fetchAdminsError = action.payload;
            state.isFetchingAdmins = false;
            state.fetchAdminsResult = null

        },
        setfetchServerErr: (state, action) => {
            state.fetchServerErr = action.payload
        },

        setConnected: (state, action) => {
            state.isConnected = action.payload;
        },
        setIsFetchingAdmins: (state, action) => {
            state.isFetchingAdmins = action.payload;
        },
    }
});

export const {

    setfetchServerErr,

    setFetchAdminsResult,
    setFetchAdminsError,
    setConnected,
    setIsFetchingAdmins,

} = AdminsSlice.actions;

export const fetchAdmins = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: admins_url,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data

    };
    dispatch(setIsFetchingAdmins(true));
    const response = axios(config)
        .then(response => {
            dispatch(setFetchAdminsResult(response.data));
        })
        .catch(err => {
            if (err.respons) {
                dispatch(setFetchAdminsError(err.response.data.error));
            }
            else {
                dispatch(setfetchServerErr('Err'))
            }
        });

    return response.data;

}
export const AddnewAdmin = (data) => async (dispatch, getState) => {
    const config = {
      method: "post",
      url: add_admin_url,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.token,
      },
      data,
    };
    console.log("add Admin ", data);
  
    const response = await axios(config)
      .then((response) => {
        message.success(response.data.message)
        dispatch(fetchAdmins());
      })
      .catch((error) => {
        message.error(error.response.data.message)
      });
    console.log(response);
    return response;
  };



export default AdminsSlice.reducer;