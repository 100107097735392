import { createSlice } from "@reduxjs/toolkit";

import {  moduleAchivemntPerenctageUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";

export const SignUpChartSlice = createSlice({
    name: "modulesAchv",
    initialState: {
        fetchModulesAchvDataResult: null,
        fetchModulesAchvDataError: null,
        isFetchingModulesAchvData: false,
    },
    reducers: {


        setModulesAchvDataResult: (state, action) => {
            state.fetchModulesAchvDataResult = action.payload;
            state.fetchModulesAchvDataError = null;

            state.isFetchingModulesAchvData = false;
        },

        setModulesAchvDataError: (state, action) => {
            state.fetchModulesAchvDataError = action.payload;
            state.isFetchingModulesAchvData = false;
            state.fetchModulesAchvDataResult = null

        },
        setIsFetchingSingupChartData: (state, action) => {
            state.isFetchingModulesAchvData = action.payload;
        },
    },
});

export const {


    setModulesAchvDataResult,
    setIsFetchingSingupChartData,
    setModulesAchvDataError,

} = SignUpChartSlice.actions;


export const fetchmodulesAchivemntPerenctage = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: moduleAchivemntPerenctageUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data: data
    };
    dispatch(setIsFetchingSingupChartData(true));
    const response = axios(config)
        .then(response => {
            dispatch(setModulesAchvDataResult(response.data));
        })
        .catch((error) => {
            message.error(error.response.data.message)
            dispatch(setModulesAchvDataError(error.response.data.message));

        });

    return response.data;

}



export default SignUpChartSlice.reducer;