import React, { useState, useEffect } from "react";
import { Button, Col, Image, Layout, Row, Space } from 'antd';
import "../activation/css/Activation.css";
import { Helmet } from "react-helmet";
import Dialog from "../activation/components/Dialog";
import { useSearchParams } from "react-router-dom";
const { Header, Footer, Content } = Layout;

const headerStyle = {
    textAlign: 'center',
    color: '#fff',
    height: '10vh',
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: '#fff',
}

const contentStyle = {
    textAlign: 'center',
    minHeight: '90vh',
    lineHeight: '120px',
    display: "flex",
    justifyContent: "center",
    color: '#fff',
    backgroundColor: '#fff',
}

export default function ConfirmPurchase({ props }) {
    const myLink = window.location.href;
    const [queryParameters] = useSearchParams();
    const [fatalError, setFatalError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const confirmationUrl = "https://cactusfrance.com/api/app/confirmPayment";

    useEffect(() => {

        fetch(confirmationUrl, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                checkoutSessionId: queryParameters.get("checkoutSessionId"),
                packId: queryParameters.get("packId"),
                userId: queryParameters.get("userId"),
            })
        }).then(async (response) => {
            if (response.status !== 200) {
                const returnValue = await response.json()
                setErrorMessage(returnValue.message)
                setFatalError(returnValue.fatalError);
                return;
            }
            // Only way to close the window
            window.opener = null;
            window.open("", "_self");
            window.close();

        }).catch((error) => {
            setErrorMessage("Une erreur inattendue est survenue, veuillez contacter l'équipe Cactus.");
            setFatalError(true);
        })

    }, []);
    return (
        <Space direction="vertical" style={{ width: '100%', height: "100%" }}>
            <Layout>
                <Header style={headerStyle}></Header>
                <Helmet>
                    <title>Confirmation d'achat</title>
                </Helmet>
                <Content className="activationBody" style={contentStyle}>
                    <Col style={{
                        width: "30vw",
                        position: "absolute",
                        top: "3vh"
                    }}>
                        <Row style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "8vh"
                        }}>
                            <Dialog text={fatalError ? "Oups" :"Je confirme ton achat.."} />
                        </Row>
                        <Row style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                            <Image
                                src="/activation/Lilly.png"
                                preview={false} />
                        </Row>
                        <Row style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "2vh"
                        }}>
                            <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "20px", color: fatalError ? "#FF4B4B" : "#616161" }}>{fatalError ? errorMessage : "Ton achat est en cours de confirmation, tu retrouveras tes achats dans l'application !"}</p>
                        </Row>
                    </Col>
                    {fatalError &&
                        <Col style={{
                            width: "30vw",
                            position: "absolute",
                            top: "3vh",
                            right: "3vh"
                        }}>
                            <a href="https://cactusfrance.com/contact" target="_blank"><Button className="customButton" style={{ color: "#fff", backgroundColor: "#FF4B4B", borderRadius: "100px", width: "10vw", height: "2vh" }}>Lien de contact</Button></a>
                        </Col>
                    }
                </Content>
            </Layout>
        </Space>
    )
}