

import { Button, Card } from "antd";
import { useEffect, useState } from "react";
import { EditOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PageHeader from "../../comps/pageHeader";
import { editSingleExercice, fetchSingleExercice } from "./singleExerciceSlice";
import StatementComps from "../../comps/statmentExo";
import PrposExo from "../../comps/propsExo";
import QuestionsComps from "../../comps/questionsComps";
import { editQuestions } from "./questionsSlice";
import QuestionsPropsComps from "../../comps/questionsPropsComps";



const SingleExercice = () => {
    const { lseq, id, seq } = useParams();
    const dispatch = useDispatch();

    const [exoType, setExoType] = useState("");
    const [switcherEdit, setSwitcherEdit] = useState(false);
    const [exerciceForm, setExerciceForm] = useState({ statement: "", propositions: [], id: null });
    const [questionsForm, setQuestionsForm] = useState({ questionType: "", questions: [] });

    const fetchSingleExerciceResult = useSelector((state) => state.exercice.fetchSingleExerciceResult);

    const updateQuestionsResult = useSelector((state) => state.questions.updateQuestionsResult);
    const updateQuestionsError = useSelector((state) => state.questions.updateQuestionsError);
    const isUpdatingQuestions = useSelector((state) => state.questions.isUpdatingQuestions);

    useEffect(() => {
        if (id) {

            dispatch(fetchSingleExercice({ id }));
            setExerciceForm({ ...exerciceForm, id: id })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, updateQuestionsResult, updateQuestionsError]);

    useEffect(() => {
        if (fetchSingleExerciceResult) {
            const prpos = fetchSingleExerciceResult.exercice.propositions.map(proposition => proposition.content);
            if (fetchSingleExerciceResult?.exercice.propositions.length > 0) {
                setExoType("common")
            } else {
                setExoType("nonComon")
            }

            setExerciceForm({ ...exerciceForm, statement: fetchSingleExerciceResult?.exercice.statement, propositions: prpos })
            let qst = []
            qst = fetchSingleExerciceResult.questions.map(question => ({
                id: question._id, content: [question.content.join(" ")],
                correction: question.correction.content,
                propositions: question.propositions.map(props=>props.content)
            }));
            setQuestionsForm({ ...questionsForm, questions: qst })

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchSingleExerciceResult]);
    const handleConfirmExercice = (e) => {
        // console.log('exerciceForm' ,exerciceForm);
        // console.log('questionsForm ' ,questionsForm);
        dispatch(editSingleExercice(exerciceForm));
        dispatch(editQuestions(questionsForm));
        setSwitcherEdit(false)

    };
    return (
        < >
            {fetchSingleExerciceResult ?
                <PageHeader title={"Aperçu de l’exercice " + fetchSingleExerciceResult?.exercice.theme.displayName}
                    breadcrumbItems={["Création de Contenu", "Modules", "Module " + seq, "leçon " + lseq, "Exercice " + fetchSingleExerciceResult?.exercice.theme.displayName]} />
                : null}
            <Card title='Ajouter Exercice à Réponses Communes'
                style={{ width: "95%", margin: "24px" }}
                bodyStyle={{ padding: "24px 80px 24px 80px" }}
                actions={!switcherEdit ? [

                    <EditOutlined style={{ fontSize: "21px" }}
                        onClick={() => {
                            setSwitcherEdit(!switcherEdit)

                            if (fetchSingleExerciceResult.questions[0].content.length > 1) {
                                setQuestionsForm({ ...questionsForm, questionType: "fill" })

                            } else {
                                setQuestionsForm({ ...questionsForm, questionType: "replace" })

                            }
                        }

                        }

                    />

                ] : [


                    <Button type="primary"
                        onClick={handleConfirmExercice}
                        loading={isUpdatingQuestions}
                    >Sauvgarder</Button>,
                ]}
            >
                {fetchSingleExerciceResult && exoType === "common" ?
                    <>
                        <StatementComps switcherEdit={switcherEdit} setExerciceForm={setExerciceForm} exerciceForm={exerciceForm} />
                        <PrposExo switcherEdit={switcherEdit} setExerciceForm={setExerciceForm} exerciceForm={exerciceForm} />
                        <QuestionsComps switcherEdit={switcherEdit} questionsForm={questionsForm} setQuestionsForm={setQuestionsForm} exerciceForm={exerciceForm} />
                    </>
                    : (fetchSingleExerciceResult && exoType === "nonComon") ?
                        <>
                            <StatementComps switcherEdit={switcherEdit} setExerciceForm={setExerciceForm} exerciceForm={exerciceForm} />
                            <QuestionsPropsComps switcherEdit={switcherEdit} questionsForm={questionsForm} setQuestionsForm={setQuestionsForm} exerciceForm={exerciceForm} />
                        </>
                        : null
                }
            </Card>
        </>
    );
}

export default SingleExercice;