import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Space, Row, Button, Layout, Radio, Col, DatePicker, Typography } from 'antd';

import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import { fetchUserTransactions } from './userSlice';
// import { deleteSingleUserTransactions, UpdateUserTransactions } from '../us/singleUserTransactionsSlice';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';

const { Content } = Layout;
const { Text } = Typography;
const { RangePicker } = DatePicker;


const UserTransactionsList = () => {
    const { id } = useParams();

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const fetchUserTransactionsResult = useSelector((state) => state.user.fetchUserTransactionsResult);
    const isfetchingUserTransactions = useSelector((state) => state.user.isfetchingUserTransactions);



    const [transactionField, setUserTransactionsFilter] = useState({

        id: id,
        page: 1,
        pageLength: 5,
        startDate: null,
        endDate: null,
        sort: null,
        currency: null,
        type: null,

    });


    useEffect(() => {
        // dispatch(getBannerInfo());
        dispatch(fetchUserTransactions(transactionField));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionField]);
    const getColumnFilterTypeProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Radio.Group
                    onChange={(e) => setUserTransactionsFilter({
                        ...transactionField, type: e.target.value, page: 1,
                    })} value={transactionField.type}>
                    <Space direction="vertical">
                        <Radio value={"module"}>Modules</Radio>
                        <Radio value={"item"}>Item</Radio>
                        <Radio value={"hearts"}>Cœurs</Radio>
                        <Radio value={"diamonds"}>Diamants</Radio>
                        <Radio value={null}>Tout</Radio>
                    </Space>
                </Radio.Group>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        close();
                    }}
                >
                    Fermer
                </Button>
            </div>),
        filterIcon: (filtered) => (<FilterFilled
            style={{
                color: filtered ? '#39B13D' : undefined,
            }}
        />),
    });
    const getColumnFilterCurrencyProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Radio.Group
                    onChange={(e) => setUserTransactionsFilter({
                        ...transactionField, currency: e.target.value, page: 1,
                    })} value={transactionField.currency}>
                    <Space direction="vertical">
                        <Radio value={"coins"}>Pièces</Radio>
                        <Radio value={"money"}>Argents</Radio>
                        <Radio value={"diamonds"}>Diamants</Radio>
                        <Radio value={null}>Tout</Radio>
                    </Space>
                </Radio.Group>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        close();
                    }}
                >
                    Fermer
                </Button>
            </div>),
        filterIcon: (filtered) => (<FilterFilled
            style={{
                color: filtered ? '#39B13D' : undefined,
            }}
        />),
    });



    const getColumnDateFilterProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >

                <RangePicker
                    size='small'
                    onChange={(dates, datesString) => {
                        console.log('dates', dates);

                        setUserTransactionsFilter({
                            ...transactionField, startDate: datesString[0] === "" ? null : moment(datesString[0]).format(), endDate: datesString[1] === "" ? null : moment(datesString[1]).format()

                        })

                        // datesString[0] === "" ? close() :
                        //     console.log('datesString', datesString);
                    }}
                />

            </div >
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#39B13D' : undefined,
                }}
            />
        ),
    });

    const columns = [

        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: '20%',
            align: "center",
            render: (type, row) => {
                return (
                    type === "item" ?
                        <Button type='link'
                        onClick={() => { navigate("/dashboard/content/avatar/items/" + row.documentId, { replace: true }) }}
                        >{type}</Button>
                        :
                        <Text >{type}</Text>

                );
            },
            ...getColumnFilterTypeProps(),

        },

        {
            title: 'Quantité',
            dataIndex: 'amount',
            key: 'amount',
            width: '10%',
            align: "center",


        },
        {
            title: 'Coût',
            dataIndex: 'price',
            key: 'price',
            width: '10%',
            align: "center",

        },
        {
            title: 'Type de monnaie',
            dataIndex: 'currency',
            key: 'currency',
            width: '30%',
            align: "center",
            render: (currency) => {
                return (
                    <Text>{currency === "coins" ? 'Pièces' : currency === "diamonds" ? "Diamants" : "Argent"}</Text>

                );
            },
            ...getColumnFilterCurrencyProps(),

        },
        {
            title: 'Date d’achat',
            dataIndex: 'createdAt',
            align: 'center',
            key: 'createdAt',
            width: '30%',

            render: (text) => {
                return moment(text).format("DD-MM-YYYY:HH:MM");
            },
            ...getColumnDateFilterProps()
        },
    ];

    const onChangePage = (e) => {
        setUserTransactionsFilter({ ...transactionField, page: e.current, pageLength: e.pageSize });
    }


    return (

        <Content
            className="site-layout-background"
            style={{
                padding: 0,
                margin: 0,
                minHeight: 280,
            }}
        >
            <>
                <Row >
                    <Col span={24} style={{ display: "flex", justifyContent: "left" }}>
                        <Text>Trouvez tous les transactions de l'utilisateur ici.</Text>
                    </Col>
                </Row>
                <Row justify={"center"} >


                    <Row style={{ marginTop: "16px", width: "100%" }}

                    >
                        <Col span={24}  >

                            <Table
                                style={{ width: "100%" }}
                                columns={columns}
                                pagination={{
                                    total: fetchUserTransactionsResult?.total,
                                    pageSize: transactionField.pageLength,
                                    current: transactionField.page,

                                    showTotal: (total, range) => (<>Nombre de transactions : {total} </>),
                                }}
                                loading={isfetchingUserTransactions}
                                dataSource={fetchUserTransactionsResult?.data}
                                onChange={onChangePage}
                            />

                        </Col>
                    </Row>


                </Row>
            </>

        </Content>

    );
}

export default UserTransactionsList;