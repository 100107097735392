

import React, { useState, useEffect } from 'react';
import { Column } from '@ant-design/plots';
import { Card, Col, DatePicker, Radio, Row, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingupChartData } from './signupChartSlice';
import Top5Days from './top5Days';
import moment from 'moment';

const { RangePicker } = DatePicker
const SignupChart = () => {
    const dispatch = useDispatch();
    const fetchSignupChartDataResult = useSelector((state) => state.signupChartData.fetchSignupChartDataResult);
    const isFetchingSignupChartData = useSelector((state) => state.signupChartData.isFetchingSignupChartData);

    const [statfilter, setStatfilter] = useState({
        filter: "days",
        startDate: null,
        endDate: null,
    });

    useEffect(() => {

        dispatch(fetchSingupChartData({ ...statfilter }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statfilter]);

    const config = {
        data: fetchSignupChartDataResult?.stats,
        xField: 'period',
        yField: 'count',
        color: "#39B13D",
        tooltip: {
            formatter: (datum) => ({
                name: "Nombre",
                value: datum.count, 
            }),
        },
        label: {
            position: 'middle',
            style: {
                fill: '#FFFFFF',
                opacity: 0.5,
            },
        },
        xAxis: {
            label: {
                autoRotate: true,
                autoHide: false,
                autoEllipsis: false,
            },
        },

    };
    const handleChangeFilter = (e) => {
        setStatfilter({ filter: e.target.value })
    };
    return (

        <Card title="Analytiques des Inscriptions" style={{ backgroundColor: "white", cursor: "default", minHeight: "500px" }}
            hoverable
            extra={

                <Space >
                    <Radio.Group
                        size='small'
                        value={statfilter.filter}
                        onChange={handleChangeFilter}
                        disabled={isFetchingSignupChartData || statfilter.startDate}
                    >
                        <Space>
                            <Radio.Button style={{ border: "0" }} value={"days"}>Par jours</Radio.Button>
                            <Radio.Button style={{ border: "0" }} value={"week"}>Par semaines</Radio.Button>
                            <Radio.Button style={{ border: "0" }} value={"month"}>Par mois</Radio.Button>
                        </Space>
                    </Radio.Group>
                    <RangePicker
                        disabled={isFetchingSignupChartData}
                        onChange={(dates, datesString) => {

                            setStatfilter({
                                ...statfilter, startDate: datesString[0] === "" ? null : moment(datesString[0]).format(),
                                endDate: datesString[1] === "" ? null : moment(datesString[1]).format(),
                                filter: datesString[0] === "" ? "days" : "rangeDate"
                            })
                        }}
                        size='small' />

                </Space>}

        >
            <Row justify={"space-between"}>
                <Col span={15}>

                    {fetchSignupChartDataResult ?
                        <Column {...config} />
                        : null}
                </Col>
                <Col span={8}>
                    {fetchSignupChartDataResult ?
                        <Top5Days topFive={fetchSignupChartDataResult.top5Days} />
                        : null}
                </Col>
            </Row>
        </Card>
    );
}

export default SignupChart;