

import React, { useEffect } from 'react';
import { Bar, Pie } from '@ant-design/plots';
import { Card, Col, Divider, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOnboardingdata } from './onboardingdatasSlice';

const { Title } = Typography
const Onboardingdata = () => {
    const dispatch = useDispatch();
    const fetchOnboardingdataResult = useSelector((state) => state.onboardingdatas.fetchOnboardingdataResult);
    const isFetchingOnboardingdata = useSelector((state) => state.onboardingdatas.isFetchingOnboardingdata);


    useEffect(() => {

        dispatch(fetchOnboardingdata({}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const configFrenchLevel = {

        appendPadding: 5,
        data: fetchOnboardingdataResult?.frenchLevel,
        angleField: 'pourcentage',
        colorField: 'niveau',
        radius: 0.7,
        innerRadius: 0.6,
        label: {
            offset: -20, // decrease the offset to bring the labels closer

            style: {
                fontSize: 12,
                textAlign: 'center',
            },
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
        legend: {

            offsetX: -100, // decrease the offset to bring the labels closer
            offsetY: -100, // decrease the offset to bring the labels closer

            // position: 'bottom', // move the legend to the bottom
        },
        statistic: {
            title: false,
            content: false,

        },

    };
    const configLearningGoals = {

        data: fetchOnboardingdataResult?.learningGoals,
        xField: 'pourcentage',
        yField: 'goal',
        color: "#39B13D",
        grid: false,

        // seriesField: 'goal',
        label: {
            position: 'middle',
            formatter: (datum) => `${datum.pourcentage} %`, // add the percentage symbol to the label

            style: {
                fill: '#FFFFFF',
                opacity: 0.8,
            },
        },
        tooltip: {
            formatter: (datum) => ({
                name: "pourcentage",
                value: `${datum.pourcentage} %`, // access the y value of the data
            }),
        },
        xAxis: false, // set the xAxis property to false to remove it


    };
    const configReasonToLearn = {

        data: fetchOnboardingdataResult?.reasonToLearn,
        xField: 'pourcentage',
        yField: 'reason',
        color: "#39B13D",
        grid: false,

        // seriesField: 'goal',
        label: {
            position: 'middle',
            formatter: (datum) => `${datum.pourcentage} %`, // add the percentage symbol to the label

            style: {
                fill: '#FFFFFF',
                opacity: 0.8,
            },
        },
        tooltip: {
            formatter: (datum) => ({
                name: "pourcentage",
                value: `${datum.pourcentage} %`, // access the y value of the data
            }),
        },
        xAxis: false, // set the xAxis property to false to remove it


    };
    return (

        <Card title="Données d’Inscriptions des Utilisateurs"
            style={{ backgroundColor: "white", cursor: "default", minHeight: "500px", }}
            hoverable
            loading={isFetchingOnboardingdata}

        >

            {fetchOnboardingdataResult ?
                <>
                    <Row>
                        <Col span={12} >
                            <Title level={5}>Niveau de Français des Utilisateurs :</Title>
                            <Pie {...configFrenchLevel} />
                        </Col>
                        <Col span={12} >
                            <Title level={5} style={{ marginBottom: "24px" }}>Objectifs des Utilisateurs :</Title>
                            <Bar {...configReasonToLearn} />
                        </Col>

                    </Row>
                    <Divider />
                    <Row>
                        <Col span={12} >
                            <Title level={5}>Temps d’Utilisation :</Title>
                            <Bar {...configLearningGoals} />

                        </Col>
                    </Row>
                </>
                : null}


        </Card>
    );
}

export default Onboardingdata;