import { createSlice } from "@reduxjs/toolkit";

import { getUserDefisStatsUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";

export const DefisChartSlice = createSlice({
    name: "defisChartData",
    initialState: {
        fetchDefisChartDataResult: null,
        fetchDefisChartDataError: null,
        isFetchingDefisChartData: false,
    },
    reducers: {


        setDefisChartDataResult: (state, action) => {
            state.fetchDefisChartDataResult = action.payload;
            state.fetchDefisChartDataError = null;

            state.isFetchingDefisChartData = false;
        },

        setDefisChartDataError: (state, action) => {
            state.fetchDefisChartDataError = action.payload;
            state.isFetchingDefisChartData = false;
            state.fetchDefisChartDataResult = null

        },
        setIsFetchingSingupChartData: (state, action) => {
            state.isFetchingDefisChartData = action.payload;
        },
    },
});

export const {


    setDefisChartDataResult,
    setIsFetchingSingupChartData,
    setDefisChartDataError,

} = DefisChartSlice.actions;


export const fetchSingupChartData = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getUserDefisStatsUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data: data
    };
    dispatch(setIsFetchingSingupChartData(true));
    const response = axios(config)
        .then(response => {
            dispatch(setDefisChartDataResult(response.data));
        })
        .catch((error) => {
            message.error(error.response.data.message)
            dispatch(setDefisChartDataError(error.response.data.message));

        });

    return response.data;

}



export default DefisChartSlice.reducer;