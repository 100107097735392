

import { Col, Form, Modal, Radio, Row, Space } from "antd";
import { useState } from "react";
// import { UserOutlined, LockOutlined } from '@ant-design/icons';
import {  useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";





const ChoseTypeModal = ({ visible, setVisible, slectedTheme }) => {
    let navigate = useNavigate();

    // const dispatch = useDispatch();
    const [value, setValue] = useState("common");
    const pathname = useSelector((state) => state.dashboard.pathname);


    const onChange = (e) => {

        setValue(e.target.value);
    };
    const handleFormSubmit = (event) => {
        if (value === "common") {
            navigate(pathname + '/addcommonexercice/' + slectedTheme)
        }else{
            navigate(pathname + '/addnoncommonexercice/' + slectedTheme)
            
        }
        // dispatch(AddnewAdmin(formData));
        setVisible(false)
    };


    return (
        < >
            <Modal title="Ajouter un exercice"
                open={visible}
                okText="Confirmer"
                centered
                maskClosable={false}
                closable={false}
                onOk={handleFormSubmit}
                // okButtonProps={{ disabled: (formData.name === "" || formData.password === "" || formData.type === "") }}
                onCancel={() =>
                    setVisible(false)}
                width={600}
                maskStyle={{
                    backgroundColor: "#10101088",
                    backdropFilter: "blur(0.7rem)",
                }}>
                <Row justify="center" style={{ marginTop: "40px" }}>


                    <Col span={24} >
                        <Form
                            layout="vertical"

                            style={{ display: "block", margin: "auto" }} >
                            <Form.Item label="Choisissez le type d'exercice :">
                                <Radio.Group
                                    onChange={onChange}
                                    value={value}
                                    style={{ marginTop: "16px" }}
                                >
                                    <Space direction="vertical">

                                        <Radio value="common"> Exercice à réponses communes </Radio>
                                        <Radio value="non-common"> Exercice à réponses non-communes </Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Form>
                    </Col>


                </Row>
            </Modal>

        </>
    );
}

export default ChoseTypeModal;