

import { Col, Form, Input, Modal, Row, Select } from "antd";
import { useState } from "react";
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
// import { fetchSelectCategories } from "../categories/categoriesSlice";
import { AddnewAdmin } from "../features/admins/adminsSlice";





const AddAdmin = ({ visible, setVisible }) => {

    const dispatch = useDispatch();
    const [formData, setFormData] = useState({ email: "", password: "", type: "" });

    const handleInputChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };
    const handelSelectType = (value) => {
        setFormData({ ...formData, type: value });
    };

    const handleFormSubmit = (event) => {
        console.log(formData);
        setVisible(false)
        dispatch(AddnewAdmin(formData));
    };


    return (
        < >
            <Modal title="Ajouter un administrateur"
                open={visible}
                okText="Confirmer"
                centered
                maskClosable={false}
                closable={false}
                onOk={handleFormSubmit}
                okButtonProps={{ disabled: (formData.name === "" || formData.password === "" || formData.type === "") }}
                onCancel={() =>
                    setVisible(false)}
                width={600}
                maskStyle={{
                    backgroundColor: "#10101088",
                    backdropFilter: "blur(0.7rem)",
                }}>
                <Row justify="center" style={{ marginTop: "40px" }}>


                    <Col span={12} >
                        <Form
                            layout="vertical"

                            style={{ display: "block", margin: "auto" }} >
                            <Form.Item
                                name="email"
                                label=" Adresse Mail"
                                rules={[
                                    { required: true, message: "Veuillez saisir votre adresse e-mail" },
                                ]}
                            >
                                <Input
                                    // style={{ width: 360 }}
                                    prefix={<UserOutlined style={{ color: "#39B13D" }} className="site-form-item-icon" />}
                                    name="email"
                                    type="email"
                                    placeholder="Email address"
                                    onChange={handleInputChange}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Mot de Passe "
                                name="password"
                                rules={[{ required: true, message: "Veuillez entrer votre mot de passe" }]}
                            >
                                <Input.Password
                                    prefix={<LockOutlined style={{ color: "#39B13D" }} className="site-form-item-icon" />}
                                    // style={{ width: 360 }}

                                    name="password"
                                    type="password"
                                    placeholder="Password"
                                    onChange={handleInputChange}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Type"

                                name="type"
                                rules={[{ required: true, message: "Veuillez choisir le type de l'admin" }]}
                            >
                                <Select
                                    options={[
                                        {
                                            value: 'super_admin',
                                            label: 'Super admin',
                                        },
                                        {
                                            value: 'admin',
                                            label: 'Normal admin',
                                        },

                                    ]}
                                    onSelect={handelSelectType}
                                />
                            </Form.Item>


                        </Form>
                    </Col>


                </Row>
            </Modal>

        </>
    );
}

export default AddAdmin;