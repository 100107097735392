import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import axios from 'axios';
import { addPackUrl } from '../../constants';
import { fetchPacks } from './packsListSlice';


export const AddPackSlice = createSlice({
    name: 'addPack',
    initialState: {

        fetchAddPackResult: null,
        fetchAddPackError: null,
        isFetchingAddPack: false,

    },
    reducers: {
        setFetchAddPackResult: (state, action) => {
            state.fetchAddPackResult = action.payload;
            state.isFetchingAddPack = false;
        },

        setFetchAddPackError: (state, action) => {
            state.fetchAddPackError = action.payload;
            state.isFetchingAddPack = false;
            state.fetchAddPackResult = null

        },

        setIsFetchingAddPack: (state, action) => {
            state.isFetchingAddPack = action.payload;
        },
    }
});

export const {


    setFetchAddPackResult,
    setFetchAddPackError,
    setConnected,
    setIsFetchingAddPack,

} = AddPackSlice.actions;

export const AddnewPack = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: addPackUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data

    };
    dispatch(setIsFetchingAddPack(true));
    const response = axios(config)
        .then(response => {
            dispatch(setFetchAddPackResult(response.data));
            message.success(response.data.message)
            dispatch(fetchPacks());
        })
        .catch(err => {
            if (err.response) {
                message.error(err.response.data.message)

                dispatch(setFetchAddPackError(err.response.data.error));
            }
            else {
            }
        });

    return response.data;

}




export default AddPackSlice.reducer;