import { createSlice } from "@reduxjs/toolkit";

import { createDefisUrl, getDefisUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";
export const DefisSlice = createSlice({
    name: "defis",
    initialState: {

        fetchDefisResult: null,
        fetchDefisError: null,
        isFetchingDefis: false,
    },
    reducers: {

        setFetchDefisResult: (state, action) => {
            state.fetchDefisResult = action.payload;
            state.isFetchingDefis = false;
        },

        setFetchDefisError: (state, action) => {
            state.fetchDefisError = action.payload;
            state.isFetchingDefis = false;
            state.fetchDefisResult = null

        },
        setIsFetchingDefis: (state, action) => {
            state.isFetchingDefis = action.payload;
        },
    },
});

export const {

    setFetchDefisResult,
    setFetchDefisError,
    setIsFetchingDefis,

} = DefisSlice.actions;


export const fetchDefis = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getDefisUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsFetchingDefis(true));
    const response = axios(config)
        .then(response => {
            dispatch(setFetchDefisResult(response.data));
        })
        .catch((error) => {
            console.log(error);
            // message.error(error.response.data.message)
            dispatch(setFetchDefisError(response.message));

        });

    return response.data;

}
export const AddnewDefis = (data) => async (dispatch, getState) => {
    const config = {
        method: "post",
        url: createDefisUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data,
    };
    console.log("add Defis ", data);

    const response = await axios(config)
        .then((response) => {
            message.success(response.data.message)
            dispatch(fetchDefis());
        })
        .catch((error) => {
            message.error(error.response.data.message)
        });
    console.log(response);
    return response;
};
export default DefisSlice.reducer;