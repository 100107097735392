import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import axios from 'axios';
import { addPromoCodeUrl, deletePromoCodeUrl } from '../../constants';
import { fetchPromoCodes } from './promoCodesListSlice';


export const AddPromoCodeSlice = createSlice({
    name: 'addPromoCode',
    initialState: {

        fetchAddPromoCodeResult: null,
        fetchAddPromoCodeError: null,
        isFetchingAddPromoCode: false,

        deletePromoCodeResult: null,
        deletePromoCodeError: null,
        isDeletingPromoCode: false,

    },
    reducers: {
        setFetchAddPromoCodeResult: (state, action) => {
            state.fetchAddPromoCodeResult = action.payload;
            state.isFetchingAddPromoCode = false;
        },

        setFetchAddPromoCodeError: (state, action) => {
            state.fetchAddPromoCodeError = action.payload;
            state.isFetchingAddPromoCode = false;
            state.fetchAddPromoCodeResult = null

        },

        setIsFetchingAddPromoCode: (state, action) => {
            state.isFetchingAddPromoCode = action.payload;
        },

        setDeletePromoCodeResult: (state, action) => {
            state.deletePromoCodeResult = action.payload;
            state.isDeletingPromoCode = false;
        },

        setDeletePromoCodeError: (state, action) => {
            state.deletePromoCodeError = action.payload;
            state.isDeletingPromoCode = false;
            state.deletePromoCodeResult = null

        },
        setIsDeletingPromoCode: (state, action) => {
            state.isDeletingPromoCode = action.payload;
        },
    }
});

export const {


    setFetchAddPromoCodeResult,
    setFetchAddPromoCodeError,
    setIsFetchingAddPromoCode,

    setDeletePromoCodeResult,
    setDeletePromoCodeError,
    setIsDeletingPromoCode,


} = AddPromoCodeSlice.actions;

export const AddnewPromoCode = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: addPromoCodeUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data

    };
    dispatch(setIsFetchingAddPromoCode(true));
    const response = axios(config)
        .then(response => {
            dispatch(setFetchAddPromoCodeResult(response.data));
            message.success(response.data.message)
            dispatch(fetchPromoCodes());
        })
        .catch(err => {
            if (err.response) {
                message.error(err.response.data.message)

                dispatch(setFetchAddPromoCodeError(err.response.data.error));
            }
            else {
            }
        });

    return response.data;

}
export const deletePromoCode = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: deletePromoCodeUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data

    };
    dispatch(setIsDeletingPromoCode(true));

    const response = axios(config)
        .then(response => {
            dispatch(setDeletePromoCodeResult(response.data));

            message.success(response.data.message)
            dispatch(fetchPromoCodes());
        })
        .catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                dispatch(setDeletePromoCodeError(err.response.data));

            }
            else {
                
            }
        });

    return response.data;

}




export default AddPromoCodeSlice.reducer;