import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Layout, Card, Menu, Typography, Image, Space, Switch } from 'antd';

import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { NavLink, useNavigate } from 'react-router-dom';
import { fetchItems, setSelectedMainCatItem, setSelectedsubCatItem } from './itemsSlice';
import PageHeader from '../../comps/pageHeader';
import SubCat from './subCatsComps';
import { setAddItemResult, setItemColorMaskUrl, setItemTextureUrl, setItemThumbnailUrl, setSpritesLinksALL } from './addItemSlice';
import { upload_path } from '../../constants';
import { IoMdNotificationsOutline } from "react-icons/io"

import './avatar.css'
import { setDeleteSingleItemResult, setFetchUpdateItemResult, UpdateItem } from './singleItemSlice';
// const { Text } = Typography;
const { Content } = Layout;
const mainCategories = [{ key: 'Cloth', title: 'Habillement' },
{ key: 'Face', title: 'Visage' }, { key: 'Weapon', title: 'Armes' }];
const subCategories = {
    Cloth: [
        { key: 'Armor', label: 'T-shirt' },
        { key: 'Boots', label: 'Chaussures' },
        { key: 'Gloves', label: 'Gants' },
        { key: 'Helmet', label: 'Casque' },
        { key: 'Cape', label: 'Cape' },
        { key: 'Skirt', label: 'Jupe' },
        { key: 'Pants', label: 'Pantalon' },
    ],
    Face: [
        { key: 'Hair', label: 'Cheveux' },
        { key: 'FacialHair', label: 'Poils' },
        { key: 'Eyebrow', label: 'Sourcil' },
        { key: 'Eyes', label: 'Yeux' },
        { key: 'Nose', label: 'Nez' },
        { key: 'Mouth', label: 'Bouche' },
        { key: 'Ear', label: 'Oreille' },
    ],
    Weapon: [
        { key: 'OneHanded', label: 'Arme' },
        { key: 'Shield', label: 'Bouclier' },

    ],
}
const { Text, Title } = Typography
const ItemsList = () => {

    let navigate = useNavigate();

    const pathname = useSelector((state) => state.dashboard.pathname);

    const dispatch = useDispatch();



    const fetchItemsResult = useSelector((state) => state.items.fetchItemsResult);

    const selectedMainCatItem = useSelector((state) => state.items.selectedMainCatItem);
    const selectedsubCatItem = useSelector((state) => state.items.selectedsubCatItem);

    const isUpdatingItem = useSelector((state) => state.singleItem.isUpdatingItem);
    const fetchUpdateItemResult = useSelector((state) => state.singleItem.fetchUpdateItemResult);

    const isFetchingItems = useSelector((state) => state.items.isFetchingItems);
    const handleMainCatClick = ({ key }) => {
        dispatch(setSelectedMainCatItem(key))
        dispatch(setSelectedsubCatItem(subCategories[key][0].key))
    };
    const handleSwitchStatus = ({ checked, id }) => {
        let status = checked ? "active" : "inactive"
        dispatch(UpdateItem({ id, status }));
    };

    // const [selectedMainCatItem, setSelectedMainCatItem] = useState(mainCategories[0].key);

    // const [selectedsubCatItem, setSelectedsubCatItem] = useState(subCategories[mainCategories[0].key][0].key);
    useEffect(() => {
        if (!selectedMainCatItem && !selectedsubCatItem) {
            dispatch(setSelectedMainCatItem(mainCategories[0].key))
            dispatch(setSelectedsubCatItem(subCategories[mainCategories[0].key][0].key))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (selectedsubCatItem) {
            dispatch(fetchItems({ category: selectedsubCatItem }));
            dispatch(setAddItemResult(null));
            dispatch(setFetchUpdateItemResult(null));

            dispatch(setItemThumbnailUrl(null));
            dispatch(setItemTextureUrl(null));
            dispatch(setSpritesLinksALL([]));
            dispatch(setItemColorMaskUrl(null));
            dispatch(setDeleteSingleItemResult(null));


        }



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedsubCatItem, fetchUpdateItemResult]);
    return (

        <Content
            className="site-layout-background"
            style={{
                padding: 0,
                margin: 0,
                minHeight: 280,
            }}
        >
            <PageHeader title={"Liste des items d’avatars"}

                breadcrumbItems={["Création de Contenu", "Avatar", "Liste des Items"]} />
            {selectedMainCatItem && selectedsubCatItem ?
                <>
                    <Row justify={"center"} style={{ backgroundColor: 'white' }} >
                        <Col span={23} >
                            <Menu mode="horizontal"
                                selectedKeys={selectedMainCatItem}
                                onClick={handleMainCatClick}
                            >
                                {mainCategories.map(item =>
                                    <Menu.Item key={item.key}>
                                        <NavLink >{item.title}</NavLink>
                                    </Menu.Item>
                                )}
                            </Menu>
                        </Col>
                    </Row>

                    <Row justify={"start"} style={{ margin: "24px", minHeight: "100px", backgroundColor: "white" }}>
                        <Col span={24}
                            style={{ display: "flex", alignItems: "center", margin: "24px" }}
                        >
                            <SubCat
                                tagsData={subCategories[selectedMainCatItem]}
                                 />
                        </Col>
                    </Row>
                </>
                : null}
            <Row justify={"start"} style={{ margin: "24px", minHeight: "100px" }} gutter={[24, 48]} >

                <Col className="gutter-row" span={5} style={{ display: 'flex', justifyContent: "center" }} >
                    <Card hoverable style={{ width: "176px", height: "176px", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}
                        onClick={() => {
                            navigate(pathname + "/add/" + selectedMainCatItem + "/" + selectedsubCatItem)
                        }}
                    >
                        <PlusOutlined style={{ fontSize: "24px" }} />
                    </Card>

                </Col>
                {isFetchingItems ?
                    <>
                        {Array.from({ length: 12 }, (_, i) => (

                            <Col className="gutter-row" span={4} style={{ display: 'flex', justifyContent: "center" }}  >
                                <Card

                                    style={{
                                        width: "200px", height: "200px",
                                    }}
                                    loading
                                    bodyStyle={{ padding: "0px" }}
                                    className="itemsList"

                                    actions={[<EditOutlined style={{ fontSize: "21px" }} />,
                                    <IoMdNotificationsOutline style={{ fontSize: "24px" }} />,
                                    ]}
                                />

                            </Col>

                        ))}
                    </>
                    :

                    fetchItemsResult?.data.map((item, index) => {
                        return (<>
                            <Col className="gutter-row" span={4} style={{ display: 'flex', justifyContent: "center" }}  >
                                <Card
                                    hoverable
                                    key={index}
                                    style={{
                                        width: "200px"
                                    }}
                                    bodyStyle={{ padding: "0px" }}
                                    className="itemsList"
                                    cover={

                                        <div className="container" style={{
                                            cursor: "default",
                                            borderColor: item.status === "active" ? "#14A44D" : "red",
                                            borderStyle: 'solid', borderWidth: "1px", borderRadius: "2px",
                                            height: "178px"
                                        }}>
                                            <img src={upload_path + item.thumbnail} alt={item.name} className="image"
                                                style={{ objectFit: "contain", width: "100%", height: "100%" }}
                                            />
                                            <div className="overlay">
                                                <Row >
                                                    <Col span={24} style={{ display: 'flex', justifyContent: "center", }}>
                                                        <Space direction='vertical' align='center' size={10}>

                                                            <Title level={5}>{item.name}</Title>
                                                            <Space >
                                                                <Text style={{ fontWeight: "bold" }}>{item.price}</Text>
                                                                <Image src={item.currency === "coins" ? "/Coin.svg" : "/Diamond.svg"} width={24} height={24} alt="coin" preview={false} />
                                                            </Space>
                                                            <Switch
                                                                loading={isUpdatingItem}
                                                                checked={item.status === "active"}
                                                                onChange={(checked) => handleSwitchStatus({ checked, id: item._id })} />
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    }
                                    actions={[<EditOutlined style={{ fontSize: "21px" }}
                                        onClick={() => {
                                            navigate(pathname + "/" + item._id)
                                        }}
                                    />,
                                    <IoMdNotificationsOutline style={{ fontSize: "24px" }}
                                        onClick={() => {
                                            navigate("/dashboard/params/notifications/push/" + item._id)
                                        }}

                                    />,
                                    ]}
                                />

                            </Col>
                        </>)
                    }

                    )
                }

            </Row>

        </Content>

    );
}

export default ItemsList;