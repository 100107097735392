import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";
import {  updateContractUrl } from "../../constants";
import { setfetchServerErr } from "../admins/adminsSlice";

export const CactusProSlice = createSlice({
    name: "singleContract",
    initialState: {

        // Update Contract
        isUpdatingContract: false,
        updateContractSuccess: null,
        updateContractFailure: null,
   
    },
    reducers: {

        // Update client
        setUpdateContractSuccess: (state, action) => {
            state.updateContractSuccess = action.payload;
            state.updateContractFailure = null;
            state.isUpdatingContract = false;
        },
        setUpdateContractFailure: (state, action) => {
            state.updateContractFailure = action.payload;
            state.updateContractSuccess = null;
            state.isUpdatingContract = false;
        },
        setUpdateContractStarted: (state, action) => {
            state.isUpdatingContract = true;
        },


    }
});

export const {

    // Update Contract
    setUpdateContractSuccess,
    setUpdateContractFailure,
    setUpdateContractStarted,



} = CactusProSlice.actions;




export const UpdateContract = (data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: updateContractUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    console.log("DATA creation :", data);
    dispatch(setUpdateContractStarted());

    axios(config)
        .then((response) => {
            dispatch(setUpdateContractSuccess(response.data));
        })
        .catch((error) => {
            if (!error.response) {
                dispatch(setfetchServerErr(true));
                dispatch(setUpdateContractFailure("Le serveur ne répond pas, veuillez réessayer plus tard."))
                return;
            }
            if (error.response.status === 401) {
                message.error("Vous êtes déconnecté, veuillez vous reconnecter.");
                dispatch(setUpdateContractFailure("Vous n'êtes pas autorisé à faire cette action."));
                return;
            }
            message.error(error.response.data.message);
            dispatch(setUpdateContractFailure(error.response.data.message));
        });
}


export default CactusProSlice.reducer;
