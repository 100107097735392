import { Input, Col, Row, Button, Form, Checkbox, message } from "antd";
import { useEffect, useState } from "react";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { ComponentStates } from '../Activation';
import SuccessActivation from "../../../comps/successActivation";

export default function SignIn({ setComponentState }) {
    const [form] = Form.useForm();
    const signInUrl = "https://cactusfrance.com/api/app/authentication/signIn";
    const activationUrl = "https://cactusfrance.com/api/app/module/autoActivationCode";
    const [token, setToken] = useState(null);

    const [messageApi, contextHolder] = message.useMessage();

    const [signInSuccess, setSignInSuccess] = useState(false);
    const [signInError, setSignInError] = useState(false);
    const [modulesError, setModulesError] = useState(false);
    const [visible, setVisible] = useState(false)


    useEffect(() => {
        if (token && signInSuccess) {
            fetch(activationUrl, {
                method: "POST",
                mode: "cors",
                referrerPolicy: "unsafe-url",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": token,
                    "device": "Web"
                },
                body: JSON.stringify({ link: localStorage.getItem("ModulesLink") })
            }).then(async (response) => {
                if (response.status !== 200) {
                    setModulesError((await response.json()).message);
                } else {
                    setVisible(true)
                    // setModulesSuccess(true);
                    // setModulesSuccess(true);
                }
                // let data = await response.json();

            }).catch((response) => {
                console.error("Issue here too")
                setModulesError("Veuillez vérifier votre connexion.")
            })
        }
    }, [token]);

    useEffect(() => {
        if (!signInError) {
            return;
        }
        messageApi.error(signInError);
        setSignInError(false);

    }, [signInError])

    useEffect(() => {
        if (!modulesError) {
            return;
        }
        messageApi.error(modulesError);
        setModulesError(false);

    }, [modulesError])

    const onPressSignin = () => {
        let email = form.getFieldValue("email");
        let password = form.getFieldValue("password");
        let checkbox = form.getFieldValue("check");
        if (!email || !password) {
            return;
        }
        if (!checkbox) {
            checkbox = false;
        }
        let body = {
            email,
            password,
            rememberMe: checkbox
        }
        fetch(signInUrl, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "device": "Web"
            },
            body: JSON.stringify(body)
        }).then(async (response) => {
            if (response.status !== 200) {
                setSignInError((await response.json()).message);
                // console.log((await response.json()).message);
                return;
            }
            let data = await response.json();
            setToken(data.token);

            setSignInSuccess(true);
        }).catch((response) => {
            setSignInError("Veuillez vérifier votre connexion.");
        })
    }
    return (
        <Col style={{
            width: "30vw"
        }}>
            <SuccessActivation visible={visible} />
            {contextHolder}
            <Row style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
            }}>
                <Button onClick={() => { setComponentState(ComponentStates.ChoiceScreen) }} style={{ margin: 0, border: "none" }} icon={<ArrowLeftOutlined />} />
            </Row>
            <Row style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                marginBottom: "5vh",
                marginTop: "2vh"
            }}>
                <h1 style={{ color: "black", lineHeight: "normal", margin: 0, fontSize: "32px" }}>Bonjour 👋</h1>
            </Row>
            <Row style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
            }}>
                <Form
                    layout="vertical"
                    form={form}
                >
                    <Form.Item name="email" label="E-mail">
                        <Input rootClassName="inputField" bordered={false} placeholder="johndoe@gmail.com"></Input>
                    </Form.Item>
                    <Form.Item name="password" label="Mot de passe" style={{ marginTop: "5vh" }}>
                        <Input.Password
                            rootClassName="inputField"
                            bordered={false}
                            rules={[{
                                required: true,
                                message: 'Veuillez insérer votre mot de passe!'
                            }]}
                        ></Input.Password>
                    </Form.Item>
                    <Form.Item name="check" valuePropName="checked" id="rememberMe" style={{ width: "fit-content" }}>
                        <Checkbox>Se souvenir de moi</Checkbox>
                    </Form.Item>
                    <Form.Item >
                        <Button
                            className="customButton"
                            style={{
                                color: "#fff",
                                backgroundColor: "#39B13D",
                                borderRadius: "100px",
                                width: "100%",
                                marginLeft: 0,
                                marginRight: 0,
                                marginTop: "10vh"
                            }}
                            htmlType="submit"
                            onClick={onPressSignin}

                        >S'IDENTIFIER</Button>
                    </Form.Item>
                </Form>
            </Row>
        </Col>
    )
}