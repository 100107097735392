import { Button, Image, Input } from "antd";
import { useEffect, useState } from "react";

const DialogComponent = () => {
    return (
        <div style={{ display: "flex" }}>
            <Image preview={false} width={"150px"} src='activation/HappyLilly.png' />
            <div className='horizontalDialog'>
                <Image preview={false} src='activation/BigDialogBubble.svg' />
                <p
                    style={{
                        position: "absolute",
                        fontWeight: 600,
                        marginLeft: "16px",
                        width: "160px",
                        lineHeight: "normal",
                        textAlign: "left",
                        fontSize: "14px",
                        color: "#4B4B4B",
                    }}>
                    Maîtrisez l’orthographe rapidement grâce à notre système d’abonnement
                </p>
            </div>
        </div>
    )

}

const ActivationScreen = ({ setComponentState }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    // TODO: change back to prod
    const activationUrl = "https://cactusfrance.com/api/app/module/useActivationCode";
    const token = localStorage.getItem("insertUserToken");
    const [data, setData] = useState({
        code: "",
    })

    const launchActivate = () => {
        setIsLoading(true);

        fetch(activationUrl, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization":token,
                "device": "Web"
            },
            body: JSON.stringify(data)
        }).then(async (response) => {
            if (response.status !== 200) {
                setError((await response.json()).message);
                setIsLoading(false);
                return;
            }

            setIsLoading(false);
            setSuccess(true);
        }).catch((response) => {
            setError("Veuillez vérifier votre connexion.");
            setIsLoading(false);
        })
    }

    useEffect(() => {
        if (!success) return;
        window.location.href = "https://app.cactusfrance.com";
    }, [success])

    return (
        <div style={{ width: "100%", height: "90vh", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", gap: "64px", alignItems: "center", padding: "0vh 10vw" }}>
                <Image
                    src="/activation/Logo.png"
                    preview={false}
                    width={"40vw"}
                />
                <div style={{ flexDirection: "row", width: "100%" }}>

                    <DialogComponent />

                </div>
                <div style={{ justifyContent: "flex-start", color: "#212121" }}>
                    <h1 title="Code d’activation 🔑" style={{ width: "fit-content", marginBottom: "8px", fontSize: "22px", color: "#212121" }}>Code d’activation 🔑</h1>
                    <p style={{ fontSize: "18px", marginTop: "8px", textAlign: "start", width: "fit-content", color: "#212121" }}>Insère le code d’activation que tu as reçu par e-mail</p>

                </div>

                <div style={{ justifyContent: "flex-start", marginTop: "-64px", width: "100%", color: "#212121" }}>
                    <p style={{ fontSize: "16px", marginTop: "8px", textAlign: "start", width: "fit-content", color: "#212121" }}>Code d'activation</p>
                    <Input rootClassName="inputFieldMobile" value={data.code} placeholder="XXXX-XXXX-XXXX-XXXX" onChange={(e) => setData({ code: e.target.value })}></Input>
                    <p style={{ fontSize: "16px", marginTop: "8px", textAlign: "start", width: "fit-content", color: "#FF4E4E" }}> {error}</p>

                </div>

            </div>
            <div>
                <Button
                    loading={isLoading}
                    className="customButton"
                    style={{
                        color: "#FFF",
                        backgroundColor: "#39B13D",
                        borderRadius: "100px",
                        width: "90%",
                        marginBottom: "32px"
                    }}
                    onClick={launchActivate}
                > ACTIVER </Button>
            </div>
        </div>
    )
}

export default ActivationScreen;