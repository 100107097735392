

import React, { useState, useEffect } from 'react';
import { Column } from '@ant-design/plots';
import { Card, Col, DatePicker, Radio, Row, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { fetchTransactionsReportData } from './reportFinanceSlice';

const { RangePicker } = DatePicker
const TransactionsReport = () => {
    const dispatch = useDispatch();
    const fetchTransactionsReportDataResult = useSelector((state) => state.transactionsReportData.fetchTransactionsReportDataResult);
    const isFetchingTransactionsReportData = useSelector((state) => state.transactionsReportData.isFetchingTransactionsReportData);

    const [statfilter, setStatfilter] = useState({
        filter: "days",
        startDate: null,
        endDate: null,
    });

    useEffect(() => {

        dispatch(fetchTransactionsReportData({ ...statfilter }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statfilter]);



    const config = {
        data: fetchTransactionsReportDataResult?.stats,
        xField: 'period',
        yField: 'value',
        seriesField: 'type',
        isPercent: true,
        isStack: true,
        xAxis: {
            label: {
                autoRotate: true,
                autoHide: false,
                autoEllipsis: false,
            },
        },

        // set a series of colors
        color: ({ type }) => {
            switch (type) {
                case 'diamonds':
                    return "#1A96F0"
                case 'hearts':
                    return "#F54336"
                case 'item':
                    return "#f6c022"
                case 'module':
                    return "#42D83F"


                default:
                    break;
            }


        },

        label: {
            position: 'bottom',

            // content: (item) => {
            //     return item.value;
            // },
            // formatter: (item) => {
            //     return `Type ${item.type}: ${item.value.toFixed(2)}`;
            // },
            style: {
                fill: '#fff',
                margin:"24px"
            },
            content: (originData) => {

                if (originData.value === 0) {
                    return null
                }
                return (originData.value * 100).toFixed(0) + '%';

            },
            offset: 10,
        },
        tooltip: false,
        interactions: [
            {
                type: 'element-highlight-by-color',
            },
            {
                type: 'element-link',
            },
        ],
    };

    // const config = {
    //     data: fetchTransactionsReportDataResult?.stats,
    //     isStack: true,
    //     xField: 'period',
    //     yField: 'value',
    //     seriesField: 'type',
    //     color: ({ type }) => {
    //         switch (type) {
    //             case 'diamonds':
    //                 return "#1A96F0"
    //             case 'hearts':
    //                 return "#F54336"
    //             case 'item':
    //                 return "#f6c022"
    //             case 'module':
    //                 return "#42D83F"


    //             default:
    //                 break;
    //         }


    //     },
    //     label: {
    //         position: 'middle', // 'top', 'bottom', 'middle'
    //         content: (originData) => {
    //             if (originData.value === 0) {
    //                 return null
    //             }

    //             return originData.value + '%'

    //         },
    //     },
    //     interactions: [
    //         {
    //             type: 'active-region',
    //             enable: false,
    //         },
    //     ],
    //     connectedArea: {
    //         style: (oldStyle, element) => {
    //             return {
    //                 fill: 'rgba(0,0,0,0.1)',
    //                 stroke: oldStyle.fill,
    //                 lineWidth: 0.5,
    //             };
    //         },
    //     },

    // };

    const handleChangeFilter = (e) => {
        setStatfilter({ filter: e.target.value })
    };
    return (

        <Card title="Rapport des Distributions des Transactions" style={{ backgroundColor: "white", cursor: "default", minHeight: "500px" }}
            hoverable
            extra={

                <Space >
                    <Radio.Group
                        size='small'
                        value={statfilter.filter}
                        onChange={handleChangeFilter}
                        disabled={isFetchingTransactionsReportData || statfilter.startDate}
                    >
                        <Space>
                            <Radio.Button style={{ border: "0" }} value={"days"}>Par jours</Radio.Button>
                            <Radio.Button style={{ border: "0" }} value={"week"}>Par semaines</Radio.Button>
                            <Radio.Button style={{ border: "0" }} value={"month"}>Par mois</Radio.Button>
                        </Space>
                    </Radio.Group>
                    <RangePicker
                        disabled={isFetchingTransactionsReportData}
                        onChange={(dates, datesString) => {

                            setStatfilter({
                                ...statfilter, startDate: datesString[0] === "" ? null : moment(datesString[0]).format(),
                                endDate: datesString[1] === "" ? null : moment(datesString[1]).format(),
                                filter: datesString[0] === "" ? "days" : "rangeDate"
                            })
                        }}
                        size='small' />

                </Space>}

        >
            <Row justify={"space-between"}>
                <Col span={24}>

                    {fetchTransactionsReportDataResult ?
                        <Column {...config} />
                        : null}
                </Col>

            </Row>
        </Card>
    );
}

export default TransactionsReport;