import { createSlice } from "@reduxjs/toolkit";

import { getTransactionsReportUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";

export const TransactionsReportSlice = createSlice({
    name: "transactionsReportData",
    initialState: {
        fetchTransactionsReportDataResult: null,
        fetchTransactionsReportDataError: null,
        isFetchingTransactionsReportData: false,
    },
    reducers: {


        setTransactionsReportDataResult: (state, action) => {
            state.fetchTransactionsReportDataResult = action.payload;
            state.fetchTransactionsReportDataError = null;

            state.isFetchingTransactionsReportData = false;
        },

        setTransactionsReportDataError: (state, action) => {
            state.fetchTransactionsReportDataError = action.payload;
            state.isFetchingTransactionsReportData = false;
            state.fetchTransactionsReportDataResult = null

        },
        setIsFetchingTransactionsReportData: (state, action) => {
            state.isFetchingTransactionsReportData = action.payload;
        },
    },
});

export const {


    setTransactionsReportDataResult,
    setIsFetchingTransactionsReportData,
    setTransactionsReportDataError,

} = TransactionsReportSlice.actions;


export const fetchTransactionsReportData = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getTransactionsReportUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data: data
    };
    dispatch(setIsFetchingTransactionsReportData(true));
    const response = axios(config)
        .then(response => {
            dispatch(setTransactionsReportDataResult(response.data));
        })
        .catch((error) => {
            message.error(error.response.data.message)
            dispatch(setTransactionsReportDataError(error.response.data.message));

        });

    return response.data;

}



export default TransactionsReportSlice.reducer;