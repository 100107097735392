import React, { useState, useEffect } from "react";
import { Button, Col, Image, Layout, Row, Space } from 'antd';
import "../activation/css/Activation.css";
import { Helmet } from "react-helmet";
import Dialog from "../activation/components/Dialog";
import { useSearchParams } from "react-router-dom";
const { Header, Footer, Content } = Layout;

const headerStyle = {
    textAlign: 'center',
    color: '#fff',
    height: '10vh',
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: '#fff',
}

const contentStyle = {
    textAlign: 'center',
    minHeight: '90vh',
    lineHeight: '120px',
    display: "flex",
    justifyContent: "center",
    color: '#fff',
    backgroundColor: '#fff',
}

export default function ConfirmPurchaseFree({ props }) {

    return (
        <Space direction="vertical" style={{ width: '100%', height: "100%" }}>
            <Layout>
                <Header style={headerStyle}></Header>
                <Helmet>
                    <title>Confirmation d'achat</title>
                </Helmet>
                <Content className="activationBody" style={contentStyle}>
                    <Col style={{
                        width: "30vw",
                        position: "absolute",
                        top: "3vh"
                    }}>
                        <Row style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "8vh"
                        }}>
                            <Dialog text="Achat confirmé !" />
                        </Row>
                        <Row style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                            <Image
                                src="/activation/Lilly.png"
                                preview={false} />
                        </Row>
                        <Row style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "2vh"
                        }}>
                            <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "20px", color: "#5CB949" }}>{"Ton achat est confirmé, tu retrouveras tes achats dans l'application !"}</p>
                            <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "20px", color: "#5CB949" }}>{"Tu peux fermer cet onglet et revenir sur l'application"}</p>
                            
                        </Row>
                    </Col>
                </Content>
            </Layout>
        </Space>
    )
}