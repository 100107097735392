


import { Col, Form, Input, Modal, Radio, Row, Descriptions, Space, InputNumber } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { AddnewDefis } from "./defisListSlice";


const AddDefis = ({ visible, setVisible }) => {

    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        question: ["", "*", ""],
        errorPropositions: [],
        correct: "",
        cactus: true,
        difficulty: 1,
        theme: null,
        status: "active"
    });

    const fetchThemesResult = useSelector((state) => state.dashboard.fetchThemesResult);
    const fetchModulesResult = useSelector((state) => state.module.fetchModulesResult);


    const handleInputChange1 = (event) => {
        let qst = [...formData.question]
        qst[0] = (event.target.value)
        setFormData({ ...formData, question: qst });
    };
    const handleInputNonCactusChange = (event) => {
        let qst = [...formData.question]
        qst[0] = (event.target.value)
        setFormData({ ...formData, question: qst });
    };

    const handleInputChange2 = (event) => {
        let qst = [...formData.question]
        qst[2] = (event.target.value)
        setFormData({ ...formData, question: qst });
    };
    const handleInputChangeError = (event) => {
        setFormData({ ...formData, errorPropositions: [(event.target.value)] });
    };
    const handleInputChangeCorrect = (event) => {
        setFormData({ ...formData, correct: (event.target.value) });
    };
    const handleChangeCactus = (event) => {
        if (event.target.value === false) {

            setFormData({ ...formData, cactus: event.target.value, question: [""], errorPropositions: [], correct: "" })
        } else {
            setFormData({ ...formData, cactus: event.target.value, question: ["", "*", ""] })

        }

    };


    const handleFormSubmit = (event) => {
        let data = { ...formData }
        const myWords = data.question.map(str => str.split(" ")).flat();
        const filteredArray = myWords.filter((str) => str !== "");

        data.question = filteredArray
        if (data.cactus === true) {
            data.correct = data.correct.trim()
            data.errorPropositions[0] = data.errorPropositions[0].trim()
        }
        // console.log('data ', data);
        setVisible(false)
        dispatch(AddnewDefis(data));
        setFormData({
            question: ["", "*", ""],
            errorPropositions: [],
            correct: "",
            cactus: true,
            difficulty: 1,
            theme: null,
            status: "active"
        })
    };
    const [RadioGrpTheme, setRadioGrpTheme] = useState(null);

    useEffect(() => {
        if (fetchThemesResult) {
            let options = []
            // eslint-disable-next-line array-callback-return
            fetchThemesResult.data.map((theme) => {
                options.push({ label: theme.displayName, value: theme._id })
            })
            setRadioGrpTheme(options)
            console.log('options ', options);
            console.log('options 2  ', fetchThemesResult.data[0]._id);
            setFormData({ ...formData, theme: fetchThemesResult.data[0]._id })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchThemesResult]);

    const handleCheckTheme = (checkedValues) => {
        console.log('options 3  ', formData.theme);

        setFormData({ ...formData, theme: checkedValues.target.value })
    };
    return (
        < >
            <Modal title="Ajouter une question"
                open={visible}
                okText="Confirmer"
                centered
                maskClosable={false}
                closable={false}
                onOk={handleFormSubmit}
                okButtonProps={{
                    disabled: ((formData.cactus && formData.question[0] === "" && formData.question[2] === "") || (formData.cactus === false && formData.question[0] === "") || formData.theme === null)
                }}
                onCancel={() =>
                    setVisible(false)}
                width={1200}
                maskStyle={{
                    backgroundColor: "#10101088",
                    backdropFilter: "blur(0.7rem)",
                }}>
                <Row justify="center" style={{ marginTop: "40px" }}>


                    <Col span={21} >
                        <Form
                            // layout="vertical"

                            style={{ display: "block", margin: "auto" }} >
                            <Form.Item
                                name="cactus"
                                label="Sélectionner si la phrase est juste ou non :">
                                <Radio.Group defaultValue={true} value={formData.cactus} onChange={handleChangeCactus}
                                >
                                    <Radio value={true}> Cactus (Il y a une faute) </Radio>
                                    <Radio value={false}> Non-Cactus (La phrase est correcte) </Radio>
                                </Radio.Group>
                            </Form.Item>
                            {formData.cactus === true ?
                                <Form.Item
                                    name="question"
                                >

                                    <Row justify="space-between" >
                                        <Col span={9} >
                                            <Input
                                                value={formData.question[0] ? formData.question[0] : ""}
                                                name="input1"
                                                onChange={handleInputChange1}
                                            />

                                        </Col>
                                        <Col span={6} >
                                            <Space
                                                direction="vertical">

                                                <Descriptions  >
                                                    <Descriptions.Item label="erreur "

                                                        labelStyle={{ alignItems: "center", width: "64px" }}
                                                    > <Input
                                                            value={formData.errorPropositions[0] ? formData.errorPropositions[0] : ""}
                                                            status="error"
                                                            name="error"
                                                            onChange={handleInputChangeError}
                                                        /></Descriptions.Item>
                                                </Descriptions>
                                                <Descriptions >
                                                    <Descriptions.Item label="réponse "
                                                        labelStyle={{ alignItems: "center" }}
                                                    > <Input value={formData.correct} name="error" onChange={handleInputChangeCorrect} />
                                                    </Descriptions.Item>
                                                </Descriptions>
                                            </Space>
                                        </Col>
                                        <Col span={8} >
                                            <Input
                                                value={formData.question[2] ? formData.question[2] : ""}
                                                name="input2"
                                                onChange={handleInputChange2}

                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                                :
                                <Form.Item
                                    name={"question2"}>
                                    <Input
                                        value={formData.question[0] ? formData.question[0] : ""}
                                        name="input"
                                        onChange={handleInputNonCactusChange}
                                    />
                                </Form.Item>
                            }

                            <Form.Item
                                label="Entrez le niveau de difficulté suivant le module correspondant "
                                name="difficulty"
                            >
                                <InputNumber
                                    min={1}
                                    max={fetchModulesResult?.total}
                                    name="difficulty"
                                    defaultValue={1}
                                    onChange={(value) => {
                                        setFormData({ ...formData, difficulty: value })
                                    }}
                                    value={formData?.difficulty}
                                />

                            </Form.Item>
                            <Form.Item
                                label="Sélectionner le thème :"
                                name="theme"
                            >
                                {formData.theme ?

                                    <Radio.Group
                                        defaultValue={fetchThemesResult.data[0]._id}
                                        value={formData.theme}
                                        options={RadioGrpTheme ? RadioGrpTheme : null} onChange={handleCheckTheme} />
                                    : null}

                            </Form.Item>


                        </Form>
                    </Col>


                </Row>
            </Modal>

        </>
    );
}

export default AddDefis;