import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Space, Row, Button, Col, Layout, Card, Divider, Form, Switch, InputNumber } from 'antd';

import { editlModule, fetchSinglModule } from './moduleSlice';
import { useParams } from "react-router-dom";


import PageHeader from '../../comps/pageHeader';
import { fetchLessons } from '../lessons/lessonsSlice';
import TotalLecons from '../lessons/numLessonsComps';
import Lessons from '../lessons/lessons';

const { Content } = Layout;


const Module = () => {

    const { useEffect } = React;
    const dispatch = useDispatch();
    const { seq } = useParams();

    const [formData, setFormData] = useState({ id: null, name: "", trial: false, price: 0, sale: false, saleRate: 0 });


    const fetchModuleResult = useSelector((state) => state.module.fetchModuleResult);
    const isfetchingModule = useSelector((state) => state.module.isfetchingModule);

    const fetchLessonsResult = useSelector((state) => state.lessons.fetchLessonsResult);
    const isFetchingLessons = useSelector((state) => state.lessons.isFetchingLessons);
    useEffect(() => {
        dispatch(fetchSinglModule({ seq: seq }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seq]);
    useEffect(() => {
        if (fetchModuleResult) {
            setFormData({
                id: fetchModuleResult.module._id,
                name: fetchModuleResult.module.name,
                trial: fetchModuleResult.module.trial,
                price: fetchModuleResult.module.price,
                sale: fetchModuleResult.module.sale,
                saleRate: fetchModuleResult.module.saleRate * 100,
            })
            dispatch(fetchLessons({ module: fetchModuleResult.module._id }));
        }

        // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
    }, [fetchModuleResult]);

    const handelUpdateInfo = async () => {

        dispatch(editlModule({ ...formData, saleRate: formData.saleRate / 100 })).then((res) => {
            dispatch(fetchSinglModule({ seq: seq }));


        });

    };
    return (

        <Content
            className="site-layout-background"
            style={{
                padding: 0,
                margin: 0,
                minHeight: 280,
            }}
        >
            <>

                <PageHeader title={"Aperçu et Paramètre du Module " + seq}
                    breadcrumbItems={["Création de Contenu", "Modules", "Module " + seq]} />

                <Row justify={"space-around"}>
                    <Col style={{ minHeight: "100vh", display: 'flex', justifyContent: 'center', paddingTop: "24px" }} span={7}  >
                        <Space direction="vertical" size="middle" style={{ display: 'flex', width: "80%" }}>
                            {fetchModuleResult ?
                                <Card title={"Module " + seq + " - " + fetchModuleResult.module.name} bordered={false}
                                    style={{ minHeight: '350px', width: "100%", }}
                                    actions={[
                                        <Button style={{ width: "90%" }}
                                            onClick={handelUpdateInfo}
                                        >Modifier</Button>
                                    ]}
                                >
                                    <Form layout="horizontal"  >

                                        <Form.Item label="Gratuit ou en essai :" labelAlign="left" labelCol={{ span: 20 }}
                                            style={{ marginBottom: '18px' }}
                                        >
                                            <Switch checked={formData.trial}
                                                onChange={(checked) => {
                                                    setFormData({
                                                        ...formData,
                                                        trial: checked,
                                                    });
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item label="Prix :" labelAlign="left">
                                            <InputNumber
                                                disabled={formData.trial}
                                                step={0.01}
                                                min={0}
                                                value={formData.trial ? 0 : formData.price}
                                                formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/€\s?|(,*)/g, '')}
                                                onChange={(value) => {
                                                    setFormData({
                                                        ...formData,
                                                        price: value,
                                                    });
                                                }}
                                            />
                                        </Form.Item>
                                        <Divider />

                                        <Form.Item label="Soldé :" labelAlign="left" labelCol={{ span: 20 }}
                                            style={{ marginBottom: '18px' }}
                                        >
                                            <Switch checked={formData.sale}
                                                onChange={(checked) => {
                                                    setFormData({
                                                        ...formData,
                                                        sale: checked,
                                                    });
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item label="Réduction de :" labelAlign="left">
                                            <InputNumber
                                                disabled={!formData.sale}
                                                step={1}
                                                value={!formData.sale ? 0 : formData.saleRate}
                                                formatter={(value) => `% ${value}`}
                                                parser={(value) => value.replace('%', '')}
                                                onChange={(value) => {
                                                    setFormData({
                                                        ...formData,
                                                        saleRate: value,
                                                    });
                                                }}
                                            />
                                        </Form.Item>
                                    </Form>
                                </Card>

                                : isfetchingModule ?
                                    <Card loading bordered={false}
                                        style={{ height: '350px', width: 278, }}
                                    >

                                    </Card> : null}

                            <Card bordered={false}
                                loading={isFetchingLessons}
                                style={{ height: '260px', width: "100%", }}
                            >
                                <TotalLecons total={fetchLessonsResult ? fetchLessonsResult.total : 0} />
                            </Card>
                        </Space>
                    </Col>
               
                    <Col style={{ minHeight: "100vh", }} span={17}>
                        <Lessons />
                    </Col>
                </Row>

            </>

        </Content>

    );
}

export default Module;