

import { Col, Layout, Row } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../comps/pageHeader";

import { TransactionOutlined, BookFilled,GiftFilled } from '@ant-design/icons';
import { fetchTransactionsCount } from "./financeSlice";
import { IoDiamondSharp } from "react-icons/io5"
import CounterComp from "../statistics/counterComp";
import RevenueChart from "./revenueChart";
import TransactionsReport from "./reportFinance";


const { Content } = Layout;

const Finance = () => {
    const fetchTransactionsCountResult = useSelector((state) => state.transactionscount.fetchTransactionsCountResult);
    const isFetchingtransactionsCount = useSelector((state) => state.transactionscount.isFetchingtransactionsCount);



    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchTransactionsCount({}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Content
            className="site-layout-background"
            style={{
                padding: 0,
                margin: 0,
                minHeight: 280,
            }}
        >
            <PageHeader title={"Analytiques des Finances de Cactus"}
                breadcrumbItems={["Finances Cactus", "Analytiques"]} />
            <Row gutter={[24, 0]} justify={"space-between"} style={{ padding: "24px" }}  >
                <Col span={6}>
                    <CounterComp value={fetchTransactionsCountResult?.stats.totalTransactions} icon={<TransactionOutlined />} label={"Transactions aujourd’hui"}
                        title={"Nombre Total des Transactions"} todaysValue={fetchTransactionsCountResult?.stats.transactionsToday} loading={isFetchingtransactionsCount} />
                </Col>
                <Col span={6}>
                    <CounterComp value={fetchTransactionsCountResult?.stats.itemTransactions} icon={<GiftFilled style={{color:'#FF7A45'}}/>} label={"Items vendus aujourd’hui"}
                        title={"Total des Items Vendu"} todaysValue={fetchTransactionsCountResult?.stats.itemTransactionsToday} loading={isFetchingtransactionsCount} />
                </Col>

                <Col span={6}>

                    <CounterComp value={fetchTransactionsCountResult?.stats.diamondsTransactions} icon={<IoDiamondSharp color="#40A9FF" />} label={"Packs vendus aujourd’hui"}
                        title={"Total des Packs de Diamonds Vendus"}  todaysValue={fetchTransactionsCountResult?.stats.diamondsTransactionsToday} loading={isFetchingtransactionsCount} />
                </Col>

                <Col span={6}>

                    <CounterComp value={fetchTransactionsCountResult?.stats.moduleTransactions} icon={<BookFilled style={{ color: "#39B13D" }} />} label={"Packs vendus aujourd’hui"}
                        title={"Total des Packs de Modules Vendus"} todaysValue={fetchTransactionsCountResult?.stats.moduleTransactionsToday} loading={isFetchingtransactionsCount} />
                </Col>

            </Row>
            <Row style={{ padding: "24px" }}>
                <Col span={24}>
                    <RevenueChart />
                </Col>
            </Row>
            <Row style={{ padding: "24px" }}>
                <Col span={24}>
                    <TransactionsReport />
                </Col>
            </Row>
            {/*
            <Row style={{ padding: "24px" }}>
                <Col span={24}>
                    <ModuleProgress />
                </Col>
            </Row>
            <Row style={{ padding: "24px" }}>
                <Col span={24}>
                    <DefisChartChart />
                </Col>
            </Row>
            <Row style={{ padding: "24px" }}>
                <Col span={24}>
                    <Onboardingdata />
                </Col>
            </Row> */}


        </Content>
    );
}

export default Finance;