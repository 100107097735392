

import { Card, Checkbox, Col, Input, Row, Select, Typography } from "antd";
import { useEffect, useState } from "react";
import { SyncOutlined } from '@ant-design/icons';





const ExerciceQuestions = ({ exerciceForm, defisCheck, questionsForm, setQuestionsForm, setQuestionType, questionType, setQuestionTypeRadio, errorIndexInput, errorIndexSelect }) => {


    const [propositions, setPropositions] = useState([]);
    useEffect(() => {
        if (exerciceForm) {
            let props = []

            // eslint-disable-next-line array-callback-return
            exerciceForm.propositions.map(item => {
                let selectPropos = { label: item, value: item }
                props.push(selectPropos)
            })
            setPropositions(props);


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exerciceForm]);
    useEffect(() => {
        if (questionsForm) {

            let changeDefis = [...questionsForm.questions]
            for (let i = 0; i < 12; i++) {
                changeDefis[i] = { defis: defisCheck, content: [''] }

            }
            // let updatedArray = changeDefis.map(item => {
            //     return { ...item, defis: defisCheck };
            // });
            setQuestionsForm({
                ...questionsForm, questions: changeDefis
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defisCheck]);
    const { Text } = Typography


    return (
        <div style={{ marginTop: "16px" }}>
            {Array.from({ length: 12 }, (_, i) => (
                <Card style={{ backgroundColor: (i % 2 === 0) ? "whitesmoke" : "white", cursor: 'default' }} hoverable>

                    <Row justify="start">

                        <Col span={1}>
                            <Text style={{ fontWeight: "bold", fontSize: "15px" }}>{(i + 1)}.  </Text>

                        </Col>
                        <Col span={23} style={{}}>
                            <Row
                                justify={"space-between"}
                            >
                                <Col span={18}>
                                    <Input
                                        value={questionsForm.questions[i]?.content[0]}
                                        status={errorIndexInput.includes(i + 1) ? "error" : null}
                                        key={i}
                                        style={{
                                            width: "100%",
                                        }}
                                        onChange={(e) => {
                                            let questionDefis = [...questionsForm.questions]
                                            questionDefis[i].content[0] = e.target.value
                                            setQuestionsForm({ ...questionsForm, questions: questionDefis })
                                            setQuestionTypeRadio(false)
                                        }}

                                    />
                                </Col>
                                <Col span={4}>



                                    <Select
                                        options={propositions}
                                        status={errorIndexSelect.includes(i + 1) ? "error" : null}
                                        value={questionsForm.questions[i]?.correction}
                                        onChange={(value) => {
                                            let questionDefis = [...questionsForm.questions]
                                            questionDefis[i].correction = value
                                            setQuestionsForm({ ...questionsForm, questions: questionDefis })
                                        }}
                                        style={{
                                            width: "100%",
                                        }}
                                    />

                                </Col>
                                <Col span={1} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    {questionsForm.questions.length > 0 ?
                                        <Checkbox
                                            disabled={!defisCheck}
                                            onChange={(e) => {
                                                let questionDefis = [...questionsForm.questions]
                                                questionDefis[i].defis = e.target.checked
                                                setQuestionsForm({ ...questionsForm, questions: questionDefis })
                                            }}
                                            checked={questionsForm.questions[i].defis}
                                        /> :
                                        <SyncOutlined spin />

                                    }

                                </Col>




                            </Row>
                            {/* <Text>{questionsForm.questions[i]?.content + "847878   "+ questionsForm.questions[i]?.correction}</Text> */}


                        </Col>
                    </Row>

                </Card>
            ))}
        </div>
    );
}

export default ExerciceQuestions;