import { Button, Divider, Form, Input, InputNumber, Layout, Modal, Select, Space, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../comps/pageHeader";
import { useEffect, useState } from "react";
import { AddClientToContract, setAddClientToContractSuccess } from "./contractSlice";

const AddClientToIndividualContract = () => {
    const { id } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [creationData, setCreationData] = useState({
        id: id,
        name: "",
        email: "",
        type: "school",
        password: "",
        referer: "",
        phoneNumber: "",
        numberOfActivationCodes: 0
    });

    const isAddingClientToContract = useSelector((state) => state.contract.isAddingClientToContract);
    const clientCreationSuccess = useSelector((state) => state.contract.addClientToContractSuccess);

    const establishmentTypes = [
        {
            value: 'school',
            label: 'École',
        },
        {
            value: 'association',
            label: 'Association',
        },
        {
            value: 'library',
            label: 'Bibliothèque',
        },
        {
            value: 'footballTeam',
            label: 'Équipe de football',
        },
        {
            value: 'company',
            label: 'Entreprise',
        },
        {
            value: 'townHall',
            label: 'Mairie',
        },
        {
            value: 'academy',
            label: 'Académie',
        },
        {
            value: 'federation',
            label: 'Fédération',
        },
    ];

    useEffect(() => {
        if (!clientCreationSuccess) {
            return;
        }
        Modal.success({
            title: "Succès",
            content: "Client ajouté avec succès",
            okText: "Oui",
            onOk() {
                dispatch(setAddClientToContractSuccess(null));
                navigate("/dashboard/pro/contracts/list");
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientCreationSuccess]);

    const handleSave = () => {
        dispatch(AddClientToContract(creationData));
    }

    return (
        <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
            <Layout>
                <PageHeader title="Ajouter un établissement" breadcrumbItems={["Panneau Clients Pro", "Ajouter un Client Pro"]} />
                <div
                    style={{
                        margin: "2em",
                        padding: "1em 2em 1em 2em",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        backgroundColor: "#fff"
                    }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}>
                        <h3>Informations pertinentes</h3>
                    </div>
                    <Divider style={{ margin: "10px 0px 10px 0px" }} />
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems:"center"}}>
                        <div style={{ width: "80%", alignItems: "center", justifyContent: "center" }}>
                            <Form
                                name="Inscription"
                                layout='vertical'
                                onFinish={handleSave}
                            >
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent:"space-between", flexWrap: "wrap" }}>
                                    <Form.Item
                                        style={{ width: "40%", marginRight: "4%" }}
                                        label={<p style={{ fontWeight: "400", margin: "0px 0px 0px 0px" }}>Entrer le nom de l’établissement</p>}
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Veuillez insérer le nom de l\'établissement.',
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder='Le nom du client Pro'
                                            onChange={(e) => setCreationData({ ...creationData, name: e.target.value })}
                                            addonAfter={(
                                                <Select
                                                    style={{ width: 100 }}
                                                    options={establishmentTypes}
                                                    defaultValue={"school"}
                                                    value={creationData.type} onChange={(e) => {
                                                        setCreationData({ ...creationData, type: e })

                                                    }} />
                                            )}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ width: "40%", marginRight: "4%" }}
                                        label={<p style={{ fontWeight: "400", margin: "0px 0px 0px 0px" }}>Entrer le nom du referant</p>}
                                        name="referer"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Veuillez insérer le nom du referant.',
                                            },
                                        ]}

                                    >
                                        <Input placeholder='Nom du referant' onChange={(e) => setCreationData({ ...creationData, referer: e.target.value })} />
                                    </Form.Item>

                                    <Form.Item
                                        style={{ width: "40%", marginRight: "4%" }}
                                        label={<p style={{ fontWeight: "400", margin: "0px 0px 0px 0px" }}>Entrer une adresse mail</p>}
                                        name="email"
                                        initialValue={null}
                                        rules={[
                                            {
                                                required: true,
                                                // eslint-disable-next-line no-useless-escape
                                                pattern: new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g),
                                                message: 'Veuillez insérer une adresse mail valide',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='address@mail.com' onChange={(e) => setCreationData({ ...creationData, email: e.target.value })} />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ width: "40%", marginRight: "4%" }}
                                        label={<p style={{ fontWeight: "400", margin: "0px 0px 0px 0px" }}>Entrer un numéro de téléphone</p>}
                                        name="phone"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Veuillez insérer un numéro de téléphone',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='00000000000' onChange={(e) => setCreationData({ ...creationData, phoneNumber: e.target.value })} />
                                    </Form.Item>

                                    <Form.Item
                                        style={{ width: "40%", marginRight: "4%" }}
                                        label={<p style={{ fontWeight: "400", margin: "0px 0px 0px 0px" }}>Ajouter un Mot de Passe</p>}
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                pattern: new RegExp(/(?=^.{8,12}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/g),
                                                message: 'Veuillez insérer un mot de passe valide',
                                            },
                                        ]}
                                    >
                                        <Input.Password type='password' placeholder='Mot de Passe' onChange={(e) => setCreationData({ ...creationData, password: e.target.value })} />
                                    </Form.Item>

                                    <Form.Item
                                        style={{ width: "40%", marginRight: "4%" }}
                                        label={<p style={{ fontWeight: "400", margin: "0px 0px 0px 0px" }}>Entrer le nombre d’accès attribués</p>}
                                        name="activationCodes"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Veuillez insérer le nombre d’accès attribués',
                                            },
                                        ]}
                                    >
                                        <InputNumber min={0} defaultValue={0}
                                            onChange={(e) => setCreationData({ ...creationData, numberOfActivationCodes: e })}
                                            changeOnWheel />
                                    </Form.Item>
                                    <div style={{ justifyContent: "start", width: "40%", marginRight: "4%" }}>
                                        <p style={{ color: "#00000073" }}>Le Mot de Passe doit comporter au moins :</p>
                                        <ul style={{ paddingLeft: "16px" }}>
                                            <li style={{ color: "#00000073" }}>De 8 à 12 caractères</li>
                                            <li style={{ color: "#00000073" }}>Au moins 1 minuscule</li>
                                            <li style={{ color: "#00000073" }}>Au moins 1 majuscule</li>
                                            <li style={{ color: "#00000073" }}>Au moins 1 chiffre</li>
                                        </ul>
                                    </div>
                                </div>

                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

                                    <Divider style={{ margin: "10px 0px 10px 0px" }} />
                                    <p>Vérifier les informations avant de confirmer.</p>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            {isAddingClientToContract ? <Spin className='proClientSpinner' /> : "Ajouter"}
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </Layout>
        </Space>
    )
};

export default AddClientToIndividualContract;