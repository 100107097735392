import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Space, Row, Button, Layout, Card, Badge, Radio, Input, Col, DatePicker } from 'antd';

import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import { fetchUsers } from './usersListSlice';
// import { deleteSingleUsers, UpdateUsers } from '../us/singleUsersSlice';
import PageHeader from '../../comps/pageHeader';
import { useNavigate } from 'react-router';
import moment from 'moment';

const { Content } = Layout;
const { RangePicker } = DatePicker;


const UsersList = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const fetchUsersResult = useSelector((state) => state.users.fetchUsersResult);
    const isFetchingUsers = useSelector((state) => state.users.isFetchingUsers);



    const [usersField, setUsersFilter] = useState({

        email: "",
        pageLength: 10,
        page: 1,
        name: "",
        status: null,
        startDate: null,
        endDate: null,
        username: "",

    });
    const [usersFieldTemp, setUsersFilterTemp] = useState({

        email: "",
        pageLength: 10,
        page: 1,
        name: "",
        status: null,
        username: "",
        startDate: null,
        endDate: null,

    });

    useEffect(() => {
        // dispatch(getBannerInfo());
        dispatch(fetchUsers(usersField));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usersField]);
    const getColumnFilterStatusProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Radio.Group
                    onChange={(e) => setUsersFilter({
                        ...usersField, status: e.target.value, page: 1,
                    })} value={usersField.status}>
                    <Space direction="vertical">
                        <Radio value={"active"}>Actif</Radio>
                        <Radio value={"blocked"}>Bloqué</Radio>
                        <Radio value={null}>Tout</Radio>
                    </Space>
                </Radio.Group>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        close();
                    }}
                >
                    Fermer
                </Button>
            </div>),
        filterIcon: (filtered) => (<FilterFilled
            style={{
                color: filtered ? '#39B13D' : undefined,
            }}
        />),
    });
    const getColumnNomSearchProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >

                <Input
                    placeholder={`Tapez un seul mot`}
                    value={usersField.name}
                    onChange={(e) => {

                        setUsersFilter({ ...usersField, name: e.target.value, page: 1 });
                    }} style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                // onPressEnter={() => dispatch(getClientList(clientsFilter))}
                />

                <Space>
                    <Button
                        onClick={() => { setUsersFilter({ ...usersField, name: "" }); }}
                        size="small"
                        style={{
                            width: 110,
                        }}
                    >
                        Réinitialiser
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fermer
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#39B13D' : undefined,
                }}
            />
        ),
    });
    const getColumnUserNameSearchProps = () => ({

        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (

            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >

                <Input
                    placeholder={`Cherchez par nom d'utilisateur`}
                    value={usersFieldTemp.username}
                    onChange={(e) => {

                        setUsersFilterTemp({ ...usersFieldTemp, username: e.target.value, page: 1 });
                    }} style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                    onPressEnter={() => setUsersFilter({ ...usersField, username: usersFieldTemp.username })}
                />

                <Space size={50}>
                    <Button
                        onClick={() => {
                            setUsersFilterTemp({ ...usersFieldTemp, username: "" });
                            setUsersFilter({ ...usersField, username: "" })
                            close();

                        }}
                        size="small"
                        style={{
                            width: 110,
                        }}
                    >
                        Réinitialiser
                    </Button>
                    <Button
                        icon={<SearchOutlined />}
                        type="primary"
                        size="small"
                        onClick={() => {
                            close();

                            setUsersFilter({ ...usersField, username: usersFieldTemp.username })
                        }}
                    >

                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#39B13D' : undefined,
                }}
            />
        ),
    });
    const getColumnEmailSearchProps = () => ({

        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (

            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >

                <Input
                    placeholder={`Cherchez un Email`}
                    value={usersFieldTemp.email}
                    onChange={(e) => {

                        setUsersFilterTemp({ ...usersFieldTemp, email: e.target.value, page: 1 });
                    }} style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                    onPressEnter={() => setUsersFilter({ ...usersField, email: usersFieldTemp.email })}
                />

                <Space size={50}>
                    <Button
                        onClick={() => {
                            setUsersFilterTemp({ ...usersFieldTemp, email: "" });
                            setUsersFilter({ ...usersField, email: "" })
                        }}
                        size="small"
                        style={{
                            width: 110,
                        }}
                    >
                        Réinitialiser
                    </Button>
                    <Button
                        icon={<SearchOutlined />}
                        type="primary"
                        size="small"
                        onClick={() => {
                            close();
                            setUsersFilter({ ...usersField, email: usersFieldTemp.email })
                        }}
                    >

                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#39B13D' : undefined,
                }}
            />
        ),
    });
    const getColumnDateFilterProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >

                <RangePicker
                    size='small'
                    onChange={(dates, datesString) => {
                        console.log('dates', dates);

                        setUsersFilter({
                            ...usersField, startDate: datesString[0] === "" ? null : moment(datesString[0]).format(), endDate: datesString[1] === "" ? null : moment(datesString[1]).format()

                        })

                        // datesString[0] === "" ? close() :
                        //     console.log('datesString', datesString);
                    }}
                />

            </div >
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#39B13D' : undefined,
                }}
            />
        ),
    });

    const columns = [

        {

            title: 'Nom',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            ...getColumnNomSearchProps(),

        },
        {

            title: 'Nom d’utilisateur',
            dataIndex: 'username',
            key: 'username',
            width: '20%',
            ...getColumnUserNameSearchProps(),

        },
        {

            title: 'Adresse Mail',
            dataIndex: 'email',
            key: 'email',
            width: '25%',
            ...getColumnEmailSearchProps(),

        },
        {
            title: 'Date d’Inscription',
            dataIndex: 'createdAt',
            align: 'center',
            key: 'createdAt',
            width: '25%',

            // width: '20%',
            render: (text) => {
                return moment(text).format("DD-MM-YYYY");
            },
            ...getColumnDateFilterProps()
        },
        {
            title: 'État',
            dataIndex: 'status',
            key: 'status',
            width: '12%',
            align: "center",
            ...getColumnFilterStatusProps(),
            render: (status) => {
                return (
                    <Badge color={status === "blocked" ? "red" : "green"} text={status === "active" ? "Actif" : "Bloqué"} />

                );
            },
        },
        {
            title: "Actions",
            width: "20%",
            align: "center",
            dataIndex: "_id",
            key: "_id",
            render: (_id) => {
                return (
                    <Button
                        type="link"
                        color='green'
                        // icon={<EditOutlined />}
                        size="small"
                        onClick={(e) => {
                            navigate(_id)
                        }}
                    >
                        Plus d’informations
                    </Button>
                );
            },
        },
    ];

    const onChangePage = (e) => {
        setUsersFilter({ ...usersField, page: e.current, pageLength: e.pageSize });
    }


    return (

        <Content
            className="site-layout-background"
            style={{
                padding: 0,
                margin: 0,
                minHeight: 280,
            }}
        >
            <>

                <PageHeader title={"Vue d’ensemble des utilisateurs"}
                    breadcrumbItems={["Panneau Utilisateurs", "Vue d’ensemble"]} />


                <Row justify={"center"} style={{ margin: "24px" }}>
                    <Card title='Liste des Utilisateurs'
                        style={{ width: "100%" }}
                        bodyStyle={{ padding: "18px" }}
                    >

                        <Row style={{ marginTop: "16px" }} >
                            <Col span={24}>

                                <Table
                                    style={{ width: "100%" }}
                                    columns={columns}
                                    pagination={{
                                        total: fetchUsersResult?.total,
                                        pageSize: usersField.pageLength,
                                        current: usersField.page,
                                        showSizeChanger: true,
                                        pageSizeOptions: [10, 15, 20, 30, 40, 50],
                                        showQuickJumper: true,
                                        showTotal: (total, range) => (<>Nombre de users : {total} </>),
                                    }}
                                    loading={isFetchingUsers}
                                    dataSource={fetchUsersResult?.data}
                                    onChange={onChangePage}
                                />
                            </Col>
                        </Row>

                    </Card>

                </Row>
            </>

        </Content>

    );
}

export default UsersList;