import { createSlice } from "@reduxjs/toolkit";

import { addLessonUrl, getLessonUrl, updateLessonUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";

export const SingleLessonSlice = createSlice({
    name: "lesson",
    initialState: {

        fetchSingleLessonResult: null,
        fetchSingleLessonError: null,
        isFetchingSingleLesson: false,
        lessonAddResult: null,
    },
    reducers: {

        setLessonAddResult: (state, action) => {
            state.lessonAddResult = action.payload;
        },
        setFetchSingleLessonResult: (state, action) => {
            state.fetchSingleLessonResult = action.payload;
            state.fetchSingleLessonError = null;

            state.isFetchingSingleLesson = false;
        },

        setFetchSingleLessonError: (state, action) => {
            state.fetchSingleLessonError = action.payload;
            state.isFetchingSingleLesson = false;
            state.fetchSingleLessonResult = null

        },
        setIsFetchingSingleLesson: (state, action) => {
            state.isFetchingSingleLesson = action.payload;
        },
    },
});

export const {
    setLessonAddResult,
    setFetchSingleLessonResult,
    setFetchSingleLessonError,
    setIsFetchingSingleLesson,

} = SingleLessonSlice.actions;


export const fetchSingleLesson = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getLessonUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsFetchingSingleLesson(true));
    const response = axios(config)
        .then(response => {
            console.log('55555555555555555555555555',);
            dispatch(setFetchSingleLessonResult(response.data));
        })
        .catch((error) => {
            console.log(error);
            message.error(error.response.data.message)
            dispatch(setFetchSingleLessonError(error.response.data.message));

        });

    return response.data;

}
export const editLesson = (data) => async (dispatch, getState) => {

    const config = {
        method: "post",
        url: updateLessonUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data: data,
    };

    const response = await axios(config)
        .then((response) => {
            message.success(response.data.message)
        })
        .catch((error) => {
            console.log("can't edit")
            message.error(error.response.data.message)

            // dispatch( setmessage({name:'error',message :response.message}))
        });
    return response;
};

export const AddnewLesson = (data) => async (dispatch, getState) => {

    const config = {
        method: "post",
        url: addLessonUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data,
    };
    console.log("add Lesson ", data);

    const response = await axios(config)
        .then((response) => {
            dispatch(setLessonAddResult(response.data));

            message.success(response.data.message)

        })
        .catch((error) => {
            message.error(error.response.data.message)
        });
    return response;
};

export default SingleLessonSlice.reducer;