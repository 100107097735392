

import React, { useState, useEffect } from 'react';
import {  Line } from '@ant-design/plots';
import { Card, Col, DatePicker, Divider, Radio, Row, Space, Statistic } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Top5Days from './top5Days';
import moment from 'moment';
import { fetchRevenueChartData } from './revenueChartSlice';

const { RangePicker } = DatePicker
const RevenueChart = () => {
    const dispatch = useDispatch();
    const fetchRevenueChartDataResult = useSelector((state) => state.revenueChartData.fetchRevenueChartDataResult);
    const isFetchingRevenueChartData = useSelector((state) => state.revenueChartData.isFetchingRevenueChartData);

    const [statfilter, setStatfilter] = useState({
        filter: "days",
        startDate: null,
        endDate: null,
    });

    useEffect(() => {

        dispatch(fetchRevenueChartData({ ...statfilter }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statfilter]);


    const config = {
        data: fetchRevenueChartDataResult?.stats,
        padding: 'auto',
        xField: 'period',
        yField: 'price',

        xAxis: {
            label: {
                autoRotate: true,
                autoHide: false,
                autoEllipsis: false,
            },
        },
        tooltip: {
            formatter: (datum) => ({
                name: "prix  ",
                value: (datum.price).toFixed(2) + " €",
            }),
        },
        color: "#42D83F",
        point: {
            size: 4,
            style: {
                fill: '#39B13D',
                stroke: false,
                lineWidth: 0,
            },
        },
    };

    const handleChangeFilter = (e) => {
        setStatfilter({ filter: e.target.value })
    };
    return (

        <Card title="Graphique des Revenus" style={{ backgroundColor: "white", cursor: "default", minHeight: "500px" }}
            hoverable
            extra={

                <Space >
                    <Radio.Group
                        size='small'
                        value={statfilter.filter}
                        onChange={handleChangeFilter}
                        disabled={isFetchingRevenueChartData || statfilter.startDate}
                    >
                        <Space>
                            <Radio.Button style={{ border: "0" }} value={"days"}>Par jours</Radio.Button>
                            <Radio.Button style={{ border: "0" }} value={"week"}>Par semaines</Radio.Button>
                            <Radio.Button style={{ border: "0" }} value={"month"}>Par mois</Radio.Button>
                        </Space>
                    </Radio.Group>
                    <RangePicker
                        disabled={isFetchingRevenueChartData}
                        onChange={(dates, datesString) => {

                            setStatfilter({
                                ...statfilter, startDate: datesString[0] === "" ? null : moment(datesString[0]).format(),
                                endDate: datesString[1] === "" ? null : moment(datesString[1]).format(),
                                filter: datesString[0] === "" ? "days" : "rangeDate"
                            })
                        }}
                        size='small' />

                </Space>}

        >
            <Row justify={"space-between"}>
                <Col span={15}>

                    {fetchRevenueChartDataResult ?
                        <Line {...config} />
                        : null}
                </Col>
                <Col span={8}>


                    {fetchRevenueChartDataResult ?
                        <>

                            <Top5Days topFive={fetchRevenueChartDataResult.top5Days} />

                            <Divider style={{ margin: "12px" }} />
                            <Statistic
                                style={{ padding: "24px" }}
                                title='Revenus d’aujourd’hui'
                                value={(fetchRevenueChartDataResult.totalMoneyTransactionsToday.price).toFixed(2) + " €"}

                            />
                        </>
                        : null}
                </Col>
            </Row>
        </Card>
    );
}

export default RevenueChart;