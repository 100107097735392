import { Image, Col, Row, Button } from "antd";
import Dialog from '../components/Dialog';
import { ComponentStates } from '../Activation';

export default function ChoiceScreen({ setComponentState }) {
    return <Col style={{
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-evenly"
    }}>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "0vh"
        }}>
            <Dialog text="Bonjour à tous ! Je suis Lily !" />
        </Row>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Image
                src="/activation/Lilly.png"
                preview={false} />
        </Row>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Image
                src="/activation/Logo.png"
                preview={false} />
        </Row>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // marginTop: "15vh"
        }}>
            <p style={{ lineHeight:"normal", fontWeight:"700", fontSize: "20px", color: "#616161" }}>Apprendre l'orthographe en s'amusant !</p>
        </Row>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width:"100%",
            marginTop: "12vh"
        }}>
            
                <Button className="customButton" onClick={() => {setComponentState(ComponentStates.SignUpScreen)}} style={{ color: "#fff", backgroundColor: "#39B13D", borderRadius: "100px", width:"100%" }}>COMMENCER</Button>
                <Button className="customButton" style={{
                    color: "#39B13D", 
                    backgroundColor: "#F0FFF0", 
                    borderRadius: "100px",
                    width:"100%",
                    margin: "24px 0px"
                }}
                onClick={() => setComponentState(ComponentStates.SignInScreen)}
                >J’AI DÉJÀ UN COMPTE</Button>
            
        </Row>
    </Col>;
}