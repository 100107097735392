import { createSlice } from "@reduxjs/toolkit";

import { updatePackUrl, getSinglePackUrl, deletePackUrl, updatePackStatusUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";
export const UpdatePackSlice = createSlice({
    name: "singlePack",
    initialState: {

        fetchUpdatePackResult: null,
        fetchUpdatePackError: null,
        isUpdatingPack: false,

        fetchSinglePackResult: null,
        fetchSinglePackError: null,
        isFetchingSinglePack: false,

        deleteSinglePackResult: null,
        deleteSinglePackError: null,
        isDeletingSinglePack: false,
    },
    reducers: {

        setFetchUpdatePackResult: (state, action) => {
            state.fetchUpdatePackResult = action.payload;
            state.isUpdatingPack = false;
        },

        setFetchUpdatePackError: (state, action) => {
            state.fetchUpdatePackError = action.payload;
            state.isUpdatingPack = false;
            state.fetchUpdatePackResult = null

        },
        setIsUpdatingPack: (state, action) => {
            state.isUpdatingPack = action.payload;
        },

        setFetchSinglePackResult: (state, action) => {
            state.fetchSinglePackResult = action.payload;
            state.fetchSinglePackError = null;

            state.isFetchingSinglePack = false;
        },

        setFetchSinglePackError: (state, action) => {
            state.fetchSinglePackError = action.payload;
            state.isFetchingSinglePack = false;
            state.fetchSinglePackResult = null

        },
        setIsFetchingSinglePack: (state, action) => {
            state.isFetchingSinglePack = action.payload;
        },

        setDeleteSinglePackResult: (state, action) => {
            state.deleteSinglePackResult = action.payload;
            state.deleteSinglePackError = null;

            state.isDeletingSinglePack = false;
        },

        setDeleteSinglePackError: (state, action) => {
            state.deleteSinglePackError = action.payload;
            state.isDeletingSinglePack = false;
            state.deleteSinglePackResult = null

        },
        setIsDeleteingSinglePack: (state, action) => {
            state.isDeletingSinglePack = action.payload;
        },
    },
});

export const {

    setFetchUpdatePackResult,
    setFetchUpdatePackError,
    setIsUpdatingPack,

    setFetchSinglePackResult,
    setFetchSinglePackError,
    setIsFetchingSinglePack,

    setDeleteSinglePackResult,
    setDeleteSinglePackError,
    setIsDeleteingSinglePack,

} = UpdatePackSlice.actions;

export const fetchSinglePack = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getSinglePackUrl,

        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsFetchingSinglePack(true));
    const response = axios(config)
        .then(response => {
            dispatch(setFetchSinglePackResult(response.data));
        })
        .catch((error) => {
            console.log(error);
            message.error(error.response.data.message)
            dispatch(setFetchSinglePackError(error.response.data.message));

        });

    return response.data;

}

export const UpdatePack = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: updatePackUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsUpdatingPack(true));
    const response = axios(config)
        .then(response => {

            message.success(response.data.message)
            dispatch(setFetchUpdatePackResult(response.data));
        })
        .catch((error) => {
            console.log(error);
            message.error(error.response.data.message)
            dispatch(setFetchUpdatePackError(response.message));

        });

    return response.data;

}

export const updatePackStatus = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: updatePackStatusUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsUpdatingPack(true));
    const response = axios(config)
        .then(response => {

            message.success(response.data.message)
            dispatch(setFetchUpdatePackResult(response.data));
        })
        .catch((error) => {
            console.log(error);
            message.error(error.response.data.message)
            dispatch(setFetchUpdatePackError(response.message));

        });

    return response.data;

}

export const deleteSinglePack = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: deletePackUrl,

        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsDeleteingSinglePack(true));
    const response = axios(config)
        .then(response => {
            message.success(response.data.message)

            dispatch(setDeleteSinglePackResult(response.data));
        })
        .catch((error) => {
            console.log(error);
            message.error(error.response.data.message)
            dispatch(setDeleteSinglePackError(error.response.data.message));

        });

    return response.data;

}

export default UpdatePackSlice.reducer;