import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import Signin from './features/sigIn/signIn';
import Activation from './features/activation/Activation';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';
import './index.css';
import Dashboard from './features/dashboard/dashboard';
import Admins from './features/admins/adminsList';
import Module from './features/modules/module';
import SingleLesson from './features/lesson/singleLesson';
import AddCommunExo from './features/exercices/addCommonExo';
import AddNonCommunExo from './features/exercices/addNonCommonExo';
import SingleExercice from './features/exercices/singleExercice';
// import ClientList from './features/cactuspro/cactusPro';
// import AddProClient from './features/cactuspro/addProClient';
import ActivationCodes from './features/cactuspro/activationCodes';
import ItemsList from './features/avatar/itemsList';
import AddItem from './features/avatar/addItem';
import SingleItem from './features/avatar/singleItem';
import AppPrams from './features/appParams/appPrams';
import PacksList from './features/shop/packsList';
import PromoCodesList from './features/promoCode/promocodesList';
import DefisList from './features/defis/defisList';
import NotificatoinPush from './features/notifications/notification';
import UsersList from './features/users/usersList';
import SingleUser from './features/user/singleUser';
import Stats from './features/statistics/statistics';
import Finance from './features/finance/finance';
import ConfirmPurchase from './features/confirmPurchase/ConfirmPurchase';
import ConfirmPurchaseFree from './features/confirmPurchase/ConfirmPurchaseFree';
import Contracts from './features/cactuspro/contractList';
import AddInvdContract from './features/cactuspro/addInvdContract';
import ContractClientList from './features/cactuspro/contractClientList';
import InsertCode from './features/activation/insertCode';
import AddClientToIndividualContract from './features/cactuspro/addClientToIndividualContract';
import ModuleList from './features/modules/moduleList';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/signin" element={<Signin />} />
          <Route path="/dashboard" element={<Dashboard />} >
            <Route path='admins'  >
              <Route path='panel'  >
                <Route path='list' element={<Admins />} />
              </Route>
            </Route>
            <Route path='content'  >
              <Route exact path='modules'  >
                <Route path=':seq' element={<Module />} />
                <Route exact path=':seq/addLesson/:lseq' element={<SingleLesson />} />
                <Route exact path=':seq/:id' element={<SingleLesson />} />
                <Route exact path=':seq/:id/addcommonexercice/:theme' element={<AddCommunExo />} />
                <Route exact path=':seq/:id/addnoncommonexercice/:theme' element={<AddNonCommunExo />} />
                <Route exact path=':seq/lessons/:lseq/exercice/:theme/:id' element={<SingleExercice />} />

              </Route>
              <Route exact path='avatar'>
                <Route exact path='items' element={<ItemsList />} />
                <Route exact path='items/add/:section/:cat' element={<AddItem />} />
                <Route exact path='items/:id' element={<SingleItem />} />
              </Route>
              <Route exact path='defis'>
                <Route exact path='list' element={<DefisList />} />
              </Route>

            </Route>
            <Route path='users'>
              <Route exact path='users'>
                <Route exact path='list' element={<UsersList />} />
                <Route exact path='list/:id' element={<SingleUser />} />
              </Route>
            </Route>
            <Route path='params'  >
              <Route exact path='general'  >
                <Route path='appparams' element={<AppPrams />} />
              </Route>
              <Route exact path='notifications'  >
                <Route path='push' element={<NotificatoinPush />} />
                <Route path='push/:id' element={<NotificatoinPush />} />
              </Route>
              <Route exact path='shop'  >
                <Route path='organisation' element={<PacksList />} />
                <Route path='promo' element={<PromoCodesList />} />
              </Route>
            </Route>

            <Route path='pro'>
              <Route path='contracts'>
                <Route path='list' element={<Contracts />} />
                <Route path='clientcontract/:id' element={<ContractClientList />} />
                <Route path='addtoclientcontract/:id' element={<AddClientToIndividualContract />} />
                <Route path='add' element={<AddInvdContract />} />
              </Route>
              <Route path='codes'>
                <Route path='history' element={<ActivationCodes />} />
              </Route>
            </Route>

            <Route path='analytics'>
              <Route path='stats'>
                <Route path='analytics' element={<Stats />} />

              </Route>
              <Route path='finances'>
                <Route path='finance' element={<Finance />} />
              </Route>
            </Route>

          </Route>
          <Route path='*' element={<Dashboard />} />
          <Route exact path="/" element={<Signin />} />
          <Route path="/activation*" element={<Activation />} />
          <Route path="/success*" element={<ConfirmPurchase />} />
          <Route path="/achat*" element={<ConfirmPurchaseFree />} />
          <Route path="/inserercode*" element={<InsertCode />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
