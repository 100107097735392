import { createSlice } from "@reduxjs/toolkit";

import { getItemsUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";
export const ItemsSlice = createSlice({
    name: "items",
    initialState: {

        fetchItemsResult: null,
        fetchItemsError: null,
        isFetchingItems: false,
        selectedMainCatItem: null,
        selectedsubCatItem: null,


    },
    reducers: {

        setFetchItemsResult: (state, action) => {
            state.fetchItemsResult = action.payload;
            state.isFetchingItems = false;
        },

        setFetchItemsError: (state, action) => {
            state.fetchItemsError = action.payload;
            state.isFetchingItems = false;
            state.fetchItemsResult = null

        },
        setIsFetchingItems: (state, action) => {
            state.isFetchingItems = action.payload;
        },
        setSelectedMainCatItem: (state, action) => {
            state.selectedMainCatItem = action.payload;
        },
        setSelectedsubCatItem: (state, action) => {
            state.selectedsubCatItem = action.payload;
        },
    },
});

export const {

    setFetchItemsResult,
    setFetchItemsError,
    setIsFetchingItems,

    setSelectedMainCatItem,
    setSelectedsubCatItem


} = ItemsSlice.actions;


export const fetchItems = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getItemsUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsFetchingItems(true));
    const response = axios(config)
        .then(response => {

            dispatch(setFetchItemsResult(response.data));
        })
        .catch((error) => {
            console.log(error);
            message.error(error.response.data.message)
            dispatch(setFetchItemsError(response.message));

        });

    return response.data;

}

export default ItemsSlice.reducer;