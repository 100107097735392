import { createSlice } from "@reduxjs/toolkit";

import { deleteExerciceUrl, getExerciceUrl, updateSingleExerciceUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";

export const SingleExerciceSlice = createSlice({
    name: "exercice",
    initialState: {

        fetchSingleExerciceResult: null,
        fetchSingleExerciceError: null,
        isFetchingSingleExercice: false,

        deleteSingleExerciceResult: null,
        deleteSingleExerciceError: null,
        isdeleteingSingleExercice: false,

    },
    reducers: {


        setdeleteSingleExerciceResult: (state, action) => {
            state.deleteSingleExerciceResult = action.payload;
            state.deleteSingleExerciceError = null;

            state.isdeleteingSingleExercice = false;
        },
        setFetchSingleExerciceResult: (state, action) => {
            state.fetchSingleExerciceResult = action.payload;
            state.fetchSingleExerciceError = null;

            state.isFetchingSingleExercice = false;
        },

        setFetchSingleExerciceError: (state, action) => {
            state.fetchSingleExerciceError = action.payload;
            state.isFetchingSingleExercice = false;
            state.fetchSingleExerciceResult = null

        },
        setIsFetchingSingleExercice: (state, action) => {
            state.isFetchingSingleExercice = action.payload;
        },
    },
});

export const {
    setFetchSingleExerciceResult,
    setFetchSingleExerciceError,
    setIsFetchingSingleExercice,
    setdeleteSingleExerciceResult

} = SingleExerciceSlice.actions;


export const fetchSingleExercice = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getExerciceUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsFetchingSingleExercice(true));
    const response = axios(config)
        .then(response => {

            dispatch(setFetchSingleExerciceResult(response.data));
        })
        .catch((error) => {
            console.log(error);
            message.error(error.response.data.message)
            dispatch(setFetchSingleExerciceError(error.response.data.message));

        });

    return response.data;

}
export const editSingleExercice = (data) => async (dispatch, getState) => {

    const config = {
        method: "post",
        url: updateSingleExerciceUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data: data,
    };

    axios(config)
        .then((response) => {
            message.success(response.data.message)
        })
        .catch((error) => {
            console.log("can't edit")
            message.error(error.response.data.message)

            // dispatch( setmessage({name:'error',message :response.message}))
        });
};
export const deleteSingleExercice = (data) => async (dispatch, getState) => {

    const config = {
        method: "post",
        url: deleteExerciceUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data: data,
    };

    axios(config)
        .then((response) => {
            dispatch(setdeleteSingleExerciceResult(response.data));

            message.success(response.data.message)
        })
        .catch((error) => {
            console.log("can't delete")
            // message.error(error.response.data.message)

            // dispatch( setmessage({name:'error',message :response.message}))
        });
};



export default SingleExerciceSlice.reducer;