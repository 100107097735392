import { createSlice } from "@reduxjs/toolkit";

import { getUsersUrl } from "../../constants";
import axios from "axios";
export const UsersSlice = createSlice({
    name: "users",
    initialState: {

        fetchUsersResult: null,
        fetchUsersError: null,
        isFetchingUsers: false,
    },
    reducers: {

        setFetchUsersResult: (state, action) => {
            state.fetchUsersResult = action.payload;
            state.isFetchingUsers = false;
        },

        setFetchUsersError: (state, action) => {
            state.fetchUsersError = action.payload;
            state.isFetchingUsers = false;
            state.fetchUsersResult = null

        },
        setIsFetchingUsers: (state, action) => {
            state.isFetchingUsers = action.payload;
        },
    },
});

export const {

    setFetchUsersResult,
    setFetchUsersError,
    setIsFetchingUsers,

} = UsersSlice.actions;


export const fetchUsers = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getUsersUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsFetchingUsers(true));
    const response = axios(config)
        .then(response => {
            dispatch(setFetchUsersResult(response.data));
        })
        .catch((error) => {
            console.log(error);
            // message.error(error.response.data.message)
            dispatch(setFetchUsersError(response.message));

        });

    return response.data;

}

export default UsersSlice.reducer;