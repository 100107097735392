import { createSlice } from "@reduxjs/toolkit";

import {  getAppPramsUrl, updateAppParamsUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";

export const appPramsSlice = createSlice({
    name: "appPrams",
    initialState: {

        fetchappPramsResult: null,
        fetchappPramsError: null,
        isFetchingappPrams: false,
    },
    reducers: {


        setFetchappPramsResult: (state, action) => {
            state.fetchappPramsResult = action.payload;
            state.fetchappPramsError = null;

            state.isFetchingappPrams = false;
        },

        setFetchappPramsError: (state, action) => {
            state.fetchappPramsError = action.payload;
            state.isFetchingappPrams = false;
            state.fetchappPramsResult = null

        },
        setIsFetchingappPrams: (state, action) => {
            state.isFetchingappPrams = action.payload;
        },
    },
});

export const {
    setFetchappPramsResult,
    setFetchappPramsError,
    setIsFetchingappPrams,

} = appPramsSlice.actions;


export const fetchappPrams = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getAppPramsUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsFetchingappPrams(true));
    const response = axios(config)
        .then(response => {
            dispatch(setFetchappPramsResult(response.data));
        })
        .catch((error) => {
            console.log(error);
            message.error(error.response.data.message)
            dispatch(setFetchappPramsError(error.response.data.message));

        });

    return response.data;

}
export const editAppParams = (data) => async (dispatch, getState) => {

    const config = {
        method: "post",
        url: updateAppParamsUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data: data,
    };

    const response = await axios(config)
        .then((response) => {
            message.success(response.data.message)
        })
        .catch((error) => {
            console.log("can't edit")
            message.error(error.response.data.message)

            // dispatch( setmessage({name:'error',message :response.message}))
        });
    return response;
};


export default appPramsSlice.reducer;