import { createSlice } from "@reduxjs/toolkit";

import { getUserDeviceOSStatsUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";

export const UsersCountSlice = createSlice({
    name: "userscount",
    initialState: {

        fetchUserCountResult: null,
        fetchUserCountError: null,
        isFetchingusersCount: false,

    },
    reducers: {


        setUserCountResult: (state, action) => {
            state.fetchUserCountResult = action.payload;
            state.fetchUserCountError = null;

            state.isFetchingusersCount = false;
        },

        setUserCountError: (state, action) => {
            state.fetchUserCountError = action.payload;
            state.isFetchingusersCount = false;
            state.fetchUserCountResult = null

        },
        setIsCountingUsers: (state, action) => {
            state.isFetchingusersCount = action.payload;
        },

   
    },
});

export const {
    setUserCountResult,
    setUserCountError,
    setIsCountingUsers,

    

} = UsersCountSlice.actions;


export const fetchUsersCount = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getUserDeviceOSStatsUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsCountingUsers(true));
    const response = axios(config)
        .then(response => {
            dispatch(setUserCountResult(response.data));
        })
        .catch((error) => {
            message.error(error.response.data.message)
            dispatch(setUserCountError(error.response.data.message));

        });

    return response.data;

}



export default UsersCountSlice.reducer;