import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Space, Row, Result, Button, Col, Typography, Layout, Badge } from 'antd';

import { fetchAdmins } from './adminsSlice';


import { EditOutlined } from "@ant-design/icons";
import PageHeader from '../../comps/pageHeader';
import AddAdmin from '../../comps/addadmin';
import SingleAdmin from '../admin/singleAdmin';

const { Text } = Typography;
const { Content } = Layout;


const Admins = () => {

    const { useEffect } = React;
    const dispatch = useDispatch();
    let fetchServerErr = useSelector(
        (state) => state.admins.fetchServerErr
    );

    useEffect(() => {
        dispatch(fetchAdmins({ limit: 10 }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchAdminsResult = useSelector(
        (state) => state.admins.fetchAdminsResult
    );

    const isFetchingAdmins = useSelector(
        (state) => state.admins.isFetchingAdmins
    );

    const [visible, setVisible] = useState(false)
    const [openDrawer, setOpenDrawer] = useState(false);
    const [adminID, setAdminID] = useState(null);

    const columns = [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'Type',
            width: '10%',

        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '25%',
        },
        {
            title: 'Créer par',
            dataIndex: 'added_by',
            key: 'added_by',
            width: '25%',
            render: (added_by) => {

                return added_by ? added_by.email : null;
            },
        },
        {
            title: "états",
            dataIndex: "status",
            key: "status",
            width: "10%",

            render: (text) => {
                const colors = {
                    active: "green",
                    blocked: "red",
                };
                return <Badge color={colors[text]} text={text} />
            },
        },

        {
            title: "Actions",
            width: "10%",
            align: "center",
            dataIndex: "_id",
            key: "_id",
            render: (_id) => {
                return (
                    <Button
                        type="link"
                        color='green'
                        icon={<EditOutlined />}
                        size="small"
                        onClick={(e) => {
                            setOpenDrawer(true)
                            setAdminID(_id)
                        }}
                    >
                        Gérer
                    </Button>
                );
            },
        },

    ];



    const onClose = () => {
        setOpenDrawer(false);
    };


    return (

        <Content
            className="site-layout-background"
            style={{
                padding: 0,
                margin: 0,
                minHeight: 280,
            }}
        >
            {fetchServerErr ?
                <Result
                    status="500"
                    title="500"
                    subTitle="Une erreur inattendue est survenue, veuillez réessayer plus tard !"
                />
                : <>
                    <SingleAdmin openDrawer={openDrawer} onClose={onClose} adminID={adminID} />

                    <AddAdmin visible={visible} setVisible={setVisible} />

                    <PageHeader title={"Vue d’ensemble des administrateurs "}
                        breadcrumbItems={["Panneau Administratif", "Vue d’ensemble "]} />
                    <Row justify={"center"} style={{ background: "#424242", margin: "24px", minHeight: "112px" }}
                    >
                        <Col span={5} style={{ paddingTop: 10 }}                >
                            <Space align='center' direction="vertical" size="middle" style={{ display: 'flex' }}>
                                <Text style={{ color: "white", fontSize: "16px" }}>Ajouter un administrateur</Text>
                                <Button type='primary'
                                    onClick={() => { setVisible(true) }}
                                    style={{ width: "132px", height: "32px" }}> Nouveau Admin</Button>
                            </Space>
                        </Col>
                    </Row>
                    <Row justify={"center"} style={{ margin: "24px" }}>


                        {fetchAdminsResult && fetchAdminsResult.data ?
                            <Table style={{ width: "100%" }} columns={columns} pagination={{ total: fetchAdminsResult?.total, pageSize: 10 }}
                                loading={isFetchingAdmins} dataSource={fetchAdminsResult ? fetchAdminsResult.data : null} />
                            :
                            <Table style={{ width: "100%" }} columns={columns}

                                loading={isFetchingAdmins} dataSource={null} />
                        }
                    </Row>
                </>
            }
        </Content>

    );
}

export default Admins;