import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";
import { createClientUrl, getBannerInfoUrl, getClientListUrl, getSingleClientUrl, updateClientUrl } from "../../constants";
import { setfetchServerErr } from "../admins/adminsSlice";

export const CactusProSlice = createSlice({
    name: "cactusPro",
    initialState: {
        // Client list
        fetchClientsSuccess: null,
        fetchClientsFailure: null,
        isFetchingClients: false,

        // Banner Info
        fetchBannerInfoSuccess: null,
        fetchBannerInfoFailure: null,
        isFetchingBannerInfo: false,

        // Create Client
        isCreatingClient: false,
        createClientSuccess: null,
        createClientFailure: null,

        // Update Client
        clientToUpdateId: null,
        isUpdatingClient: false,
        updateClientSuccess: null,
        updateClientFailure: null,

        // Get single client
        fetchSingleClientSuccess: null,
        fetchSingleClientFailure: null,
        isFetchingSingleClient: false,
        
    },
    reducers: {
        // Client list
        setFetchClientsSuccess: (state, action) => {
            state.fetchClientsSuccess = action.payload;
            state.fetchClientsFailure = null;
            state.isFetchingClients = false;
        },
        setFetchClientsFailure: (state, action) => {
            state.fetchClientsFailure = action.payload;
            state.fetchClientsSuccess = null;
            state.isFetchingClients = false;
        },
        setFetchClientsStarted: (state, action) => {
            state.isFetchingClients = true;
        },
        // Banner Info
        setFetchBannerInfoSuccess: (state, action) => {
            state.fetchBannerInfoSuccess = action.payload;
            state.fetchBannerInfoFailure = null;
            state.isFetchingBannerInfo = false;
        },
        setFetchBannerInfoFailure: (state, action) => {
            state.fetchBannerInfoFailure = action.payload;
            state.fetchBannerInfoSuccess = null;
            state.isFetchingBannerInfo = false;
        },
        setFetchBannerInfoStarted: (state, action) => {
            state.isFetchingBannerInfo = true;
        },
        // Create client
        setCreateClientSuccess: (state, action) => {
            state.createClientSuccess = action.payload;
            state.createClientFailure = null;
            state.isCreatingClient = false;
        },
        setCreateClientFailure: (state, action) => {
            state.createClientFailure = action.payload;
            state.createClientSuccess = null;
            state.isCreatingClient = false;
        },
        setCreateClientStarted: (state, action) => {
            state.isCreatingClient = true;
        },
        // Update Client
        setClientToUpdateId: (state, action) => {
            state.clientToUpdateId = state.action;
        },
        setUpdateClientSuccess: (state, action) => {
            state.updateClientSuccess = action.payload;
            state.updateClientFailure = null;
            state.isUpdatingClient = false;
        },
        setUpdateClientFailure: (state, action) => {
            state.updateClientFailure = action.payload;
            state.updateClientSuccess = null;
            state.isUpdatingClient = false;
        },
        setUpdateClientStarted: (state, action) => {
            state.isUpdatingClient = true;
        },
        // Get single client
        setFetchSingleClientSuccess: (state, action) => {
            state.fetchSingleClientSuccess = action.payload;
            state.fetchSingleClientFailure = null;
            state.isFetchingSingleClient = false;
        },
        setFetchSingleClientFailure: (state, action) => {
            state.fetchSingleClientFailure = action.payload;
            state.fetchSingleClientSuccess = null;
            state.isFetchingSingleClient = false;
        },
        setFetchSingleClientStarted: (state, action) => {
            state.isFetchingSingleClient = true;
        },

    }
});

export const {
    // Client list
    setFetchClientsSuccess,
    setFetchClientsFailure,
    setFetchClientsStarted,
    
    // Banner info
    setFetchBannerInfoSuccess,
    setFetchBannerInfoFailure,
    setFetchBannerInfoStarted,

    // Create Client
    setCreateClientSuccess,
    setCreateClientFailure,
    setCreateClientStarted,

    // Update Client
    setClientToUpdateId,
    setUpdateClientSuccess,
    setUpdateClientFailure,
    setUpdateClientStarted,

    // Single Client
    setFetchSingleClientSuccess,
    setFetchSingleClientFailure,
    setFetchSingleClientStarted,

} = CactusProSlice.actions;


export const getBannerInfo = () => (dispatch, getState) => {
    const config = {
        method: "post",
        url: getBannerInfoUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
    };
    dispatch(setFetchBannerInfoStarted());
    axios(config)
        .then((response) => {
            dispatch(setFetchBannerInfoSuccess(response.data));
        })
        .catch((error) => {
            if (!error.response) {
                dispatch(setfetchServerErr(true));
                dispatch(setFetchBannerInfoFailure("Le serveur ne répond pas, veuillez réessayer plus tard."))
                return;
            }
            if (error.response.status === 401) {
                message.error("Vous êtes déconnecté, veuillez vous reconnecter.");
                dispatch(setFetchBannerInfoFailure("Vous n'êtes pas autorisé à faire cette action."));
                return;
            }
            message.error(error.response.data.message);
            dispatch(setFetchBannerInfoFailure(error.response.data.message));
        });
}

export const getClientList = (data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: getClientListUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setFetchClientsStarted());
    axios(config)
        .then((response) => {
            dispatch(setFetchClientsSuccess(response.data));
        })
        .catch((error) => {
            if (!error.response) {
                dispatch(setfetchServerErr(true));
                dispatch(setFetchClientsFailure("Le serveur ne répond pas, veuillez réessayer plus tard."))
                return;
            }
            if (error.response.status === 401) {
                message.error("Vous êtes déconnecté, veuillez vous reconnecter.");
                dispatch(setFetchClientsFailure("Vous n'êtes pas autorisé à faire cette action."));
                return;
            }
            message.error(error.response.data.message);
            dispatch(setFetchClientsFailure(error.response.data.message));
        });
}

export const CreateClient = (data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: createClientUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setCreateClientStarted());
    axios(config)
        .then((response) => {
            dispatch(setCreateClientSuccess(response.data));
        })
        .catch((error) => {
            if (!error.response) {
                dispatch(setfetchServerErr(true));
                dispatch(setCreateClientFailure("Le serveur ne répond pas, veuillez réessayer plus tard."))
                return;
            }
            if (error.response.status === 401) {
                message.error("Vous êtes déconnecté, veuillez vous reconnecter.");
                dispatch(setCreateClientFailure("Vous n'êtes pas autorisé à faire cette action."));
                return;
            }
            message.error(error.response.data.message);
            dispatch(setCreateClientFailure(error.response.data.message));
        });
}

export const UpdateClient = (data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: updateClientUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setUpdateClientStarted());
    axios(config)
        .then((response) => {
            dispatch(setUpdateClientSuccess(response.data));
        })
        .catch((error) => {
            if (!error.response) {
                dispatch(setfetchServerErr(true));
                dispatch(setUpdateClientFailure("Le serveur ne répond pas, veuillez réessayer plus tard."))
                return;
            }
            if (error.response.status === 401) {
                message.error("Vous êtes déconnecté, veuillez vous reconnecter.");
                dispatch(setUpdateClientFailure("Vous n'êtes pas autorisé à faire cette action."));
                return;
            }
            message.error(error.response.data.message);
            dispatch(setUpdateClientFailure(error.response.data.message));
        });
}

export const GetSingleClient = (data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: getSingleClientUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setFetchSingleClientStarted());
    axios(config)
        .then((response) => {
            dispatch(setFetchSingleClientSuccess(response.data));
        })
        .catch((error) => {
            if (!error.response) {
                dispatch(setfetchServerErr(true));
                dispatch(setFetchSingleClientFailure("Le serveur ne répond pas, veuillez réessayer plus tard."))
                return;
            }
            if (error.response.status === 401) {
                message.error("Vous êtes déconnecté, veuillez vous reconnecter.");
                dispatch(setFetchSingleClientFailure("Vous n'êtes pas autorisé à faire cette action."));
                return;
            }
            message.error(error.response.data.message);
            dispatch(setFetchSingleClientFailure(error.response.data.message));
        });
}

export default CactusProSlice.reducer;
