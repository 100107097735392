

import { Card, Col, Input, Row, Select, Typography } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PrposQst from "./propsqst";


const { Text } = Typography;


const QuestionsPropsComps = ({ switcherEdit, setQuestionsForm, questionsForm, exerciceForm }) => {

    // const dispatch = useDispatch();
    const isUpdatingQuestions = useSelector((state) => state.questions.isUpdatingQuestions);

    const [propositions, setPropositions] = useState([[], [], [], [], [], [], [], [], [], [], [], []]);
    useEffect(() => {
        if (questionsForm) {
            let props = [[], [], [], [], [], [], [], [], [], [], [], []]
            for (let i = 0; i < questionsForm.questions.length; i++) {

                // eslint-disable-next-line array-callback-return
                questionsForm.questions[i].propositions.map(item => {
                    let selectPropos = { label: item, value: item }
                    props[i].push(selectPropos)
                })
                setPropositions(props);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionsForm]);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [exerciceForm]);


    return (
        < >
            <Card title="Questions : "
                headStyle={{ padding: "0px" }}
                bodyStyle={{ border: "0px" }}
                style={{ marginTop: "24px", minHeight: "100vh" }} bordered={false}>


                {
                    questionsForm.questions.map((question, index) =>
                        <Card loading={isUpdatingQuestions} style={{ backgroundColor: (index % 2 === 0) ? "whitesmoke" : "white", cursor: 'default' }} hoverable>
                            <Row justify={"end"}>
                                <Col span={23}>

                                    <PrposQst switcherEdit={switcherEdit}
                                        setQuestionsForm={setQuestionsForm} questionsForm={questionsForm} indexQ={index} />
                                </Col>
                            </Row>
                            <Row justify="start">

                                <Col span={1}>
                                    <Text style={{ fontWeight: "bold", fontSize: "15px" }}>{(index + 1)}.  </Text>

                                </Col>
                                <Col span={23} style={{}}>
                                    <Row
                                        justify={"space-between"}
                                    >
                                        <Col span={18}>
                                            <Input

                                                disabled={!switcherEdit}
                                                value={questionsForm.questions[index]?.content[0]}
                                                key={index}
                                                style={{
                                                    width: "100%",
                                                }}
                                                onChange={(e) => {
                                                    let questionDefis = [...questionsForm.questions]
                                                    questionDefis[index].content[0] = e.target.value
                                                    setQuestionsForm({ ...questionsForm, questions: questionDefis })
                                                }}

                                            />
                                        </Col>
                                        <Col span={4}>



                                            <Select
                                                disabled={!switcherEdit}

                                                options={propositions[index]}
                                                value={questionsForm.questions[index]?.correction}
                                                onChange={(value) => {
                                                    let questionDefis = [...questionsForm.questions]
                                                    questionDefis[index].correction = value
                                                    setQuestionsForm({ ...questionsForm, questions: questionDefis })
                                                }}
                                                style={{
                                                    width: "100%",
                                                }}
                                            />

                                        </Col>




                                    </Row>
                                    {/* <Text>{questionsForm.questions[index]?.content + "847878   "+ questionsForm.questions[index]?.correction}</Text> */}


                                </Col>
                            </Row>

                        </Card>)

                }

            </Card>

        </>
    );
}

export default QuestionsPropsComps;