

import { Card, Descriptions, Divider, Statistic } from 'antd';
import CountUp from 'react-countup';

import {
    LoadingOutlined,

} from '@ant-design/icons';
import "./stats.css"
const formatter = (value) => <CountUp end={value} separator="," />;



const CounterComp = ({ value, icon, label, todaysValue, loading,title }) => {



    return (
        <Card
            style={{ backgroundColor: "white", cursor: "default" }}
            hoverable

        >
            <Statistic title={title}
                loading={loading}
                prefix={icon}
                value={value}
                valueStyle={{ display: 'flex', justifyContent: 'flex-start' }} 
                // valueStyle={{ marginLeft: "14px" }}
                formatter={formatter} />
            <Divider style={{ margin: "12px 0px" }} />
            <Descriptions

                column={1}
                contentStyle={{ width: "100%", justifyContent: "flex-end" }}
                labelStyle={{ color: "black" }}
            >

                <Descriptions.Item span={24} style={{ padding: 0 }} label={label}>{loading ? <LoadingOutlined /> : todaysValue} </Descriptions.Item>

            </Descriptions>


        </Card>
    );
}

export default CounterComp;