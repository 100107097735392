

import { Button, Card, Checkbox, Col, Form, Input, Row, Select, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { MinusCircleOutlined, SyncOutlined, PlusOutlined } from '@ant-design/icons';
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";





const ExerciceQuestionsWithProps = ({ exerciceForm, defisCheck, questionsForm, setQuestionsForm, setQuestionType, questionType, setQuestionTypeRadio, errorIndexInput, errorIndexSelect }) => {
    // let navigate = useNavigate();

    // const dispatch = useDispatch();

    const [propositions, setPropositions] = useState([[], [], [], [], [], [], [], [], [], [], [], [],]);
    // const pathname = useSelector((state) => state.dashboard.pathname);
    // useEffect(() => {
    //     if (exerciceForm) {
    //         let props = []

    //         exerciceForm.propositions.map(item => {
    //             let selectPropos = { label: item, value: item }
    //             props.push(selectPropos)
    //         })
    //         setPropositions(props);


    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [exerciceForm]);
    useEffect(() => {
        if (questionsForm) {
            let changeDefis = [...questionsForm.questions]
            for (let i = 0; i < 12; i++) {
                changeDefis[i] = { defis: defisCheck, content: [''], propositions: [] }
            }
            setQuestionsForm({
                ...questionsForm, questions: changeDefis
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defisCheck]);
    const { Text } = Typography

    // style={{ backgroundColor: (i % 2 === 0) ? "whitesmoke" : "white" }}
    return (
        <div style={{ marginTop: "16px" }}>
            {Array.from({ length: 12 }, (_, i) => (
                <Card style={{ backgroundColor: (i % 2 === 0) ? "whitesmoke" : "white", cursor: 'default' }} hoverable>
                    <Row >
                        <Col span={24}>
                            <Text style={{ fontWeight: "bold", fontSize: "15px" }}>Propositions :    </Text>

                            <Form.List key={i} name={"Propos" + i}>
                                {(fields, { add, remove }) => (
                                    <>
                                        <Space size={[12, 0]} wrap>
                                            <Form.Item>
                                                <Button type="primary" onClick={() => {
                                                    add()
                                                }} block icon={<PlusOutlined />}>
                                                    Ajouter
                                                </Button>
                                            </Form.Item>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <Space key={key + i} style={{ display: 'flex', }} align="baseline"  >

                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, i]}
                                                        style={{ width: "100px" }}

                                                    >
                                                        <Input
                                                            // value={questionsForm.questions[i]?.propositions[name]?questionsForm.questions[i].propositions[name]:null}
                                                            onChange={(e) => {
                                                                let questionPropos = [...questionsForm.questions]
                                                                // let newProp=[]
                                                                let PropsOptions = JSON.parse(JSON.stringify(propositions))


                                                                if (questionPropos[i].propositions.length >= name) {
                                                                    questionPropos[i].propositions[name] = (e.target.value).trim()
                                                                } else {
                                                                    questionPropos[i].propositions.push((e.target.value).trim())
                                                                }
                                                                setQuestionsForm({ ...questionsForm, questions: questionPropos })

                                                                if (PropsOptions.length >= i) {

                                                                    if (!PropsOptions[i]) {
                                                                        PropsOptions.push([{ label: e.target.value, value: name }])
                                                                    }
                                                                    if (PropsOptions[i].length >= name) {
                                                                        PropsOptions[i][name] = { label: e.target.value, value: name }
                                                                    } else {
                                                                        PropsOptions[i].push({ label: e.target.value, value: name })
                                                                    }
                                                                } else {
                                                                    PropsOptions.push([{ label: e.target.value, value: name }])

                                                                }
                                                                setPropositions(PropsOptions)

                                                            }} />
                                                    </Form.Item>
                                                    <MinusCircleOutlined onClick={() => {
                                                        remove(name)
                                                        let propsE = [...questionsForm.questions]
                                                        let propsOpt = [...propositions]
                                                        propsE[i].propositions.splice(name, 1)
                                                        propsOpt[i].splice(name, 1)
                                                        setQuestionsForm({ ...questionsForm, questions: propsE })
                                                        setPropositions(propsOpt)

                                                    }
                                                    } />

                                                </Space>
                                            ))}

                                        </Space>
                                    </>
                                )}
                            </Form.List>

                        </Col>
                    </Row>
                    <Row style={{}} justify="start">

                        <Col span={1}>
                            <Text style={{ fontWeight: "bold", fontSize: "15px" }}>{(i + 1)}.  </Text>

                        </Col>
                        <Col span={23} style={{}}>


                            <Row
                                justify={"space-between"}
                            >
                                <Col span={18}>
                                    <Input
                                        status={errorIndexInput.includes(i + 1) ? "error" : null}

                                        value={questionsForm.questions[i]?.content[0]}
                                        key={i}
                                        style={{
                                            width: "100%",
                                        }}
                                        onChange={(e) => {
                                            let questionsArray = [...questionsForm.questions]
                                            questionsArray[i].content[0] = e.target.value
                                            setQuestionsForm({ ...questionsForm, questions: questionsArray })
                                            setQuestionTypeRadio(false)
                                        }}

                                    />
                                </Col>
                                <Col span={4}>



                                    <Select
                                        key={i}
                                        status={errorIndexSelect.includes(i + 1) ? "error" : null}

                                        options={propositions[i]}
                                        value={questionsForm.questions[i]?.correction}
                                        onChange={(value) => {
                                            let questionDefis = [...questionsForm.questions]
                                            questionDefis[i].correction = value
                                            setQuestionsForm({ ...questionsForm, questions: questionDefis })
                                        }}
                                        style={{
                                            width: "100%",
                                        }}
                                    />

                                </Col>
                                <Col span={1} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    {questionsForm.questions.length > 0 ?
                                        <Checkbox
                                            key={i}
                                            disabled={!defisCheck}
                                            onChange={(e) => {
                                                let questionDefis = [...questionsForm.questions]
                                                questionDefis[i].defis = e.target.checked
                                                setQuestionsForm({ ...questionsForm, questions: questionDefis })
                                            }}
                                            checked={questionsForm.questions[i].defis}
                                        /> :
                                        <SyncOutlined spin />

                                    }

                                </Col>




                            </Row >

                            {/* <Text>{questionsForm.questions[i]?.content + "847878   "+ questionsForm.questions[i]?.correction}</Text> */}

                            {/* <Divider style={{ margin: "12px" }} /> */}

                        </Col>
                    </Row>

                </Card>
            ))}
        </div>
    );
}

export default ExerciceQuestionsWithProps;