

import { Badge, Button, Card, Col, Descriptions, Divider, Image, Row, Space, Statistic, Tabs, Typography } from "antd";
import { useEffect } from "react";
import { UserOutlined, MailOutlined, FieldTimeOutlined, EyeOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PageHeader from "../../comps/pageHeader";
import { fetchSinglSingleUser, fetchUserStats, updatelSingleUser } from "./userSlice";

import "./user.css"
import moment from "moment";
import UserTransactionsList from "./userTransactions";
import UserProgression from "./userProgression";
import { hostname } from "../../constants";

const { Title, Text } = Typography

const SingleUser = ({ visible, setVisible, slectedTheme }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const fetchSingleUserResult = useSelector((state) => state.user.fetchSingleUserResult);
    const isfetchingSingleUser = useSelector((state) => state.user.isfetchingSingleUser);

    const fetchUserStatsResult = useSelector((state) => state.user.fetchUserStatsResult);

    const updateSingleUserResult = useSelector((state) => state.user.updateSingleUserResult);
    const isUpdatingSingleUser = useSelector((state) => state.user.isUpdatingSingleUser);

    useEffect(() => {
        if (id) {
            dispatch(fetchSinglSingleUser({ id }));
            // dispatch(fetchUserTransactions({ id }));
            // dispatch(fetchUserProgressions({ id }));
            dispatch(fetchUserStats({ id }));
        }
        // dispatch(getBannerInfo());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, updateSingleUserResult]);

    const items = [
        {
            key: 'transactions',
            label: `Transactions`,
            children: <UserTransactionsList />,
        },
        {
            key: 'progression',
            label: `Progression`,
            children: <UserProgression />,
        },

    ];

    return (
        <>
            <PageHeader title={"Informations détaillées sur l’utilisateur “ " + fetchSingleUserResult?.data.name + " ”"}
                breadcrumbItems={["Panneau Utilisateurs", "Informations Détaillées"]} />

            <Row justify={"space-between"} style={{ margin: "24px" }}>
                <Col span={8}>
                    <Card
                        title='État : ' style={{ minHeight: "100vh" }} loading={isfetchingSingleUser}
                        extra={<Badge text={fetchSingleUserResult?.data.status === "active" ? "Actif" : "Bloqué"} status={fetchSingleUserResult?.data.status === "active" ? "success" : "error"} />}
                        actions={[
                            <Button type="primary" style={{ width: "85%" }}
                                loading={isUpdatingSingleUser}
                                danger={fetchSingleUserResult?.data.status === "active"}
                                onClick={() => {
                                    dispatch(updatelSingleUser({ id: id, status: fetchSingleUserResult?.data.status === "active" ? "blocked" : "active" }))
                                }} >{fetchSingleUserResult?.data.status === "active" ? "Suspendre" : "Activé"}</Button>
                        ]}
                    >
                        <Space className="Avatar"
                            direction="vertical" align="center" style={{ width: "100%" }}>
                            <Image

                                fallback='/sad_cactus_128.png'
                                style={{
                                    width: 128,
                                    backgroundColor: "#F3F3F3",
                                    height: 128, objectFit: "none", objectPosition: '50% 25%', border: "1px solid gray", borderWidth: "thin", borderRadius: "100%"
                                }}
                                src={hostname + fetchSingleUserResult?.data.portraitLink}
                            />
                            <Title level={5} style={{ marginTop: "8px" }}> {fetchSingleUserResult?.data.name}</Title>
                        </Space>
                        <Divider />

                        <div style={{ margin: '24px' }}>


                            <Space
                                size={10}
                                direction="vertical" style={{ width: "100%" }}>
                                <Space>
                                    <UserOutlined style={{ fontSize: "18px" }} />
                                    <Text>
                                        {fetchSingleUserResult?.data.username}
                                    </Text>
                                </Space>
                                <Space>
                                    <MailOutlined style={{ fontSize: "18px" }} />
                                    <Text >
                                        {fetchSingleUserResult?.data.email}
                                    </Text>
                                </Space>
                                <Space>
                                    <FieldTimeOutlined style={{ fontSize: "18px", }} />
                                    <Text >
                                        {moment(fetchSingleUserResult?.data.createdAt).format("DD-MM-YYYY")}
                                    </Text>
                                </Space>
                                <Space>
                                    <EyeOutlined style={{ fontSize: "18px" }} />
                                    <Text >
                                        {moment(fetchSingleUserResult?.data.lastSeen).format("DD-MM-YYYY")}
                                    </Text>
                                </Space>
                            </Space>
                        </div>
                        <Divider />

                        <div style={{ margin: '24px' }}>


                            <Row  >
                                <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>



                                    <Title level={5} style={{ marginTop: "0px" }}>
                                        Informations Portefeuille
                                    </Title>
                                </Col>
                            </Row>
                            <Row justify={"start"} style={{ width: "100%" }}>
                                <Col span={12}>

                                    <Statistic valueStyle={{ display: 'flex', justifyContent: 'flex-start' }} title="Diamonds" value={fetchSingleUserResult?.data.wallet.diamonds} prefix={<img width={32} height={32} src="/Diamond.svg" alt="D" />} />
                                </Col>
                                <Col span={12}>
                                    <Statistic valueStyle={{ display: 'flex', justifyContent: 'flex-start' }} title="Coins" value={fetchSingleUserResult?.data.wallet.coins} prefix={<img width={32} height={32} src="/Coin.svg" alt="P" />} />
                                </Col>


                            </Row>

                            <Divider />
                            <Row  >
                                <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>



                                    <Title level={5} style={{ marginTop: "0px" }}>
                                        Informations Jeu
                                    </Title>
                                </Col>
                            </Row>
                            <Row justify={"start"} style={{ width: "100%" }}>
                                <Col span={12}>

                                    <Statistic valueStyle={{ display: 'flex', justifyContent: 'flex-start' }} title="Points actuels" value={fetchSingleUserResult?.data.currentPoints.points} prefix={<img width={32} height={32} src="/Star.svg" alt="D" />} />
                                </Col>
                                <Col span={12} >
                                    <Statistic valueStyle={{ display: 'flex', justifyContent: 'flex-start' }} title="Cœurs actuels" value={fetchSingleUserResult?.data.currentHearts} prefix={<img width={32} height={32} src="/Heart.svg" alt="P" />} />
                                </Col>


                            </Row>
                            <Divider />
                            <Row  >
                                <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>



                                    <Title level={5} style={{ marginTop: "0px" }}>
                                        Statistiques
                                    </Title>
                                </Col>
                            </Row>
                            <Row justify={"start"} style={{ width: "100%" }}>
                                <Descriptions
                                    column={1}
                                    contentStyle={{ width: "100%", justifyContent: "flex-end" }}
                                    labelStyle={{ color: "black" }}
                                >

                                    <Descriptions.Item span={24} label="Attaque">{fetchUserStatsResult?.stats.attaque}</Descriptions.Item>
                                    <Descriptions.Item span={24} label="Défense">{fetchUserStatsResult?.stats.defense}</Descriptions.Item>
                                    <Descriptions.Item span={24} label="Intelligence">{fetchUserStatsResult?.stats.intelligence}</Descriptions.Item>
                                    <Descriptions.Item span={24} label="Technique">{fetchUserStatsResult?.stats.technique}</Descriptions.Item>
                                    <Descriptions.Item span={24} label="Vitesse">{fetchUserStatsResult?.stats.vitesse}</Descriptions.Item>
                                </Descriptions>


                            </Row>

                        </div>

                    </Card>
                </Col>
                <Col span={15}>
                    <Tabs style={{ backgroundColor: 'white', minHeight: "500px", paddingLeft: "24px" }} defaultActiveKey="transactions" items={items} />
                </Col>
            </Row>
        </>
    );
}

export default SingleUser;