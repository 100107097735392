import { createSlice } from "@reduxjs/toolkit";

import { addExerciceQuestionsWithPropositionsUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";

export const AddNonCommonQstSlice = createSlice({
    name: "addNonCommonQst",
    initialState: {

        addNonCommonQstResult: null,
        addNonCommonQstError: null,
        isAddingNonCommonQst: false,
    },
    reducers: {
        setAddNonCommonQstResult: (state, action) => {
            state.addNonCommonQstResult = action.payload;
            state.addNonCommonQstError = null;
            state.isAddingNonCommonQst = false;
        },
        setAddNonCommonQstError: (state, action) => {
            state.addNonCommonQstError = action.payload;
            state.isAddingNonCommonQst = false;
            state.addNonCommonQstResult = null

        },
        setIsAddingNonCommonQst: (state, action) => {
            state.isAddingNonCommonQst = action.payload;
        },
    },
});

export const {
    setAddNonCommonQstResult,
    setAddNonCommonQstError,
    setIsAddingNonCommonQst,

} = AddNonCommonQstSlice.actions;


export const CreateNonCommonQst = (data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: addExerciceQuestionsWithPropositionsUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsAddingNonCommonQst(true));
    const response = axios(config)
        .then(response => {

            dispatch(setAddNonCommonQstResult(response.data));
        })
        .catch((error) => {
            message.error(error.response.data.message)
            dispatch(setAddNonCommonQstError(error.response.data.message));

        });

    return response.data;

}
// export const editLesson = (data) => async (dispatch, getState) => {

//     const config = {
//         method: "post",
//         url: updateLessonUrl,
//         headers: {
//             "Content-Type": "application/json",
//             Authorization: localStorage.token,
//         },
//         data: data,
//     };

//     const response = await axios(config)
//         .then((response) => {
//             message.success(response.data.message)
//         })
//         .catch((error) => {
//             console.log("can't edit")
//             message.error(error.response.data.message)

//             // dispatch( setmessage({name:'error',message :response.message}))
//         });
//     return response;
// };

// export const AddnewLesson = (data) => async (dispatch, getState) => {

//     const config = {
//         method: "post",
//         url: addLessonUrl,
//         headers: {
//             "Content-Type": "application/json",
//             Authorization: localStorage.token,
//         },
//         data,
//     };
//     console.log("add Lesson ", data);

//     const response = await axios(config)
//         .then((response) => {
//             dispatch(setAddNonCommonQstError(response.data));

//             message.success(response.data.message)

//         })
//         .catch((error) => {
//             message.error(error.response.data.message)
//         });
//     return response;
// };

export default AddNonCommonQstSlice.reducer;