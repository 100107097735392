import React, { useEffect, useState } from 'react';
import {

    Col,
    Button,
    Divider,
    Row,
    Typography,
    Input,
    Drawer,
    Layout,
    Form,

} from "antd";
import { Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleDefi, UpdateDefi } from "./singleDefiSlice";
import {
    CloseCircleOutlined,
    CheckCircleTwoTone,
    LoadingOutlined,
} from "@ant-design/icons";


const { Text } = Typography;

const SingleDefi = ({ defiID, openDrawer, onClose }) => {
    const dispatch = useDispatch();
    const [state, setstate] = useState({
        switcher: false,
        disable: false,
        editSingleDefi: false,
    });
    const [edit, setEdit] = useState({
        id: null,
        question: "",

    });
    const isUpdatingDefi = useSelector((state) => state.singleDefi?.isUpdatingDefi);
    const fetchUpdateDefiResult = useSelector((state) => state.singleDefi?.fetchUpdateDefiResult);


    const fetchSingleDefiResult = useSelector((state) => state.singleDefi?.fetchSingleDefiResult);
    const isFetchingSingleDefi = useSelector((state) => state.singleDefi?.isFetchingSingleDefi);

    useEffect(() => {
        if (defiID) {
            dispatch(fetchSingleDefi({ id: defiID }));
            setEdit({ ...edit, id: defiID })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defiID]);



    const handelUpdateInfo = async () => {
        let edita = { ...edit }
        edita.question = edit.question.trim()
        console.log('...edit ', edita);

        dispatch(UpdateDefi({ id: defiID, ...edita }))
    };
    // const onClose = () => {
    //   setOpen(false);
    // };
    const { Content } = Layout;



    useEffect(() => {
        if (fetchUpdateDefiResult && defiID) {
            dispatch(fetchSingleDefi({ id: defiID }));

            setstate({
                ...state,
                switcher: false,
                disable: false,
            });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps, no-use-before-define
    }, [fetchUpdateDefiResult]);
    return (
        <Drawer title="Defi" placement="right" size='large' onClose={onClose} open={openDrawer} closable={false} maskClosable={!state.switcher}>
            <Content className="site-layout-background" style={{ padding: 24, margin: 0, minHeight: 280 }} >
                <Row gutter={[25, 8]} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{
                        background: "white", height: "100%", minHeight: "30vh", padding: 10
                    }} >
                        {!isFetchingSingleDefi && fetchSingleDefiResult ? (
                            <>
                                <Switch
                                    checkedChildren="Edit"
                                    unCheckedChildren="Edit"
                                    disabled={state.disable}
                                    checked={state.switcher}
                                    style={{ marginLeft: "90%", marginBottom: "24px" }}
                                    onChange={(e) => {

                                        setstate({
                                            ...state,
                                            switcher: true,
                                            disable: true,
                                            editSingleDefi: true,
                                        });
                                        setEdit({
                                            ...edit,
                                            question: fetchSingleDefiResult?.defiQuestion.question.join(" "),
                                            difficulty: fetchSingleDefiResult?.defiQuestion.difficulty,

                                        });
                                    }}
                                />

                                <Content>
                                    {
                                        <div>
                                            <Form
                                                labelCol={{ span: 4 }}
                                                wrapperCol={{ span: 18 }}
                                                layout="horizontal"
                                                // style={{ backgroundColor: "red" }}
                                                labelAlign="left"
                                            >

                                                <Form.Item label="La question"

                                                >
                                                    {state.switcher ?
                                                        <Input.TextArea
                                                            autoSize
                                                            value={edit.question}
                                                            onChange={(e) => {
                                                                setEdit({
                                                                    ...edit,
                                                                    question: (e.target.value),
                                                                });
                                                            }}
                                                        />
                                                        :
                                                        <Text style={{ color: 'GrayText' }}>
                                                            {fetchSingleDefiResult.defiQuestion?.question.join(" ")}
                                                        </Text>}

                                                </Form.Item>







                                            </Form>
                                            {state.disable ? (
                                                <Divider>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            marginTop: 33,
                                                        }}
                                                    >
                                                        <Button
                                                            shape="round"
                                                            type="dashed"
                                                            onClick={handelUpdateInfo}
                                                            style={{
                                                                margin: "5px",
                                                                border: "1px solid #4CAF50",
                                                            }}
                                                            disabled={edit.question === fetchSingleDefiResult?.defiQuestion.question.join(" ")}
                                                            loading={isUpdatingDefi}
                                                        >
                                                            Update
                                                            <CheckCircleTwoTone twoToneColor="#52c41a" />
                                                        </Button>
                                                        <Button
                                                            style={{ margin: "5px" }}
                                                            shape="round"
                                                            danger
                                                            onClick={() => {
                                                                setEdit({ ...edit });
                                                                setstate({
                                                                    ...state,
                                                                    switcher: false,
                                                                    disable: false,
                                                                    editSingleDefi: false,
                                                                });
                                                            }}
                                                        >
                                                            Cancel
                                                            <CloseCircleOutlined twoToneColor="#eb2f96" />
                                                        </Button>
                                                    </div>
                                                </Divider>
                                            ) : null}
                                        </div>
                                    }
                                </Content>
                            </>
                        ) : (
                            <div style={{ height: "80vh" }}>
                                <LoadingOutlined
                                    twoToneColor="#eb2f96"
                                    style={{ fontSize: 24, marginLeft: "50%", marginTop: "50%" }}
                                    spin
                                />
                            </div>
                        )}
                    </Col>

                    <>
                        <Divider type="vertical" style={{
                            height: "100%",
                            minHeight: "30vh",

                        }} />
                    </>
                </Row>
            </Content >
        </Drawer>

    );
};
export default SingleDefi;