import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Select, DatePicker, Button, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { CheckDuplicatedContract, CreateContract, setCreateContractSuccess } from '../features/cactuspro/addContractSlice';
import { fetchContracts } from '../features/cactuspro/contractListSlice';
// import { CreateContract } from './path-to-your-slice';

const { RangePicker } = DatePicker;

const ContractFormModal = ({ visible, onClose }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [clientProList, setClientProList] = useState([]);
    const [fileList, setFileList] = useState([]);

    const expectedHeaders = [
        "Nom de l’établissement",
        "Type de l’établissement",
        "Nom du referant",
        "Adresse mail",
        "Numéro de téléphone",
        "Mot de Passe",
        "Nombre de code d’accès attribués"
    ];

    const establishmentTypes = [
        {
            value: 'school',
            label: 'École',
        },
        {
            value: 'association',
            label: 'Association',
        },
        {
            value: 'library',
            label: 'Bibliothèque',
        },
        {
            value: 'footballTeam',
            label: 'Équipe de football',
        },
        {
            value: 'company',
            label: 'Entreprise',
        },
        {
            value: 'townHall',
            label: 'Mairie',
        },
        {
            value: 'academy',
            label: 'Académie',
        },
        {
            value: 'federation',
            label: 'Fédération',
        },
    ]

    const typeMapping = {
        "École": "school",
        "Association": "association",
        "Entreprise": "company",
        "Mairie": "townHall",
        "Équipe de football": "footballTeam",
        "Académie": "academy",
        "Fédération": "federation"
    };
    const checkExistingContractSuccess = useSelector((state) => state.addContract.checkExistingContractSuccess);
    const createContractSuccess = useSelector((state) => state.addContract.createContractSuccess);
    const isCreatingContract = useSelector((state) => state.addContract.isCreatingContract);
    const isCheckingContract = useSelector((state) => state.addContract.isCheckingContract);

    useEffect(() => {
        if (!checkExistingContractSuccess) {
            return;
        }
        if (checkExistingContractSuccess) {
            // dispatch(CreateContract(formattedValues));
            // onClose();
            form.validateFields()
                .then(values => {
                    const formattedValues = {
                        name: values.name,
                        startDate: values.dateRange[0].format('YYYY-MM-DD'),
                        endDate: values.dateRange[1].format('YYYY-MM-DD'),
                        establishmentType: values.establishmentType,
                        contractType: "grouped",
                        clientProList: clientProList.map(client => ({
                            name: client.name,
                            email: client.email,
                            phoneNumber: client.phoneNumber,
                            type: client.type,
                            status: client.status,
                            ReferentName: client.ReferentName,
                            password: client.password,
                            numberOfActivationCodes: client.numberOfActivationCodes,
                        })),
                    };
                    // dispatch the action
                    // const dataTosend = { ...contractData, clientProList: [{ ...clientProData }] }
                    // dispatch(CreateContract(dataTosend));
                    dispatch(CreateContract(formattedValues));


                })
                .catch(info => {
                    console.log('Validate Failed:', info);
                });
        }
    }, [checkExistingContractSuccess])
    const handleFileUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
            const headers = jsonData[0];

            const isValid = expectedHeaders.every(header => headers.includes(header));
            if (!isValid) {
                message.error('Les en-têtes du fichier sont incorrects. Veuillez vérifier que les en-têtes sont conformes.');
                return;
            }

            const dataRows = XLSX.utils.sheet_to_json(firstSheet);

            const emails = new Set();
            const mappedData = [];

            for (let client of dataRows) {
                const email = client['Adresse mail'];
                if (emails.has(email)) {
                    message.error('Le fichier contient des emails en double. Veuillez vous assurer que chaque email est unique.');
                    return;
                }
                emails.add(email);
                mappedData.push({
                    name: client['Nom de l’établissement'],
                    ReferentName: client['Nom du referant'],
                    email: client['Adresse mail'],
                    phoneNumber: client['Numéro de téléphone'],
                    type: typeMapping[client["Type de l’établissement"]] || client["Type de l’établissement"],
                    status: 'active', // Assuming all clients have the status 'active'
                    password: client['Mot de Passe'],
                    numberOfActivationCodes: client['Nombre de code d’accès attribués']
                });
            }

            setClientProList(mappedData);
            setFileList([file]);
            message.success('Fichier téléchargé et traité avec succès !');
        };
        reader.onerror = (error) => {
            message.error('Échec du téléchargement du fichier !');
        };
        reader.readAsArrayBuffer(file);

        // Prevent upload
        return false;
    };

    useEffect(() => {
        if (!createContractSuccess) {
            return;
        }
        Modal.success({
            title: "Succès",
            content: "Contract ajouté avec succès",
            onOk() {
                dispatch(setCreateContractSuccess(null));
                dispatch(fetchContracts({
                    name: "",
                    establishmentType: null,
                    contractType: 'grouped',
                    pageLength: 10,
                    page: 1,

                }));
                onClose()
            },
        })
    }, [createContractSuccess])


    const handleFileRemove = () => {
        setFileList([]);
        setClientProList([]);
        message.success('Fichier supprimé et données effacées.');
    };

    const handleOk = () => {

        form.validateFields()
            .then(values => {
                const formattedValues = {
                    name: values.name,
                    startDate: values.dateRange[0].format('YYYY-MM-DD'),
                    endDate: values.dateRange[1].format('YYYY-MM-DD'),
                    establishmentType: values.establishmentType,
                    contractType: "grouped",
                    clientProList: clientProList.map(client => ({
                        name: client.name,
                        email: client.email,
                        phoneNumber: client.phoneNumber,
                        type: client.type,
                        status: client.status,
                        ReferentName: client.ReferentName,
                        password: client.password,
                        numberOfActivationCodes: client.numberOfActivationCodes,
                    })),
                };
                // dispatch the action
                // const dataTosend = { ...contractData, clientProList: [{ ...clientProData }] }
                // dispatch(CreateContract(dataTosend));
                dispatch(CheckDuplicatedContract(formattedValues));


            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };

    return (
        <Modal
            title="Create Contract"
            open={visible}
            onOk={handleOk}
            maskClosable={false}
            closable={false}
            onCancel={onClose}
            footer={[
                <Button key="back" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk} loading={isCreatingContract || isCheckingContract} disabled={clientProList.length < 1}>
                    Create
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                name="contractForm"
            >
                <Form.Item
                    name="name"
                    label="Nom"
                    rules={[{ required: true, message: 'Veuillez saisir le nom !' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="establishmentType"
                    label="Type"
                    rules={[{ required: true, message: 'Veuillez sélectionner le type d\'établissement !' }]}
                >
                    <Select
                        // style={{ width: 100 }}
                        options={establishmentTypes}
                        defaultValue={"school"}
                    />
                </Form.Item>

                <Form.Item
                    name="dateRange"
                    label="date de début/fin du contrat"
                    rules={[{ required: true, message: 'Veuillez sélectionner la période !' }]}
                >
                    <RangePicker />
                </Form.Item>
                <Form.Item
                    label="Télécharger la liste ClientPro"
                    rules={[{ required: true, message: 'Veuillez téléchargez la liste ClientPro ' }]}

                >
                    <Upload
                        fileList={fileList}
                        beforeUpload={handleFileUpload}
                        onRemove={handleFileRemove}
                        accept=".xlsx, .xls"
                        showUploadList={{ showRemoveIcon: true }}
                    >
                        <Button icon={<UploadOutlined />}
                            disabled={fileList.length > 0 || clientProList.length > 0}
                        >Télécharger le fichier Excel</Button>
                    </Upload>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ContractFormModal;
