

import { Button, Card, Form, Input, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';



const gridStyle = {
    width: '15%',
    textAlign: 'center',
    padding: '8px',
    border: "0px"

};
const PrposExo = ({ switcherEdit, setExerciceForm, exerciceForm }) => {

    // const dispatch = useDispatch();

    const propositions = JSON.parse(JSON.stringify(exerciceForm.propositions))

    // const dispatch = useDispatch();
    // useEffect(() => {
    //     if (exerciceForm) {

    //         setPropositions(exerciceForm.propositions);


    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [exerciceForm]);


    return (
        < >
            <Card title="Prpositions : "
                headStyle={{ padding: "0px" }}
                bodyStyle={{ border: "0px" }}
                style={{ marginTop: "24px" }} bordered={false}>
                {!switcherEdit ?

                    exerciceForm.propositions.map((prop, index) =>
                        <Card.Grid key={index} style={gridStyle}>{prop}</Card.Grid>)

                    :
                    exerciceForm ?
                        <Form name="dynamic_form_item"
                        >
                            <Space size={[12, 0]} wrap>
                                <Form.Item>

                                    <Button type="primary" onClick={() => {
                                        let propsE = [...exerciceForm.propositions]
                                        propsE.push('')
                                        setExerciceForm({ ...exerciceForm, propositions: propsE })
                                    }} block icon={<PlusOutlined />}>
                                        Ajouter une proposition
                                    </Button>
                                </Form.Item>
                                {propositions.map((item, index) => (
                                    <Space
                                        key={index}
                                        style={{
                                            display: 'flex',
                                        }}
                                        align="baseline"
                                    >

                                        <Form.Item
                                            name={[index, 'last']}
                                             
                                        >
                                            <Input
                                                defaultValue={item}
                                                value={exerciceForm?.propositions[index]}
                                                onChange={(e) => {
                                                    let propsE = [...exerciceForm.propositions]
                                                    propsE[index] = (e.target.value).trim()
                                                    setExerciceForm({ ...exerciceForm, propositions: propsE })
                                                }} />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => {
                                            // remove(index)
                                            let propsE = [...exerciceForm.propositions]
                                            propsE.splice(index, 1)
                                            setExerciceForm({ ...exerciceForm, propositions: propsE })

                                        }
                                        } />

                                    </Space>
                                ))}

                            </Space>


                        </Form>
                        : null
                }

            </Card>

        </>
    );
}

export default PrposExo;