import { createSlice } from "@reduxjs/toolkit";

import { getPromoCodesUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";
export const PromoCodesSlice = createSlice({
    name: "pormoCodes",
    initialState: {

        fetchPromoCodesResult: null,
        fetchPromoCodesError: null,
        isFetchingPromoCodes: false,
    },
    reducers: {

        setFetchPromoCodesResult: (state, action) => {
            state.fetchPromoCodesResult = action.payload;
            state.isFetchingPromoCodes = false;
        },

        setFetchPromoCodesError: (state, action) => {
            state.fetchPromoCodesError = action.payload;
            state.isFetchingPromoCodes = false;
            state.fetchPromoCodesResult = null

        },
        setIsFetchingPromoCodes: (state, action) => {
            state.isFetchingPromoCodes = action.payload;
        },
    },
});

export const {

    setFetchPromoCodesResult,
    setFetchPromoCodesError,
    setIsFetchingPromoCodes,

} = PromoCodesSlice.actions;


export const fetchPromoCodes = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getPromoCodesUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsFetchingPromoCodes(true));
    const response = axios(config)
        .then(response => {

            dispatch(setFetchPromoCodesResult(response.data));
        })
        .catch((error) => {
            console.log(error);
            message.error(error.response.data.message)
            dispatch(setFetchPromoCodesError(response.message));

        });

    return response.data;

}

export default PromoCodesSlice.reducer;