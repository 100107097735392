

import { Card, Col, Descriptions, Progress, Row, } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchmodulesAchivemntPerenctage } from './modulesAchvSlice';

import "./stats.css"

const ModulesAchv = () => {
    const dispatch = useDispatch();

    const fetchModulesAchvDataResult = useSelector((state) => state.modulesAchv.fetchModulesAchvDataResult);
    const isFetchingModulesAchvData = useSelector((state) => state.modulesAchv.isFetchingModulesAchvData);

    useEffect(() => {
        dispatch(fetchmodulesAchivemntPerenctage());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (

        <Card
            title="Taux d'Achèvement Global des Modules"
            style={{ backgroundColor: "white", cursor: "default", boxShadow: "none" }}
            bordered={false}
            bodyStyle={{ paddingBottom: "0" }}
            hoverable
            loading={isFetchingModulesAchvData}

        >

            <Row
                justify={"start"}
                gutter={[46, 12]}
            >
                {
                    fetchModulesAchvDataResult?.map((item, index) => (
                        <>
                            <Col span={6}>
                                <Descriptions >
                                    <Descriptions.Item label={"Module " + item.seq}>
                                        <Progress percent={Math.round(item.completionRate)} />
                                    </Descriptions.Item>

                                </Descriptions>
                            </Col>
                        </>

                    ))
                }
            </Row>






        </Card>
    );
}

export default ModulesAchv;