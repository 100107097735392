import React, { useEffect, useState } from 'react';
import {

  Col,
  Button,
  Divider,
  Row,
  Typography,
  Input,
  Select,
  Drawer,
  Layout,
  Form,

} from "antd";
import { Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchSinglAdmin, editlAdmin } from "./singleAdminSlice";
import {
  CloseCircleOutlined,
  CheckCircleTwoTone,
  LoadingOutlined,
} from "@ant-design/icons";

import moment from "moment";
import { fetchAdmins } from '../admins/adminsSlice';

const { Text } = Typography;
const { Option } = Select;

const SingleAdmin = ({ adminID, openDrawer, onClose }) => {
  const dispatch = useDispatch();
  const [state, setstate] = useState({
    switcher: false,
    disable: false,
    editSingleAdmin: false,
  });
  const [edit, setEdit] = useState({
    id: null,
    email: "",
    type: "",
  });
  useEffect(() => {
    if (adminID) {
      dispatch(fetchSinglAdmin({ id: adminID }));
      setEdit({ ...edit, id: adminID })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminID]);


  const handelUpdateInfo = async () => {

    dispatch(editlAdmin({ admin_id: adminID, edit })).then((res) => {
      dispatch(fetchSinglAdmin({ id: adminID }));

      setstate({
        ...state,
        switcher: false,
        disable: false,
        editSingleVariant: false,
      });
    });
    dispatch(fetchAdmins({  }));

  };
  // const onClose = () => {
  //   setOpen(false);
  // };
  const { Content } = Layout;

  const fetchAdminResult = useSelector((state) => state.admin?.fetchAdminResult);
  const isfetchingAdmin = useSelector((state) => state.admin?.isfetchingAdmin);
  return (
    <Drawer title="Admin" placement="right" onClose={onClose} open={openDrawer} closable={false} maskClosable={!state.switcher}>
      <Content className="site-layout-background" style={{ padding: 24, margin: 0, minHeight: 280 }} >
        <Row gutter={[25, 8]} align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{
            background: "white", height: "100%", minHeight: "30vh", padding: 10
          }} >
            {!isfetchingAdmin && fetchAdminResult ? (
              <>
                <Switch
                  checkedChildren="Edit"
                  unCheckedChildren="Edit"
                  disabled={state.disable}
                  checked={state.switcher}
                  style={{ marginLeft: "90%", marginBottom: "24px" }}
                  onChange={(e) => {

                    setstate({
                      ...state,
                      switcher: true,
                      disable: true,
                      editSingleAdmin: true,
                    });
                    setEdit({
                      ...edit,
                      email: fetchAdminResult?.data.email,
                      type: fetchAdminResult?.data.type,
                      status: fetchAdminResult?.data.status,

                    });
                  }}
                />

                <Content>
                  {
                    <div>
                      <Form
                        labelCol={{ span: 10 }}
                        wrapperCol={{ span: 14 }}
                        layout="horizontal"
                      >

                        <Form.Item label="Adress Mail">
                          {state.switcher ?
                            <Input
                              value={edit.email}
                              onChange={(e) => {
                                setEdit({
                                  ...edit,
                                  email: e.target.value,
                                });
                              }}
                            />
                            :
                            <Text style={{ color: 'GrayText' }}>
                              {fetchAdminResult.data?.email}
                            </Text>}

                        </Form.Item>



                        {state.switcher ? (
                          <Form.Item label="Type">
                            <Select
                              defaultValue={edit.type}
                              options={[
                                {
                                  value: 'super_admin',
                                  label: 'Super admin',
                                },
                                {
                                  value: 'admin',
                                  label: 'Normal admin',
                                },

                              ]}
                              onSelect={(value) => {

                                setEdit({
                                  ...edit,
                                  type: value,
                                });

                              }}
                            />
                          </Form.Item>
                        ) : fetchAdminResult?.data?.type ? (
                          <Form.Item label="Type">
                            <Text style={{ color: 'GrayText' }}>
                              {fetchAdminResult.data?.type}
                            </Text>
                          </Form.Item>
                        ) : null}
                        <Form.Item label="Etat">
                          {state.switcher ? (
                            <Select defaultValue={fetchAdminResult.data?.status}
                              onChange={(e) => {
                                setEdit({
                                  ...edit,
                                  status: e,
                                });
                              }}>
                              <Option value="active">Actif</Option>
                              <Option value="blocked">Bloqué</Option>

                            </Select>
                          ) : (
                            <Text style={{ color: 'GrayText' }}>{fetchAdminResult.data?.status}   </Text>
                          )}
                        </Form.Item>

                        <Form.Item label="Date d'inscription">
                          <Text style={{ color: 'GrayText' }}>{moment(fetchAdminResult.data?.createdAt).format("DD-MM-YYYY")}   </Text>
                        </Form.Item>



                      </Form>
                      {state.disable ? (
                        <Divider>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: 33,
                            }}
                          >
                            <Button
                              shape="round"
                              type="dashed"
                              onClick={handelUpdateInfo}
                              style={{
                                margin: "5px",
                                border: "1px solid #4CAF50",
                              }}
                            >
                              Update
                              <CheckCircleTwoTone twoToneColor="#52c41a" />
                            </Button>
                            <Button
                              style={{ margin: "5px" }}
                              shape="round"
                              danger
                              onClick={() => {
                                setEdit({ ...edit });
                                setstate({
                                  ...state,
                                  switcher: false,
                                  disable: false,
                                  editSingleAdmin: false,
                                });
                              }}
                            >
                              Cancel
                              <CloseCircleOutlined twoToneColor="#eb2f96" />
                            </Button>
                          </div>
                        </Divider>
                      ) : null}
                    </div>
                  }
                </Content>
              </>
            ) : (
              <div style={{ height: "80vh" }}>
                <LoadingOutlined
                  twoToneColor="#eb2f96"
                  style={{ fontSize: 24, marginLeft: "50%", marginTop: "50%" }}
                  spin
                />
              </div>
            )}
          </Col>

          <>
            <Divider type="vertical" style={{
              height: "100%",
              minHeight: "30vh",

            }} />
          </>
        </Row>
      </Content >
    </Drawer>

  );
};
export default SingleAdmin;