import { createSlice } from "@reduxjs/toolkit";

import { createItemUrl, getItemCategoryUrl, uploadImagesUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";
export const ItemsSlice = createSlice({
    name: "addItem",
    initialState: {

        addItemResult: null,
        addItemError: null,
        isAddingItems: false,

        fetchItemCategoryResult: null,
        fetchItemCategoryError: null,

        fetchUploadItemThumbnailResult: null,
        fetchUploadItemThumbnailError: null,
        isUploadingItemThumbnail: false,
        itemThumbnailUrl: null,

        fetchUploadItemTextureResult: null,
        fetchUploadItemTextureError: null,
        isUploadingItemTexture: false,
        itemTextureUrl: null,

        fetchUploadSpritesResult: null,
        fetchUploadSpritesError: null,
        isFetchingUploadSprites: false,
        spritesUrls: [],

        fetchUploadItemColorMaskResult: null,
        fetchUploadItemColorMaskError: null,
        isUploadingItemColorMask: false,
        itemColorMaskUrl: null,

    },
    reducers: {

        setAddItemResult: (state, action) => {
            state.addItemResult = action.payload;
            state.isAddingItems = false;
        },

        setAddItemError: (state, action) => {
            state.addItemError = action.payload;
            state.isAddingItems = false;
            state.addItemResult = null

        },
        setIsAddingItems: (state, action) => {
            state.isAddingItems = action.payload;
        },

        setFetchItemCategoryResult: (state, action) => {
            state.fetchItemCategoryResult = action.payload;
            state.fetchItemCategoryError = null;
        },

        setFetchItemCategoryError: (state, action) => {
            state.fetchItemCategoryError = action.payload;
            state.fetchItemCategoryResult = null

        },

        setItemThumbnailUrl: (state, action) => {
            state.itemThumbnailUrl = action.payload;
        },
        setUploadingItemThumbnail: (state, action) => {
            if (action.payload) {
                state.fetchUploadItemThumbnailResult = null;
                state.fetchUploadItemThumbnailError = null;
            }
            state.isUploadingItemThumbnail = action.payload;
        },
        setUploadItemThumbnailResult: (state, action) => {
            state.fetchUploadItemThumbnailResult = action.payload;
            state.isUploadingItemThumbnail = false;

        },
        setUploadItemThumbnailError: (state, action) => {
            state.fetchUploadItemThumbnailError = action.payload;
            state.isUploadingItemThumbnail = false;
        },

        setItemTextureUrl: (state, action) => {
            state.itemTextureUrl = action.payload;
        },
        setUploadingItemTexture: (state, action) => {
            if (action.payload) {
                state.fetchUploadItemTextureResult = null;
                state.fetchUploadItemTextureError = null;
            }
            state.isUploadingItemTexture = action.payload;
        },
        setUploadItemTextureResult: (state, action) => {
            state.fetchUploadItemTextureResult = action.payload;
            state.isUploadingItemTexture = false;

        },
        setUploadItemTextureError: (state, action) => {
            state.fetchUploadItemTextureError = action.payload;
            state.isUploadingItemTexture = false;
        },

        setFetchingUploadSprites: (state, action) => {
            if (action.payload) {
                state.fetchUploadSpritesResult = null;
                state.fetchUploadSpritesError = null;
            }
            state.isFetchingUploadSprites = action.payload;
        },
        setUploadSpritesResult: (state, action) => {
            state.fetchUploadSpritesResult = action.payload;
            state.isFetchingUploadSprites = false;

        },
        setUploadSpritesError: (state, action) => {
            state.fetchUploadSpritesError = action.payload;
            state.isFetchingUploadSprites = false;
        },

        setSpritesLinks: (state, action) => {
            state.spritesUrls.push(action.payload);
        },
        setSpritesLinksALL: (state, action) => {
            state.spritesUrls = action.payload;
        },

        setItemColorMaskUrl: (state, action) => {
            state.itemColorMaskUrl = action.payload;
        },
        setUploadingItemColorMask: (state, action) => {
            if (action.payload) {
                state.fetchUploadItemColorMaskResult = null;
                state.fetchUploadItemColorMaskError = null;
            }
            state.isUploadingItemColorMask = action.payload;
        },
        setUploadItemColorMaskResult: (state, action) => {
            state.fetchUploadItemColorMaskResult = action.payload;
            state.isUploadingItemColorMask = false;

        },
        setUploadItemColorMaskError: (state, action) => {
            state.fetchUploadItemColorMaskError = action.payload;
            state.isUploadingItemColorMask = false;
        },

    },
});

export const {

    setAddItemResult,
    setAddItemError,
    setIsAddingItems,

    setFetchItemCategoryResult,
    setFetchItemCategoryError,

    setUploadingItemThumbnail,
    setUploadItemThumbnailResult,
    setUploadItemThumbnailError,
    setItemThumbnailUrl,

    setUploadingItemTexture,
    setUploadItemTextureResult,
    setUploadItemTextureError,
    setItemTextureUrl,

    setFetchingUploadSprites,
    setUploadSpritesResult,
    setUploadSpritesError,
    setSpritesLinks,
    setSpritesLinksALL,

    setUploadingItemColorMask,
    setUploadItemColorMaskResult,
    setUploadItemColorMaskError,
    setItemColorMaskUrl,

} = ItemsSlice.actions;


export const addItem = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: createItemUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsAddingItems(true));
    const response = axios(config)
        .then(response => {
            message.success(response.data.message)

            dispatch(setAddItemResult(response.data));
        })
        .catch((error) => {
            console.log(error);
            message.error(error.response.data.message)
            dispatch(setAddItemError(response.message));

        });

    return response.data;

}

export const fetchItemCategory = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getItemCategoryUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    const response = axios(config)
        .then(response => {
            dispatch(setFetchItemCategoryResult(response.data));
        })
        .catch((error) => {
            console.log(error);
            message.error(error.response.data.message)
            dispatch(setFetchItemCategoryError(error.response.data.message));

        });

    return response.data;

}

export const fetchUploadItemThumbnail = (data, file) => (dispatch, getState) => {
    dispatch(setUploadingItemThumbnail(true));
    let formData = new FormData();
    formData.append("image", file);
    formData.append("folder_path", data.folder_path);

    let url = uploadImagesUrl;
    const response = axios
        .post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: localStorage.token,
            },
        })
        .then((response) => {
            dispatch(setUploadItemThumbnailResult(response.data));
            dispatch(setItemThumbnailUrl(response.data.img_url));
            // console.log("upload image response ", response.data);
        })
        .catch((response) => {
            dispatch(setUploadItemThumbnailError(response.response.data.message));
            message.error(response.response.data.message);
        });
    // console.log("blablabla", response);
    return response.data;
};
export const fetchUploadItemTexture = (data, file) => (dispatch, getState) => {
    dispatch(setUploadingItemTexture(true));
    let formData = new FormData();
    formData.append("image", file);
    formData.append("folder_path", data.folder_path);

    let url = uploadImagesUrl;
    const response = axios
        .post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: localStorage.token,
            },
        })
        .then((response) => {
            dispatch(setUploadItemTextureResult(response.data));
            dispatch(setItemTextureUrl(response.data.img_url));
            // console.log("upload image response ", response.data);
        })
        .catch((response) => {
            dispatch(setUploadItemTextureError(response.response.data.message));
            message.error(response.response.data.message);
        });
    // console.log("blablabla", response);
    return response.data;
};
export const fetchUploadSprites = (data, file) => (dispatch, getState) => {
    dispatch(setFetchingUploadSprites(true));
    let formData = new FormData();
    formData.append("image", file);
    formData.append("folder_path", data.folder_path);
    let url = uploadImagesUrl;
    const response = axios
        .post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: localStorage.token,
            },
        })
        .then((response) => {
            dispatch(setUploadSpritesResult(response.data));
            dispatch(setSpritesLinks(response.data.img_url));
            // console.log("upload image response ", response.data);
        })
        .catch((error) => {
            if (!error.response) {
                console.log("+++++++", error);

            }
            console.log(error);
            dispatch(setUploadSpritesError(error.response.data.message));
            message.error(error.response.data.message);
        });
    // console.log("blablabla", response);
    return response.data;
};

export const fetchUploadItemColorMask = (data, file) => (dispatch, getState) => {
    dispatch(setUploadingItemColorMask(true));
    let formData = new FormData();
    formData.append("image", file);
    formData.append("folder_path", data.folder_path);

    let url = uploadImagesUrl;
    const response = axios
        .post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: localStorage.token,
            },
        })
        .then((response) => {
            dispatch(setUploadItemColorMaskResult(response.data));
            dispatch(setItemColorMaskUrl(response.data.img_url));
            // console.log("upload image response ", response.data);
        })
        .catch((response) => {
            dispatch(setUploadItemColorMaskError(response.response.data.message));
            message.error(response.response.data.message);
        });
    // console.log("blablabla", response);
    return response.data;
};
export default ItemsSlice.reducer;