import { createSlice } from "@reduxjs/toolkit";

import { getUserSignupStatsUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";

export const SignUpChartSlice = createSlice({
    name: "signupChartData",
    initialState: {
        fetchSignupChartDataResult: null,
        fetchSignupChartDataError: null,
        isFetchingSignupChartData: false,
    },
    reducers: {


        setSignupChartDataResult: (state, action) => {
            state.fetchSignupChartDataResult = action.payload;
            state.fetchSignupChartDataError = null;

            state.isFetchingSignupChartData = false;
        },

        setSignupChartDataError: (state, action) => {
            state.fetchSignupChartDataError = action.payload;
            state.isFetchingSignupChartData = false;
            state.fetchSignupChartDataResult = null

        },
        setIsFetchingSingupChartData: (state, action) => {
            state.isFetchingSignupChartData = action.payload;
        },
    },
});

export const {


    setSignupChartDataResult,
    setIsFetchingSingupChartData,
    setSignupChartDataError,

} = SignUpChartSlice.actions;


export const fetchSingupChartData = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getUserSignupStatsUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data: data
    };
    dispatch(setIsFetchingSingupChartData(true));
    const response = axios(config)
        .then(response => {
            dispatch(setSignupChartDataResult(response.data));
        })
        .catch((error) => {
            message.error(error.response.data.message)
            dispatch(setSignupChartDataError(error.response.data.message));

        });

    return response.data;

}



export default SignUpChartSlice.reducer;