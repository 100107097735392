import { createSlice } from "@reduxjs/toolkit";

import { progressionUsersModuleByLeconUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";

export const ModuleProgressSlice = createSlice({
    name: "moduleProgress",
    initialState: {
        fetchModulesProgressResult: null,
        fetchModulesProgressError: null,
        isFetchingModulesProgress: false,
    },
    reducers: {


        setModulesProgressResult: (state, action) => {
            state.fetchModulesProgressResult = action.payload;
            state.fetchModulesProgressError = null;

            state.isFetchingModulesProgress = false;
        },

        setModulesProgressError: (state, action) => {
            state.fetchModulesProgressError = action.payload;
            state.isFetchingModulesProgress = false;
            state.fetchModulesProgressResult = null

        },
        setIsFetchingSingupChartData: (state, action) => {
            state.isFetchingModulesProgress = action.payload;
        },
    },
});

export const {


    setModulesProgressResult,
    setIsFetchingSingupChartData,
    setModulesProgressError,

} = ModuleProgressSlice.actions;


export const fetchModulesProgress = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: progressionUsersModuleByLeconUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data: data
    };
    dispatch(setIsFetchingSingupChartData(true));
    const response = axios(config)
        .then(response => {
            dispatch(setModulesProgressResult(response.data));
        })
        .catch((error) => {
            message.error(error.response.data.message)
            dispatch(setModulesProgressError(error.response.data.message));

        });

    return response.data;

}



export default ModuleProgressSlice.reducer;