import { Space, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedsubCatItem } from './itemsSlice';
const { CheckableTag } = Tag;
// const tagsData = ['Movies', 'Books', 'Music', 'Sports'];
const SubCat = ({ tagsData }) => {
  const selectedsubCatItem = useSelector((state) => state.items.selectedsubCatItem);
  const dispatch = useDispatch();


  return (
    <>
      <span
        style={{
          marginRight: 8,
          fontWeight: "bold",
          fontSize: "16px"
        }}
      >
        Categories :
      </span>
      <Space size={[0, 8]} wrap>
        {tagsData.map((tag) => (
          <CheckableTag
            style={{ fontSize: "16px", height: "25px" }}
            key={tag.key}
            checked={selectedsubCatItem === tag.key}
            onChange={() => {
              dispatch(setSelectedsubCatItem(tag.key))
            }}
          >
            {tag.label}
          </CheckableTag>
        ))}
      </Space>
    </>
  );
};
export default SubCat;