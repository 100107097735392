import { Row, Col, Typography, Layout, Card, Form, Space, Image, Button, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../comps/pageHeader';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { fetchPacks } from './packsListSlice';
import { deleteSinglePack, setFetchUpdatePackResult, updatePackStatus } from './singlePackSlice';
import AddPack from './addPack';
import SinglePack from './singlePack';
import { setFetchAddPackResult } from './addPackSlice';

const { Text } = Typography;


const { Content } = Layout;

const PacksList = ({ title, breadcrumbItems }) => {
    const dispatch = useDispatch();


    const [visible, setVisible] = useState(false)
    const [openDrawer, setOpenDrawer] = useState(false);
    const [packID, setPackID] = useState(null);



    const fetchPacksResult = useSelector((state) => state.packs.fetchPacksResult);
    const isFetchingPacks = useSelector((state) => state.packs.isFetchingPacks);


    const fetchUpdatePackResult = useSelector((state) => state.singlePack.fetchUpdatePackResult);
    const isUpdatingPack = useSelector((state) => state.singlePack.isUpdatingPack);

    const isDeletingSinglePack = useSelector((state) => state.singlePack.isDeletingSinglePack);
    const deleteSinglePackResult = useSelector((state) => state.singlePack.deleteSinglePackResult);

    const isFetchingAddPack = useSelector((state) => state.addPack.isFetchingAddPack);

    useEffect(() => {
        dispatch(fetchPacks());
        dispatch(setFetchUpdatePackResult(null));
        dispatch(setFetchAddPackResult(null));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchUpdatePackResult, deleteSinglePackResult]);

    const handleSwitchStatus = ({ checked, id }) => {
        let status = checked ? "active" : "inactive"
        dispatch(updatePackStatus({ id, status }));
    };

    const onClose = () => {
        setOpenDrawer(false);
    };

    return (
        <Content
            className="site-layout-background"
            style={{
                padding: 0,
                margin: 0,
                minHeight: 280,
            }}
        >
            <SinglePack openDrawer={openDrawer} onClose={onClose} packID={packID} />

            <AddPack visible={visible} setVisible={setVisible} />
            <PageHeader title={"Organisation du Shop In-App"}
                breadcrumbItems={["Paramètres Shop", "Shop In-App"]} />

            <Row justify={"center"} style={{ background: "#424242", margin: "24px", minHeight: "100px" }}
            >
                <Col span={5} style={{ paddingTop: 10 }}                >
                    <Space align='center' direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <Text style={{ color: "white", fontSize: "16px" }}>Ajouter un Pack</Text>
                        <Button type='primary'
                            onClick={() => { setVisible(true) }}
                            style={{ width: "132px", height: "32px" }}> Nouveau pack</Button>
                    </Space>
                </Col>
            </Row>

            <Row justify={"start"} style={{ margin: "24px", minHeight: "100px" }}  >


                {isFetchingPacks || isFetchingAddPack ?
                    <>
                        {Array.from({ length: 12 }, (_, i) => (

                            <Col className="gutter-row" span={6} style={{ display: 'flex', justifyContent: "center" }}  >
                                <Card

                                    style={{
                                        width: "278px",
                                    }}
                                    bodyStyle={{
                                        height: "140px"

                                    }}
                                    loading
                                    className="itemsList"

                                    actions={[<EditOutlined style={{ fontSize: "21px" }} />,
                                    ]}
                                />

                            </Col>

                        ))}
                    </>
                    :

                    fetchPacksResult?.data.map((item, index) => {
                        return (<>
                            <Col className="gutter-row" span={5} style={{ display: 'flex', justifyContent: "center", marginRight: "24px", marginBottom: "24px" }}  >

                                <Card
                                    // hoverable
                                    key={index}
                                    title={item.name}
                                    style={{
                                        width: "278px",
                                    }}
                                    bodyStyle={{
                                        height: "140px"

                                    }}
                                    loading={isDeletingSinglePack}
                                    className="itemsList"
                                    // headStyle={{
                                    //     border:"3px solid ",
                                    //     borderColor: "#0250ff"

                                    // }}
                                    actions={[<EditOutlined style={{ fontSize: "21px" }}
                                        onClick={() => {
                                            setOpenDrawer(true)
                                            setPackID(item._id)
                                        }}
                                    />,
                                    <DeleteOutlined style={{ fontSize: "21px" }}
                                        onClick={() => {
                                            dispatch(deleteSinglePack({ id: item._id }))
                                        }}
                                    />

                                    ]}
                                    extra={<Switch
                                        loading={isUpdatingPack}
                                        checked={item.status === "active"}
                                        onChange={(checked) => handleSwitchStatus({ checked, id: item._id })} />}

                                >
                                    <Form>
                                        <Form.Item style={{ marginBottom: "0px" }} label="Quantité">
                                            <Text>
                                                {item.amount}
                                            </Text>
                                        </Form.Item>
                                        <Form.Item style={{ marginBottom: "0px" }} label="Coût">
                                            <Space align='center'>
                                                <Text> {item.price} </Text>
                                                {item.currency === "money" ? <Text>€</Text> :
                                                    <Image src={item.currency === "coins" ? "/Coin.svg" : "/Diamond.svg"} width={16} height={16} alt="coin" preview={false} />
                                                }

                                            </Space>
                                        </Form.Item>
                                        <Form.Item style={{ marginBottom: "0px" }} label="Soldes :">

                                            <Text> {(item.saleRate * 100)} % </Text>

                                        </Form.Item>
                                    </Form>

                                </Card>

                            </Col>


                        </>)
                    }

                    )
                }

            </Row>

        </Content>
    );
}

export default PacksList;