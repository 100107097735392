import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Row, Col, Typography, ConfigProvider, Checkbox } from "antd";
import { fetchSignin } from "./signInSlice";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Navigate, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

const { Text } = Typography;

const Signin = () => {
  let navigate = useNavigate();

  const [formData, setFormData] = useState({ email: "", password: "", rememberMe: false });
  const dispatch = useDispatch();
  const redirectToDashboard = useSelector((state) => state.signIn.redirectToDashboard);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwt_decode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        localStorage.removeItem("token");
        // Token has expired
      } else {
        navigate("/dashboard/admins/panel/list")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handlerememberMeChanege = (event) => {
    setFormData({ ...formData, rememberMe: !formData.rememberMe });
  };
  const handleFormSubmit = (event) => {
    dispatch(fetchSignin(formData));
  };
  if (redirectToDashboard) return <Navigate to="/dashboard/admins/panel/list" />;



  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#39B13D",
          borderRadius: "2px",
        },
      }}
    >
      <div style={{ backgroundColor: "#F5F5F5", minHeight: "100vh", padding: "24px" }}>
        <Row justify="center" align="middle" >
          <Col span={12}>
            <Row justify={"center"} style={{ marginBottom: "24px", marginTop: "127px" }}>
              <Col span={8} >
                <img
                  src="/logo3.png"
                  alt="logo"
                  style={{ height: "100px", width: "177px", display: "block", margin: "auto", }}
                />
              </Col>
            </Row>
            <Row style={{ textAlign: "center", marginBottom: "24px" }}>
              <Col span={24} >
                <Text style={{ fontSize: "14px", fontWeight: 400, color: "rgba(0,0,0,0.45)", display: "block", margin: "auto" }}>Bienvenue dans l’interface de Cactus, veuillez introduire vos identifiants pour continuer.</Text>
              </Col>
            </Row>
            <Row justify="center" >


              <Col span={12} >
                <Form onFinish={handleFormSubmit}
                  style={{ display: "block", margin: "auto" }}

                >
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: "Veuillez saisir votre adresse e-mail" },
                    ]}
                  >
                    <Input
                      // style={{ width: 360 }}
                      prefix={<UserOutlined style={{ color: "#39B13D" }} className="site-form-item-icon" />}
                      name="email"
                      type="email"
                      placeholder="Email address"
                      onChange={handleInputChange}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: "Veuillez entrer votre mot de passe" }]}
                  >
                    <Input.Password
                      prefix={<LockOutlined style={{ color: "#39B13D" }} className="site-form-item-icon" />}
                      // style={{ width: 360 }}

                      name="password"
                      type="password"
                      placeholder="Password"
                      onChange={handleInputChange}
                    />
                  </Form.Item>
                  <Form.Item
                    name="remember"
                    checked={formData.rememberMe}
                    onChange={handlerememberMeChanege}


                  >
                    <Checkbox>Se souvenir de moi</Checkbox>
                  </Form.Item>
                  <Form.Item>

                    <Button type="primary" htmlType="submit"
                      style={{ display: "block", margin: "auto" }}
                    // style={{ height: 40, width: 114 }}
                    >
                      Connexion
                    </Button>

                  </Form.Item>
                </Form>
              </Col>


            </Row>
          </Col>
        </Row>
      </div>
    </ConfigProvider>
  );
};

export default Signin;
