import { Progress, Space, Typography } from 'antd';
import { useParams } from 'react-router-dom';


const { Title } = Typography;



const TotalLecons = ({ total }) => {
    const { seq } = useParams();

    return (
        <Space
            direction="vertical"
            size="middle"
            style={{
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <Title level={5} > Capacité (Leçons)</Title>

            <Progress type="circle" percent={seq === "1" ? (total * 100 / 10) : (total * 100 / 42)}
                format={(percent) => `${total}${seq === "1" ? "/10" : "/42"}`}
                strokeColor='#39B13D'
            />
        </Space>

    );
}

export default TotalLecons;