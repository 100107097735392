

import { Button, Card, Col, Form, Input, message, Radio, Row, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../comps/pageHeader";
import { fetchSingleLesson } from "../lesson/singleLessonSlice";
// import { fetchSelectCategories } from "../categories/categoriesSlice";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quillAdd.css'
// import FormItem from "antd/es/form/FormItem";
import ExerciceQuestions from "./exerciceQuestions";
import { addExercice, setAddExerciceResult } from "./addExerciceSlice";
import { CreateCommonQst, setAddCommonQstResult } from "./addCommonQstSlice";
import '../dashboard/dashboard.css'
let Inline = Quill.import('blots/inline');
class BoldBlot extends Inline { }
BoldBlot.blotName = 'bold';
BoldBlot.tagName = 'b';
Quill.register(BoldBlot);
class ItalicBlot extends Inline { }
ItalicBlot.blotName = 'italic';
ItalicBlot.tagName = 'i';
Quill.register(ItalicBlot);

const modules = {

    toolbar: [
        ['bold', 'italic', 'underline'],
    ],
}
const formats = [

    'bold', 'italic', 'underline'
]

const AddCommunExo = () => {
    const { Text } = Typography

    let navigate = useNavigate();


    const { id, seq, theme } = useParams();
    const dispatch = useDispatch();

    const fetchSingleLessonResult = useSelector((state) => state.lesson.fetchSingleLessonResult);

    const addExerciceResult = useSelector((state) => state.addExo.addExerciceResult);
    // const isAddingExercice = useSelector((state) => state.addExo.isAddingExercice);

    // const isAddingCommonQst = useSelector((state) => state.addCommonQst.isAddingCommonQst);
    const addCommonQstResult = useSelector((state) => state.addCommonQst.addCommonQstResult);

    const fetchThemesResult = useSelector((state) => state.dashboard.fetchThemesResult);


    const [exerciceForm, setExerciceForm] = useState({ statement: "", propositions: [], lesson: id, theme: null });
    const [questionsForm, setQuestionsForm] = useState({ questions: [], moduleSeq: seq, exerciceId: null, questionType: "fill" });


    const [defisCheck, setDefisCheck] = useState(true);
    const [questionType, setQuestionType] = useState("fill");
    const [questionTypeRadio, setQuestionTypeRadio] = useState(true);

    const [displayTheme, setDisplayTheme] = useState("");

    const [errorIndexInput, setErrorIndexInput] = useState([]);
    const [errorIndexSelect, setErrorIndexSelect] = useState([]);

    const regex = /^[^()]*\([^()]+\)[^()]*$/;

    useEffect(() => {
        if (!fetchSingleLessonResult) {

            dispatch(fetchSingleLesson({ id, seq }));

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seq]);
    useEffect(() => {
        if (addExerciceResult) {

            // console.log("hjhjjh", { ...questionsForm, exerciceId: addExerciceResult._id });
            setQuestionsForm({ ...questionsForm, exerciceId: addExerciceResult._id });
            dispatch(CreateCommonQst({ ...questionsForm, exerciceId: addExerciceResult._id }))

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addExerciceResult]);
    useEffect(() => {
        if (theme && fetchThemesResult) {
            const themeID = fetchThemesResult.data.find(themed => themed.type === theme);
            if (themeID) {
                setDisplayTheme(themeID.displayName)
                setExerciceForm({ ...exerciceForm, theme: themeID._id });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [theme, fetchThemesResult]);
    useEffect(() => {
        if (addCommonQstResult) {
            dispatch(setAddCommonQstResult(null))
            navigate(-1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addCommonQstResult]);

    const handleQstTypeChanege = (e) => {
        if (e.target.value === "replace") {
            setDefisCheck(false)
        } else {
            setDefisCheck(true)
        }
        setQuestionType(e.target.value);
        setQuestionsForm({ ...questionsForm, questionType: e.target.value })
    };

    const handleConfirmExercice = (e) => {
        let checkExoSyntax = { ...questionsForm }
        let errorSyntaxIndex = []
        let errorEmptyIndex = []
        let errorSelectIndex = []
        // eslint-disable-next-line array-callback-return
        checkExoSyntax.questions.map((qst, index) => {
            if (qst.content[0].trim() === "") {

                // console.log('qst.content[0].trim()', qst.content[0].trim());
                errorEmptyIndex.push(index + 1)

            } else {

                if (questionType === "fill" && !(/^[^*]*\*[^*]*$/.test(qst.content[0]))) {
                    errorSyntaxIndex.push(index + 1)
                }
                if (questionType === "replace" && !(regex.test(qst.content[0]))) {
                    errorSyntaxIndex.push(index + 1)
                }
                if (!qst.correction) {
                    errorSelectIndex.push(index + 1)
                }
            }
        })
        if (errorSyntaxIndex.length > 0 || errorEmptyIndex.length > 0) {
            if (errorSyntaxIndex.length > 0) {

                if (questionType === "replace") {

                    message.error('Veuillez vérifier les ( )  dans les questions : ' + errorSyntaxIndex.join(" | "))
                } else {
                    message.error('Les questions ' + errorSyntaxIndex.join(" | ") + ' doivent contenir une seule * ')

                }
            }
            if (errorEmptyIndex.length > 0) {

                message.error('Les questions ' + errorEmptyIndex.join(" | ") + ' sont vides ')

            }
            setErrorIndexInput([...new Set([...errorSyntaxIndex, ...errorEmptyIndex])])
        } else {
            setErrorIndexInput([])
            if (errorSelectIndex.length > 0) {
                message.error('Veuillez sélectionner les réponses des questions : ' + errorSelectIndex.join(" | "))
                setErrorIndexSelect(errorSelectIndex)
            }
            else {

                if (addExerciceResult) {
                    setQuestionsForm({ ...questionsForm, exerciceId: addExerciceResult._id });
                    dispatch(CreateCommonQst({ ...questionsForm, exerciceId: addExerciceResult._id }))
                    dispatch(setAddExerciceResult(null))

                } else {

                    dispatch(addExercice(exerciceForm));
                }
            }
        }
    };

    return (
        <>
            {fetchSingleLessonResult ?
                <PageHeader title={"Ajouter L'exercice du thème " + displayTheme}
                    breadcrumbItems={["Création de Contenu", "Modules", "Module " + seq, "leçon " + fetchSingleLessonResult.lesson.seq, "Ajouter un exercice"]} />
                : null}
            <Card title='Ajouter Exercice à Réponses Communes'
                className="CardExo"
                style={{ width: "95%", margin: "24px" }}
                bodyStyle={{ padding: "24px 80px 24px 80px" }}
                actions={[
                    <Button>Annuler</Button>,
                    <Button type="primary" onClick={handleConfirmExercice}>Confirmer</Button>,

                ]}
            >
                <Form layout="horizontal">
                    <Form.Item label="Énoncé : " labelAlign="right"
                        labelCol={{ span: 24, offset: 12 }}
                    >
                        <ReactQuill theme="snow" className='quillSTATMENT'
                            value={exerciceForm.statement}

                            modules={modules}
                            formats={formats}
                            onChange={(value) => {
                                setExerciceForm({
                                    ...exerciceForm,
                                    statement: value,
                                });
                            }}
                        >
                        </ReactQuill>
                    </Form.Item>
                    <Row >
                        <Col span={24}>
                            <Text style={{ fontWeight: "bold", fontSize: "15px" }}>Propositions :    </Text>

                            <Form.List name="users" >
                                {(fields, { add, remove }) => (
                                    <>
                                        <Space size={[12, 0]} wrap>
                                            <Form.Item>
                                                <Button type="primary" onClick={() => {
                                                    add()
                                                }} block icon={<PlusOutlined />}>
                                                    Ajouter
                                                </Button>
                                            </Form.Item>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <Space
                                                    key={key}
                                                    style={{
                                                        display: 'flex',
                                                    }}
                                                    align="baseline"
                                                >

                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'last']}

                                                    >
                                                        <Input
                                                            style={{ minWidth: "100px" }}
                                                            onChange={(e) => {
                                                                let propsE = exerciceForm.propositions
                                                                propsE[name] = (e.target.value).trim()
                                                                setExerciceForm({ ...exerciceForm, propositions: propsE })
                                                            }} />
                                                    </Form.Item>
                                                    <MinusCircleOutlined onClick={() => {
                                                        remove(name)
                                                        let propsE = exerciceForm.propositions
                                                        propsE.splice(name, 1)
                                                        setExerciceForm({ ...exerciceForm, propositions: propsE })

                                                    }
                                                    } />

                                                </Space>
                                            ))}

                                        </Space>
                                    </>
                                )}
                            </Form.List>

                        </Col>
                    </Row>
                    <Row >
                        <Col span={8}>
                            <Text style={{ fontWeight: "bold", fontSize: "15px" }}>Questions  :    </Text>

                        </Col>
                        <Col span={14}>
                            <Radio.Group
                                onChange={handleQstTypeChanege}
                                value={questionType}
                                disabled={!questionTypeRadio}
                            >
                                <Radio value={"fill"}>Question à remplir</Radio>
                                <Radio value={"replace"}>Question à remplacer</Radio>

                            </Radio.Group>

                        </Col>
                        <Col span={2} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Text style={{ fontWeight: "bold", fontSize: "15px" }}>Défis</Text>
                        </Col>
                    </Row>
                    <Row >
                        <Col span={24}>

                            <ExerciceQuestions exerciceForm={exerciceForm} defisCheck={defisCheck} errorIndexInput={errorIndexInput} errorIndexSelect={errorIndexSelect}
                                questionType={questionType} setQuestionType={setQuestionType} questionsForm={questionsForm} setQuestionsForm={setQuestionsForm} setQuestionTypeRadio={setQuestionTypeRadio} />
                        </Col>
                    </Row>

                </Form>
            </Card>
        </>
    );
}

export default AddCommunExo;