import { createSlice } from "@reduxjs/toolkit";

import { updateMultipleExerciceQuestionUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";

export const SingleExerciceSlice = createSlice({
    name: "questions",
    initialState: {

        updateQuestionsResult: null,
        updateQuestionsError: null,
        isUpdatingQuestions: false,
    },
    reducers: {


        setUpdateQuestionsResult: (state, action) => {
            state.updateQuestionsResult = action.payload;
            state.updateQuestionsError = null;

            state.isUpdatingQuestions = false;
        },

        setUpdateQuestionsError: (state, action) => {
            state.updateQuestionsError = action.payload;
            state.isUpdatingQuestions = false;
            state.updateQuestionsResult = null

        },
        setIsUpdating: (state, action) => {
            state.isUpdatingQuestions = action.payload;
        },
    },
});

export const {
    setUpdateQuestionsResult,
    setUpdateQuestionsError,
    setIsUpdating,

} = SingleExerciceSlice.actions;


export const editQuestions = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: updateMultipleExerciceQuestionUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsUpdating(true));
    const response = axios(config)
        .then(response => {
            message.success(response.data.message)
            dispatch(setUpdateQuestionsResult(response.data));
        })
        .catch((error) => {
            message.error(error.response.data.message)
            dispatch(setUpdateQuestionsError(error.response.data.message));

        });

    return response.data;

}



export default SingleExerciceSlice.reducer;