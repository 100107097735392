import { createSlice } from "@reduxjs/toolkit";

import { addMultipleExerciceQuestiontUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";
import { setAddExerciceResult } from "./addExerciceSlice";

export const AddCommonQstSlice = createSlice({
    name: "addCommonQst",
    initialState: {

        addCommonQstResult: null,
        addCommonQstError: null,
        isAddingCommonQst: false,
    },
    reducers: {
        setAddCommonQstResult: (state, action) => {
            state.addCommonQstResult = action.payload;
            state.addCommonQstError = null;
            state.isAddingCommonQst = false;
        },
        setAddCommonQstError: (state, action) => {
            state.addCommonQstError = action.payload;
            state.isAddingCommonQst = false;
            state.addCommonQstResult = null

        },
        setIsAddingCommonQst: (state, action) => {
            state.isAddingCommonQst = action.payload;
        },
    },
});

export const {
    setAddCommonQstResult,
    setAddCommonQstError,
    setIsAddingCommonQst,

} = AddCommonQstSlice.actions;


export const CreateCommonQst = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: addMultipleExerciceQuestiontUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };
    dispatch(setIsAddingCommonQst(true));
    const response = axios(config)
        .then(response => {

            dispatch(setAddCommonQstResult(response.data));
            dispatch(setAddExerciceResult(null))

        })
        .catch((error) => {
            message.error(error.response.data)
            dispatch(setAddCommonQstError(error.response.data));

        });

    return response.data;

}

export default AddCommonQstSlice.reducer;