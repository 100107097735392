/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Layout, Card, Form, Checkbox, Input, Radio, Divider, InputNumber, Space, Select, Image, Switch, Button, Upload, Popconfirm } from 'antd';
import { PlusOutlined, LoadingOutlined, UploadOutlined, SyncOutlined } from '@ant-design/icons';

import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../comps/pageHeader';
import { fetchUploadItemColorMask, fetchUploadItemTexture, fetchUploadItemThumbnail, fetchUploadSprites, setItemColorMaskUrl, setItemTextureUrl, setItemThumbnailUrl, setSpritesLinksALL } from './addItemSlice';
import { deleteSingleItem, deleteSpriteFile, fetchSingleItem, setFetchUpdateItemResult, UpdateItem, UpdateItemStats } from './singleItemSlice';
import { upload_path } from '../../constants';

// const { Text } = Typography;
const { Content } = Layout;

const statsLabel = {
    attaque: "Attaque", defense: "Défense", vitesse: "Vitesse", technique: "Technique", intelligence: "Intelligence"
}
const gridStyle = {
    width: '33.33%',
    padding: '8px',
    border: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

};

const SingleItem = () => {
    const { id } = useParams();

    let navigate = useNavigate();

    // const pathname = useSelector((state) => state.dashboard.pathname);

    const dispatch = useDispatch();
    const [fileListSprites, setFileList] = useState([

    ]);
    const [itemForm, setItemForm] = useState({
        id: id, category: null,
        name: "", rare: false,
        currency: "", isColorable: false, price: 0,
        sale: false, saleRate: 0, thumbnail: "", texture: "", colorMask: "", status: "active", sprites: []
    })
    const [itemStats, setitemStats] = useState([]);
    const [SwitcherEdit, setSwitcherEdit] = useState(false);
    const [SwitcherEditDisabled, setSwitcherEditDisabled] = useState(false);

    const fetchSingleItemResult = useSelector((state) => state.singleItem.fetchSingleItemResult);

    const fetchUpdateItemResult = useSelector((state) => state.singleItem.fetchUpdateItemResult);
    const isUpdatingItem = useSelector((state) => state.singleItem.isUpdatingItem);

    const itemThumbnailUrl = useSelector((state) => state.addItem.itemThumbnailUrl);
    const isUploadingItemThumbnail = useSelector((state) => state.addItem.isUploadingItemThumbnail);

    const itemTextureUrl = useSelector((state) => state.addItem.itemTextureUrl);
    const isUploadingItemTexture = useSelector((state) => state.addItem.isUploadingItemTexture);

    // const isFetchingUploadSprites = useSelector((state) => state.addItem.isFetchingUploadSprites);
    const spritesUrls = useSelector((state) => state.addItem.spritesUrls);
    const isFetchingUploadSprites = useSelector((state) => state.addItem.isFetchingUploadSprites);

    const deleteSingleItemResult = useSelector((state) => state.singleItem.deleteSingleItemResult);

    const isUploadingItemColorMask = useSelector((state) => state.addItem.isUploadingItemColorMask);
    const itemColorMaskUrl = useSelector((state) => state.addItem.itemColorMaskUrl);
    useEffect(() => {
        dispatch(fetchSingleItem({ id }));
        dispatch(setItemThumbnailUrl(null));
        dispatch(setItemTextureUrl(null));
        dispatch(setSpritesLinksALL([]));
        dispatch(setItemColorMaskUrl(null));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchUpdateItemResult]);
    const handleInputStatsChange = (key, value, index) => {
        let statsTemp = itemStats
        statsTemp[index].value = value ? value : 0
        setitemStats(statsTemp);
        setItemForm({ ...itemForm, stats: statsTemp })
    };
    useEffect(() => {
        if (fetchSingleItemResult) {
            setItemForm({
                ...itemForm, name: fetchSingleItemResult.data.name, category: fetchSingleItemResult.data.category,
                rare: fetchSingleItemResult.data.rare, status: fetchSingleItemResult.data.status,
                price: fetchSingleItemResult.data.price, sale: fetchSingleItemResult.data.sale, saleRate: fetchSingleItemResult.data.saleRate,
                thumbnail: fetchSingleItemResult.data.thumbnail, texture: fetchSingleItemResult.data.texture, colorMask: fetchSingleItemResult.data.colorMask,
                sprites: fetchSingleItemResult.data.sprites, isColorable: fetchSingleItemResult.data.isColorable,
                currency: fetchSingleItemResult.data.currency,

            })
            let newArr = fetchSingleItemResult.data.stats.map(({ _id, value, name }) => ({ id: _id, value, name }));
            let newArrFileList = fetchSingleItemResult.data.sprites.map((spriteUrl, index) => (
                {
                    name: index,
                    status: 'done',
                    url: upload_path + spriteUrl,
                }

            ));
            setFileList(newArrFileList)
            setitemStats(newArr)
            dispatch(setFetchUpdateItemResult(null))
            dispatch(setSpritesLinksALL(fetchSingleItemResult.data.sprites))

        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchSingleItemResult]);

    // const isFetchingItems = useSelector((state) => state.lessons.isFetchingItems);
    useEffect(() => {
        if (itemThumbnailUrl) {
            setItemForm({ ...itemForm, thumbnail: itemThumbnailUrl })
        }
    }, [itemThumbnailUrl]);
    const uploadItemThumbnailProps = {
        beforeUpload: (file) => {

            dispatch(
                fetchUploadItemThumbnail({ folder_path: fetchSingleItemResult?.data.folderPath }, file)
            );
            return false;
        },
    };

    const uploadSpritesProps = {
        beforeUpload: (files) => {
            dispatch(fetchUploadSprites({ folder_path: fetchSingleItemResult?.data.folderPath }, files))
            return false;
        },
        onRemove: (file) => {
            // console.log('file : ', file);
            let newSprites = [...itemForm.sprites]
            newSprites.splice(file.name, 1)
            let spriteUrl = file.url.split('uploads')[1]
            // console.log('spriteUrlspriteUrl ', spriteUrl);
            dispatch(deleteSpriteFile({ filePath: spriteUrl }))
            dispatch(UpdateItem({ id, sprites: newSprites }));
        }
        // fileList: state.fileList,
    };
    useEffect(() => {
        if (spritesUrls.length > 0 && SwitcherEdit) {

            setItemForm({ ...itemForm, sprites: spritesUrls })
            let newArrFileList = spritesUrls.map((spriteUrl, index) => (
                {
                    name: index,
                    status: 'done',
                    url: upload_path + spriteUrl,
                }
            ));
            setFileList(newArrFileList)
            // dispatch(UpdateItem({ id, sprites: spritesUrls }));

        }
    }, [spritesUrls]);
    useEffect(() => {
        if (itemTextureUrl) {
            setItemForm({ ...itemForm, texture: itemTextureUrl })
        }
    }, [itemTextureUrl]);
    const uploadItemTextureProps = {
        beforeUpload: (file) => {
            dispatch(
                fetchUploadItemTexture({ folder_path: fetchSingleItemResult?.data.folderPath }, file)
            );
            return false;
        },
    };
    const uploadItemColorMaskProps = {
        beforeUpload: (file) => {
            dispatch(fetchUploadItemColorMask({ folder_path: fetchSingleItemResult?.data.folderPath }, file));
            return false;
        },
    };
    useEffect(() => {
        if (deleteSingleItemResult) {
            navigate(-1)
        }
    }, [deleteSingleItemResult]);
    useEffect(() => {
        if (itemColorMaskUrl) {
            setItemForm({ ...itemForm, colorMask: itemColorMaskUrl })
        }
    }, [itemColorMaskUrl]);

    const handleConfirmItem = (e) => {
        dispatch(UpdateItemStats({ itemStats }));
        dispatch(UpdateItem({ id, ...itemForm }));
        setSwitcherEdit(false)
        setSwitcherEditDisabled(false)

    };

    const confirmDeleteItem = (e) => {
        console.log(e);
        dispatch(deleteSingleItem({ id }));
    };
    return (

        <Content
            className="site-layout-background"
            style={{
                padding: 0,
                margin: 0,
                minHeight: 280,
            }}
        >
            <PageHeader title={"Editer " + fetchSingleItemResult?.data.name}
                breadcrumbItems={["Création de Contenu", "Avatar", "Liste des Items", fetchSingleItemResult?.data.name]} />

            <Card title={!SwitcherEdit ? "Les information d'item " + fetchSingleItemResult?.data.name : 'Editer un Item'}
                className="cardItem"
                style={{ width: "95%", margin: "24px" }}
                bodyStyle={{ padding: "24px 80px 24px 80px" }}
                extra={

                    <Switch
                        unCheckedChildren="Modifer"
                        disabled={SwitcherEditDisabled}
                        checked={SwitcherEdit}
                        onChange={(checked) => {
                            setSwitcherEdit(checked);
                        }}
                    />}

                actions={[
                    <Popconfirm
                        className="left-button"
                        title="Supprimer l'item"
                        description="Voulez-vous vraiment supprimer cet item?"
                        onConfirm={confirmDeleteItem}
                        // onCancel={cancel}
                        okButtonProps={{ danger: true }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" danger>
                            Supprimer l'item
                        </Button>
                    </Popconfirm>,
                    <Button type="primary"
                        loading={isUpdatingItem}
                        disabled={!SwitcherEdit}
                        onClick={handleConfirmItem}
                    // loading={isUpdatingQuestions}
                    >Confirmer</Button>

                ]}
            >
                <Row justify={"space-between"}>
                    <Col span={11}>
                        <Form onChange={() => {
                            setSwitcherEditDisabled(true)
                        }} disabled={!SwitcherEdit} labelCol={{ span: 10, }} wrapperCol={{ span: 14, }} layout="vertical"
                        >

                            <Form.Item key={"name"} style={{ fontWeight: "normal" }} label="Nom de l’item :">
                                <Input
                                    value={itemForm.name}
                                    // style={{ width: "488px" }}
                                    onChange={(e) => {
                                        setItemForm({ ...itemForm, name: e.target.value })
                                    }}
                                />
                            </Form.Item>
                            <Form.Item key={"rare"}>
                                <Radio.Group value={itemForm.rare}
                                    onChange={(e) => {
                                        setItemForm({ ...itemForm, rare: e.target.value })
                                    }}
                                >

                                    <Radio key={"normal"} value={false}> Normal </Radio>
                                    <Radio key={"raree"} value={true}> Rare </Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Divider />
                        </Form>
                        <Form onChange={() => {
                            setSwitcherEditDisabled(false)
                        }} disabled={!SwitcherEdit} layout="horizontal"  >
                            <Space style={{ width: "100%" }} size={15}>
                                <Form.Item key={"price"}>
                                    <InputNumber
                                        style={{ minWidth: "100px" }}

                                        min={0}
                                        value={itemForm.price}
                                        // style={{ width: "488px" }}
                                        onChange={(value) => {
                                            setItemForm({ ...itemForm, price: value })
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item key={"currencySelect"} >
                                    <Select
                                        disabled={itemForm.price < 1 || !SwitcherEdit}
                                        style={{ minWidth: "120px" }}
                                        onChange={(value) => {
                                            setItemForm({ ...itemForm, currency: value })
                                        }}
                                        value={itemForm.currency}
                                        options={[
                                            {
                                                value: 'coins',
                                                label: 'Pièce',
                                            },
                                            {
                                                value: 'diamonds',
                                                label: 'Diamants',
                                            },
                                        ]}
                                    />
                                </Form.Item>
                                <Form.Item key={"currency"}>
                                    {itemForm.currency === "coins" ?
                                        <Image src="/Coin.svg" width={24} height={24} alt="coin" preview={false} />

                                        :
                                        <Image src="/Diamond.svg" width={24} height={24} alt="coin" preview={false} />

                                    }
                                </Form.Item>

                            </Space>
                            <Space>

                                <Form.Item label="En Soldes :" labelAlign="left" labelCol={{ span: 10 }}
                                    style={{ width: '180px' }}

                                >
                                    <Switch checked={itemForm.sale}
                                        onChange={(checked) => {
                                            setItemForm({
                                                ...itemForm,
                                                sale: checked,
                                            });
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Réduction de :" labelAlign="left">
                                    <InputNumber
                                        disabled={!itemForm.sale || !SwitcherEdit}
                                        step={1}
                                        value={!itemForm.sale ? 0 : itemForm.saleRate * 100}
                                        formatter={(value) => `% ${value}`}
                                        parser={(value) => value.replace('%', '')}
                                        onChange={(value) => {
                                            setItemForm({
                                                ...itemForm,
                                                saleRate: (value / 100),
                                            });
                                        }}
                                    />
                                </Form.Item>

                            </Space>
                            <Divider />

                            <Form onChange={() => {
                                setSwitcherEditDisabled(false)
                            }} disabled={!SwitcherEdit} labelAlign="left" labelCol={{ span: 10, }}  >
                                <Row >

                                    {itemStats.map((item, index) => (
                                        <Col span={11}>
                                            <Form.Item label={statsLabel[item.name]}

                                                key={item.name}>
                                                <InputNumber
                                                    min={0}
                                                    key={item.name}
                                                    value={item.value}
                                                    onChange={(value) => handleInputStatsChange(item, value, index)}
                                                />
                                            </Form.Item>

                                        </Col>
                                    ))}
                                </Row>

                            </Form>
                        </Form>
                    </Col>

                    <Col span={11}>
                        <Form onChange={() => {
                            setSwitcherEditDisabled(false)
                        }} disabled={!SwitcherEdit} labelCol={{ span: 10, }} wrapperCol={{ span: 14, }}
                            layout="vertical">
                            <Form.Item className="upload"
                                label="Miniature :" valuePropName="fileList" rules={[{ required: true, message: 'Voulliez saiser l\'image d\'item!' }]}>

                                {SwitcherEdit ?

                                    <Upload maxCount={1}
                                        disabled={itemThumbnailUrl}
                                        accept="image/*"

                                        {...uploadItemThumbnailProps}
                                        listType="picture-card">
                                        <div>
                                            {isUploadingItemThumbnail ? (<LoadingOutlined />
                                            ) : (<PlusOutlined />)}

                                            <div style={{ marginTop: 8 }}>Upload</div>
                                        </div>
                                    </Upload>
                                    :
                                    <Image
                                        fallback='/sadCactus.svg'
                                        height={100}
                                        src={upload_path + fetchSingleItemResult?.data.thumbnail}
                                    />
                                }
                            </Form.Item>
                            <Form.Item className="upload" label="Texture :" valuePropName="fileList" rules={[{ required: true, message: 'Voulliez saiser l\'image d\'item!' }]}>
                                {SwitcherEdit ?

                                    <Upload
                                        maxCount={1}
                                        disabled={itemTextureUrl}

                                        accept="image/*"
                                        {...uploadItemTextureProps}>
                                        <Button
                                            disabled={itemTextureUrl}
                                            loading={isUploadingItemTexture}
                                            icon={<UploadOutlined />}>Cliquez pour télécharger</Button>
                                    </Upload> :
                                    <Image
                                        height={70}
                                        fallback='/sadCactus.svg'

                                        src={upload_path + fetchSingleItemResult?.data.texture}
                                    />
                                }

                            </Form.Item>
                            <Form.Item className="upload" label="Sprites :" valuePropName="fileList" rules={[{ required: true, message: 'Voulliez saiser l\'image d\'item!' }]}>
                                {SwitcherEdit ?

                                    <Upload
                                        {...uploadSpritesProps}
                                        listType="picture-card"
                                        fileList={fileListSprites}
                                        // onChange={onChangeSprite}

                                        showUploadList={{ showRemoveIcon: true }}
                                    >
                                        {isFetchingUploadSprites ? <SyncOutlined spin />
                                            :
                                            fileListSprites.length < 20 && '+ Upload'
                                        }
                                    </Upload>

                                    :
                                    itemForm.sprites.length > 0 ?
                                        <Card>

                                            {itemForm.sprites.map((prop, index) =>
                                                <Card.Grid key={index} style={gridStyle}>
                                                    <Space direction='vertical' style={{ width: "100%", display: "flex", alignItems: 'center' }}>

                                                        <Image
                                                            height={70}
                                                            fallback='/sadCactus.svg'

                                                            src={upload_path + prop}
                                                        />



                                                    </Space >

                                                </Card.Grid>)}
                                        </Card>
                                        :
                                        <Image
                                            height={70}
                                            src='/sadCactus.svg'
                                            preview={false}
                                        />
                                }

                            </Form.Item>
                            <Divider />

                            <Form.Item className="upload" label="Couleur :" valuePropName="fileList" rules={[{ required: true, message: 'Voulliez saiser l\'image d\'item!' }]}>
                                <Space direction='vertical'
                                    size={10}>
                                    <Checkbox checked={itemForm.isColorable} onChange={(e) => {
                                        setItemForm({ ...itemForm, isColorable: e.target.checked })
                                    }}>Colorable</Checkbox>
                                    {SwitcherEdit ?
                                        <Upload
                                            multiple
                                            disabled={!itemForm.isColorable || itemColorMaskUrl}

                                            accept="image/*"
                                            {...uploadItemColorMaskProps}>
                                            <Button
                                                disabled={!itemForm.isColorable || itemColorMaskUrl}

                                                loading={isUploadingItemColorMask}
                                                icon={<UploadOutlined />}>Cliquez pour télécharger</Button>
                                        </Upload> :
                                        <Image
                                            height={70}
                                            fallback='/sadCactus.svg'

                                            src={upload_path + fetchSingleItemResult?.data.colorMask}
                                        />
                                    }
                                </Space>

                            </Form.Item>
                        </Form>
                    </Col>
                </Row>



            </Card>
        </Content>

    );
}

export default SingleItem;