import { Input, Col, Row, Button, Progress, Radio, Space, Image, DatePicker, message, Checkbox } from "antd";
import { useEffect, useState } from "react";
import { ArrowLeftOutlined } from '@ant-design/icons';
import HorizontalDialog from "../components/HorizontalDialog";
import LevelIcon from "../components/LevelIcon";
import { ComponentStates } from '../insertCode';
import InfoCard from "../components/InfoCard";
import Dialog from "../components/Dialog";
import CalendarIcon from "../components/CalendarIcon";
import 'dayjs/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';

const SignUpStates = {
    StepOne: 1,
    StepTwo: 2,
    StepThree: 3,
    StepFour: 4,
    StepFive: 5,
    StepSix: 6,
    StepSeven: 7,
    StepEight: 8,
    StepNine: 9,
    StepTen: 10,
}

export default function SignUp({ setComponentState }) {
    const [signUpState, setSignUpState] = useState(SignUpStates.StepOne);
    const [messageApi, contextHolder] = message.useMessage();
    const [signUpSuccess, setSignUpSuccess] = useState(null);
    const [signUpError, setSignUpError] = useState(null);
    const [activationError, setActivationError] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [token, setToken] = useState(null);
    const [conditions, setConditions] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    //TODO: Switch this up
    const signUpUrl = "https://cactusfrance.com/api/app/authentication/signup"

    const [isCalOpen, setIsCalOpen] = useState(false);

    const prependZero = (number) => {
        if (number > 9) {
            return number;
        }
        return '0' + number;
    }

    const [signUpData, setSignUpData] = useState({
        email: "",
        name: "",
        newsletter: true,
        rememberMe: true,
        password: "",
        confirmPassword: "",
        dateOfBirth: "",
        deviceToken: "web",
        frenchLevel: 0,
        learningGoals: 0,
        reasonToLearn: 0
    });

    const signUpAndActivate = () => {
        setIsLoading(true);
        fetch(signUpUrl, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "device": "Web"
            },
            body: JSON.stringify(signUpData)
        }).then(async (response) => {
            setIsLoading(false);
            if (response.status !== 200) {
                setErrorMessage((await response.json()).message)
                setSignUpError(true);
                return;
            }
            let data = await response.json();
            setToken(data.token);
            localStorage.setItem("insertUserToken", data.token);

            setSignUpSuccess(true);
        }).catch((response) => {
            setErrorMessage("Une erreur inattendue est survenue, veuillez réessayer plus tard.");
            setSignUpError(true);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        if (!signUpError && !activationError) {
            return;
        }
        messageApi.error(errorMessage);
        setSignUpError(false);
        setActivationError(false);
        setErrorMessage(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signUpError, activationError]);

    useEffect(() => {
        if (!token || !signUpSuccess) {
            return;
        }

        setComponentState(ComponentStates.ActivationScreen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signUpSuccess]);


    //#region SubComponents
    // Step One
    const StepOne = (
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%"

        }}>
            <div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "start",
                    paddingTop: "24px"
                }}>
                    <Button onClick={() => { setComponentState(ComponentStates.ChoiceScreen) }} style={{ margin: 0, marginRight: "1vw", border: "none" }} icon={<ArrowLeftOutlined />} />
                    <Progress percent={25} showInfo={false} strokeColor="#42D83F" style={{ lineHeight: 0, margin: 0 }} />
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "start",
                    marginTop: "5vh"
                }}>
                    <HorizontalDialog fontSize={"14px"} text="Quel est ton niveau de français  ?" />
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "start",
                    marginTop: "5vh"
                }}>
                    <Radio.Group onChange={(e) => setSignUpData({ ...signUpData, frenchLevel: e.target.value })} value={signUpData.frenchLevel} style={{ width: "100%" }}>
                        <Space direction="vertical" style={{ width: "100%", gap: "24px" }}>
                            <Radio.Button className="RadioButton" value={0}> <LevelIcon level={0} />Je suis un débutant</Radio.Button>
                            <Radio.Button className="RadioButton" value={1}> <LevelIcon level={1} />Je connais quelques règles</Radio.Button>
                            <Radio.Button className="RadioButton" value={2}> <LevelIcon level={2} />Je possède un bon niveau</Radio.Button>
                            <Radio.Button className="RadioButton" value={3}> <LevelIcon level={3} />Je suis un expert</Radio.Button>
                        </Space>
                    </Radio.Group>
                </div>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                width: "100%",
                marginBottom: "3vh"
            }}>
                <Button className="customButton" onClick={() => setSignUpState(SignUpStates.StepTwo)} style={{ color: "#fff", backgroundColor: "#39B13D", borderRadius: "100px", width: "100%" }}>CONTINUER</Button>
            </div>
        </div>
    )

    // Step Two
    const StepTwo = (<div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%"

    }}>
        <div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                paddingTop: "24px"
            }}>
                <Button onClick={() => { setSignUpState(SignUpStates.StepOne) }} style={{ margin: 0, marginRight: "1vw", border: "none" }} icon={<ArrowLeftOutlined />} />
                <Progress percent={50} showInfo={false} strokeColor="#42D83F" style={{ lineHeight: 0, margin: 0 }} />
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                marginTop: "5vh"
            }}>
                <HorizontalDialog fontSize={"14px"} text="Pourquoi étudies-tu le français ?" />
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                marginTop: "5vh"
            }}>
                <Radio.Group onChange={(e) => setSignUpData({ ...signUpData, reasonToLearn: e.target.value })} value={signUpData.reasonToLearn} style={{ width: "100%" }}>
                    <Space direction="vertical" style={{ width: "100%", gap: "24px" }}>
                        <Radio.Button
                            className="RadioButton"
                            style={{
                                flexDirection: "row", justifyContent: "flex-start",
                                width: "100%", alignItems: "center"
                            }} value={0}>
                            <div style={{ display: "flex", flexDirection: "row", alignContent: "center" }}><p style={{ margin: "0px 8px 0px 0px" }}>😁 </p> <p style={{ margin: 0 }}>Juste pour le fun</p></div>
                        </Radio.Button>
                        <Radio.Button
                            className="RadioButton"
                            style={{
                                flexDirection: "row", justifyContent: "flex-start",
                                width: "100%", alignItems: "center", height: "100%", paddingTop: "8px", paddingBottom: "8px"
                            }}
                            value={2}
                        >
                            <div style={{ display: "flex", flexDirection: "row", alignContent: "center" }}><p style={{ margin: "0px 8px 0px 0px", alignContent: "center" }}>🎓</p> <p style={{ margin: 0 }}>Perfectionner mon apprentissage scolaire</p></div>
                        </Radio.Button>
                        <Radio.Button
                            className="RadioButton"
                            style={{
                                flexDirection: "row", justifyContent: "flex-start",
                                width: "100%", alignItems: "center"
                            }}
                            value={1}
                        >
                            <div style={{ display: "flex", flexDirection: "row", alignContent: "center" }}><p style={{ margin: "0px 8px 0px 0px", alignContent: "center" }}>💼</p> <p style={{ margin: 0 }}>Améliorer ma carrière professionnelle</p></div>
                        </Radio.Button>
                        <Radio.Button
                            className="RadioButton"
                            style={{
                                flexDirection: "row", justifyContent: "flex-start",
                                width: "100%", alignItems: "center"
                            }}
                            value={5}
                        >
                            <div style={{ display: "flex", flexDirection: "row", alignContent: "center" }}><p style={{ margin: "0px 8px  0px 0px", alignContent: "center" }}>💬</p> <p style={{ margin: 0 }}>Tester mon niveau</p></div>
                        </Radio.Button>
                    </Space>
                </Radio.Group>
            </div>
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
            marginBottom: "3vh"
        }}>
            <Button className="customButton" onClick={() => setSignUpState(SignUpStates.StepThree)} style={{ color: "#fff", backgroundColor: "#39B13D", borderRadius: "100px", width: "100%" }}>CONTINUER</Button>
        </div>
    </div>)

    // Step Three
    const StepThree = (<div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%"
    }}>
        <div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                paddingTop: "24px"
            }}>
                <Button onClick={() => { setSignUpState(SignUpStates.StepTwo) }} style={{ margin: 0, marginRight: "1vw", border: "none" }} icon={<ArrowLeftOutlined />} />
                <Progress percent={75} showInfo={false} strokeColor="#42D83F" style={{ lineHeight: 0, margin: 0 }} />
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                marginTop: "5vh"
            }}>
                <HorizontalDialog fontSize={"12px"} text="Voici ce que tu peux réaliser avec cactus" />
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                marginTop: "24px"
            }}>
                <InfoCard style={{ width: "100%" }} />
            </div>
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
            marginBottom: "3vh"
        }}>
            <Button className="customButton" onClick={() => setSignUpState(SignUpStates.StepFour)} style={{ color: "#fff", backgroundColor: "#39B13D", borderRadius: "100px", width: "100%" }}>CONTINUER</Button>
        </div>
    </div>)

    // Step Four
    const StepFour = (<div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%"

    }}>
        <div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                paddingTop: "24px"
            }}>
                <Button onClick={() => { setSignUpState(SignUpStates.StepThree) }} style={{ margin: 0, marginRight: "1vw", border: "none" }} icon={<ArrowLeftOutlined />} />
                <Progress percent={100} showInfo={false} strokeColor="#42D83F" style={{ lineHeight: 0, margin: 0 }} />
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                marginTop: "5vh"
            }}>
                <HorizontalDialog fontSize={"14px"} text="Quel est ton objectif quotidien ?" />
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                marginTop: "5vh"
            }}>
                <Radio.Group onChange={(e) => setSignUpData({ ...signUpData, learningGoals: e.target.value })} value={signUpData.learningGoals} style={{ width: "100%" }}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Radio.Button className="RadioButton" value={0}>5 mins / jour</Radio.Button>
                        <Radio.Button className="RadioButton" value={1}>10 mins / jour</Radio.Button>
                        <Radio.Button className="RadioButton" value={2}>15 mins / jour</Radio.Button>
                        <Radio.Button className="RadioButton" value={3}>30 mins / jour</Radio.Button>
                    </Space>
                </Radio.Group>
            </div>
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
            marginBottom: "3vh"
        }}>
            <Button className="customButton" onClick={() => setSignUpState(SignUpStates.StepFive)} style={{ color: "#fff", backgroundColor: "#39B13D", borderRadius: "100px", width: "100%" }}>CONTINUER</Button>
        </div>
    </div>)

    // Step Five
    const StepFive = (<div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%"

    }}>
        <div >
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                paddingTop: "24px"
            }}>
                <Button onClick={() => { setSignUpState(SignUpStates.StepFour) }} style={{ margin: 0, marginRight: "1vw", border: "none" }} icon={<ArrowLeftOutlined />} />
            </div>
            <Row style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "8vh"
            }}>
                <Dialog text="Hourra !!" />
            </Row>
            <Row style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
                <Image
                    src="/activation/Lilly.png"
                    preview={false} />
            </Row>
            <Row style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "2vh"
            }}>
                <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "20px", color: "#616161" }}>Crée un profil maintenant pour pouvoir enregistrer ta progression et te connecter avec tes amis.</p>
            </Row>
        </div>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginBottom: "3vh"
        }}>
            <Button className="customButton" onClick={() => { setSignUpState(SignUpStates.StepSix); console.log(signUpData) }} style={{ color: "#fff", backgroundColor: "#39B13D", borderRadius: "100px", width: "100%" }}>CONTINUER</Button>
        </Row>
    </div>)

    // Step Six
    const StepSix = (<div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%"

    }}>
        <div style={{ width: "100%" }}>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                paddingTop: "24px"
            }}>
                <Button style={{ margin: 0, marginRight: "1vw", border: "none" }} icon={<ArrowLeftOutlined />} />
                <Progress percent={25} showInfo={false} strokeColor="#42D83F" style={{ lineHeight: 0, margin: 0 }} />
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                marginTop: "5vh"
            }}>
                <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "28px", color: "#212121" }}>Quel est ton nom? 🧑 👩</p>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
                marginTop: "0vh"
            }}>
                <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "18px", color: "#212121" }}>Nom et prénom</p>
                <Input rootClassName="inputField" style={{ width: "100%" }} value={signUpData.name} onChange={(e) => setSignUpData({ ...signUpData, name: e.target.value })} bordered={false}></Input>
            </div>
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
            marginBottom: "3vh"
        }}>
            <Button className="customButton" onClick={() => setSignUpState(SignUpStates.StepSeven)} style={{ color: "#fff", backgroundColor: "#39B13D", borderRadius: "100px", width: "100%" }}>CONTINUER</Button>
        </div>
    </div>)

    // Step Seven
    const StepSeven = (<div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%"

    }}>
        <div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                paddingTop: "24px"
            }}>
                <Button onClick={() => setSignUpState(SignUpStates.StepSix)} style={{ margin: 0, marginRight: "1vw", border: "none" }} icon={<ArrowLeftOutlined />} />
                <Progress percent={50} showInfo={false} strokeColor="#42D83F" style={{ lineHeight: 0, margin: 0 }} />
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                marginTop: "5vh"
            }}>
                <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "28px", color: "#212121" }}>Quel âge as-tu? 🎂</p>
            </div>
            <Row style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                marginTop: "0vh"
            }}>
                <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "18px", color: "#212121" }}>Date de naissance </p>
                {/*// TODO: Sanitize this input*/}
                <div style={{ flexDirection: "column", justifyContent: "flex-start", width: "100%" }}>
                    <Input
                        rootClassName="inputField"
                        style={{ width: "100%" }}
                        value={signUpData.dateOfBirth}
                        // onChange={(e) => { setSignUpData({ ...signUpData, dateOfBirth: e.target.value }) }}
                        suffix={
                            <Button className="calendarButton" onClick={() => setIsCalOpen(!isCalOpen)} icon={<CalendarIcon />}></Button>
                        }
                        bordered={false}
                        placeholder="aaaa-mm-jj"
                    />
                    <DatePicker locale={locale} onChange={(e) => setSignUpData({ ...signUpData, dateOfBirth: `${e.year()}-${prependZero(e.month() + 1)}-${prependZero(e.date())}` })} placeholder="" open={isCalOpen} style={{ opacity: 0, border: "none", textIndent: "-9999px" }} aria-hidden="true" />
                </div>
            </Row>
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
            marginBottom:"3vh"
        }}>
            <Button className="customButton" onClick={() => setSignUpState(SignUpStates.StepEight)} style={{ color: "#fff", backgroundColor: "#39B13D", borderRadius: "100px", width: "100%" }}>CONTINUER</Button>
        </div>
    </div>)

    // Step Eight
    const StepEight = (<div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%"

    }}>
        <div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            paddingTop: "24px"
        }}>
            <Button onClick={() => setSignUpState(SignUpStates.StepSeven)} style={{ margin: 0, marginRight: "1vw", border: "none" }} icon={<ArrowLeftOutlined />} />
            <Progress percent={75} showInfo={false} strokeColor="#42D83F" style={{ lineHeight: 0, margin: 0 }} />
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "5vh"
        }}>
            <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "24px", color: "#212121" }}>Quelle est ton adresse email? ✉️</p>
        </div>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "0vh"
        }}>
            <div style={{ flexDirection: "column", justifyContent: "flex-start", alignContent: "flex-start", width: "100%" }}>
                <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "18px", width: "fit-content", color: "#212121" }}>E-mail </p>
                <Input rootClassName="inputField" style={{ width: "100%" }} value={signUpData.email} onChange={(e) => { setSignUpData({ ...signUpData, email: e.target.value }) }} bordered={false} placeholder="johndoe@gmail.com"></Input>
            </div>
        </Row>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "2.5vh"
        }}>
            <Checkbox checked={conditions} onChange={(e) => setConditions(e.target.checked)} ><span style={{ fontSize: "14px" }}>Accepter nos conditions d’utilisation générales.</span></Checkbox>
        </Row>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "2.5vh"
        }}>
            <Checkbox checked={signUpData.newsletter} onChange={(e) => setSignUpData({ ...signUpData, newsletter: e.target.checked })}><span style={{ fontSize: "16px" }}>Accepter de recevoir notre newsletter.</span></Checkbox>
        </Row>
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
            marginBottom: "3vh"
        }}>
            <Button className="customButton" disabled={!conditions} onClick={() => setSignUpState(SignUpStates.StepNine)} style={{ color: "#fff", backgroundColor: conditions ? "#39B13D" : "#39B13D81", borderRadius: "100px", width: "100%" }}>CONTINUER</Button>
        </div>
    </div>)

    // Step Nine
    const StepNine = (<div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%"

    }}>
        <div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            paddingTop: "24px"
        }}>
            <Button onClick={() => setSignUpState(SignUpStates.StepEight)} style={{ margin: 0, marginRight: "1vw", border: "none" }} icon={<ArrowLeftOutlined />} />
            <Progress percent={100} showInfo={false} strokeColor="#42D83F" style={{ lineHeight: 0, margin: 0 }} />
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "5vh"
        }}>
            <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "28px", color: "#212121" }}>Crée un mot de passe 🔐</p>
        </div>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "0vh"
        }}>
            <div style={{ flexDirection: "column", justifyContent: "flex-start", alignContent: "flex-start", width: "100%" }}>
                <p style={{ lineHeight: "normal", width: "fit-content", fontWeight: "700", fontSize: "18px", color: "#212121" }}>Mot de passe </p>
                <Input.Password
                    rootClassName="inputField"
                    style={{ width: "100%" }}
                    value={signUpData.password}
                    onChange={(e) => { setSignUpData({ ...signUpData, password: e.target.value }) }}
                    bordered={false}
                    rules={[{
                        required: true,
                        message: 'Veuillez insérer votre mot de passe!'
                    }]}
                ></Input.Password>
            </div>
        </Row>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "0vh"
        }}>
            <div style={{ flexDirection: "column", justifyContent: "flex-start", alignContent: "flex-start", width: "100%" }}>
                <p style={{ lineHeight: "normal", width: "fit-content", fontWeight: "700", fontSize: "18px", color: "#212121" }}>Confirme ton mot de passe </p>
                <Input.Password
                    rootClassName="inputField"
                    style={{ width: "100%" }}
                    value={signUpData.confirmPassword}
                    onChange={(e) => { setSignUpData({ ...signUpData, confirmPassword: e.target.value }) }}
                    bordered={false}
                    rules={[{
                        required: true,
                        message: 'Veuillez insérer votre mot de passe!'
                    }]}
                ></Input.Password>
            </div>
        </Row>
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
            marginBottom: "3vh"
        }}>
            <Button loading={isLoading} className="customButton" onClick={signUpAndActivate} style={{ color: "#fff", backgroundColor: "#39B13D", borderRadius: "100px", width: "100%" }}>CONTINUER</Button>
        </div>
    </div>)

    // Step Ten
    const StepTen = (<>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "8vh"
        }}>
            <Dialog text="Hourra !!" />
        </Row>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Image
                src="/activation/Lilly.png"
                preview={false} />
        </Row>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "0vh"
        }}>
            <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "40px", color: "#424242" }}>Bienvenue 👋</p>
        </Row>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "0vh"
        }}>
            {/* <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "20px", color: "#616161" }}>Ton profil a été créé avec succès, et tu as débloqué {getModulesText()} !</p> */}
        </Row>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10vh"
        }}>
            {/* <a href="https://app.cactusfrance.com/"> */}
            <Button onClick={() => setComponentState(ComponentStates.ActivationScreen)} className="customButton" style={{ color: "#fff", backgroundColor: "#39B13D", borderRadius: "100px" }}>CONTINUER VERS LE CODE D'ACTIVATION</Button>
            {/* </a> */}
        </Row>
    </>)
    //#endregion SubComponents

    let StepToRender;
    switch (signUpState) {
        case SignUpStates.StepOne:
            StepToRender = StepOne;
            break;
        case SignUpStates.StepTwo:
            StepToRender = StepTwo;
            break;
        case SignUpStates.StepThree:
            StepToRender = StepThree;
            break;
        case SignUpStates.StepFour:
            StepToRender = StepFour;
            break;
        case SignUpStates.StepFive:
            StepToRender = StepFive;
            break;
        case SignUpStates.StepSix:
            StepToRender = StepSix;
            break;
        case SignUpStates.StepSeven:
            StepToRender = StepSeven;
            break;
        case SignUpStates.StepEight:
            StepToRender = StepEight;
            break;
        case SignUpStates.StepNine:
            StepToRender = StepNine;
            break;
        case SignUpStates.StepTen:
            StepToRender = StepTen;
            break;
        default:
            StepToRender = StepOne;
            break;
    }

    return (
        <Col style={{
            width: "100%",
            padding: "0px 24px",
            position: "absolute",
            height: "100%",
            top: "0"
        }}>
            {contextHolder}
            {StepToRender}
        </Col>
    )
}