import Icon from '@ant-design/icons';
import { Card, Divider } from 'antd';

const Bubble = () => (
    <svg viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.666992 19.0275C0.666992 9.36968 8.38533 0.666626 19.037 0.666626C29.4503 0.666626 37.3337 9.20443 37.3337 18.9724C37.3337 30.3011 28.0937 37.3333 19.0003 37.3333C15.9937 37.3333 12.657 36.5254 9.98032 34.9464C9.04533 34.3772 8.25699 33.9549 7.24866 34.2854L3.54533 35.387C2.61033 35.6808 1.76699 34.9464 2.04199 33.9549L3.27033 29.8421C3.47199 29.2729 3.43533 28.667 3.14199 28.1896C1.56533 25.2886 0.666992 22.1121 0.666992 19.0275ZM16.617 19.0275C16.617 20.3311 17.662 21.3777 18.9637 21.3961C20.2653 21.3961 21.3103 20.3311 21.3103 19.0459C21.3103 17.7422 20.2653 16.6957 18.9637 16.6957C17.6803 16.6773 16.617 17.7422 16.617 19.0275ZM25.0687 19.0459C25.0687 20.3311 26.1137 21.3961 27.4153 21.3961C28.717 21.3961 29.762 20.3311 29.762 19.0459C29.762 17.7422 28.717 16.6957 27.4153 16.6957C26.1137 16.6957 25.0687 17.7422 25.0687 19.0459ZM10.512 21.3961C9.22866 21.3961 8.16533 20.3311 8.16533 19.0459C8.16533 17.7422 9.21033 16.6957 10.512 16.6957C11.8137 16.6957 12.8587 17.7422 12.8587 19.0459C12.8587 20.3311 11.8137 21.3777 10.512 21.3961Z" fill="#EE59A0" />
    </svg>

)
const Document = () => (
    <svg viewBox="0 0 32 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3642 27.0483H20.2388C20.983 27.0483 21.6002 26.425 21.6002 25.6733C21.6002 24.9216 20.983 24.3166 20.2388 24.3166H10.3642C9.61996 24.3166 9.0028 24.9216 9.0028 25.6733C9.0028 26.425 9.61996 27.0483 10.3642 27.0483ZM16.4995 15.15H10.3642C9.61996 15.15 9.0028 15.7733 9.0028 16.525C9.0028 17.2766 9.61996 17.8816 10.3642 17.8816H16.4995C17.2437 17.8816 17.8609 17.2766 17.8609 16.525C17.8609 15.7733 17.2437 15.15 16.4995 15.15ZM29.4534 13.5469C29.8801 13.542 30.3447 13.5366 30.7668 13.5366C31.2206 13.5366 31.5837 13.9033 31.5837 14.3616V29.1016C31.5837 33.6483 27.9351 37.3333 23.4335 37.3333H8.98465C4.26518 37.3333 0.416992 33.465 0.416992 28.6983V8.93496C0.416992 4.38829 4.08366 0.666626 8.60346 0.666626H18.2965C18.7685 0.666626 19.1315 1.05163 19.1315 1.50996V7.41329C19.1315 10.7683 21.8724 13.5183 25.1942 13.5366C25.9701 13.5366 26.6541 13.5424 27.2528 13.5475C27.7185 13.5514 28.1325 13.555 28.4978 13.555C28.7562 13.555 29.0912 13.5511 29.4534 13.5469ZM29.954 10.871C28.4619 10.8765 26.703 10.871 25.4378 10.8581C23.4302 10.8581 21.7766 9.18796 21.7766 7.16029V2.32763C21.7766 1.53746 22.7259 1.14513 23.2687 1.71529C24.2511 2.747 25.6012 4.16521 26.945 5.57691C28.2853 6.98484 29.6195 8.38631 30.5766 9.39146C31.1066 9.94696 30.7182 10.8691 29.954 10.871Z" fill="#EE59A0" />
    </svg>

)
const Time = () => (
    <svg viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0003 37.3335C8.88033 37.3335 0.666992 29.1385 0.666992 19.0002C0.666992 8.8802 8.88033 0.66687 19.0003 0.66687C29.1387 0.66687 37.3337 8.8802 37.3337 19.0002C37.3337 29.1385 29.1387 37.3335 19.0003 37.3335ZM24.8487 25.8019C25.0687 25.9302 25.307 26.0035 25.5637 26.0035C26.022 26.0035 26.4803 25.7652 26.737 25.3252C27.122 24.6835 26.9203 23.8402 26.2603 23.4369L19.7337 19.5502V11.0802C19.7337 10.3102 19.1103 9.7052 18.3587 9.7052C17.607 9.7052 16.9837 10.3102 16.9837 11.0802V20.3385C16.9837 20.8152 17.2403 21.2552 17.662 21.5119L24.8487 25.8019Z" fill="#EE59A0" />
    </svg>
)

export default function InfoCard(props) {

    const BubbleIcon = (props) => <Icon component={Bubble} {...props} />
    const DocumentIcon = (props) => <Icon component={Document} {...props} />
    const TimeIcon = (props) => <Icon component={Time} {...props} />

    return (
        <Card {...props}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems:"center",
                    width: "100%",
                    lineHeight: "normal"
                }}
            >
                <BubbleIcon
                    style={{
                        width: '30px',
                        height: '30px'
                    }}
                />
                <div
                    style={{
                        width:"100%",
                        flexDirection:"column",
                        alignItems:"center",
                        textAlign:"start",
                        marginLeft:"2vw"
                    }}
                >
                    <h2 style={{fontWeight:"700", color:"#212121", fontSize:"20px"}}>Parler couramment et en toute confiance</h2>
                    <p style={{fontWeight:"500", fontSize:"16px", color:"#616161"}}>Plus de 4000 exercices interactifs sans stress</p>
                </div>
            </div>
            <Divider />
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems:"center",
                    width: "100%",
                    lineHeight: "normal"
                }}
            >
                <DocumentIcon
                    style={{
                        width: '30px',
                        height: '30px'
                    }}
                />
                <div
                    style={{
                        width:"100%",
                        flexDirection:"column",
                        alignItems:"center",
                        textAlign:"start",
                        marginLeft:"2vw"
                    }}
                >
                    <h2 style={{fontWeight:"700", color:"#212121", fontSize:"20px"}}>Maîtriser beaucoup de vocabulaire</h2>
                    <p style={{fontWeight:"500", fontSize:"16px", color:"#616161"}}>Plus de 2000 mots et phrases pratiques</p>
                </div>
            </div>
            <Divider />
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems:"center",
                    width: "100%",
                    lineHeight: "normal"
                }}
            >
                <TimeIcon
                    style={{
                        width: '30px',
                        height: '30px'
                    }}
                />
                <div
                    style={{
                        width:"100%",
                        flexDirection:"column",
                        alignItems:"center",
                        textAlign:"start",
                        marginLeft:"2vw"
                    }}
                >
                    <h2 style={{fontWeight:"700", color:"#212121", fontSize:"20px"}}>Cultiver des habitudes d'étude</h2>
                    <p style={{fontWeight:"500", fontSize:"16px", color:"#616161"}}>Rappels intelligents, défis amusants et plus encore</p>
                </div>
            </div>
        </Card>
    )
}