import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import axios from 'axios';
import {  add_admin_url, getContractsUrl } from '../../constants';


export const ContractsSlice = createSlice({
    name: 'contracts',
    initialState: {

        fetchServerErr: null,
        fetchContractsResult: null,
        fetchContractsError: null,
        isFetchingContracts: false,

    },
    reducers: {
        setFetchContractsResult: (state, action) => {
            state.fetchContractsResult = action.payload;
            state.isFetchingContracts = false;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setFetchContractsError: (state, action) => {
            state.fetchContractsError = action.payload;
            state.isFetchingContracts = false;
            state.fetchContractsResult = null

        },
        setfetchServerErr: (state, action) => {
            state.fetchServerErr = action.payload
        },

        setConnected: (state, action) => {
            state.isConnected = action.payload;
        },
        setIsFetchingContracts: (state, action) => {
            state.isFetchingContracts = action.payload;
        },
    }
});

export const {

    setfetchServerErr,

    setFetchContractsResult,
    setFetchContractsError,
    setConnected,
    setIsFetchingContracts,

} = ContractsSlice.actions;

export const fetchContracts = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getContractsUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data

    };
    dispatch(setIsFetchingContracts(true));
    const response = axios(config)
        .then(response => {
            dispatch(setFetchContractsResult(response.data));
        })
        .catch(err => {
            if (err.respons) {
                dispatch(setFetchContractsError(err.response.data.error));
            }
            else {
                dispatch(setfetchServerErr('Err'))
            }
        });

    return response.data;

}
export const AddnewContract = (data) => async (dispatch, getState) => {
    const config = {
      method: "post",
      url: add_admin_url,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.token,
      },
      data,
    };
    console.log("add Contract ", data);
  
    const response = await axios(config)
      .then((response) => {
        message.success(response.data.message)
        dispatch(fetchContracts());
      })
      .catch((error) => {
        message.error(error.response.data.message)
      });
    console.log(response);
    return response;
  };



export default ContractsSlice.reducer;