import { createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import {  
    addClientToContractUrl, getSingleContractUrl, updateIndividualContractUrl,
    suspendContractUrl, suspendSingleClientFromAContractUrl
} from '../../constants';
import { message } from "antd";

export const ContractSlice = createSlice({
    name: 'contract',
    initialState: {

        singleContractSuccess: null,
        singleContractError: null,
        isFetchingSingleContract: false,

        individualContractUpdateSuccess: null,
        individualContractUpdateError: null,
        isUpdatingIndividualContract: false,

        addClientToContractSuccess: null,
        addClientToContractError: null,
        isAddingClientToContract: false,

        suspendContractSuccess: null,
        suspendContractError: null,
        isSuspendingContract: false,

        suspendSingleClientFromContractSuccess: null,
        suspendSingleClientFromContractError: null,
        isSuspendingSingleClientFromContract: false,

    },
    reducers: {
        setSingleContractSuccess: (state, action) => {
            state.singleContractSuccess = action.payload;
            state.singleContractError = null;
            state.isFetchingSingleContract = false;
        },
        setSingleContractError: (state, action) => {
            state.singleContractError = action.payload;
            state.singleContractSuccess = null;
            state.isFetchingSingleContract = false;
        },
        setIsFetchingContract: (state, action) => {
            state.isFetchingSingleContract = action.payload;
        },

        setIndividualContractUpdateSuccess: (state, action) => {
            state.individualContractUpdateSuccess = action.payload;
            state.individualContractUpdateError = null;
            state.isUpdatingIndividualContract = false;
        },
        setIndividualContractUpdateError: (state, action) => {
            state.individualContractUpdateError = action.payload;
            state.individualContractUpdateSuccess = null;
            state.isUpdatingIndividualContract = false;
        },
        setIsUpdatingIndividualContract: (state, action) => {
            state.isUpdatingIndividualContract = action.payload;
        },
        
        setAddClientToContractSuccess: (state, action) => {
            state.addClientToContractSuccess = action.payload;
            state.addClientToContractError = null;
            state.isAddingClientToContract = false;
        },
        setAddClientToContractError: (state, action) => {
            state.addClientToContractError = action.payload;
            state.addClientToContractSuccess = null;
            state.isAddingClientToContract = false;
        },
        setIsAddingClientToContract: (state, action) => {
            state.isAddingClientToContract = action.payload;
        },
        
        setSuspendContractSuccess: (state, action) => {
            state.suspendContractSuccess = action.payload;
            state.suspendContractError = null;
            state.isSuspendingContract = false;
        },
        setSuspendContractError: (state, action) => {
            state.suspendContractError = action.payload;
            state.suspendContractSuccess = null;
            state.isSuspendingContract = false;
        },
        setIsSuspendingContract: (state, action) => {
            state.isSuspendingContract = action.payload;
        },
        
        setSuspendSingleClientFromContractSuccess: (state, action) => {
            state.suspendSingleClientFromContractSuccess = action.payload;
            state.suspendSingleClientFromContractError = null;
            state.isSuspendingSingleClientFromContract = false;
        },
        setSuspendSingleClientFromContractError: (state, action) => {
            state.suspendSingleClientFromContractError = action.payload;
            state.suspendSingleClientFromContractSuccess = null;
            state.isSuspendingSingleClientFromContract = false;
        },
        setIsSuspendingSingleClientFromContract: (state, action) => {
            state.isSuspendingSingleClientFromContract = action.payload;
        },

    }
});

export const {

    setSingleContractSuccess,
    setSingleContractError,
    setIsFetchingContract,

    setIndividualContractUpdateSuccess,
    setIndividualContractUpdateError,
    setIsUpdatingIndividualContract,

    setAddClientToContractSuccess,
    setAddClientToContractError,
    setIsAddingClientToContract,

    setSuspendContractSuccess,
    setSuspendContractError,
    setIsSuspendingContract,

    setSuspendSingleClientFromContractSuccess,
    setSuspendSingleClientFromContractError,
    setIsSuspendingSingleClientFromContract,

} = ContractSlice.actions;

export const fetchContract = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getSingleContractUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data

    };
    dispatch(setIsFetchingContract(true));
    const response = axios(config)
        .then(response => {
            dispatch(setSingleContractSuccess(response.data));
        })
        .catch(err => {
            if (err.respons) {
                dispatch(setSingleContractError(err.response.data.error));
            }
            else {
                dispatch(setSingleContractError('Err'))
            }
        });

    return response.data;

}

export const updateIndividualContract = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: updateIndividualContractUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data

    };
    dispatch(setIsUpdatingIndividualContract(true));
    const response = axios(config)
        .then(response => {
            
            dispatch(setIndividualContractUpdateSuccess(response.data));
        })
        .catch(err => {
            if (err.respons) {
                dispatch(setIndividualContractUpdateError(err.response.data.error));
            }
            else {
                dispatch(setIndividualContractUpdateError('Err'))
            }
        });

    return response.data;

}

export const AddClientToContract = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: addClientToContractUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data

    };
    dispatch(setIsAddingClientToContract(true));
    const response = axios(config)
        .then(response => {
            
            dispatch(setAddClientToContractSuccess(response.data));
        })
        .catch(err => {
            if (err.respons) {
                dispatch(setAddClientToContractError(err.response.data.error));
            }
            else {
                dispatch(setAddClientToContractError('Err'))
            }
        });

    return response.data;

}

export const SuspendContract = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: suspendContractUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data

    };
    dispatch(setIsSuspendingContract(true));
    const response = axios(config)
        .then(response => {
            
            dispatch(setSuspendContractSuccess(response.data));
        })
        .catch(err => {
            if (err.respons) {
                dispatch(setSuspendContractError(err.response.data.error));
            }
            else {
                dispatch(setSuspendContractError('Err'))
            }
        });

    return response.data;

}

export const SuspendClientFromContract = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: suspendSingleClientFromAContractUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data

    };
    dispatch(setIsSuspendingSingleClientFromContract(true));
    const response = axios(config)
        .then(response => {
            
            dispatch(setSuspendSingleClientFromContractSuccess(response.data));
        })
        .catch(err => {
            if (err.response) {
                message.error(err.response.data.error);
                dispatch(setSuspendSingleClientFromContractError(err.response.data.error));
            }
            else {
                message.error("Une erreur inattendue est survenue.");
                dispatch(setSuspendSingleClientFromContractError('Err'))
            }
        });

    return response.data;

}

export default ContractSlice.reducer;